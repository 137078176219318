var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "auth-form--container text-center" }, [
    _c("div", { staticClass: "auth-form" }, [
      _c("form", { staticClass: "container", attrs: { autocomplete: "off" } }, [
        _c("h2", [_vm._v("Create an Account")]),
        _vm._v(" "),
        _c("p", { staticClass: "hint-text" }, [
          _vm._v("Sign up with your social media account or email address")
        ]),
        _vm._v(" "),
        _vm._m(0),
        _vm._v(" "),
        _vm._m(1),
        _vm._v(" "),
        _c("div", { staticClass: "row" }, [
          _c("div", { staticClass: "col-12 col-md-6 col-lg-6 col-xl-6" }, [
            _c("div", { staticClass: "form-group" }, [
              _c("input", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.form.first_name,
                    expression: "form.first_name"
                  }
                ],
                staticClass: "form-control input-lg",
                attrs: { type: "text", placeholder: "First Name" },
                domProps: { value: _vm.form.first_name },
                on: {
                  input: function($event) {
                    if ($event.target.composing) {
                      return
                    }
                    _vm.$set(_vm.form, "first_name", $event.target.value)
                  }
                }
              }),
              _vm._v(" "),
              _vm.errors.first_name
                ? _c("div", { staticClass: "text-danger text-left" }, [
                    _vm._v(
                      "\n              " +
                        _vm._s(_vm.errors.first_name[0]) +
                        "\n            "
                    )
                  ])
                : _vm._e()
            ])
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "col-12 col-md-6 col-lg-6 col-xl-6" }, [
            _c("div", { staticClass: "form-group" }, [
              _c("input", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.form.last_name,
                    expression: "form.last_name"
                  }
                ],
                staticClass: "form-control input-lg",
                attrs: { type: "text", placeholder: "Last Name" },
                domProps: { value: _vm.form.last_name },
                on: {
                  input: function($event) {
                    if ($event.target.composing) {
                      return
                    }
                    _vm.$set(_vm.form, "last_name", $event.target.value)
                  }
                }
              }),
              _vm._v(" "),
              _vm.errors.last_name
                ? _c("div", { staticClass: "text-danger text-left" }, [
                    _vm._v(
                      "\n              " +
                        _vm._s(_vm.errors.last_name[0]) +
                        "\n            "
                    )
                  ])
                : _vm._e()
            ])
          ])
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "row" }, [
          _c("div", { staticClass: "col-12" }, [
            _c("div", { staticClass: "form-group" }, [
              _c("input", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.form.email,
                    expression: "form.email"
                  }
                ],
                staticClass: "form-control input-lg",
                attrs: { type: "email", placeholder: "Email address" },
                domProps: { value: _vm.form.email },
                on: {
                  input: function($event) {
                    if ($event.target.composing) {
                      return
                    }
                    _vm.$set(_vm.form, "email", $event.target.value)
                  }
                }
              }),
              _vm._v(" "),
              _vm.errors.email
                ? _c("div", { staticClass: "text-danger text-left" }, [
                    _vm._v(
                      "\n              " +
                        _vm._s(_vm.errors.email[0]) +
                        "\n            "
                    )
                  ])
                : _vm._e()
            ])
          ])
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "row" }, [
          _c("div", { staticClass: "col-12" }, [
            _c("div", { staticClass: "form-group" }, [
              _c("input", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.form.password,
                    expression: "form.password"
                  }
                ],
                staticClass: "form-control input-lg",
                attrs: { type: "password", placeholder: "Password" },
                domProps: { value: _vm.form.password },
                on: {
                  input: function($event) {
                    if ($event.target.composing) {
                      return
                    }
                    _vm.$set(_vm.form, "password", $event.target.value)
                  }
                }
              }),
              _vm._v(" "),
              _vm.errors.password
                ? _c("div", { staticClass: "text-danger text-left" }, [
                    _vm._v(
                      "\n              " +
                        _vm._s(_vm.errors.password[0]) +
                        "\n            "
                    )
                  ])
                : _vm._e()
            ])
          ])
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "row" }, [
          _c("div", { staticClass: "col-12" }, [
            _c("div", { staticClass: "form-group" }, [
              _c("input", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.form.password_confirmation,
                    expression: "form.password_confirmation"
                  }
                ],
                staticClass: "form-control input-lg",
                attrs: { type: "password", placeholder: "Confirm Password" },
                domProps: { value: _vm.form.password_confirmation },
                on: {
                  input: function($event) {
                    if ($event.target.composing) {
                      return
                    }
                    _vm.$set(
                      _vm.form,
                      "password_confirmation",
                      $event.target.value
                    )
                  }
                }
              }),
              _vm._v(" "),
              _vm.errors.password_confirmation
                ? _c("div", { staticClass: "text-danger text-left" }, [
                    _vm._v(
                      "\n              " +
                        _vm._s(_vm.errors.password_confirmation[0]) +
                        "\n            "
                    )
                  ])
                : _vm._e()
            ])
          ])
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "row margin-bottom-2x" }, [
          _c("div", { staticClass: "col-12" }, [
            _c("div", { staticClass: "form-group form-check text-left" }, [
              _c("input", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.terms_and_conditions,
                    expression: "terms_and_conditions"
                  }
                ],
                staticClass: "form-check-input",
                attrs: { id: "terms_check", type: "checkbox" },
                domProps: {
                  checked: Array.isArray(_vm.terms_and_conditions)
                    ? _vm._i(_vm.terms_and_conditions, null) > -1
                    : _vm.terms_and_conditions
                },
                on: {
                  change: function($event) {
                    var $$a = _vm.terms_and_conditions,
                      $$el = $event.target,
                      $$c = $$el.checked ? true : false
                    if (Array.isArray($$a)) {
                      var $$v = null,
                        $$i = _vm._i($$a, $$v)
                      if ($$el.checked) {
                        $$i < 0 &&
                          (_vm.terms_and_conditions = $$a.concat([$$v]))
                      } else {
                        $$i > -1 &&
                          (_vm.terms_and_conditions = $$a
                            .slice(0, $$i)
                            .concat($$a.slice($$i + 1)))
                      }
                    } else {
                      _vm.terms_and_conditions = $$c
                    }
                  }
                }
              }),
              _vm._v(" "),
              _c(
                "label",
                {
                  staticClass: "form-check-label text-left",
                  attrs: { for: "terms_check" }
                },
                [
                  _vm._v("\n              By Signing up I agree to the "),
                  _c(
                    "router-link",
                    {
                      staticClass: "text-info",
                      attrs: { to: { name: "terms" }, target: "_blank" }
                    },
                    [_vm._v("Terms & Conditions ")]
                  )
                ],
                1
              ),
              _vm._v(" "),
              _vm.errors.terms_and_conditions
                ? _c("div", { staticClass: "text-danger text-left" }, [
                    _vm._v(
                      "\n              " +
                        _vm._s(_vm.errors.terms_and_conditions[0]) +
                        "\n            "
                    )
                  ])
                : _vm._e()
            ])
          ])
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "row" }, [
          _c("div", { staticClass: "col-12" }, [
            _c("div", { staticClass: "form-group" }, [
              _c(
                "button",
                {
                  staticClass: "btn btn-info btn-lg btn-block auth-btn",
                  attrs: { type: "submit", disabled: _vm.loading },
                  on: {
                    click: function($event) {
                      $event.preventDefault()
                      return _vm.signup($event)
                    }
                  }
                },
                [
                  !_vm.loading
                    ? _c("span", [
                        _vm._v("\n              Sign Up\n            ")
                      ])
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.loading
                    ? _c("span", [
                        _c("i", { staticClass: "fa fa-spinner fa-spin" }),
                        _vm._v("\n                Signing Up...\n            ")
                      ])
                    : _vm._e()
                ]
              )
            ])
          ])
        ])
      ])
    ]),
    _vm._v(" "),
    _c(
      "div",
      { staticClass: "text-center auth--registered" },
      [
        _vm._v("\n    Already have an account?\n    "),
        _c(
          "router-link",
          {
            staticClass: "auth--form-button",
            attrs: { to: { name: "login" } }
          },
          [_vm._v("Login ")]
        )
      ],
      1
    )
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "social-btn text-center" }, [
      _c(
        "a",
        { staticClass: "button button-facebook btn-lg", attrs: { href: "#" } },
        [_c("i", { staticClass: "fa fa-facebook" })]
      ),
      _vm._v(" "),
      _c(
        "a",
        { staticClass: "button button-twitter btn-lg", attrs: { href: "#" } },
        [_c("i", { staticClass: "fa fa-twitter" })]
      ),
      _vm._v(" "),
      _c(
        "a",
        { staticClass: "button button-google btn-lg", attrs: { href: "#" } },
        [_c("i", { staticClass: "fa fa-google" })]
      )
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "or-seperator" }, [_c("b", [_vm._v("or")])])
  }
]
render._withStripped = true
export { render, staticRenderFns }
if (module.hot) {
  module.hot.accept()
  if (module.hot.data) {
    require("vue-hot-reload-api")      .rerender("data-v-2c87a07f", { render: render, staticRenderFns: staticRenderFns })
  }
}