import { http } from 'services'

// initial state
const state = () => ({
  all: [],
  active: {},
  receipt: null,
  download: null,
  errors: null,
  availability: {},
  cost: null,
  bundle_costs: [],
  parking_costs: [],
  room_costs: [],
  combination_costs: [],
  refund_list: []
})

// getters
const getters = {

}
let counter = 1;
// actions
const actions = {

  ['reservations.getAsGuest'] ({commit}, data) {
    commit('CLEAR_ERRORS');
    return (http.get('reservations/retrieve-as-guest', {
      params: data
    })).then((res) => {
      commit('GET_RESERVATION', res);
    })
    .catch ((res) => {
      commit('RESERVATIONS_ERROR', res);
    })
  },

  ['reservations.getReceipt'] ({commit}, {id, params}) {
    console.log('params', params)
    console.log('id', id)
    commit('CLEAR_ERRORS');
    return (http.get(`reservations/${id}/print-receipt`,{params}).then((res) => {
      commit('GET_RECEIPT', res);
    })
    .catch ((res) => {
      commit('RESERVATIONS_ERROR', res);
    }));
  },

  ['reservations.sendReceiptViaEmail'] ({commit}, {id, params}) {

    commit('CLEAR_ERRORS');
    return (http.post(`reservations/${id}/via-email`,params).then((res) => {
      commit('GET_RECEIPT', res);
    })
    .catch ((res) => {
      commit('RESERVATIONS_ERROR', res);
    }));
  },

  ['reservations.add'] ({commit}, data) {
    commit('CLEAR_ERRORS');

    return (http.post(`reservations`, data)).then ((res) => {
      commit('NEW_RESERVATION', res);
    })
    .catch ((res) => {
      commit('RESERVATIONS_ERROR', res);
    })
  },

  //update reservation
  ['reservations.edit'] ({dispatch, commit, state}, {id, data}) {
    commit('CLEAR_ERRORS');

    return (http.put(`reservations/${id}`, data)).then ((res) => {
      commit('EDIT_RESERVATION', {res});
    })
    .catch ((res) => {
      commit('RESERVATIONS_ERROR', res);
    })
  },

  ['reservations.getRefundList'] ({commit}, {id, data}) {
    commit('CLEAR_ERRORS');

    return (http.post('reservations/refund/' + id, data)).then ((res) => {
      commit('REFUND_LIST', res);
    }).catch ((res) => {
      commit('RESERVATIONS_ERROR', res);
    })
  },

  ['reservations.cancel'] ({commit}, {id, data}) {
    commit('CLEAR_ERRORS');

    return (http.delete(`reservations/${id}`, {params: data})).then ((res) => {
      commit('CANCEL_RESERVATION', res);
    }).catch ((res) => {
      commit('RESERVATIONS_ERROR', res);
    })
  },

  ['reservations.clearCosts'] ({commit}) {
    commit('CLEAR_RESERVATION_COSTS');
  },

  ['reservations.cost'] ({commit}, {data, currencyCode}) {
    commit('CLEAR_ERRORS');

    let url = `search/cost`;

    if (data.reservation_id) {
      url = url + '/' + data.reservation_id;
    }

    if (currencyCode) data.currency_code = currencyCode;
    return (http.post(url, data)).then ((res) => {
      commit('CLEAR_ERRORS');
      commit('GET_COST', {res, data});
    })
    .catch ((res) => {
      commit('RESERVATIONS_ERROR', res);
    })
  },

  ['reservations.download'] ({commit}, {id, params, responseType}) {
    commit('CLEAR_ERRORS')

    return (http.get(`reservations/${id}/download`, {params: params, responseType: responseType}))
    .then(res => {
      console.log('res', res);
      commit('GET_DOWNLOAD_RESERVATION_DATA', res);
    }).catch ((res) => {
      commit('RESERVATIONS_ERROR', res);
    });
  },
  
  ['reservations.clear_active'] ({commit}) {
    commit('CLEAR_ACTIVE');
  }
}

// mutations
const mutations = {

  GET_RESERVATION (state, res) {
    console.log('resssssss --->>>', res);
    state.active = res.data;
    state.active = _.cloneDeep(state.active);
  },

  GET_RECEIPT (state, res) {
    console.log('RECEIPT --->>>', res);
    state.receipt = res.data;
    state.receipt = _.cloneDeep(state.receipt);
  },

  NEW_RESERVATION (state, res) {
    state.active = _.assign(state.active, res.data);
    state.active = _.cloneDeep(state.active);
  },

  EDIT_RESERVATION (state, {res}) {
    state.active = _.assign(state.active, res.data);
    state.active = _.cloneDeep(state.active);
  },

  REFUND_LIST (state, res) {
    state.refund_list = _.cloneDeep(res.data);
  },

  CANCEL_RESERVATION (state, res) {
    state.all.data = _.filter(state.all.data, (item) => item.id != res.data.id);
    state.active = _.cloneDeep(res.data);
    state.all = _.cloneDeep(state.all);
  },

  GET_COST(state, {res, data}) {
    res.data.request = data;
    console.log(data);
    if (data.parking_type && data.room_type) {
      state.combination_costs = state.combination_costs.concat([res.data]);
    } else if (data.bundle_id) {
      state.bundle_costs = state.bundle_costs.concat([res.data]);
    } else if (data.parking_type) {
      state.parking_costs = state.parking_costs.concat([res.data]);
    } else if (data.room_type) {
      state.room_costs = state.room_costs.concat([res.data]);
    }

    console.log('combination_costs', state.combination_costs);
  },

  CLEAR_RESERVATION_COSTS (state) {
    state.bundle_costs = _.cloneDeep([]);
    state.parking_costs = _.cloneDeep([]);
    state.room_costs = _.cloneDeep([]);
    state.combination_costs = _.cloneDeep([]);
  },

  GET_DOWNLOAD_RESERVATION_DATA (state, res) {
    state.download = res.data
  },

  //ERRORS
  RESERVATIONS_ERROR (state, err) {
    try {
      state.errors = err.response.data;
    } catch (e) {
      state.errors = err;
    }
  },

  CLEAR_ERRORS (state) {
    state.errors = null
  },

  CLEAR_ACTIVE (state) {
    state.active = {}
  }
}

export default {
  state,
  getters,
  actions,
  mutations
}
