var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("layout", [
    _c("section", { staticClass: "airports-page best-airports bg-light" }, [
      _vm.airports.us.length
        ? _c("div", {}, [
            _c("div", { staticClass: "super-title text-center" }, [
              _vm._v("TOP US AIRPORTS")
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "container" }, [
              _c("div", { staticClass: "row" }, [
                _c(
                  "div",
                  { staticClass: "col-xs-12 col-md-3 col-sm-6" },
                  _vm._l(_vm.airports.us, function(port, i) {
                    return i < 25
                      ? _c(
                          "div",
                          { staticClass: "best-airports--item" },
                          [
                            _c(
                              "router-link",
                              {
                                attrs: {
                                  to: {
                                    name: "airport",
                                    params: { slug: port.url }
                                  }
                                }
                              },
                              [_vm._v(_vm._s(port.name))]
                            )
                          ],
                          1
                        )
                      : _vm._e()
                  })
                ),
                _vm._v(" "),
                _c(
                  "div",
                  { staticClass: "col-xs-12 col-md-3 col-sm-6" },
                  _vm._l(_vm.airports.us, function(port, i) {
                    return i < 50 && i >= 25
                      ? _c(
                          "div",
                          { staticClass: "best-airports--item" },
                          [
                            _c(
                              "router-link",
                              {
                                attrs: {
                                  to: {
                                    name: "airport",
                                    params: { slug: port.url }
                                  }
                                }
                              },
                              [_vm._v(_vm._s(port.name))]
                            )
                          ],
                          1
                        )
                      : _vm._e()
                  })
                ),
                _vm._v(" "),
                _c(
                  "div",
                  { staticClass: "col-xs-12 col-md-3 col-sm-6" },
                  _vm._l(_vm.airports.us, function(port, i) {
                    return i < 75 && i >= 50
                      ? _c(
                          "div",
                          { staticClass: "best-airports--item" },
                          [
                            _c(
                              "router-link",
                              {
                                attrs: {
                                  to: {
                                    name: "airport",
                                    params: { slug: port.url }
                                  }
                                }
                              },
                              [_vm._v(_vm._s(port.name))]
                            )
                          ],
                          1
                        )
                      : _vm._e()
                  })
                ),
                _vm._v(" "),
                _c(
                  "div",
                  { staticClass: "col-xs-12 col-md-3 col-sm-6" },
                  _vm._l(_vm.airports.us, function(port, i) {
                    return i < 100 && i >= 75
                      ? _c(
                          "div",
                          { staticClass: "best-airports--item" },
                          [
                            _c(
                              "router-link",
                              {
                                attrs: {
                                  to: {
                                    name: "airport",
                                    params: { slug: port.url }
                                  }
                                }
                              },
                              [_vm._v(_vm._s(port.name))]
                            )
                          ],
                          1
                        )
                      : _vm._e()
                  })
                )
              ])
            ])
          ])
        : _vm._e(),
      _vm._v(" "),
      _vm.airports.ca.length
        ? _c("div", {}, [
            _c("div", { staticClass: "super-title text-center" }, [
              _vm._v("TOP CANADIAN AIRPORTS")
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "container" }, [
              _c("div", { staticClass: "row" }, [
                _c(
                  "div",
                  { staticClass: "col-xs-12 col-md-3 col-sm-6" },
                  _vm._l(_vm.airports.ca, function(port, i) {
                    return i < 25
                      ? _c(
                          "div",
                          { staticClass: "best-airports--item" },
                          [
                            _c(
                              "router-link",
                              {
                                attrs: {
                                  to: {
                                    name: "airport",
                                    params: { slug: port.url }
                                  }
                                }
                              },
                              [_vm._v(_vm._s(port.name))]
                            )
                          ],
                          1
                        )
                      : _vm._e()
                  })
                ),
                _vm._v(" "),
                _c(
                  "div",
                  { staticClass: "col-xs-12 col-md-3 col-sm-6" },
                  _vm._l(_vm.airports.ca, function(port, i) {
                    return i < 50 && i >= 25
                      ? _c(
                          "div",
                          { staticClass: "best-airports--item" },
                          [
                            _c(
                              "router-link",
                              {
                                attrs: {
                                  to: {
                                    name: "airport",
                                    params: { slug: port.url }
                                  }
                                }
                              },
                              [_vm._v(_vm._s(port.name))]
                            )
                          ],
                          1
                        )
                      : _vm._e()
                  })
                ),
                _vm._v(" "),
                _c(
                  "div",
                  { staticClass: "col-xs-12 col-md-3 col-sm-6" },
                  _vm._l(_vm.airports.ca, function(port, i) {
                    return i < 75 && i >= 50
                      ? _c(
                          "div",
                          { staticClass: "best-airports--item" },
                          [
                            _c(
                              "router-link",
                              {
                                attrs: {
                                  to: {
                                    name: "airport",
                                    params: { slug: port.url }
                                  }
                                }
                              },
                              [_vm._v(_vm._s(port.name))]
                            )
                          ],
                          1
                        )
                      : _vm._e()
                  })
                ),
                _vm._v(" "),
                _c(
                  "div",
                  { staticClass: "col-xs-12 col-md-3 col-sm-6" },
                  _vm._l(_vm.airports.ca, function(port, i) {
                    return i < 100 && i >= 75
                      ? _c(
                          "div",
                          { staticClass: "best-airports--item" },
                          [
                            _c(
                              "router-link",
                              {
                                attrs: {
                                  to: {
                                    name: "airport",
                                    params: { slug: port.url }
                                  }
                                }
                              },
                              [_vm._v(_vm._s(port.name))]
                            )
                          ],
                          1
                        )
                      : _vm._e()
                  })
                )
              ])
            ])
          ])
        : _vm._e()
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true
export { render, staticRenderFns }
if (module.hot) {
  module.hot.accept()
  if (module.hot.data) {
    require("vue-hot-reload-api")      .rerender("data-v-1c2029e2", { render: render, staticRenderFns: staticRenderFns })
  }
}