import { http, user } from 'services'
// initial state
const state = () => ({
  token: user.getToken(),
  data: user.getUser(),
  errors: null,
  confirmed: null,
})

// getters
const getters = {
}

// actions
const actions = {
  ['user.login'] ({ commit }, data) {
    commit('CLEAR_ERRORS');
    return (http.post(`customers/auth`, data))
    .then((res) => {
      commit('USER_LOGIN', res);
    })
    .catch((err) => {
      commit('USER_ERROR', {err});
    });
  },
  ['user.logout'] ({ commit }) {
    commit('CLEAR_ERRORS');
    commit('USER_LOGOUT');
  },
  ['user.signup'] ({ commit }, data) {
    commit('CLEAR_ERRORS');
    return (http.post(`customers/sign-up`, data))
    .then((res) => {
      commit('USER_REGISTERED', res);
    })
    .catch((err) => {
      commit('USER_ERROR', {err, clearUser: false});
    });
  },
  ['user.confirmation'] ({ commit }, token) {
    commit('CLEAR_ERRORS');
    return (http.post(`customers/activate-account/${token}`))
    .then((res) => {
      commit('USER_CONFIRMED', res);
    })
    .catch((err) => {
      commit('USER_ERROR', {err, clearUser: false});
    });
  },
  ['user.updatePersonalData'] ({ commit }, data) {
    commit('CLEAR_ERRORS');

    let roles = data.roles;
    delete data.roles;
    console.log(data);
    return (http.put(`users/update-profile`, data))
    .then((res) => {
      res.data.roles = roles;
      commit('USER_UPDATED', res);
    })
    .catch((err) => {
      commit('USER_ERROR', {err, clearUser: false});
    });
  },
  ['user.forgotPassword'] ({ commit }, email) {
    commit('CLEAR_ERRORS');
    return (http.post(`customers/reset-password`, {email}))
    .catch((err) => {
      commit('USER_ERROR', {err});
    });
  },
  ['user.resetPassword'] ({ commit }, {token, password}) {
    commit('CLEAR_ERRORS');
    return (http.post(`customers/reset-customer-password`, {token, password}))
    .catch((err) => {
      commit('USER_ERROR', {err});
    });
  },

  ['user.getUser'] ({ commit }) {
    commit('CLEAR_ERRORS');
    let data = {data: {user: user.getUser()}}
    commit('GET_USER', data);
  },
}

// mutations
const mutations = {
  USER_LOGIN (state, res) {
    state.token = res.data.token;
    state.data = res.data.data;
    state.data.locations = res.data.locations;
    state.data.channels = res.data.channels;

    user.setUser(state.data);
    user.setToken(state.token);
  },

  USER_UPDATED (state, res) {
    state.data = _.assign(state.data, res.data);
    user.setUser(state.data);
  },
  USER_CONFIRMED (state, res) {
    state.confirmed = true;
  },
  GET_USER (state, res) {
    state.data = user.getUser()
    state.token = user.getToken()
    user.setUser(state.data);
    user.setToken(state.token);
  },
  USER_REGISTERED (state, res) {
    state.data = _.cloneDeep(res.data);
    user.setUser(null);
  },
  USER_LOGOUT (state) {
    state.token = null;
    state.data = null;

    user.setUser(state.data);
    user.setToken(state.token);
  },
  //ERRORS
  USER_ERROR (state, {err, clearUser = true}) {
    state.errors = err.response.data;
    if (clearUser) {
      user.setUser(null);
      user.setToken(null);
    }
  },

  CLEAR_ERRORS (state) {
    state.errors = null
  }
}

export default {
  state,
  getters,
  actions,
  mutations
}
