import moment from 'moment'
import { stripeService } from 'services'

const watch = {
  formData (n) {
    this.form = _.assign(this.form, n);
  },
  formErrors (n) {
    this.errors = n || {};
  },
}

const methods = {
  goToStep (step, ignoreValidation) {
    if (this.validate() || ignoreValidation) {
      this.$router.replace({
        query: Object.assign({}, this.$route.query, { step }),
        // No need to specify route name and params, they will be preserved
      });
      this.$emit('changeStep', step);
    }
  },

  validate () {
    return true;
  },

  confirm () {
    this.processing = true;
    this.submitForm(this.stripe, this.elements).then(() => {
      this.processing = false;
    });
  },

  onChangeCardElement () {
    this.errors.card_number = null;
  },

  onChangeExpirationElement () {
    this.errors.expiration = null;
  },

  onChangeCvcElement () {
    this.errors.cvc = null;
  },
}

const computed = {
  years () {
    let ys = [];
    let now = parseInt(moment().format('YYYY'));
    for (let i = 0; i < 10; i++) {
      ys.push(now + i);
    }

    return ys;
  },
  months () {
    return {
      "January": "01",
      "February": "02",
      "March": "03",
      "April": "04",
      "May": "05",
      "June": "06",
      "July": "07",
      "August": "08",
      "September": "09",
      "October": "10",
      "November": "11",
      "December": "12"
    }
  },

  totalGuestsNumber () {
    return _.sum(this.form.guests_in_rooms);
  },

  tripProtections () {
    return this.$store.state.tripProtections.all;
  }
}

export default {
  name: 'checkout-payment-step',
  props: ['formData', 'formErrors', 'location', 'airport', 'submitForm', 'costs', 'selectedTypes', 'numberOfSpots'],
  watch,
  methods,
  computed,
  data () {
    return {
      errors: this.formErrors || {},
      form: this.formData,
      processing: false,
      loadingStripe: false,
      stripe: null,
      elements: {
        card_element: null,
        expiration_element: null,
        cvc_element: null,
      }
    }
  },
  mounted () {
    this.$nextTick(() => {
      $('html, body').animate({
        scrollTop: $(".checkout-step-label.active").offset().top
      }, 500);
    });

    let stripeAccount = this.location.currency_code == 'USD' ? 'osp' : 'tripteo';
    this.loadingStripe = true;
    stripeService.init(stripeAccount).then(({stripe, elements}) => {
      this.loadingStripe = false;
      this.stripe = stripe;

      let classes = {
        base: 'payment-field'
      }

      let style = {
        base: {
          color: '#495057',
          fontSize: '16px',
          fontWeight: '300',
          '::placeholder': {
            fontWeight: '300',
            color: '#bbb'
          }
        }
      }

      this.elements.card_element = elements.create('cardNumber', {classes, style});
      this.elements.expiration_element = elements.create('cardExpiry', {classes, style});
      this.elements.cvc_element = elements.create('cardCvc', {classes, style});

      this.elements.card_element.mount('#cardNumber-element');
      this.elements.expiration_element.mount('#expiration-element');
      this.elements.cvc_element.mount('#cvc-element');

      this.elements.card_element.addEventListener('change', this.onChangeCardElement);
      this.elements.expiration_element.addEventListener('change', this.onChangeExpirationElement);
      this.elements.cvc_element.addEventListener('change', this.onChangeCvcElement);

    });
  },

  destroyed () {
    console.log('destroy');
    try {
      this.elements.card_element.removeEventListener('change', this.onChangeCardElement);
      this.elements.expiration_element.removeEventListener('change', this.onChangeExpirationElement);
      this.elements.cvc_element.removeEventListener('change', this.onChangeCvcElement);
    } catch (e) {}

    try {
      this.elements.card_element.destroy()
      this.elements.expiration_element.destroy()
      this.elements.cvc_element.destroy()
    } catch (e) {}
  }
}
