var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c(
      "div",
      {
        staticClass:
          "d-flex justify-content-between align-items-center number-of-cars",
        class: { disabled: _vm.disabled }
      },
      [
        _c("div", { staticClass: "number-of-cars--action" }, [
          _c(
            "a",
            {
              staticClass: "border",
              attrs: { href: "#" },
              on: {
                click: function($event) {
                  $event.preventDefault()
                  _vm.decrementCars()
                }
              }
            },
            [_c("i", { staticClass: "fa fa-minus" })]
          )
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "parkings--number-of-spots" }, [
          _vm._v(
            "\n      " +
              _vm._s(_vm.number_of_spots) +
              " Car" +
              _vm._s(_vm.number_of_spots != 1 ? "s" : "") +
              "\n    "
          )
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "number-of-cars--action" }, [
          _c(
            "a",
            {
              staticClass: "border",
              attrs: { href: "#" },
              on: {
                click: function($event) {
                  $event.preventDefault()
                  _vm.incrementCars()
                }
              }
            },
            [_c("i", { staticClass: "fa fa-plus" })]
          )
        ])
      ]
    )
  ])
}
var staticRenderFns = []
render._withStripped = true
export { render, staticRenderFns }
if (module.hot) {
  module.hot.accept()
  if (module.hot.data) {
    require("vue-hot-reload-api")      .rerender("data-v-409121fc", { render: render, staticRenderFns: staticRenderFns })
  }
}