/*
* @INFO: This component makes a a jquery-ui datepicker
* @PROP value: (pass it as v-model)
* @PROP minDate: is the minimum date to reach
* @PROP maxDate: is the maximum date to reach
*/
import moment from 'moment'

const methods = {
  reload () {

    let data = {
      maxDate: this.maxDate,
      onClose: this.onChange,
      dateFormat: "dd/M/yy"
    }

    if (this.minDate != "none") {

        data.minDate = this.minDate || moment().format('DD/MMM/YYYY')
    }

    $(this.$refs.input).datepicker("destroy");

    $(this.$refs.input).css('z-index', 999999999999).datepicker(data);
  },
  onChange (value) {
    this.$emit('input', value)
  }
}

const watch = {
  maxDate () {
    this.reload()
  },
  minDate () {
    this.reload()
  }
}

const computed = {
  // newValue () {
  //   return this.value
  // }
}

export default {
  name: 'datepicker',
  props: ['maxDate', 'minDate', 'value'],
  methods,
  watch,
  computed,
  mounted () {
    this.reload();
  }
}
