import moment from 'moment'
import toastr from 'toastr'
import { dateTime , stripeService, helper } from 'services'
import { currencySymbol } from 'filters'
import { funcModal } from 'components/modals'
import datepicker from 'components/datepicker'
import numberOfRooms from '../../numberOfRooms'
import numberOfCars from '../../numberOfCars'
import threeDSecure from '../../threeDSecure'


let components = {
  funcModal,
  datepicker,
  numberOfRooms,
  numberOfCars,
  threeDSecure
}

if (!isServer) {
  components.popover = require('components/popover').default;
}

const methods = {
  open () {
    this.$refs.modal.open();
  },
  validateExtraFields () {
    this.errors = {};
    if (!_.isEmpty(this.empty_extra_fields)) {
      for (let i in this.empty_extra_fields) {
        if (this.activeHistory.reservation_type != 'room' && this.empty_extra_fields[i].per_car) {
          if (this.activeHistory.extra_fields.some(ef => ef.id == this.empty_extra_fields[i].id)) {
            for (let j = 0 ; j < Number(this.form.number_of_spots - this.activeHistory.number_of_spots); j++) {
              if (!this.extra_fields[this.empty_extra_fields[i].name][j] || !this.extra_fields[this.empty_extra_fields[i].name].length) {
                this.$set(this.errors, (this.empty_extra_fields[i].name + '.' + j), ['This field is required'])
              } else if (this.empty_extra_fields[i].input_type == 'flight_number') {
                let flight_number = this.extra_fields[this.empty_extra_fields[i].name][j];
                if (flight_number.match(/[~`!@#$%\^&*+=\[\]\\';(),/[\]{}|\\":<>\?]/g) || !helper.isValidFlightNumber(helper.extractFlightNumber(flight_number))) {
                    this.$set(this.errors, (this.empty_extra_fields[i].name + '.' + j), ['Invalid Flight Number'])
                }
              }
            }
          } else {
            for (let j = 0 ; j < this.form.number_of_spots; j++) {
              if (!this.extra_fields[this.empty_extra_fields[i].name][j] || !this.extra_fields[this.empty_extra_fields[i].name].length) {
                this.$set(this.errors, (this.empty_extra_fields[i].name + '.' + j), ['This field is required'])
              } else if (this.empty_extra_fields[i].input_type == 'flight_number') {
                let flight_number = this.extra_fields[this.empty_extra_fields[i].name][j];
                if (flight_number.match(/[~`!@#$%\^&*+=\[\]\\';(),/[\]{}|\\":<>\?]/g) || !helper.isValidFlightNumber(helper.extractFlightNumber(flight_number))) {
                    this.$set(this.errors, (this.empty_extra_fields[i].name + '.' + j), ['Invalid Flight Number'])
                }
              }
            }
          }
        } else {
          if (!this.extra_fields[this.empty_extra_fields[i].name][0] || !this.extra_fields[this.empty_extra_fields[i].name].length) {
            this.$set(this.errors, this.empty_extra_fields[i].name + '.0', ['This field is required'])
          }  else if (this.empty_extra_fields[i].input_type == 'flight_number') {
            let flight_number = this.extra_fields[this.empty_extra_fields[i].name][0];
            if (flight_number.match(/[~`!@#$%\^&*+=\[\]\\';(),/[\]{}|\\":<>\?]/g) || !helper.isValidFlightNumber(helper.extractFlightNumber(flight_number))) {
                this.$set(this.errors, this.empty_extra_fields[i].name + '.0', ['Invalid Flight Number'])
            }
          }
        }
      }
      if (!_.isEmpty(this.errors)) {
        return;
      }
    }

    this.step = 2;
  },

  getEmptyExtraFileds () {
    let filled_extra_fields = _.cloneDeep(this.activeHistory.extra_fields);
    let location_extra_fields = _.cloneDeep(this.activeHistory.location.extra_fields)
    // get the empty fields by comparing the two arrays depending on extra_field `id`
    let empty_fields = _.differenceBy(location_extra_fields, filled_extra_fields, 'id');
    this.empty_extra_fields = _.cloneDeep(empty_fields);
    if (this.activeHistory.reservation_type != 'room' && this.activeHistory.number_of_spots < this.form.number_of_spots) {
      for (let i in filled_extra_fields) {
        if (filled_extra_fields[i].per_car) {
          this.empty_extra_fields.push(filled_extra_fields[i]);
        }
      }
    }
    // set the extra_fields object for the v-model so we can check if the fields are empty or not
    // in case the user didn't fill it.
    _.forEach(this.empty_extra_fields, (field, index) => {
      let name = field.name;
      this.$set(this.extra_fields, name, [""]);
    })
  },
  continueToNextStep () {
    this.validateExtraFields();
  },
  onChangeNumberOfRooms ({rooms, guests}) {
    this.form.number_of_rooms = rooms
    this.form.guests_in_rooms = guests
    this.form.guests_names = this.form.guests_names.slice(0, rooms);
    this.$store.dispatch('reservations.clearCosts');
    this.step = 1;
    console.log('this.form.number_of_rooms', this.form.number_of_rooms);
  },

  changeNumberOfSpots (value) {
    this.form.number_of_spots = value;
    this.getEmptyExtraFileds();
    this.$store.dispatch('reservations.clearCosts');
    this.step = 1;
  },

  onChangeDateTime () {
    this.$store.dispatch('reservations.clearCosts');
    this.step = 1;
  },

  initDataToSend () {
    this.processing = false;
    let date_customer_format = 'DD/MMM/YYYY';
    let date_database_format = 'YYYY-MM-DD';
    let time_database_format = 'HH:mm:ss';
    let data = {
      reservation_type: this.activeHistory.reservation_type,
      from_date: moment(this.form.from_date, [date_customer_format]),
      to_date: moment(this.form.to_date, [date_customer_format]),
      reservation_id: this.reservation.id,
      location_id: this.activeHistory.location_id
    };

    //TODO: check if user is logged in and wrap the next three lines in if statement
    data.number = this.reservation.reservation_number;
    data.reserved_by = this.reservation.reserved_by;
    data.check_in = moment(this.activeHistory.from_date, [`${date_database_format} ${time_database_format}`]).format(date_database_format);

    if (this.activeHistory.reservation_type == 'room' || this.activeHistory.reservation_type == 'both') {
      data.number_of_rooms = this.form.number_of_rooms;
      data.guests_in_rooms = this.form.guests_in_rooms;
      data.guests_names = _.cloneDeep(this.form.guests_names);

      if(this.activeHistory.rooms.length) {
        data.room_type = this.activeHistory.rooms[0].room_type;
      }
    }

    if (this.activeHistory.reservation_type == 'parking' || this.activeHistory.reservation_type == 'both') {
      data.number_of_spots = this.form.number_of_spots;
      data.parking_type = this.activeHistory.parking_type;
    }
    let _fromTime = null;
    let _toTime = null;
    if (this.activeHistory.reservation_type == 'both') {
      if (this.activeHistory.nights_to_sleep == 'end') {
        if (!this.form.from_time) {
          this.errors.from_time = [];
          this.errors.from_time.push('Vehicle Drop-Off time is required');
          return;
        }

        _fromTime = this.form.from_time;
        _toTime = this.activeHistory.location.checkout_time
      }

      if (this.activeHistory.nights_to_sleep == 'beginning') {
        if (!this.form.to_time) {
          this.errors.to_time = [];
          this.errors.to_time.push('Vehicle Pick-Up time is required');
          return;
        }
        _fromTime = this.activeHistory.location.checkin_time;
        _toTime = this.form.to_time;
      }

      
    }

    if (
      this.activeHistory.reservation_type == 'room' ||
      this.activeHistory.nights_to_sleep == 'both'
      ) {
      _fromTime = this.activeHistory.location.checkin_time || '12:00';
      _toTime = this.activeHistory.location.checkout_time || '12:00'; 
    }
    
    if (this.activeHistory.reservation_type == 'parking') {
      if (_.isEmpty(this.form.from_time)) {
          this.errors.from_time = [];
          this.errors.from_time.push('Vehicle Drop-Off time is required');
          return;
      }

      if (_.isEmpty(this.form.to_time)) {
        this.errors.to_time = [];
        this.errors.to_time.push('Vehicle Pick-Up time is required');
        return;
      }
      _fromTime = this.form.from_time;
      _toTime = this.form.to_time;
    }
    data.from_date = data.from_date.format(date_database_format) + ` ${_fromTime}:00`;
    data.to_date = data.to_date.format(date_database_format) + ` ${_toTime}:00`;
    this.checkout_date = data.to_date;

    if (this.activeHistory.trip_protection_price_id && data.reservation_type == 'parking') {
      data.trip_protection_id = this.activeHistory.trip_price.trip_protection_id;
    }

    console.log('data', data);

    if (data.reservation_type != 'parking') {
      delete data.trip_protection_id;
    }

    if (this.activeHistory.reservation_type == 'both') {
      data.nights_to_sleep = this.activeHistory.nights_to_sleep;
      data.bundle_id = this.activeHistory.bundle_id;
    }
    this.processing = true;
    return data;
  },

  onCloseThreeDSecure () {
    this.threeDsecure.url = null;
  },

  checkAvailability () {
    this.errors = {};

    if (this.activeHistory.reservation_type == 'room' || this.activeHistory.reservation_type == 'both') {
      for (let i in this.form.guests_in_rooms) {
        if (!this.form.guests_names[i] || !this.form.guests_names[i].length) {          
          let room_number = 1 + parseInt(i);
          this.errors['guests_names.' + i] = ['Guest name in room ' + room_number + ' is required.']
        } else {
          if (this.form.guests_names[i].length > 255) {
            this.errors['guests_names.' + i] = ["This field must not contain more than 255 chars."]
          }
        }
      }
    }

    if (!_.isEmpty(this.errors)) {
      return;
    }

    this.processing = true;
    this.$store.dispatch('reservations.clearCosts');
    this.$store.dispatch('reservations.cost', {
      data: this.initDataToSend(),
      currencyCode: this.activeHistory.payment_currency
    }).then((res) => {
      this.processing = false;
      let errors = this.$store.state.reservations.errors;

      if (errors) {
        if(!_.isEmpty(errors.errors)) {
          this.errors = _.cloneDeep(errors.errors);
        }
        toastr.error(errors.message);
      }
    })
  },

  initiateStripe () {
    this.loadingStripe = true;
    stripeService.init(this.reservation.stripe_account).then(({stripe, elements}) => {
      this.loadingStripe = false;
      this.stripe = stripe;

      let classes = {
        base: 'payment-field'
      }

      let style = {
        base: {
          color: '#495057',
          fontSize: '16px',
          fontWeight: '300',
          '::placeholder': {
            fontWeight: '300',
            color: '#bbb'
          }
        }
      }

      this.elements.card_element = elements.create('cardNumber', {classes, style});
      this.elements.expiration_element = elements.create('cardExpiry', {classes, style});
      this.elements.cvc_element = elements.create('cardCvc', {classes, style});

      this.elements.card_element.mount('#cardNumber-element');
      this.elements.expiration_element.mount('#expiration-element');
      this.elements.cvc_element.mount('#cvc-element');

      this.elements.card_element.addEventListener('change', this.onChangeCardElement);
      this.elements.expiration_element.addEventListener('change', this.onChangeExpirationElement);
      this.elements.cvc_element.addEventListener('change', this.onChangeCvcElement);

    });
  },

  destroyStripe () {
    try {
      this.elements.card_element.removeEventListener('change', this.onChangeCardElement);
      this.elements.expiration_element.removeEventListener('change', this.onChangeExpirationElement);
      this.elements.cvc_element.removeEventListener('change', this.onChangeCvcElement);
    } catch (e) {}

    try {
      this.elements.card_element.destroy()
      this.elements.expiration_element.destroy()
      this.elements.cvc_element.destroy()
    } catch (e) {}
  },

  onChangeCardElement () {
    this.errors.card_number = null;
  },

  onChangeExpirationElement () {
    this.errors.expiration = null;
  },

  onChangeCvcElement () {
    this.errors.cvc = null;
  },

  onUseNewCardClicked () {
    this.use_new_card = true;
    this.$nextTick(() => {
      this.initiateStripe()
    })
  },

  onDoNotUseNewCard () {
    this.use_new_card = false;
    this.destroyStripe()
  },

  onSubmitForm () {
    if (!this.use_new_card) {
      this.confirm();
      return;
    }

    this.payment_processing = true;
    this.stripe.createSource(this.elements.card_element).then((result) => {
      if (result.error) {
        if (result.error.code == 'incomplete_expiry') {
          this.errors.expiration = [result.error.message];
        } else if (result.error.code == 'incomplete_cvc') {
          this.errors.cvc = [result.error.message];
        } else {
          this.errors.card_number = [result.error.message];
        }

        this.payment_processing = false;
        return;
      }

      this.payment_form.three_d_secure = result.source.card.three_d_secure;

      this.stripe.createToken(this.elements.card_element, {
        address_zip: this.payment_form.zipcode || '',
        name: this.payment_form.first_name + " " + this.payment_form.last_name
      }).then((result) => {
        if (result.error) {
          if (result.error.code == 'incomplete_expiry') {
            this.errors.expiration = [result.error.message];
          } else if (result.error.code == 'incomplete_cvc') {
            this.errors.cvc = [result.error.message];
          } else {
            this.errors.card_number = [result.error.message];
          }

          this.payment_processing = false;
          return;
        }

        this.payment_form.card_number = result.token.card.last4
        this.payment_form.expiry_month = result.token.card.exp_month
        this.payment_form.expiry_year = result.token.card.exp_year
        this.payment_form.card_id = result.token.card.id
        this.payment_form.brand = result.token.card.brand
        this.payment_form.card_token = result.token.id

        this.confirm();
      });
    })
  },

  confirm () {
    this.errors = {}
    let data = _.assign(_.cloneDeep(this.activeHistory), _.cloneDeep(this.initDataToSend()));

    if (this.use_new_card) {
      this.payment_form.name_on_card = `${this.payment_form.first_name} ${this.payment_form.last_name}`
      data = _.assign(data, _.cloneDeep(this.payment_form));

      for (var i in this.payment_form) {
        if (!this.payment_form[i]) {
          this.$set(this.errors, i, ['this field is required'])
        }
      }

      if (!_.isEmpty(this.errors)) return;
    }

    data.url = this.threeDsecure.redirectUrl;

    if (this.threeDsecure.source_id) {
      data.source_id = this.threeDsecure.source_id;
    }

    // if there are any new extra empty fields get them.
    if (!_.isEmpty(this.extra_fields)) {
      // append the extra_fields (v-model) to the request
      _.forEach(this.extra_fields, (field, index) => {
        data[index] = field;
      });
    }

    // append the old values of filled extra fields from the activeHistory object.
    _.forEach(this.activeHistory.extra_fields, (field, index) => {
      if (this.empty_extra_fields.some(ef => ef.id == field.id)) {
        data[field.name].unshift(...field.value);
      } else {
        data[field.name] = field.value;
      }
      if (this.form.number_of_spots < this.activeHistory.number_of_spots && field.per_car) {
        data[field.name].length = this.form.number_of_spots;
      }
    });
    this.payment_processing = true;
    this.errors = {}

    this.$store.dispatch('reservations.edit', {
      id: this.reservation.id,
      data
    }).then(() => {
      this.payment_processing = false;
      this.threeDsecure.source_id = null;
      let errors = this.$store.state.reservations.errors;
      if (errors) {
        if (errors.status_code == 400) {
          let error_code = errors.error_code;
          if (!_.isEmpty(error_code) && error_code == "hours_before_reservation_error") {
            toastr.error(errors.message);
          } else if (!_.isEmpty(error_code) && error_code == "3d_secure_required") {
            this.threeDsecure.url = errors.extra_params.return_url;
            toastr.warning('The card you used requires 3d secure confirmation');
          } else {
            toastr.error(errors.message);
          }
        } else {
          toastr.error(errors.message);
        }
      } else {
        this.$emit("reservationUpdated");
        toastr.success('A new Reservation has been updated');
        this.$store.dispatch('reservations.clearCosts');
        this.step = 1;
        this.use_new_card = false;
        this.$refs.modal.close();
      }
    })
  },

  decreaseNumberOfSpots (value) {
    for (let i in this.empty_extra_fields) {
      if (this.empty_extra_fields[i].per_car) {
        while (this.extra_fields[this.empty_extra_fields[i].name].length > this.form.number_of_spots) {
          this.extra_fields[this.empty_extra_fields[i].name].pop();
        }
      }
    }
  },
}

const computed = {
  activeHistory () {
    let activeRes =  _.find(this.reservation.history, h => h.active);

    return activeRes;
  },

  showCustomer() {
    let show_to_customer = this.reservation.history ? this.reservation.history.find(history => history.show_to_customer === 1) : null;
    return show_to_customer ? show_to_customer : this.activeHistory;
  },

  differentCurrencyWhenDueAtLot () {
    let activeRes =  _.find(this.reservation.history, h => h.active);
    let is_due_at_lot = activeRes.location.due_at_location;
    let location_currency = activeRes.location.currency_code;
    let payment_currency = activeRes.payment_currency;
    return is_due_at_lot && location_currency !== payment_currency;
  },

  locationCurrencySymbol() {
    return currencySymbol(this.activeHistory.location.currency_code);
  },
  guestsInRooms () {
    return _.map(this.activeHistory.rooms, item => item.number_of_guests);
  },

  totalGuestsNumber () {
    return _.sum(this.form.guests_in_rooms);
  },

  years () {
    let ys = [];
    let now = parseInt(moment().format('YYYY'));
    for (let i = 0; i < 10; i++) {
      ys.push(now + i);
    }

    return ys;
  },
  months () {
    return {
      "January": "01",
      "February": "02",
      "March": "03",
      "April": "04",
      "May": "05",
      "June": "06",
      "July": "07",
      "August": "08",
      "September": "09",
      "October": "10",
      "November": "11",
      "December": "12"
    }
  },

  symbol () {
    return currencySymbol(this.activeHistory.payment_currency)
  },
  costsDiff() {
    let diff = {
      grand_total : 0,
      sub_total : 0,
      due_at_location: 0,
    };
    if (_.isEmpty(this.costs) || _.isEmpty(this.activeHistory)) {
      return;
    }
    diff.grand_total = Number(this.costs.reservation.grand_total) - Number(this.activeHistory.grand_total);
    diff.sub_total = Number(this.costs.reservation.sub_total) - Number(this.activeHistory.subtotal);
    diff.due_at_location = Number(this.costs.reservation.due_at_location) - Number(this.activeHistory.due_at_location_total);
    diff.total_fees = Number(this.costs.reservation.fees_total) - Number(this.activeHistory.total_fees)
    diff.total_tax = Number(this.costs.reservation.tax_total) - Number(this.activeHistory.total_tax)
    diff.total_long_term_discount = Number(this.costs.reservation.long_term_discount) - Number(this.activeHistory.long_term_discount)
    return diff;
  },
  costs () {
    let reservations = this.$store.state.reservations;
    console.log('reservations', reservations);
    if (this.activeHistory.parking_type && this.activeHistory.rooms.length) {
      return reservations.combination_costs[0];
    }

    if (this.activeHistory.bundle_id) {
      return reservations.bundle_costs[0];
    }

    if (this.activeHistory.parking_type) {
      return reservations.parking_costs[0];
    }

    if (this.activeHistory.rooms.length) {
      return reservations.room_costs[0];
    }

    return null;
  },

  is_reservation_started () {
    if (this.activeHistory) {
        let from_date = this.activeHistory.from_date;
        let timezone = this.activeHistory.airport.timezone.code;
        let now = moment().tz(timezone);
        let date = moment.tz(from_date, "YYYY-MM-DD HH:mm:ss", timezone);
        return now.isAfter(date);
    }
    return false;
  },
}

const watch = {
  ['form.from_date'] () {
    this.onChangeDateTime()
  },
  ['form.to_date'] () {
    this.onChangeDateTime()
  }
}

export default {
  name: 'update-reservation-modal',
  props: ['reservation', 'closeFunction'],
  components,
  methods,
  watch,
  computed,
  data () {
    return {
      // form: this.reservation,
      form: {
        from_date: null,
        to_date: null,
        from_time: null,
        to_time: null,
        number_of_rooms: null,
        guests_in_rooms: null,
        number_of_spots: null,
        guests_names: []
      },
      payment_form: {
        name_on_card: null,
        first_name: null,
        last_name: null,
        card_number: null,
        expiry_month: null,
        expiry_year: null,
        zipcode: null,
        card_id: null,
        brand: null,
        card_token: null,
        three_d_secure: null
      },
      loadingStripe: false,
      stripe: null,
      elements: {
        card_element: null,
        expiration_element: null,
        cvc_element: null,
      },
      threeDsecure: {
        source_id: null,
        url: null,
        redirectUrl: null
      },
      times: dateTime.getHalfHours(),
      step: 1,
      errors: {},
      processing: false,
      payment_processing: false,
      use_new_card: false,
      empty_extra_fields:[],
      extra_fields: {},
      checkout_date: null,
    }
  },

  mounted () {

    this.threeDsecure.redirectUrl = document.location.protocol + '//' + document.location.hostname + (location.port ? ':'+location.port: '')
    window.setSource = (source) => {
      console.log('source', source);
      this.threeDsecure.source_id = source;
      this.threeDsecure.url = null;
      this.confirm();
    }

    this.form.from_date = moment(this.activeHistory.from_date, [`YYYY-MM-DD HH:mm:00`]).format('DD/MMM/YYYY')
    this.form.to_date =  moment(this.activeHistory.to_date, [`YYYY-MM-DD HH:mm:00`]).format('DD/MMM/YYYY')
    this.form.from_time = moment(this.activeHistory.from_date, [`YYYY-MM-DD HH:mm:00`]).format(`HH:mm`);
    this.form.to_time = moment(this.activeHistory.to_date, [`YYYY-MM-DD HH:mm:00`]).format(`HH:mm`);
    this.form.number_of_rooms = this.activeHistory.rooms.length;
    this.form.guests_in_rooms = this.guestsInRooms;
    this.form.number_of_spots = this.activeHistory.number_of_spots;
    this.getEmptyExtraFileds();

    _.forEach(this.activeHistory.rooms, (room) => {
      this.form.guests_names.push(room.guest_name);
    });
  },
  destroyed () {
    console.log('destroy');
    this.destroyStripe()
  }
}
