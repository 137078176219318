import layout from 'layouts/default'
import reservationDetails from 'layouts/default/partials/reservationDetails'

const components = {
  layout,
  reservationDetails
}

const computed = {
  reservation () {
    return this.$store.state.reservations.active;
  },

  noReservation () {
    return _.isEmpty(this.reservation);
  }
}

export default {
  name: 'reservation-details-page',
  components,
  computed,
  data () {
    return {
      // reservation: require('layouts/default/partials/reservationDetails/both_reservation.json')
    }
  },

  mounted () {
    if (this.noReservation) {
      this.$router.replace({name: 'home'});
    }

    this.$store.dispatch('title.setTitle', 'Manage Reservation');
  },

  beforeDestroy () {
    this.$store.dispatch('reservations.clear_active');
  }
}
