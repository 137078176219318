import moment from 'moment'
import toastr from 'toastr'
import { currencySymbol } from 'filters'
import { funcModal } from 'components/modals'

let components = {
  funcModal
}

const methods = {
  open () {
    this.$refs.modal.open();
  },

  confirm() {
    if (!this.reservation) {
      return;
    }

    let data = {
      number: this.reservation.reservation_number,
      reserved_by: this.reservation.reserved_by,
      check_in: moment(this.activeHistory.from_date, ['YYYY-MM-DD HH:mm:ss']).format('YYYY-MM-DD')
    }

    this.loading = true

    this.$store.dispatch('reservations.cancel', {
      id: this.reservation.id,
      data
    }).then((res) => {
      this.loading = false
      let errors = this.$store.state.reservations.errors;
      if (errors) {
        if (errors.errors) {
          this.errors = _.cloneDeep(errors.errors);
        }
        toastr.error(errors.message);
      } else {
        this.$refs.modal.close();
        toastr.success('Reservation has been cancelled');
      }
    })
  },

  requestCancellationRefunds () {
    if (!this.reservation) {
      return {}
    }

    let data = {
      number: this.reservation.reservation_number,
      reserved_by: this.reservation.reserved_by,
      check_in: moment(this.activeHistory.from_date, ['YYYY-MM-DD HH:mm:ss']).format('YYYY-MM-DD')
    }

    this.$store.dispatch('reservations.getRefundList', {
      id: this.reservation.id,
      data
    }).then(() => {
      if (this.$store.state.reservations.errors) {
        toastr.error(this.$store.state.reservations.errors.message);
      }
    });
  }
}

const computed = {
  symbol (code) {
    return currencySymbol(this.activeHistory.payment_currency);
  },
  activeHistory () {
    return _.find(this.reservation.history, item => item.active == 1) || {}
  },
  refundList () {
    return _.cloneDeep(this.$store.state.reservations.refund_list);
  }
}

export default {
  name: 'cancel-reservation',
  props: ['reservation'],
  components,
  methods,
  computed,
  data () {
    return {
      loading: false
    }
  },
  created () {
  },
  mounted () {
    this.requestCancellationRefunds()
  }
}
