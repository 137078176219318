import imageBg from 'components/imageBg'
import searchForm from '../searchForm'

const components = {
  imageBg,
  searchForm
}

export default {
  name: 'big-search',
  components,
  mounted() {

  }
}
