import toastr from 'toastr'
import { user } from 'services'

export function beforeRouteUpdateMixin () {
  return {
    beforeRouteUpdate (to, from, next) {
      const { asyncData } = this.$options
      console.log('beforeRouteUpdate', this.$options);

      if (asyncData) {
        this.$Progress.start()
        asyncData({
          app: this.$root,
          router: this.$router,
          store: this.$store,
          route: to
        }).then(() => {
          this.$Progress.finish()
          next()
        }).catch((e) => {
          this.$Progress.fail()
          if (e.response) {
            let errors = e.response.data.errors;
            let error = e.response.data.error;
            if (errors) {
              for (let i in errors) {
                toastr.error(errors[i][0]);
              }
            } else if (error) {
              toastr.error(error);
            }
          } else {
            toastr.error(e.message)
          }
          next(e)
        })
      } else {
        next()
      }
    }
  }
}

export function beforeRouteEnterMixin () {
  return {
    beforeCreate () {
      // try {
      //   if (!user.getToken() && !this.$route.meta.public) {
      //     console.log('test user.getToken()', user.getToken());
      //     this.$router.replace({
      //       name: 'login'
      //     });
      //   }
      // } catch (e) {

      // }

    }
  }
}

export function lodash () {
  return {
    computed: {
      _ () {
        return _;
      }
    }
  };
}
