var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", {}, [
    _vm._m(0),
    _vm._v(" "),
    _c("div", { staticClass: "margin-top-2x margin-bottom-4x" }, [
      _c("div", { staticClass: "row" }, [
        _c("div", { staticClass: "form-group col-12" }, [
          _c("label", { staticClass: "regular" }, [_vm._v("Email")]),
          _vm._v(" "),
          _c("input", {
            directives: [
              {
                name: "model",
                rawName: "v-model",
                value: _vm.form.email,
                expression: "form.email"
              }
            ],
            staticClass: "form-control",
            attrs: { type: "email" },
            domProps: { value: _vm.form.email },
            on: {
              input: function($event) {
                if ($event.target.composing) {
                  return
                }
                _vm.$set(_vm.form, "email", $event.target.value)
              }
            }
          }),
          _vm._v(" "),
          _vm.errors.email
            ? _c("div", { staticClass: "text-danger" }, [
                _vm._v(
                  "\n          " + _vm._s(_vm.errors.email[0]) + "\n        "
                )
              ])
            : _vm._e()
        ])
      ]),
      _vm._v(" "),
      _vm.$route.query.reservation_type != "parking"
        ? _c(
            "div",
            {},
            [
              _c("div", { staticClass: "row" }, [
                _c("div", { staticClass: "col-12 regular margin-bottom-2x" }, [
                  _vm._v(
                    "\n          " +
                      _vm._s(_vm.form.number_of_rooms) +
                      " Room" +
                      _vm._s(_vm.form.number_of_rooms != 1 ? "s" : "") +
                      "\n          & " +
                      _vm._s(_vm.totalGuestsNumber) +
                      " Guests\n        "
                  )
                ])
              ]),
              _vm._v(" "),
              _vm._l(Number(_vm.form.number_of_rooms), function(i) {
                return _c("div", { staticClass: "row" }, [
                  _c("div", { staticClass: "form-group col-12" }, [
                    _c("label", [_vm._v("Name of guest in room " + _vm._s(i))]),
                    _vm._v(" "),
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.form.guests_names[i - 1],
                          expression: "form.guests_names[i - 1]"
                        }
                      ],
                      staticClass: "form-control",
                      attrs: { type: "text" },
                      domProps: { value: _vm.form.guests_names[i - 1] },
                      on: {
                        input: function($event) {
                          if ($event.target.composing) {
                            return
                          }
                          _vm.$set(
                            _vm.form.guests_names,
                            i - 1,
                            $event.target.value
                          )
                        }
                      }
                    }),
                    _vm._v(" "),
                    _vm.errors["guests_names." + (i - 1)]
                      ? _c("div", { staticClass: "text-danger" }, [
                          _vm._v(
                            "\n            " +
                              _vm._s(_vm.errors["guests_names." + (i - 1)][0]) +
                              "\n          "
                          )
                        ])
                      : _vm._e()
                  ])
                ])
              })
            ],
            2
          )
        : _vm._e(),
      _vm._v(" "),
      _vm.$route.query.reservation_type != "room"
        ? _c("div", { staticClass: "row bg-grey mb-3 pb-2 pt-2" }, [
            _c(
              "div",
              { staticClass: "col-md-6" },
              [
                _c("number-of-cars", {
                  staticClass: "search--number-of-cars",
                  attrs: {
                    "max-number-of-spots": _vm.costs
                      ? _vm.costs.reservation.available_spots
                      : 0,
                    "number-of-spots": _vm.numberOfSpots
                  },
                  on: {
                    change: _vm.changeNumberOfSpots,
                    decrease: _vm.decreaseNumberOfSpots
                  }
                })
              ],
              1
            )
          ])
        : _vm._e(),
      _vm._v(" "),
      _vm.$route.query.reservation_type == "parking"
        ? _c("div", { staticClass: "row" }, [
            _c("div", { staticClass: "form-group col-md-6" }, [
              _c("label", { staticClass: "regular" }, [
                _vm._v("Vehicle Drop-Off")
              ]),
              _vm._v(" "),
              _c(
                "select",
                {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.form.from_time,
                      expression: "form.from_time"
                    }
                  ],
                  staticClass: "form-control",
                  on: {
                    change: [
                      function($event) {
                        var $$selectedVal = Array.prototype.filter
                          .call($event.target.options, function(o) {
                            return o.selected
                          })
                          .map(function(o) {
                            var val = "_value" in o ? o._value : o.value
                            return val
                          })
                        _vm.$set(
                          _vm.form,
                          "from_time",
                          $event.target.multiple
                            ? $$selectedVal
                            : $$selectedVal[0]
                        )
                      },
                      _vm.changeTimes
                    ]
                  }
                },
                [
                  _c("option", { domProps: { value: null } }, [
                    _vm._v("-- Choose time --")
                  ]),
                  _vm._v(" "),
                  _vm._l(_vm.times, function(time) {
                    return _c("option", { domProps: { value: time.value } }, [
                      _vm._v(
                        "\n            " + _vm._s(time.label) + "\n          "
                      )
                    ])
                  })
                ],
                2
              ),
              _vm._v(" "),
              _vm.errors.from_time
                ? _c("div", { staticClass: "text-danger" }, [
                    _vm._v(
                      "\n          " +
                        _vm._s(_vm.errors.from_time[0]) +
                        "\n        "
                    )
                  ])
                : _vm._e()
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "form-group col-md-6" }, [
              _c("label", { staticClass: "regular" }, [
                _vm._v("Vehicle Pick-Up")
              ]),
              _vm._v(" "),
              _c(
                "select",
                {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.form.to_time,
                      expression: "form.to_time"
                    }
                  ],
                  staticClass: "form-control",
                  on: {
                    change: [
                      function($event) {
                        var $$selectedVal = Array.prototype.filter
                          .call($event.target.options, function(o) {
                            return o.selected
                          })
                          .map(function(o) {
                            var val = "_value" in o ? o._value : o.value
                            return val
                          })
                        _vm.$set(
                          _vm.form,
                          "to_time",
                          $event.target.multiple
                            ? $$selectedVal
                            : $$selectedVal[0]
                        )
                      },
                      _vm.changeTimes
                    ]
                  }
                },
                [
                  _c("option", { domProps: { value: null } }, [
                    _vm._v("-- Choose time --")
                  ]),
                  _vm._v(" "),
                  _vm._l(_vm.times, function(time) {
                    return _c("option", { domProps: { value: time.value } }, [
                      _vm._v(
                        "\n            " + _vm._s(time.label) + "\n          "
                      )
                    ])
                  })
                ],
                2
              ),
              _vm._v(" "),
              _vm.errors.to_time
                ? _c("div", { staticClass: "text-danger" }, [
                    _vm._v(
                      "\n          " +
                        _vm._s(_vm.errors.to_time[0]) +
                        "\n        "
                    )
                  ])
                : _vm._e()
            ])
          ])
        : _c("div", {}, [
            _vm.$route.query.reservation_type != "parking"
              ? _c("div", { staticClass: "row margin-bottom-4x" }, [
                  _c("div", { staticClass: "col-md-6" }, [
                    _c("div", { staticClass: "regular" }, [
                      _vm._v("Room Check-in")
                    ]),
                    _vm._v(
                      "\n          After " +
                        _vm._s(
                          _vm
                            .moment(_vm.location.checkin_time, ["HH:mm"])
                            .format("hh:mm A")
                        ) +
                        "\n        "
                    )
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "col-md-6" }, [
                    _c("div", { staticClass: "regular" }, [
                      _vm._v("Room Check-out")
                    ]),
                    _vm._v(
                      "\n          Before " +
                        _vm._s(
                          _vm
                            .moment(_vm.location.checkout_time, ["HH:mm"])
                            .format("hh:mm A")
                        ) +
                        "\n        "
                    )
                  ])
                ])
              : _vm._e(),
            _vm._v(" "),
            _vm.$route.query.reservation_type != "room"
              ? _c("div", { staticClass: "row margin-bottom-4x" }, [
                  _c("div", { staticClass: "col-md-6" }, [
                    _c("div", { staticClass: "regular" }, [
                      _vm._v("Vehicle Drop-Off")
                    ]),
                    _vm._v("\n            After \n            "),
                    _vm.$route.query.nights_to_sleep == "both" ||
                    _vm.$route.query.nights_to_sleep == "beginning"
                      ? _c("span", [
                          _vm._v(
                            "\n              " +
                              _vm._s(
                                _vm
                                  .moment(_vm.location.checkin_time, ["HH:mm"])
                                  .format("hh:mm A")
                              ) +
                              "\n            "
                          )
                        ])
                      : _c("span", [
                          _vm._v(
                            "\n              " +
                              _vm._s(
                                _vm
                                  .moment(_vm.form.from_time, ["HH:mm"])
                                  .format("hh:mm A")
                              ) +
                              "\n            "
                          )
                        ])
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "col-md-6" }, [
                    _c("div", { staticClass: "regular" }, [
                      _vm._v("Vehicle Pick-Up")
                    ]),
                    _vm._v("\n            Before\n            "),
                    _vm.$route.query.nights_to_sleep == "both" ||
                    _vm.$route.query.nights_to_sleep == "end"
                      ? _c("span", [
                          _vm._v(
                            "\n              " +
                              _vm._s(
                                _vm
                                  .moment(_vm.location.checkout_time, ["HH:mm"])
                                  .format("hh:mm A")
                              ) +
                              "\n            "
                          )
                        ])
                      : _c("span", [
                          _vm._v(
                            _vm._s(
                              _vm
                                .moment(_vm.form.to_time, ["HH:mm"])
                                .format("hh:mm A")
                            )
                          )
                        ])
                  ])
                ])
              : _vm._e()
          ]),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "row" },
        _vm._l(_vm.location.extra_fields, function(field) {
          return _c(
            "div",
            { staticClass: "col-12 col-md-6 margin-bottom-2x regular" },
            [
              _vm.form.reservation_type != "room" &&
              field.per_car &&
              _vm.number_of_spots > 1
                ? _c(
                    "div",
                    _vm._l(_vm.number_of_spots, function(i) {
                      return _c("div", { key: i }, [
                        _c("div", { staticClass: "regular" }, [
                          _vm._v(
                            "\n              " + _vm._s(i) + "\n              "
                          ),
                          i == 1 ? _c("span", [_vm._v("st")]) : _vm._e(),
                          _vm._v(" "),
                          i == 2 ? _c("span", [_vm._v("nd")]) : _vm._e(),
                          _vm._v(" "),
                          i == 3 ? _c("span", [_vm._v("rd")]) : _vm._e(),
                          _vm._v(" "),
                          i > 3 ? _c("span", [_vm._v("th")]) : _vm._e(),
                          _vm._v(" "),
                          _c("label", [_vm._v(_vm._s("  " + field.label))])
                        ]),
                        _vm._v(" "),
                        _c("input", {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value: _vm.extra_fields[field.name][i - 1],
                              expression: "extra_fields[field.name][i-1]"
                            }
                          ],
                          staticClass: "form-control",
                          attrs: { type: "text" },
                          domProps: {
                            value: _vm.extra_fields[field.name][i - 1]
                          },
                          on: {
                            input: function($event) {
                              if ($event.target.composing) {
                                return
                              }
                              _vm.$set(
                                _vm.extra_fields[field.name],
                                i - 1,
                                $event.target.value
                              )
                            }
                          }
                        }),
                        _vm._v(" "),
                        _vm.errors[field.name + "." + (i - 1)]
                          ? _c("div", { staticClass: "text-danger" }, [
                              _vm._v(
                                "\n              " +
                                  _vm._s(
                                    _vm.errors[field.name + "." + (i - 1)][0]
                                  ) +
                                  "\n            "
                              )
                            ])
                          : _vm._e()
                      ])
                    })
                  )
                : _c("div", [
                    _c("label", { staticClass: "regular" }, [
                      _vm._v(_vm._s(field.label))
                    ]),
                    _vm._v(" "),
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.extra_fields[field.name][0],
                          expression: "extra_fields[field.name][0]"
                        }
                      ],
                      staticClass: "form-control",
                      attrs: { type: "text" },
                      domProps: { value: _vm.extra_fields[field.name][0] },
                      on: {
                        input: function($event) {
                          if ($event.target.composing) {
                            return
                          }
                          _vm.$set(
                            _vm.extra_fields[field.name],
                            0,
                            $event.target.value
                          )
                        }
                      }
                    }),
                    _vm._v(" "),
                    _vm.errors[field.name + ".0"]
                      ? _c("div", { staticClass: "text-danger" }, [
                          _vm._v(
                            "\n            " +
                              _vm._s(_vm.errors[field.name + ".0"][0]) +
                              "\n          "
                          )
                        ])
                      : _vm._e()
                  ])
            ]
          )
        })
      ),
      _vm._v(" "),
      _c("div", { staticClass: "text-right margin-top-5x" }, [
        _c(
          "button",
          { staticClass: "btn btn-info", on: { click: _vm.goToStepTwo } },
          [
            _vm._v("\n        Continue\n        "),
            _c("i", {
              staticClass: "fa fa-angle-right",
              attrs: { "aria-hidden": "true" }
            })
          ]
        )
      ])
    ]),
    _vm._v(" "),
    _vm.form.reservation_type == "parking" &&
    _vm.tripProtections &&
    _vm.tripProtections.length
      ? _c("div", { staticClass: "checkout-step-label inactive" }, [
          _c("div", { staticClass: "number" }, [_vm._v("\n      2\n    ")]),
          _vm._v(" "),
          _c("div", { staticClass: "title" }, [
            _vm._v("\n      Add-ons\n    ")
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "summary" }, [
            _c("table", [
              _vm.costs && _vm.costs.reservation.trip_protection
                ? _c("tr", [
                    _c("th", { staticClass: "text-right" }, [
                      _vm._v("Trip Protection")
                    ]),
                    _vm._v(" "),
                    _c("td", [
                      _vm._v(_vm._s(_vm.costs.reservation.trip_protection.name))
                    ])
                  ])
                : _c("tr", [
                    _c("td", { attrs: { colspan: "2" } }, [_vm._v("None")])
                  ])
            ])
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "edit-btn" })
        ])
      : _vm._e(),
    _vm._v(" "),
    _c("div", { staticClass: "checkout-step-label inactive" }, [
      _c("div", { staticClass: "number" }, [
        _vm._v(
          "\n      " +
            _vm._s(
              _vm.form.reservation_type == "parking" &&
                _vm.tripProtections &&
                _vm.tripProtections.length
                ? "3"
                : "2"
            ) +
            "\n    "
        )
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "title" }, [_vm._v("\n      Payment\n    ")])
    ])
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "checkout-step-label active" }, [
      _c("div", { staticClass: "number" }, [_vm._v("\n      1\n    ")]),
      _vm._v(" "),
      _c("div", { staticClass: "title" }, [
        _vm._v("\n      Your Details\n    ")
      ])
    ])
  }
]
render._withStripped = true
export { render, staticRenderFns }
if (module.hot) {
  module.hot.accept()
  if (module.hot.data) {
    require("vue-hot-reload-api")      .rerender("data-v-91490180", { render: render, staticRenderFns: staticRenderFns })
  }
}