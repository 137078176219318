import Vue from 'vue'
import Vuex from 'vuex'
import * as actions from './actions'
import * as getters from './getters'
import * as mutations from './mutations'
import title from './modules/title'
import nav from './modules/nav'
import user from './modules/user'
import googleMaps from './modules/googleMaps'

// import users from './modules/users'
import search from './modules/search'
import airports from './modules/airports'
import locations from './modules/locations'
import reservations from './modules/reservations'
import amenities from './modules/amenities'
import carousels from './modules/carousels'
import currencies from './modules/currencies'
import tripProtections from './modules/tripProtection'
import pages from './modules/pages'
import faq from './modules/faq'


Vue.use(Vuex)

export function createStore () {
  const debug = process.env.NODE_ENV !== 'prod'
  return new Vuex.Store({
    actions,
    getters,
    mutations,
    modules: {
      title,
      nav,
      user,
      googleMaps,
      // users,
      search,
      airports,
      locations,
      reservations,
      amenities,
      carousels,
      currencies,
      tripProtections,
      pages,
      faq
    },
    strict: debug,
  })
}
