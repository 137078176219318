var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("func-modal", {
    ref: "modal",
    attrs: {
      size: "large",
      "hide-footer": true,
      "hide-header": true,
      centered: true
    },
    on: { close: _vm.onCloseModal },
    scopedSlots: _vm._u([
      {
        key: "body",
        fn: function(props) {
          return [
            _c("login-form", {
              on: {
                userLoggedIn: _vm.handleUserLoggedIn,
                triggerPasswordResetModal: _vm.togglePasswordResetModal
              }
            })
          ]
        }
      }
    ])
  })
}
var staticRenderFns = []
render._withStripped = true
export { render, staticRenderFns }
if (module.hot) {
  module.hot.accept()
  if (module.hot.data) {
    require("vue-hot-reload-api")      .rerender("data-v-76fe7547", { render: render, staticRenderFns: staticRenderFns })
  }
}