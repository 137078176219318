const computed = {
  totalGuests () {
    return _.sum(this.guests);
  }
}

const methods = {
  increaseRooms () {
    this.rooms++;

    if (this.rooms > 5) this.rooms = 5;

    if (this.guests.length < 5)
      this.guests.push(2);

    this.emitChanges();
  },

  decreaseRooms () {
    this.rooms--;
    if (this.rooms < 1) this.rooms = 1;
    if (this.guests.length > 1)
      this.guests.splice(-1,1);

    this.emitChanges();
  },

  increaseGuests (i) {
    this.$set(this.guests, i, this.guests[i] + 1);
    if (this.guests[i] > 8) this.$set(this.guests, i, 8);

    this.emitChanges();
  },

  decreaseGuests (i) {
    this.$set(this.guests, i, this.guests[i] - 1);
    if (this.guests[i] < 1) this.$set(this.guests, i, 1);

    this.emitChanges();
  },

  emitChanges () {
    this.$emit('change', {
      guests: this.guests,
      rooms: this.rooms
    });
  },

  ok () {
    this.active = false;
  }
}

const watch = {
  numberOfRooms (n) {
    if (n < 1) n = 1;
    this.rooms = n;
  },

  numberOfGuests (n) {
    this.guests = n;
  }
}

export default {
  props: ['numberOfRooms', 'numberOfGuests'],
  watch,
  computed,
  methods,
  data () {
    let rooms = this.numberOfRooms;
    let guests = this.numberOfGuests;

    if (!rooms || rooms < 1) {
      rooms = 1;
    }

    if (!guests) {
      guests = [2];
    }

    if (guests.length < rooms) {
      for (let i = guests.length; i < rooms; i++) {
        guests.push(2);
      }
    }

    return {
      rooms,
      guests,
      active: false
    }
  },

  mounted () {
    //NOTE: to handle clicks outside the component
    $('body').click(evt => {
      if(
        !$(evt.target).is('.number-of-rooms-field--list, .number-of-rooms-field--label')
        && !$('.number-of-rooms-field--list').has(evt.target).length
      ) {
        this.active = false;
      }
    });
  }
}
