import layout from 'layouts/default'
import reservationDetails from 'layouts/default/partials/reservationDetails'

const components = {
  layout,
  reservationDetails
}

const computed = {
  reservation () {
    return this.$store.state.reservations.active;
  }
}

export default {
  name: 'email-reservation-details-page',
  components,
  computed,
  data () {
    return {
      // reservation: require('layouts/default/partials/reservationDetails/both_reservation.json')
    }
  },

  beforeDestroy () {
    this.$store.dispatch('reservations.clear_active');
  },

  created () {
    if (_.isEmpty(this.$route.query.access_token)) {
      this.$router.replace({name: 'home'});
    }

    this.$store.dispatch('reservations.getAsGuest', {"access_token": this.$route.query.access_token}).then(() => {
      if (_.isEmpty(this.reservation)) {
        this.$router.replace({name: 'home'});
      }
    });
  }
}
