/*
* @DESC: this component sets markers on google map and sends a callback when the center is changed
* @param markers: an array of markers with {lat, lng}
* @param canter: {lat, lng} of the map center
* @callback: v-on:mapMoved="onMapMoved({lat, lng})"
*/

import Bus from "bus"

const methods = {
  initMap () {
    this.$store.dispatch('loadGoogleMapsAPI').then(() => {

      this.map = new google.maps.Map(this.$refs.map, {
        zoom: this.zoom || 13,
        scrollwheel: false,
        center: this.center || {lat: 40.7127837, lng: -74.00594130000002}
      });

      this.iconObj = {
        url: this.icon,
        scaledSize: new google.maps.Size(40, 56),
        labelOrigin: new google.maps.Point(19, 20)
      }

      this.activeIconObj = {
        url: this.activeIcon,
        scaledSize: new google.maps.Size(50, 67),
        labelOrigin: new google.maps.Point(25, 25)
      }

      this.inactiveIconObj = {
        url: this.inactiveIcon,
        scaledSize: new google.maps.Size(40, 56),
        labelOrigin: new google.maps.Point(19, 20)
      }

      this.placeMarkers();

      this.map.addListener('tilesloaded', (function() {
        let center = this.map.getCenter();
        console.log(center.lat(), center.lng());
        this.$emit('mapMoved', {
          lat: center.lat(),
          lng: center.lng()
        });
      }).bind(this));
    });
  },
  //place all markers on map when they are changed
  placeMarkers () {
    this.deleteMarkers();
    try {
      _.each(this.markers, (item) => {
        let obj = {
          position: item,
          map: this.map,
          id: item.id,
          zIndex: item.id
        };

        if (item.label) {
          obj.label = item.label
        }

        if (this.icon) {
          obj.icon = !item.sold_out ? this.iconObj : this.inactiveIconObj
        }

        let marker = new google.maps.Marker(obj);

        if (this.icon) {
          google.maps.event.addListener(marker, 'mouseover', (() => {
            marker.setIcon(this.activeIconObj);
            marker.setZIndex(999999999999999);
            this.$emit('mouseEnterIcon', marker);
          }));
          google.maps.event.addListener(marker, 'mouseout', (() => {
            marker.setIcon(!item.sold_out ? this.iconObj : this.inactiveIconObj);
            marker.setZIndex(item.id);
            this.$emit('mouseOutIcon', marker);
          }));
          google.maps.event.addListener(marker, 'click', (() => {
            this.$emit('clickIcon', marker);
          }));
        }

        this.localMarkers.push(marker);
      });
    } catch (e) {}
    this.setMapOnAll(this.map);
  },
  //Delete all markers
  deleteMarkers () {
    this.setMapOnAll(null);
    for (let i in this.localMarkers) {
      this.localMarkers[i]
      google.maps.event.clearListeners(this.localMarkers[i], 'mouseover');
      google.maps.event.clearListeners(this.localMarkers[i], 'mouseout');
    }
    this.localMarkers = [];
  },
  // Sets the map on all markers in the array.
  setMapOnAll(map) {
    for (var i = 0; i < this.localMarkers.length; i++) {
      this.localMarkers[i].setMap(map);
    }
  }
}

const computed = {
  // marker () {
  //   console.log("this.defaultMarker changed", this.defaultMarker);
  //   return _.cloneDeep(this.defaultMarker);
  // }
}

const watch = {
  markers (n) {
    this.placeMarkers();
    console.log("markers have changed", n);
  },
  center (n) {
    console.log("center of map has changed", n);
    this.map.setCenter(n);
    this.map.setZoom(this.zoom || 13);
  }
}

export default {
  name: 'google-maps-results',
  props: ['markers', 'center', 'icon', 'activeIcon', 'inactiveIcon', 'zoom'],
  methods,
  computed,
  watch,
  data () {
    return {
      localMarkers: [],
      map: null,
      iconObj: {},
      activeIconObj: {},
      inactiveIconObj: {}
    }
  },
  mounted () {

    this.initMap();

    Bus.$on('search.mouseEnterLocation', (location) => {
      _.forEach(this.localMarkers, (item) => {
        if (location.id == item.id) {
          // item.setAnimation(google.maps.Animation.BOUNCE);

          item.setIcon(this.activeIconObj);
          item.setZIndex(999999999999999);
        }
      })
    })

    Bus.$on('search.mouseOutLocation', (location) => {
      _.forEach(this.localMarkers, (item) => {
        if (location.id == item.id) {
          // item.setAnimation(null);
          let mark = _.find(this.markers, (x) => x.id == item.id);
          let sold_out = mark ? mark.sold_out : false;
          item.setIcon(!sold_out ? this.iconObj : this.inactiveIconObj);
          item.setZIndex(item.id);
        }
      })
    })
  },
  destroyed () {
    Bus.$off('search.mouseEnterLocation')
    Bus.$off('search.mouseOutLocation')
  }
}
