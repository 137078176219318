import toastr from 'toastr'
import moment from 'moment'
import { currencySymbol } from 'filters'
import layout from 'layouts/default'
import { bookingData, urlDataValidator, searchTokens } from 'services'
import detailsStep from './details'
import addonsStep from './addons'
import paymentStep from './payment'
import checkoutSummary from './summary'
import confirmation from './confirmation'
import searchModal from 'layouts/default/partials/searchModal'
import threeDSecure from 'layouts/default/partials/threeDSecure'
import subtotalModal from 'pages/location/partials/subtotalBreakdownModal'

const components = {
  layout,
  detailsStep,
  addonsStep,
  paymentStep,
  checkoutSummary,
  confirmation,
  searchModal,
  threeDSecure,
  subtotalModal
}

const computed = {
  airport () {
    try {
      return this.$store.state.locations.active.airports[0] || null;
    } catch (e) {
      return null
    }
  },

  currencyCode() {
    return _.cloneDeep(this.$store.state.currencies.selected);
  },

  symbol () {
    if (this.location && _.isEmpty(this.currencyCode)){
      return currencySymbol(this.location.currency_code)
    } else if (!_.isEmpty(this.currencyCode)) {
      return currencySymbol(this.currencyCode)
    }

    return "$"
  },

  location () {
    return this.$store.state.locations.active || {};
  },

  currentUser () {
    return _.cloneDeep(this.$store.state.user.data);
  },

  selectedTypes () {
    let location_types = this.location.types;
    let types = {};
    console.log('this.location', this.location);
    console.log('location_types', location_types);
    if (!location_types) return types;

    for (let j of ['parking', 'room', 'bundle']) {
      if (!this.form[j + '_type']) continue;

      for (let i in location_types[j]) {
        if (location_types[j][i].id == this.form[j + '_type']) {
          types[j + '_type'] = location_types[j][i];
          break;
        }
      }
    }

    return types
  },

  costs () {
    let reservations = this.$store.state.reservations;
    console.log('reservations', reservations);
    if (this.selectedTypes.parking_type && this.selectedTypes.room_type) {
      return reservations.combination_costs[0];
    } else if (this.selectedTypes.bundle_type) {
      return reservations.bundle_costs[0];
    } else if (this.selectedTypes.parking_type) {
      return reservations.parking_costs[0];
    } else if (this.selectedTypes.room_type) {
      return reservations.room_costs[0];
    }
  },

  numberOfSpots () {
    if (this.query.reservation_type == 'room') return false;

    let number_of_spots = 1;

    if (this.form.number_of_spots) {
      if (this.form.number_of_spots > 5) {
        number_of_spots = 5;
      } else if (this.form.number_of_spots < 1) {
        number_of_spots = 1;
      } else {
        number_of_spots = this.form.number_of_spots;
      }
    }

    return number_of_spots
  },

  totalDays () {
    if (!this.costs) return null
    
    return this.query.reservation_type == 'parking' ? 
      this.costs.total_days.parking.number_of_days :
      this.costs.total_days.room.number_of_days;
  }
}

const methods = {
  initDataToSend () {
    let data = {
      reservation_type: this.query.reservation_type,
      from_date: moment(this.query.from_date, ['DD/MMM/YYYY']),
      to_date: moment(this.query.to_date, ['DD/MMM/YYYY']),
      location_id: this.$route.query.location_id
    };

    if (this.query.reservation_type == 'room' || this.query.reservation_type == 'both') {
      data.number_of_rooms = this.query.number_of_rooms;
      data.guests_in_rooms = this.query.guests_in_rooms.slice(0, this.query.number_of_rooms);
      data.room_type = this.query.room_type;
      data.bundle_id = this.query.bundle_type;
    }

    if (this.query.reservation_type == 'parking' || this.query.reservation_type == 'both') {
      data.number_of_spots = this.numberOfSpots;
      data.parking_type = this.query.parking_type;
    }

    if (this.query.reservation_type == 'parking' ) {
      let fromHr, toHr;
      if (!this.form.from_time || !this.form.to_time) {
        fromHr = moment().add(1, 'hours').format('HH:00:00');
        toHr = moment().add(2, 'hours').format('HH:00:00');
      } else {
        fromHr = this.form.from_time + ':00';
        toHr = this.form.to_time + ':00';
      }
      data.from_date = data.from_date.format('YYYY-MM-DD ' + fromHr);
      data.to_date = data.to_date.format('YYYY-MM-DD ' + toHr);
      data.trip_protection_id = this.form.trip_protection_id;
    } else {
      let from_time = this.form.from_time, to_time = this.form.to_time;
      data.from_date = data.from_date.format(`YYYY-MM-DD ${from_time}:00`);
      data.to_date = data.to_date.format(`YYYY-MM-DD ${to_time}:00`);
    }

    if (this.query.reservation_type == 'both') {
      data.nights_to_sleep = this.query.nights_to_sleep;
    }

    return data;
  },

  calculateCosts () {
    let data = this.initDataToSend();
    this.$store.dispatch('reservations.clearCosts');
    return (this.$store.dispatch('reservations.cost', {data, currencyCode: this.currencyCode})).then((res) => {

      if (this.$store.state.reservations.errors) {
        if (!_.isEmpty(this.$store.state.reservations.errors.errors)) {
          this.errors = _.cloneDeep(this.$store.state.reservations.errors.errors);
        }
        toastr.error(this.$store.state.reservations.errors.message);
      }
    })
  },

  submitForm (stripe, elements) {
    this.errors = {}
    return new Promise((resolve, reject) => {
      stripe.createSource(elements.card_element).then((result) => {
        if (result.error) {
          if (result.error.code == 'incomplete_expiry') {
            this.errors.expiration = [result.error.message];
          } else if (result.error.code == 'incomplete_cvc') {
            this.errors.cvc = [result.error.message];
          } else {
            this.errors.card_number = [result.error.message];
          }
          resolve(this.errors);
          return;
        }

        this.form.three_d_secure = result.source.card.three_d_secure;

        stripe.createToken(elements.card_element, {
          address_zip: this.form.zipcode || '',
          name: this.form.first_name + " " + this.form.last_name
        }).then((result) => {
          if (result.error) {
            if (result.error.code == 'incomplete_expiry') {
              this.errors.expiration = [result.error.message];
            } else if (result.error.code == 'incomplete_cvc') {
              this.errors.cvc = [result.error.message];
            } else {
              this.errors.card_number = [result.error.message];
            }
            resolve(this.errors);
            return;
          }

          this.form.card_number = result.token.card.last4
          this.form.expiry_month = result.token.card.exp_month
          this.form.expiry_year = result.token.card.exp_year
          this.form.card_id = result.token.card.id
          this.form.brand = result.token.card.brand
          this.form.card_token = result.token.id

          this.sendData().then(resolve).catch(reject);
        });
      })
    })
  },

  getErrorMessage(message) {
    if (message.includes("Number of check-in booking exceeded hourly slot")) {
      return "The shuttle bus will be full for the selected check-in time. Please select an alternative check-in time to reserve a place on the shuttle bus";
    } else if (message.includes("Number of check-out booking exceeded hourly slot")) {
      return "The shuttle bus will be full for the selected check-out time. Please select an alternative check-out time to reserve a place on the shuttle bus"
    }
    return message;
  },

  sendData () {
    let data = _.assign(_.cloneDeep(this.form), this.initDataToSend());
    
    // if (!data.first_name) {
    //   data.first_name = data.first_name_on_card;
    // }
    //
    // if (!data.last_name) {
    //   data.last_name = data.last_name_on_card;
    // }

    if (data.reservation_type != 'parking') {
      delete data.trip_protection_id;
    }

    data.name_on_card = `${data.first_name} ${data.last_name}`;

    data.reserved_by = data.email;
    data.currency_code = this.currencyCode;
    data.url = this.threeDsecure.redirectUrl;

    if (this.threeDsecure.source_id) {
      data.source_id = this.threeDsecure.source_id;
    }

    this.$refs.paymentComponent.processing = true;
    this.errors = {}

    let tokens = searchTokens.get();
    data.search_token = tokens ? tokens[this.$route.query.location_id] || '' : '';
    return (this.$store.dispatch('reservations.add', _.cloneDeep(data))).then(() => {
      this.$refs.paymentComponent.processing = false;
      this.threeDsecure.source_id = null;
      let errors = this.$store.state.reservations.errors;
      if (errors) {
        if (errors.status_code == 400) {
          let error_code = this.$store.state.reservations.errors.error_code;
          if (!_.isEmpty(error_code) && error_code == "hours_before_reservation_error") {
            toastr.error(this.$store.state.reservations.errors.message);
          } else if (!_.isEmpty(error_code) && error_code == "3d_secure_required") {
            this.threeDsecure.url = errors.extra_params.return_url;
            toastr.warning('The card you used requires 3d secure confirmation');
          } else {
            toastr.error(this.getErrorMessage(this.$store.state.reservations.errors.message));
          }
        } else {
          toastr.error(this.getErrorMessage(this.$store.state.reservations.errors.message));
          this.errors = _.cloneDeep(errors.errors);
        }
      } else {
        let reservation = this.$store.state.reservations.active;
        let res_type = this.query.reservation_type != 'both' ? this.query.reservation_type : 'bundle';
        let type_obj = {}
        if (['parking', 'room'].indexOf(this.query.reservation_type) > -1 || this.selectedTypes.bundle_type) {
          type_obj.name = res_type + " - " + this.selectedTypes[res_type + '_type'].name;
          type_obj.id = this.selectedTypes[res_type + '_type'].id;
        } else {
          type_obj.name = "combination - parking:" + this.selectedTypes.parking_type.name + ", room:" + this.selectedTypes.room_type.name
          type_obj.id = "parking:" + this.selectedTypes.parking_type.id + ", room:" + this.selectedTypes.room_type.id;
        }

        dataLayer.push({
          'ecommerce': {
            'purchase': {
              'actionField': {
                'id': reservation.reservation_number, // Transaction ID. Required for purchases and refunds.
                'affiliation': 'Online Store',
                'revenue': reservation.history[0].grand_total, // Total transaction value (incl. tax and shipping)
                'tax': reservation.history[0].total_tax,
                // 'coupon': reservation.history[0].coupon.code,
                'fees': reservation.history[0].total_fees
              },
              'products': [{ // List of productFieldObjects.
                'name': type_obj.name, // Name or ID is required.
                'id': type_obj.id,
                'price': reservation.history[0].grand_total,
                'category': this.location.name,
                'quantity': 1,
                'number_of_cars': data.number_of_spots,
                'number_of_rooms': data.number_of_rooms
              }]
            }
          }
        });

        //google analytics event
        var obj = {
          'event': 'purchase-completed',
          'reservationId': reservation.reservation_number,
          'purchaseTotal': reservation.history[0].grand_total
        };

        try {
          obj.portName = this.airport.name;
        } catch (e) {
          obj.portName = "not specified";
        }
        dataLayer.push(obj);

        toastr.success('A new Reservation has been created');
        bookingData.clear();
        // this.$router.push({name: 'home'});
        this.confirmation = true;
        this.$router.replace({query: {}});
        $('html, body').scrollTop(0);
      }
    })
  },
  onStepChange (step) {
    this.step = step;
    dataLayer.push({
      'event': 'checkoutOption',
      'ecommerce': {
        'checkout_option': {
          'actionField': { 'step': step }
        }
      }
    });
  },
  onSelectTripProtection (id) {
    this.form.trip_protection_id = id;
    this.loading = true;
    this.calculateCosts().then(() => {
      this.loading = false;
    });
  },
  onChangeTimes ({from_time, to_time}) {
    this.form.from_time = from_time;
    this.form.to_time = to_time;
    this.loading = true;
    this.calculateCosts().then(() => {
      this.loading = false;
    });
  },

  onChangeNumberOfSpots (value) {
    this.loading = true;
    this.form.number_of_spots = value
    this.query.number_of_spots = value
    this.calculateCosts().then(() => {
      this.loading = false;
    });
  },

  onFormChanged (newData) {
    this.form = _.assign(this.form, newData);
    let data_to_save = {};
    let fields_to_save = [
      'first_name',
      'last_name',
      'phone',
      'zipcode',
      'email',
      'guests_names',
      'from_time',
      'to_time',
      'trip_protection_id',
    ];

    console.log('fields_to_save', fields_to_save);


    for (let i in fields_to_save) {
      data_to_save[fields_to_save[i]] = this.form[fields_to_save[i]]
    }
    bookingData.set(data_to_save);
  },

  openSearchModal () {
    this.canShowSearchModal = true;
    this.$nextTick(() => {
      this.$refs.searchModal.open();
    })
  },
  openLoginModal () {
    this.$refs.loginModal.open();
  },
  onCloseModal () {
    this.canShowSearchModal = false;
  },
  goToDetails () {
    this.step = 'details';
    this.$router.replace({
      query: Object.assign({}, this.$route.query, { step: 'details' }),
    });
  },
  goToSummary () {
    this.$nextTick(() => {
      console.log('$(".checkout-summary")', $(".checkout-summary"));

      $('html, body').animate({
        scrollTop: $(".checkout-summary").offset().top
      }, 500);
    });
  },

  onCloseThreeDSecure () {
    this.threeDsecure.url = null;
  },
  openSubtotalModal() {
    this.showSubtotalModal = false;
    
    if (this.query.reservation_type == 'parking') {
      this.selectedType = this.selectedTypes.parking_type;
    } else if (this.query.reservation_type == 'room') {
      this.selectedType = this.selectedTypes.room_type;
    } else if (this.selectedTypes.bundle_type) {        
      this.selectedType = this.selectedTypes.bundle_type;
    } else {
      this.selectedType = {
        parking_type: this.selectedTypes.parking_type,
        room_type: this.selectedTypes.room_type
      }
    }

    console.log('this.selectedTypes', this.selectedTypes);
    console.log('selectedType', this.selectedType);
    

    this.$nextTick(() => {
      this.showSubtotalModal = true;
      this.$nextTick(() => {
        this.$refs.breakDownModal.open();  
      })
    })
  },
}

const watch = {
  currencyCode (n, o) {
    if (n && o && n != o && !this.confirmation) {
      this.loading = true;
      this.calculateCosts().then(() => {
        this.loading = false;
      });
    }
    console.log('currencyCode xxx', n, o);
  }
}

export default {
  name: 'checkout-process',
  components,
  methods,
  computed,
  watch,
  data () {
    let query = _.cloneDeep(this.$route.query);
    console.log('this.$route.query', this.$route.query);
    if (query.guests_in_rooms) {
      try {
        query.guests_in_rooms = JSON.parse(query.guests_in_rooms);
      } catch (err) {
        query.guests_in_rooms = [2];
      }
    }

    query.number_of_rooms = urlDataValidator.validateNoOfRoomsAndParking(query.number_of_rooms)
    query.number_of_spots = urlDataValidator.validateNoOfRoomsAndParking(query.number_of_spots)
    query.guests_in_rooms = urlDataValidator.validateGuestsInRooms(query.guests_in_rooms, query.number_of_rooms);

    let form = {
      first_name: null,
      last_name: null,
      phone: null,
      zipcode: null,
      email: null,
      guests_names: [],
      card_number: null,
      expiry_month: null,
      expiry_year: null,
      card_token: null,
      three_d_secure: null,
      card_id: null,
      brand: null,
      from_time: null,
      to_time: null,
      trip_protection_id: null,
      state: "none"
    }

    form = _.assign(form, query);

    return {
      step: ['details', 'addons', 'payment'].indexOf(this.$route.query.step) < 0
        ? 'details'
        : this.$route.query.step, //{details, addons, payment}
      loading: true,
      canShowSearchModal: false,
      confirmation: false,
      query,
      form,
      threeDsecure: {
        source_id: null,
        url: null,
        redirectUrl: null
      },
      errors: {},
      selectedType: null,
      showSubtotalModal: false,
    }
  },

  // created () {
  //   console.log('this.$route.query', this.$route.query, _.isEmpty(this.$route.query));
  //
  // },
  mounted () {
    if (_.isEmpty(this.$route.query)) {
      this.$router.replace({name: 'home'});
      return;
    }
    this.$store.dispatch('title.setTitle', 'Checkout your booking');

    this.threeDsecure.redirectUrl = document.location.protocol + '//' + document.location.hostname + (location.port ? ':'+location.port: '')

    window.setSource = (source) => {
      console.log('source', source);
      this.threeDsecure.source_id = source;
      this.threeDsecure.url = null;
      this.sendData();
    }
    if (this.currentUser) {
      this.form.first_name = this.currentUser.first_name;
      this.form.last_name = this.currentUser.last_name;
      this.form.email = this.currentUser.email;
    }
    let form_data = bookingData.get();

    if (form_data) {
      this.form = _.assign(this.form, form_data);
    } else {
      this.goToDetails()
    }

    this.$store.dispatch('tripProtections.getAll', {_all: true})
    this.$store.dispatch('locations.get', {
      id: this.$route.query.location_id,
      params: {reservation_type: this.$route.query.reservation_type}
    }).then(() => {
      if (this.location.tripadvisor_id) {
        this.$store.dispatch('locations.getTripadvisorReviews', this.location.id)
      }
      
      Promise.all([
        this.$store.dispatch('locations.getTypes', {
          id: this.$route.query.location_id,
          params: {_all: true, reservation_type: this.$route.query.reservation_type}
        }),
        this.calculateCosts()
      ]).then(() => {
        this.loading = false;
        this.goToSummary();
      }).catch((x) => {
        console.log('x', x);
        this.loading = false;
      });
    })
  }
}
