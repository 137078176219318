import { http } from 'services'
// import axios from 'axios'
// initial state
const state = () => ({
  active: {},
  errors: null,
})

// getters
const getters = {
}

// actions
const actions = {
  ['pages.get'] ({commit}, slug) {
    commit('CLEAR_ERRORS');
    return (http.get(`pages/${slug}`)).then ((res) => {
      commit('PAGES_GET', res);
    }).catch ((res) => {
      commit('PAGES_ERROR', res);
    })
  },
}

// mutations
const mutations = {
  PAGES_GET (state, res) {
    state.active = Object.assign({}, res.data);
  },

  //ERRORS
  PAGES_ERROR (state, err) {
    state.errors = err.response.data;
  },

  CLEAR_ERRORS (state) {
    state.errors = null
  }
}

export default {
  state,
  getters,
  actions,
  mutations
}
