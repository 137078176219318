var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "checkout-summary" },
    [
      _c("div", { staticClass: "summary-location d-flex" }, [
        _c("div", { staticClass: "location-info" }, [
          _c("div", { staticClass: "regular" }, [
            _vm._v("\n        " + _vm._s(_vm.location.name) + "\n      ")
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "f14" }, [
            _vm._v("\n        " + _vm._s(_vm.location.address) + "\n      ")
          ]),
          _vm._v(" "),
          _vm.form.reservation_type != "parking"
            ? _c(
                "div",
                [
                  _vm.showTripadvisorReviews
                    ? _c("tripadvisor-rating", {
                        staticClass:
                          "d-flex bold margin-top-2x align-items-center",
                        attrs: {
                          location_link:
                            _vm.location.tripadvisor_reviews.web_url,
                          rating: _vm.location.tripadvisor_reviews.rating,
                          reviews_count:
                            _vm.location.tripadvisor_reviews.num_reviews,
                          font_size: "12px",
                          show_icon: true
                        }
                      })
                    : _c("div", { staticClass: "d-flex bold f14" }, [
                        _c("span", { staticClass: "text-warning" }, [
                          _vm._v("Not rated yet")
                        ])
                      ])
                ],
                1
              )
            : _vm._e()
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "location-image" }, [
          _vm.featuredPhoto
            ? _c(
                "a",
                {
                  attrs: { "data-lightbox": "gallery", href: _vm.featuredPhoto }
                },
                [
                  _c("img", {
                    staticClass: "w-100",
                    attrs: { src: _vm.featuredPhoto, alt: "" }
                  })
                ]
              )
            : _c(
                "a",
                {
                  attrs: {
                    "data-lightbox": "gallery",
                    href: "/assets/images/not-found.png"
                  }
                },
                [
                  _c("img", {
                    staticClass: "w-100",
                    attrs: { src: "/assets/images/not-found.png", alt: "" }
                  })
                ]
              )
        ])
      ]),
      _vm._v(" "),
      _c("hr"),
      _vm._v(" "),
      _c("div", { staticClass: "guests-and-dates f15" }, [
        _vm.selectedTypes.bundle_type &&
        !(_vm.selectedTypes.room_type || _vm.selectedTypes.parking_type)
          ? _c("div", {}, [
              _c("div", { staticClass: "margin-bottom-2x" }, [
                _vm._v(
                  "\n        " +
                    _vm._s(_vm.selectedTypes.bundle_type.room_type.name) +
                    " room &\n        " +
                    _vm._s(_vm.selectedTypes.bundle_type.parking_type.name) +
                    " parking\n      "
                )
              ])
            ])
          : _vm._e(),
        _vm._v(" "),
        _c("div", { staticClass: "margin-bottom-2x" }, [
          _vm.selectedTypes.room_type && !_vm.selectedTypes.bundle_type
            ? _c("span", [
                _vm._v(
                  "\n        " +
                    _vm._s(_vm.selectedTypes.room_type.name) +
                    " room\n      "
                )
              ])
            : _vm._e(),
          _vm._v(" "),
          _vm.selectedTypes.room_type && _vm.selectedTypes.parking_type
            ? _c("span", [_vm._v(" & ")])
            : _vm._e(),
          _vm._v(" "),
          _vm.selectedTypes.parking_type && !_vm.selectedTypes.bundle_type
            ? _c("span", [
                _vm._v(
                  "\n        " +
                    _vm._s(_vm.selectedTypes.parking_type.name) +
                    " parking\n      "
                )
              ])
            : _vm._e()
        ]),
        _vm._v(" "),
        _vm.costs
          ? _c("div", { staticClass: "margin-bottom-2x" }, [
              _vm._v(
                "\n      " +
                  _vm._s(
                    _vm.selectedTypes.room_type
                      ? _vm.costs.total_days.room.number_of_days +
                          " night" +
                          (_vm.costs.total_days.room.number_of_days != 1
                            ? "s"
                            : "")
                      : ""
                  ) +
                  "\n      "
              ),
              _vm.selectedTypes.parking_type && _vm.selectedTypes.room_type
                ? _c("span", [_vm._v(" & ")])
                : _vm._e(),
              _vm._v(
                "\n      " +
                  _vm._s(
                    _vm.selectedTypes.parking_type
                      ? _vm.costs.total_days.parking.number_of_days +
                          " day" +
                          (_vm.costs.total_days.parking.number_of_days != 1
                            ? "s"
                            : "") +
                          " of parking"
                      : ""
                  ) +
                  "\n    "
              )
            ])
          : _vm._e(),
        _vm._v(" "),
        _c("div", { staticClass: "margin-bottom-2x" }, [
          _c("i", {
            staticClass: "fa fa-calendar-o",
            attrs: { "aria-hidden": "true" }
          }),
          _vm._v("\n      " + _vm._s(_vm.form.from_date) + "\n      "),
          _c("i", {
            staticClass: "fa fa-long-arrow-right margin-left-1x",
            attrs: { "aria-hidden": "true" }
          }),
          _vm._v("\n      " + _vm._s(_vm.form.to_date) + "\n    ")
        ]),
        _vm._v(" "),
        _vm.form.reservation_type != "parking"
          ? _c("div", { staticClass: "margin-bottom-2x" }, [
              _c("i", {
                staticClass: "fa fa-users",
                attrs: { "aria-hidden": "true" }
              }),
              _vm._v(
                "\n      " +
                  _vm._s(_vm.totalGuestsNumber) +
                  " Guest" +
                  _vm._s(_vm.totalGuestsNumber != 1 ? "s" : "") +
                  "\n      in\n      " +
                  _vm._s(_vm.form.number_of_rooms) +
                  " Room" +
                  _vm._s(_vm.form.number_of_rooms != 1 ? "s" : "") +
                  "\n    "
              )
            ])
          : _vm._e(),
        _vm._v(" "),
        _vm.form.reservation_type == "both"
          ? _c("div", { staticClass: "margin-bottom-2x" }, [
              _c("i", {
                staticClass: "fa fa-bed",
                attrs: { "aria-hidden": "true" }
              }),
              _vm._v(" "),
              _vm.form.nights_to_sleep == "both"
                ? _c("span", [
                    _vm._v("\n        Stay first & last nights\n      ")
                  ])
                : _vm._e(),
              _vm._v(" "),
              _vm.form.nights_to_sleep == "end"
                ? _c("span", [_vm._v("\n        Stay the last night\n      ")])
                : _vm._e(),
              _vm._v(" "),
              _vm.form.nights_to_sleep == "beginning"
                ? _c("span", [_vm._v("\n        Stay the first night\n      ")])
                : _vm._e()
            ])
          : _vm._e(),
        _vm._v(" "),
        _vm.numberOfSpots
          ? _c("div", { staticClass: "margin-bottom-2x" }, [
              _c("i", {
                staticClass: "fa fa-car",
                attrs: { "aria-hidden": "true" }
              }),
              _vm._v(
                "\n      " +
                  _vm._s(_vm.numberOfSpots) +
                  " car" +
                  _vm._s(_vm.numberOfSpots != 1 ? "s" : "") +
                  "\n    "
              )
            ])
          : _vm._e(),
        _vm._v(" "),
        _vm.form.from_time && _vm.form.reservation_type == "parking"
          ? _c("div", { staticClass: "margin-bottom-2x" }, [
              _c("i", {
                staticClass: "fa fa-clock-o",
                attrs: { "aria-hidden": "true" }
              }),
              _vm._v(
                "\n      Vehicle Drop-Off on: " +
                  _vm._s(_vm.form.from_date) +
                  " " +
                  _vm._s(
                    _vm
                      .moment(_vm.form.from_time, ["HH:mm:ss"])
                      .format("hh:mm a")
                  ) +
                  "\n    "
              )
            ])
          : _vm._e(),
        _vm._v(" "),
        _vm.form.to_time && _vm.form.reservation_type == "parking"
          ? _c("div", { staticClass: "margin-bottom-2x" }, [
              _c("i", {
                staticClass: "fa fa-clock-o",
                attrs: { "aria-hidden": "true" }
              }),
              _vm._v(
                "\n      Vehicle Pick-Up on: " +
                  _vm._s(_vm.form.to_date) +
                  " " +
                  _vm._s(
                    _vm.moment(_vm.form.to_time, ["HH:mm:ss"]).format("hh:mm a")
                  ) +
                  "\n    "
              )
            ])
          : _vm._e(),
        _vm._v(" "),
        _c("div", { staticClass: "text-right f14" }, [
          _c(
            "a",
            {
              attrs: { href: "#" },
              on: {
                click: function($event) {
                  $event.preventDefault()
                  return _vm.editSearch($event)
                }
              }
            },
            [_vm._v("Edit")]
          )
        ]),
        _vm._v(" "),
        _c("hr")
      ]),
      _vm._v(" "),
      _vm.costs
        ? _c("div", { staticClass: "calculations" }, [
            _vm.showCheckOutInWarning
              ? _c(
                  "div",
                  {
                    staticClass: "alert alert-warning",
                    attrs: { role: "alert" }
                  },
                  [
                    _c("i", {
                      staticClass: "fa fa-warning",
                      attrs: { "aria-hidden": "true" }
                    }),
                    _vm._v(" "),
                    _vm.costs.warning.includes(
                      "Number of check-in booking exceeded hourly slot"
                    )
                      ? _c("span", [
                          _vm._v(
                            "\n        The shuttle bus will be full for the selected check-in time. Please select an alternative check-in time to reserve a place on the shuttle bus\n      "
                          )
                        ])
                      : _c("span", [
                          _vm._v(
                            "\n        The shuttle bus will be full for the selected check-out time. Please select an alternative check-out time to reserve a place on the shuttle bus\n      "
                          )
                        ])
                  ]
                )
              : _vm._e(),
            _vm._v(" "),
            _c(
              "table",
              { staticClass: "w-100" },
              [
                _c("tr", [
                  _c("th", [
                    _c(
                      "a",
                      {
                        attrs: { href: "#" },
                        on: {
                          click: function($event) {
                            $event.preventDefault()
                            return _vm.toggleSubtotalModal($event)
                          }
                        }
                      },
                      [_vm._v("Subtotal")]
                    )
                  ]),
                  _vm._v(" "),
                  _c("td", [
                    _vm._v(
                      _vm._s(
                        _vm._f("currency")(
                          _vm.costs.reservation.sub_total,
                          _vm.symbol
                        )
                      )
                    )
                  ])
                ]),
                _vm._v(" "),
                _vm._l(_vm.costs.reservation.fees, function(fee) {
                  return _c("tr", [
                    _c(
                      "th",
                      [
                        _vm._v(
                          "\n          " + _vm._s(fee.name) + "\n          "
                        ),
                        fee.description
                          ? _c(
                              "popover",
                              {
                                attrs: { options: { content: fee.description } }
                              },
                              [
                                _c("i", {
                                  staticClass: "fa fa-info-circle",
                                  attrs: { "aria-hidden": "true" }
                                })
                              ]
                            )
                          : _vm._e()
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c("td", [
                      _vm._v(_vm._s(_vm._f("currency")(fee.total, _vm.symbol)))
                    ])
                  ])
                }),
                _vm._v(" "),
                _vm.costs.reservation.coupon &&
                _vm.costs.reservation.coupon.code
                  ? _c("tr", [
                      _c("th", [
                        _c("i", {
                          staticClass: "fa fa-times",
                          staticStyle: { cursor: "pointer" },
                          attrs: { "aria-hidden": "true" },
                          on: {
                            click: function($event) {
                              _vm.removeCoupon()
                            }
                          }
                        }),
                        _vm._v(
                          "\n          Coupon (" +
                            _vm._s(_vm.costs.reservation.coupon.code) +
                            ") Total\n        "
                        )
                      ]),
                      _vm._v(" "),
                      _c("td", [
                        _vm._v(
                          "-" +
                            _vm._s(
                              _vm._f("currency")(
                                _vm.costs.reservation.channel_discount,
                                _vm.symbol
                              )
                            )
                        )
                      ])
                    ])
                  : _vm._e(),
                _vm._v(" "),
                _vm.costs.reservation.long_term_discount
                  ? _c("tr", [
                      _c("th", [
                        _vm._v("\n          Long Term Discount\n        ")
                      ]),
                      _vm._v(" "),
                      _c("td", [
                        _vm._v(
                          "-" +
                            _vm._s(
                              _vm._f("currency")(
                                _vm.costs.reservation.long_term_discount,
                                _vm.symbol
                              )
                            )
                        )
                      ])
                    ])
                  : _vm._e(),
                _vm._v(" "),
                _vm.costs.reservation.tax_total
                  ? _c("tr", [
                      _c("th", [_vm._v("Tax Total")]),
                      _vm._v(" "),
                      _c("td", [
                        _vm._v(
                          _vm._s(
                            _vm._f("currency")(
                              _vm.costs.reservation.tax_total,
                              _vm.symbol
                            )
                          )
                        )
                      ])
                    ])
                  : _vm._e(),
                _vm._v(" "),
                _vm.costs.reservation.trip_protection
                  ? _c("tr", [
                      _c("th", [
                        _vm._v("\n          Trip Protection\n          "),
                        _c("small", [
                          _vm._v(
                            _vm._s(_vm.costs.reservation.trip_protection.name)
                          )
                        ])
                      ]),
                      _vm._v(" "),
                      _c("td", [
                        _vm._v(
                          _vm._s(
                            _vm._f("currency")(
                              _vm.costs.reservation.trip_protection_total,
                              _vm.symbol
                            )
                          )
                        )
                      ])
                    ])
                  : _vm._e()
              ],
              2
            ),
            _vm._v(" "),
            _c("hr"),
            _vm._v(" "),
            _c("table", { staticClass: "w-100" }, [
              _c("tr", [
                _c("th", { staticClass: "regular" }, [
                  _vm._v("\n          Total\n        ")
                ]),
                _vm._v(" "),
                _c("td", { staticClass: "regular" }, [
                  _vm._v(
                    "\n          " +
                      _vm._s(
                        _vm._f("currency")(
                          _vm.costs.reservation.grand_total,
                          _vm.symbol
                        )
                      ) +
                      "\n        "
                  )
                ])
              ]),
              _vm._v(" "),
              _c("tr", [
                _c("th", { staticClass: "regular" }, [
                  _vm._v("\n          Due Now\n        ")
                ]),
                _vm._v(" "),
                _c("td", { staticClass: "regular" }, [
                  _vm._v(
                    "\n          " +
                      _vm._s(
                        _vm._f("currency")(
                          _vm.costs.reservation.amount_to_pay,
                          _vm.symbol
                        )
                      ) +
                      "\n        "
                  )
                ])
              ]),
              _vm._v(" "),
              _vm.costs.reservation.due_at_location
                ? _c("tr", [
                    _c("th", { staticClass: "regular" }, [
                      _vm._v("\n          Due At Check-in\n        ")
                    ]),
                    _vm._v(" "),
                    _c("td", { staticClass: "regular" }, [
                      _vm._v(
                        "\n          " +
                          _vm._s(
                            _vm._f("currency")(
                              _vm.costs.reservation.due_at_location,
                              _vm.symbol
                            )
                          ) +
                          "\n        "
                      )
                    ])
                  ])
                : _vm._e()
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "regular f14" }, [
              _vm.sortedCancellationPolicies.length
                ? _c(
                    "div",
                    _vm._l(_vm.sortedCancellationPolicies, function(policy, i) {
                      return _c("div", [
                        policy.percentage == 100
                          ? _c("span", [
                              _vm._v(
                                " \n            Fully refundable until " +
                                  _vm._s(_vm.getFromDate(policy, false)) +
                                  "\n          "
                              )
                            ])
                          : _c("span", [
                              _vm._v(
                                "\n            " +
                                  _vm._s(policy.percentage) +
                                  "% Refund until " +
                                  _vm._s(_vm.getFromDate(policy, false)) +
                                  "\n          "
                              )
                            ])
                      ])
                    })
                  )
                : _c("div", [
                    _c("span", { staticClass: "text-danger" }, [
                      _vm._v(
                        "\n          This reservation is NON-REFUNDABLE\n        "
                      )
                    ])
                  ])
            ]),
            _vm._v(" "),
            _c("hr")
          ])
        : _vm._e(),
      _vm._v(" "),
      _vm.differentCurrencyWhenDueAtLot
        ? _c(
            "div",
            { staticClass: "alert alert-warning", attrs: { role: "alert" } },
            [
              _c("i", {
                staticClass: "fa fa-warning",
                attrs: { "aria-hidden": "true" }
              }),
              _vm._v(
                "\n\n      We charge you in the currency of your choice, but the remaining amount will have to be paid at location in " +
                  _vm._s(_vm.location.currency_code) +
                  " equivalent: " +
                  _vm._s(
                    _vm._f("currency")(
                      _vm.costs.reservation.orginal_due_at_location,
                      _vm.locationCurrencySymbol
                    )
                  ) +
                  "\n      \n  "
              )
            ]
          )
        : _vm._e(),
      _vm._v(" "),
      _vm.differentCurrencyWhenDueAtLot ? _c("hr") : _vm._e(),
      _vm._v(" "),
      _c("div", { staticClass: "d-block d-md-none" }, [
        _c(
          "button",
          {
            staticClass: "btn btn-block btn-light",
            on: {
              click: function($event) {
                $event.preventDefault()
                return _vm.proceed($event)
              }
            }
          },
          [
            _vm._v("\n      Proceed\n      "),
            _c("i", {
              staticClass: "fa fa-caret-down",
              attrs: { "aria-hidden": "true" }
            })
          ]
        ),
        _vm._v(" "),
        _c("hr")
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "information f14" }, [
        _vm._m(0),
        _vm._v(" "),
        _c("div", { staticClass: "margin-top-2x" }, [
          _c(
            "a",
            {
              attrs: { href: "#" },
              on: {
                click: function($event) {
                  $event.preventDefault()
                  return _vm.showDetailsModal($event)
                }
              }
            },
            [
              _c("i", {
                staticClass: "fa fa-power-off",
                attrs: { "aria-hidden": "true" }
              }),
              _vm._v(
                "\n        View Cancellation Policy & Special Conditions\n        "
              )
            ]
          )
        ])
      ]),
      _vm._v(" "),
      _c("details-modal", {
        ref: "detailsModal",
        attrs: {
          "cancellation-policy": _vm.location.cancellation_policies,
          "reservation-type": _vm.reservation_type,
          "special-conditions": _vm.location.special_conditions
        }
      })
    ],
    1
  )
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", {}, [
      _c("i", { staticClass: "fa fa-check", attrs: { "aria-hidden": "true" } }),
      _vm._v("\n      Free Shuttle Included\n    ")
    ])
  }
]
render._withStripped = true
export { render, staticRenderFns }
if (module.hot) {
  module.hot.accept()
  if (module.hot.data) {
    require("vue-hot-reload-api")      .rerender("data-v-397e70b8", { render: render, staticRenderFns: staticRenderFns })
  }
}