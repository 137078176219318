import env from 'constants/env'

// initial state
const state = () => ({
  mapsAPILoaded: false,
  errors: null
})

// getters
const getters = {
}

// actions
let promise = null;
const actions = {
  loadGoogleMapsAPI ({ commit, state }) {
    if (!state.mapsAPILoaded) {
      commit('INIT_GOOGLE_MAPS');
      console.log("mapsAPILoaded", state.mapsAPILoaded);
      promise = $.ajax({
        url: `https://maps.googleapis.com/maps/api/js?key=${env.default.googleKey}&sensor=false&libraries=places`,
        dataType: 'script',
        success: function () {

        },
        async: true
      });
    }

    return promise;
  }
}

// mutations
const mutations = {
  INIT_GOOGLE_MAPS (state) {
    state.mapsAPILoaded = true;
  },
  GOOGLE_MAPS_ERROR (state, err) {
    state.errors = err;
  }
}

export default {
  state,
  getters,
  actions,
  mutations
}
