// import PageNotFound from 'pages/notfound/pageNotFound'
import Main from 'pages/main'
import Mutual from 'pages/mutual'
import Airports from 'pages/airports'
import LocationPage from 'pages/location'
import StaticPage from 'pages/page'
import TermsAndConditions from 'pages/termsAndConditions'
import EmailReservation from 'pages/emailReservation'
import Faq from 'pages/faq'


// import Settings from 'pages/settings'



const routes = [].concat(
  require('./checkout').default,
  require('./guestReservations').default,
  // require('./auth').default,
  [
  //User settings
  // { path: '/settings', name: 'settings', component: Settings},
  // //Main and login

  { path: '/airports', name: 'airports', component: Airports },
  { path: '/port/:slug', name: 'airport', component: Mutual },
  { path: '/location/:slug', name: 'location', component: LocationPage  },
  { path: '/page/:slug', name: 'page', component: StaticPage  },
  { path: '/search', name: 'search', component: Mutual  },
  // { path: '/terms-and-conditions', name: 'terms', component: TermsAndConditions  },
  { path: '/email-reservation', name: 'emailReservation', component: EmailReservation  },
  { path: '/frequently-asked-questions', name: 'faq', component: Faq  },
  { path: '/', name: 'home', component: Main },
  
  
  // { path: '*', name: 'notfound', component: PageNotFound
  //   , meta: {public: true}}
])

export default routes
