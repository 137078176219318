class SearchTokens {

  set (obj) {
    if (!isServer)
      sessionStorage.setItem('__search_tokens__', JSON.stringify(obj));
  }

  get () {
    if (isServer) return null
    let searchTokens = sessionStorage.getItem("__search_tokens__");

    if (searchTokens) {
      return JSON.parse(searchTokens);
    }

    return null;
  }

  clear () {
    if (!isServer)
      sessionStorage.setItem('__search_tokens__', null);
  }
}

export default new SearchTokens()
