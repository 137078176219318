var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _vm.show_icon
      ? _c("img", {
          staticClass: "tripadvisor-logo",
          attrs: { src: "/assets/images/tripadvisor-min.jpg" }
        })
      : _vm._e(),
    _vm._v(" "),
    _c("span", {
      class: _vm.bubbleClass,
      style: _vm.fontSizeStyle,
      attrs: { alt: _vm.rating + " of 5 bubbles" }
    }),
    _vm._v(" "),
    _vm.location_link
      ? _c(
          "a",
          {
            staticClass: "review_count margin-left-1x",
            style: _vm.fontSizeStyle,
            attrs: { href: _vm.location_link + "#REVIEWS", target: "_BLANK" }
          },
          [_vm._v(_vm._s(_vm.reviews_count) + " reviews")]
        )
      : _vm._e()
  ])
}
var staticRenderFns = []
render._withStripped = true
export { render, staticRenderFns }
if (module.hot) {
  module.hot.accept()
  if (module.hot.data) {
    require("vue-hot-reload-api")      .rerender("data-v-636ad3ca", { render: render, staticRenderFns: staticRenderFns })
  }
}