class FlashMessage {
  set (message, type = 'success') {
    let obj = {
      message, 
      type
    };
    sessionStorage.setItem('__message__', JSON.stringify(obj));
  }

  get () {
    let message = sessionStorage.getItem("__message__");

    if (message) {
      this.clear();
      return JSON.parse(message);
    }

    return null;
  }

  clear () {
    sessionStorage.setItem('__message__', null);
  }
}

export default new FlashMessage()
