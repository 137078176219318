var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { ref: "dropdown", staticClass: "s-select2-component" }, [
    _c(
      "div",
      {
        staticClass: "toggler",
        attrs: { "aria-haspopup": "true", "aria-expanded": "false" }
      },
      [
        _c("input", {
          ref: "searchItem",
          staticClass: "form-control",
          attrs: { type: "text", placeholder: "Departure Airport" },
          domProps: { value: _vm.filter_value },
          on: {
            input: function($event) {
              _vm.onChangeSearch($event.target.value)
            },
            click: _vm.toggleDropdown
          }
        }),
        _vm._v(" "),
        _c("div", {
          staticClass: "actions",
          class: [{ "has-text": _vm.filter_value.length }],
          on: {
            click: function($event) {
              $event.preventDefault()
              return _vm.resetValue($event)
            }
          }
        })
      ]
    ),
    _vm._v(" "),
    _vm.show
      ? _c(
          "ul",
          {
            ref: "dropdownList",
            staticClass: "s-dropdown-menu",
            attrs: { "aria-labelledby": "dLabel" }
          },
          [
            _vm._.isEmpty(_vm.filterredOptions)
              ? _c("li", { staticClass: "no-results" }, [_vm._v("No Results")])
              : _vm._e(),
            _vm._v(" "),
            _vm._l(_vm.filterredOptions, function(option) {
              return _c(
                "li",
                {
                  on: {
                    click: function($event) {
                      _vm.selectOption(option)
                    }
                  }
                },
                [_vm._v(_vm._s(option.text))]
              )
            })
          ],
          2
        )
      : _vm._e()
  ])
}
var staticRenderFns = []
render._withStripped = true
export { render, staticRenderFns }
if (module.hot) {
  module.hot.accept()
  if (module.hot.data) {
    require("vue-hot-reload-api")      .rerender("data-v-26ba4cd4", { render: render, staticRenderFns: staticRenderFns })
  }
}