class User {

  setUser (user) {
    if (user) {
      if (user.roles) {
        user.permissions = user.roles[0] ?
        user.roles[0].permissions.map((perm) => {
          return perm.name
        }) : [];
      }

      if (user.locations) {
        user.permissions.push('location_user');
      }

      if (user.channels) {
        user.permissions.push('channel_user');
      }

    }
    sessionStorage.setItem("__user__", JSON.stringify(user));
  }

  getUser () {
    let user = sessionStorage.getItem("__user__");

    if(user == "null") return null;
    return JSON.parse(user);
  }

  setToken (token) {
    sessionStorage.setItem("__token__", token);
  }

  getToken () {
    let token = sessionStorage.getItem("__token__");

    if(token == "null") return null;
    return token;
  }
}

export default isServer ? {
  setUser () {

  },
  getUser () {
    return null
  },
  setToken () {

  },
  getToken () {
    return null
  },
} : new User()
