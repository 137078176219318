var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("layout", [
    _c("div", { staticClass: "container faq--container" }, [
      _c("h1", { staticClass: "text-center" }, [
        _vm._v("Frequently Asked Questions")
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "margin-top-8x text-center" }, [
        _c("div", { staticClass: "row" }, [
          _c("div", { staticClass: "col-sm-6 offset-sm-3 col-xs-12" }, [
            _c("input", {
              directives: [
                {
                  name: "model",
                  rawName: "v-model",
                  value: _vm.faqFilter,
                  expression: "faqFilter"
                }
              ],
              staticClass: "form-control",
              attrs: { type: "text", placeholder: "Filter Questions..." },
              domProps: { value: _vm.faqFilter },
              on: {
                input: [
                  function($event) {
                    if ($event.target.composing) {
                      return
                    }
                    _vm.faqFilter = $event.target.value
                  },
                  _vm.getFilteredResults
                ]
              }
            })
          ])
        ])
      ]),
      _vm._v(" "),
      _vm.filteredResults
        ? _c(
            "div",
            { staticClass: "margin-top-8x text-center" },
            _vm._l(_vm.filteredResults, function(category) {
              return _c(
                "a",
                {
                  staticClass:
                    "btn btn-info text-white margin-right-2x margin-left-2x",
                  on: {
                    click: function($event) {
                      $event.preventDefault()
                      _vm.goToRef(
                        "#" + _vm.slugify(category.name + "-" + category.id)
                      )
                    }
                  }
                },
                [_vm._v("\n        " + _vm._s(category.name) + "\n      ")]
              )
            })
          )
        : _c("div", { staticClass: "margin-top-8x text-center" }, [
            _vm._v("\n      No frequently asked questions yet!\n    ")
          ]),
      _vm._v(" "),
      _vm.filteredResults
        ? _c("div", { staticClass: "margin-top-8x " }, [
            _c(
              "div",
              { staticClass: "row margin-bottom-10x padding-bottom-8x" },
              _vm._l(_vm.filteredResults, function(category) {
                return _c(
                  "div",
                  {
                    staticClass: "col-xs-12 col-sm-12",
                    attrs: {
                      id: _vm.slugify(category.name + "-" + category.id)
                    }
                  },
                  [
                    _c(
                      "h4",
                      {
                        staticClass:
                          "margin-bottom-4x margin-top-4x faq--heading"
                      },
                      [_c("strong", [_vm._v(_vm._s(category.name))])]
                    ),
                    _vm._v(" "),
                    _vm._l(category.faqs, function(faq) {
                      return _c("div", { attrs: { id: "accordion" } }, [
                        _c("div", { staticClass: "card" }, [
                          _c(
                            "div",
                            {
                              staticClass: "card-header",
                              attrs: { id: "headingOne" }
                            },
                            [
                              _c(
                                "h5",
                                {
                                  staticClass: "mb-0",
                                  attrs: {
                                    "data-toggle": "collapse",
                                    "data-target": "#faq_col_" + faq.id,
                                    "aria-expanded": "false",
                                    "aria-controls": "collapseOne"
                                  },
                                  on: {
                                    click: function($event) {
                                      $event.preventDefault()
                                      _vm.handleCollapse(faq)
                                    }
                                  }
                                },
                                [
                                  _vm._v(
                                    "\n                    " +
                                      _vm._s(faq.question) +
                                      "\n                  "
                                  ),
                                  _vm.colapsed[faq.id]
                                    ? _c("i", {
                                        staticClass:
                                          "float-right margin-right-2x fa fa-chevron-up"
                                      })
                                    : _c("i", {
                                        staticClass:
                                          "float-right margin-right-2x fa fa-chevron-down"
                                      })
                                ]
                              )
                            ]
                          ),
                          _vm._v(" "),
                          _c(
                            "div",
                            {
                              staticClass: "collapse",
                              attrs: {
                                id: "faq_col_" + faq.id,
                                "aria-labelledby": "headingOne",
                                "data-parent": "#accordion"
                              }
                            },
                            [
                              _c("div", { staticClass: "card-body" }, [
                                _c("p", {
                                  staticClass: "faq--answer",
                                  domProps: {
                                    innerHTML: _vm._s(
                                      _vm.cleanHtmlString(faq.answer)
                                    )
                                  }
                                })
                              ])
                            ]
                          )
                        ])
                      ])
                    })
                  ],
                  2
                )
              })
            )
          ])
        : _vm._e()
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true
export { render, staticRenderFns }
if (module.hot) {
  module.hot.accept()
  if (module.hot.data) {
    require("vue-hot-reload-api")      .rerender("data-v-401dba45", { render: render, staticRenderFns: staticRenderFns })
  }
}