var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", {}, [
    _c("p"),
    _vm.sortedCancellationPolicies.length
      ? _c(
          "div",
          [
            _vm._l(_vm.sortedCancellationPolicies, function(policy, i) {
              return _c("div", [
                policy.percentage == 100
                  ? _c("span", [
                      _vm._v(
                        "\n\t           - Full Refund before \n\t          " +
                          _vm._s(
                            _vm
                              .moment(_vm.reservationFromDate, [
                                _vm.database_date_format
                              ])
                              .subtract(policy.number_of_days, "days")
                              .format(_vm.client_date_format)
                          ) +
                          "\n\t        "
                      )
                    ])
                  : _c("span", [
                      _vm._v(
                        "\n\t          - " +
                          _vm._s(policy.percentage) +
                          "% Refund before \n\t\t          " +
                          _vm._s(
                            _vm
                              .moment(_vm.reservationFromDate, [
                                _vm.database_date_format
                              ])
                              .subtract(policy.number_of_days, "days")
                              .format(_vm.client_date_format)
                          ) +
                          "\n\t         "
                      )
                    ])
              ])
            }),
            _vm._v(" "),
            _vm.sortedCancellationPolicies.length
              ? _c("div", [
                  _vm.sortedCancellationPolicies[
                    _vm.sortedCancellationPolicies.length - 1
                  ].number_of_days > 1
                    ? _c("span", [
                        _vm._v(
                          "\n\t           - No Refund after  \n\t           " +
                            _vm._s(
                              _vm
                                .moment(_vm.reservationFromDate, [
                                  _vm.database_date_format
                                ])
                                .subtract(
                                  _vm.sortedCancellationPolicies[
                                    _vm.sortedCancellationPolicies.length - 1
                                  ].number_of_days,
                                  "days"
                                )
                                .format(_vm.client_date_format)
                            ) +
                            "\n\t        "
                        )
                      ])
                    : _vm._e()
                ])
              : _vm._e()
          ],
          2
        )
      : _vm._e(),
    _vm._v(" "),
    !_vm.sortedCancellationPolicies.length
      ? _c("div", [_c("span", [_vm._v("\n      \t\t- No Refund.\n      \t")])])
      : _vm._e(),
    _vm._v(" "),
    _c("p")
  ])
}
var staticRenderFns = []
render._withStripped = true
export { render, staticRenderFns }
if (module.hot) {
  module.hot.accept()
  if (module.hot.data) {
    require("vue-hot-reload-api")      .rerender("data-v-89f1d950", { render: render, staticRenderFns: staticRenderFns })
  }
}