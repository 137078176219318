import toastr from 'toastr'
import moment from 'moment'
import { stripTags, currencySymbol, dateFormat } from 'filters'
import imageBg from 'components/imageBg'
import tripadvisorRating from 'components/tripadvisorRating'
import cancellationPolicy from 'layouts/default/partials/cancellationPolicy'
import sendEmailModal from './sendEmailModal'
import updateModal from './updateModal'
import cancellationModal from './cancellationModal'

let components = {
  imageBg,
  cancellationPolicy,
  sendEmailModal,
  updateModal,
  cancellationModal,
  tripadvisorRating
}

if (!isServer) {
  components.rating = require('vue-star-rating').default;
}

const computed = {
  receiptHtml() {
    return this.$store.state.reservations.receipt;
  },
  featuredPhoto () {
    if (!this.activeHistory.location.photos.length) return null

    let filepath = null;

    let photo_obj = this.activeHistory.location.photos.filter(photo => photo.featured);
    // console.log('photo_obj', photo_obj);
    if (photo_obj) {
      if (photo_obj.length) {
        return photo_obj[0].filename
      }
    }

    return this.activeHistory.location.photos[0].filename
  },
  otherPhotos () {
    if(!this.featuredPhoto) return []
    return this.activeHistory.location.photos.filter(photo => photo.filename != this.featuredPhoto);
  },
  hasSecurityAmenity () {
    for (let i in this.activeHistory.location.amenities) {
      if (this.activeHistory.location.amenities[i].name == 'security') {
        return true
      }
    }

    return false
  },
  showTripadvisorReviews () {
    return this.activeHistory.location.tripadvisor_id && 
    (this.activeHistory.reservation_type == 'room' || this.activeHistory.reservation_type == 'both') &&
    !_.isEmpty(this.activeHistory.location.tripadvisor_reviews);
  },
  totalGuestsNumber () {
    return _.sumBy(this.activeHistory.rooms, room => room.number_of_guests);
  },
  symbol () {
    return currencySymbol(this.activeHistory.payment_currency);
  }
}

const filters = {
  stripTags,
  currencySymbol,
  dateFormat
}

const methods = {
  printReservation () {
    this.loading.print = true;
    this.$store.dispatch('reservations.getReceipt', {
      id: this.reservation.reservation_number,
      params: this.initPrintReservationParams()
    }).then(() => {
      this.loading.print = false;
      let errors = this.$store.state.reservations.errors;
      // console.log(errors);
      if (errors) {
        if (errors.status_code == 400) {
          toastr.error(errors.message);
        } else if (errors.errors) {
          this.errors = errors.errors;
          toastr.error(errors.message);
        } else {
          toastr.error(errors.message);
        }
      } else {
        this.$nextTick(() => {
          let receiptDiv = $('#print-receipt');
          receiptDiv.html(this.receiptHtml);
          let count = 0;
          let images = $('.reservation_receipt img');
          let totalNumber = images.length;

          images.on('load', () => {
            count++;
            if (count >= totalNumber) {
              window.print();
            }
          })

        })
      }


    });
  },

  downloadReservation () {
    if (this.loading.download) return;

    this.loading.download = true;
    this.$store.dispatch('reservations.download', {
      id: this.reservation.reservation_number,
      params: this.initPrintReservationParams(),
      responseType: 'arraybuffer'
    }).then(() => {
      const blob = new Blob([this.$store.state.reservations.download], { type: `application/pdf; charset=UTF-8` });
      blob.name = 'reservation.pdf';
      const reader = new FileReader();
      reader.onload = e => {
        const anchor = document.createElement('a');
        anchor.style.display = 'none';
        anchor.href = e.target.result;
        anchor.download = blob.name;
        anchor.click();
      };
      reader.readAsDataURL(blob);

      this.loading.download = false;
    });
  },

  initPrintReservationParams () {
    return {
      reserved_by: this.reservation.reserved_by,
      check_in: moment(this.reservation.history[0].from_date,['YYYY-MM-DD hh:mm:ss']).format('YYYY-MM-DD')
    };
  },
  openEmailModal () {
    this.$nextTick(() => {
      this.$refs.sendEmailModal.open();
    })
  },
  removeLeadingZeros (value) {
    return parseInt(value);
  },
  openUpdateModal () {
    this.showModal = true;
    this.$nextTick(() => {
      this.$refs.updateModal.open();  
    })
  },
  onCloseModal () {
    console.log('handling close eventxxxxx');
    this.showModal = false;
  },
  openCancellationModal () {
    console.log('xxxx', this.$refs.cancellationModal);
    this.$refs.cancellationModal.open();
  },

  onUpdateReservationFinished () {
    this.$refs.cancellationModal.requestCancellationRefunds();
  },
  getReviews () {
    if (this.activeHistory.location.tripadvisor_id) {
      this.$store.dispatch('locations.getTripadvisorReviews', this.activeHistory.location.id).then(() => {
        let tripadvisor = this.$store.state.locations.active.tripadvisor_reviews;
        this.$set(this.activeHistory.location, 'tripadvisor_reviews', tripadvisor);
      })
    }
  }
}

const watch = {
  reservation (n) {
    if (n) {
      this.activeHistory = _.find(n.history, item => item.active == 1) || {}
      this.getReviews();
    }
  },
}

export default {
  name: 'reservation-details',
  props: ['reservation'],
  computed,
  components,
  filters,
  methods,
  watch,
  data () {
    return {
      loading: {
        print: false,
        download: false,
        email: false,
      },
      showModal: true,
      errors:{
      },
      activeHistory: _.find(this.reservation.history, item => item.active == 1) || {}
    }
  },
  mounted () {
    this.getReviews();
  }
}
