import toastr from 'toastr'
import layout from 'layouts/default'

const components = {
  layout,
}

const computed = {
  airports () {
    let ports = {};
    let sorted = _.orderBy(this.$store.state.airports.all, ['name'], ['asc']);
    ports.us = _.filter(sorted, (item) => item.country.code.toLowerCase() == 'us');
    ports.ca = _.filter(sorted, (item) => item.country.code.toLowerCase() == 'ca');

    return ports;
  }
}

export default {
  name: 'static-page',
  components,
  computed,
  asyncData ({ store, route, reject }) {
    let promises = [
      store.dispatch('airports.getAll'),
    ];
    
    store.dispatch('title.setTitle', 'Airports');
    return Promise.all(promises)
  }
}
