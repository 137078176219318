import moment from 'moment'

const watch = {
  qeury (n) {
    console.log('nnnnn', n);
  }
}

const computed = {
  totalGuests () {
    if (typeof this.query.guests_in_rooms != 'string') {
      return _.sum(this.query.guests_in_rooms);
    }

    return 0;
  }
}

const methods = {
  openSearchModalWhenNoDatesSelected () {
    if (!this.query.from_date || !this.query.to_date) {
      this.openSearchModal();
    }
  }
}

export default {
  name: 'search-criteria',
  props: ['query', 'openSearchModal'],
  watch,
  computed,
  methods,
  data () {
    return {
      moment
    }
  }
}
