import toastr from 'toastr'
import { funcModal } from 'components/modals'

const components = {
  funcModal,
}

const methods = {
  open () {
    this.$refs.forgotPass.open();
  },
  close () {
    this.$refs.forgotPass.close();
  },
  submitSendEmail (modal) {
    this.errors = {};
    this.processing = true;
    this.$store.dispatch('user.forgotPassword', this.forgotPass.email).then(() => {
      this.processing = false;
      let errors = _.cloneDeep(this.$store.state.user.errors);
      if (errors) {
        this.errors = errors.errors;
        if (errors.error_code == 'email_not_sent') {
          this.errors['email_not_sent'] = true;
        }
        if (errors.error_code == 'account_not_found') {
          this.errors['account_not_found'] = true;
        }
      } else {
        this.forgotPass.email = null;
        toastr.success("Please check your email");
        modal.close();
      }
    }).catch((err) => {
      console.log('errxx 500', err);
    });
  }
}

export default {
  name: "reset-password-modal",
  props: [],
  components,
  methods,
  data() {
    return {
      forgotPass: {
        email: null,
      },
      errors: {},
      processing: false,
    }
  },
  mounted () {
  }
}
