var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("func-modal", {
    ref: "modal",
    attrs: { "hide-footer": true, title: "Send receipt via e-mail" },
    scopedSlots: _vm._u([
      {
        key: "body",
        fn: function(props) {
          return [
            _c("div", { staticClass: "container" }, [
              _c("h5", { staticClass: "mb-3" }, [
                _vm._v("Send confirmation to this email address:")
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "form-group" }, [
                _c("input", {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.email,
                      expression: "email"
                    }
                  ],
                  staticClass: "form-control",
                  class: { "is-invalid": _vm.errors.email },
                  attrs: { type: "text" },
                  domProps: { value: _vm.email },
                  on: {
                    input: function($event) {
                      if ($event.target.composing) {
                        return
                      }
                      _vm.email = $event.target.value
                    }
                  }
                }),
                _vm._v(" "),
                _vm.errors.email
                  ? _c("div", { staticClass: "invalid-feedback" }, [
                      _vm._v(
                        "\n          " +
                          _vm._s(_vm.errors.email[0]) +
                          "\n        "
                      )
                    ])
                  : _vm._e()
              ]),
              _vm._v(" "),
              _c(
                "button",
                {
                  staticClass: "btn btn-info btn-block",
                  on: {
                    click: function($event) {
                      $event.preventDefault()
                      return _vm.sendViaEmail($event)
                    }
                  }
                },
                [
                  !_vm.loading
                    ? _c("i", {
                        staticClass: "fa fa-paper-plane",
                        attrs: { "aria-hidden": "true" }
                      })
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.loading
                    ? _c("i", {
                        staticClass: "fa fa-spinner fa-spin",
                        attrs: { "aria-hidden": "true" }
                      })
                    : _vm._e(),
                  _vm._v(" "),
                  !_vm.loading
                    ? _c("span", [_vm._v("Send to e-mail")])
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.loading ? _c("span", [_vm._v("Sending...")]) : _vm._e()
                ]
              )
            ])
          ]
        }
      }
    ])
  })
}
var staticRenderFns = []
render._withStripped = true
export { render, staticRenderFns }
if (module.hot) {
  module.hot.accept()
  if (module.hot.data) {
    require("vue-hot-reload-api")      .rerender("data-v-385d7cb6", { render: render, staticRenderFns: staticRenderFns })
  }
}