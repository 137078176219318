
const computed = {
  bubbleClass() {
    return 'ui_bubble_rating bubble_' + this.rating.replace('.', '');
  },

  fontSizeStyle() {
    return this.font_size ? 'font-size:' + this.font_size  : '';
  }
}

export default {
  name: 'tripadvisorRating',
  props: ['rating', 'location_link', 'reviews_count', 'show_icon', 'font_size'],
  computed
}
