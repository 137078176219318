import moment from 'moment'
import toastr from 'toastr'
import { dateTime, helper } from 'services'
import numberOfCars from 'layouts/default/partials/numberOfCars'
import Bus from 'bus'

const components = {
  numberOfCars
}

const watch = {
  formData (n) {
    this.form = _.assign(this.form, n);
  },
  currentUser (n) {
    this.user = _.assign({}, n);
    if (this.user) {
      this.formData.first_name = this.user.first_name;
      this.formData.last_name = this.user.last_name;
      this.formData.email = this.user.email;
      this.formData.zip_code = this.user.zip_code;
      this.formData.phone = this.user.phone;
    }
  },
  formErrors (n) {
    this.errors = n;
  },
  location (n) {
    _.each(n.extra_fields, field => {
      this.$set(this.extra_fields, field.name, this.form[field.name] || [""]);
    });
  }
}

const methods = {
  changeTimes () {
    if (!this.form.from_time || !this.form.to_time) return

    let from_date = moment(this.form.from_date, ['DD/MMM/YYYY'])
    let to_date = moment(this.form.to_date, ['DD/MMM/YYYY'])
    let from_time = moment(this.form.from_time, ['HH:mm'])
    let to_time = moment(this.form.to_time, ['HH:mm'])

    if (from_date.isSame(to_date) && from_time.isSameOrAfter(to_time)) {
      toastr.error("Vehicle Drop-Off time must be before Vehicle Pick-Up time");
    } else {
      this.$emit('changeTimes', {
        from_time: this.form.from_time,
        to_time: this.form.to_time
      })
    }

  },

  goToStepTwo () {
    this.errors = {};
    this.form = _.assign(
      this.form,
      this.extra_fields
    );

    this.$emit('formChanged', this.form);

    console.log('form', this.form);
    if (this.validate()) {
      console.log('validated correctly');
      this.$router.replace({
        query: Object.assign({}, this.$route.query, { step: (this.form.reservation_type == 'parking' && 
          this.tripProtections && this.tripProtections.length) 
          ? 'addons' : 'payment' }),
      });
      this.$emit('changeStep', (this.form.reservation_type == 'parking' && this.tripProtections && this.tripProtections.length) 
        ? 'addons' : 'payment');
    }
  },

  changeNumberOfSpots (value) {
    this.number_of_spots = value;
    this.$router.replace({
      query: Object.assign({}, this.$route.query, { number_of_spots: value }),
      // No need to specify route name and params, they will be preserved
    });
    this.$emit('changeNumberOfSpots', value);
  },
  openLoginModal () {
    Bus.$emit('details.openLoginModal');
  },
  validate () {
    // return true;

    let required_fields = ['email'];

    if (this.form.reservation_type == 'parking') {
      required_fields = required_fields.concat(['from_time', 'to_time']);
    }

    let error_found = false;
    for (let i in this.location.extra_fields) {
      if (this.form.reservation_type != 'room' && this.location.extra_fields[i].per_car) {
        for (let j = 0 ; j < this.number_of_spots; j++) {
          if (!this.form[this.location.extra_fields[i].name][j] || !this.form[this.location.extra_fields[i].name].length) {
            this.errors[this.location.extra_fields[i].name + '.' + j] = ['This field is required.'];
            error_found = true;
          } else if (this.location.extra_fields[i].input_type == 'flight_number') {
            let flight_number = this.form[this.location.extra_fields[i].name][j];
            if (flight_number.match(/[~`!@#$%\^&*+=\[\]\\';(),/[\]{}|\\":<>\?]/g) || !helper.isValidFlightNumber(helper.extractFlightNumber(flight_number))) {
                this.errors[this.location.extra_fields[i].name + '.' + j] = ['Invalid Flight Number'];
                error_found = true;
            }
          }
        }
      } 
      else {
        if (!this.form[this.location.extra_fields[i].name][0] || !this.form[this.location.extra_fields[i].name].length) {
          this.errors[this.location.extra_fields[i].name + '.0'] = ['This field is required.'];
          error_found = true;
        } else if (this.location.extra_fields[i].input_type == 'flight_number') {
          let flight_number = this.form[this.location.extra_fields[i].name][0];
          if (flight_number.match(/[~`!@#$%\^&*+=\[\]\\';(),/[\]{}|\\":<>\?]/g) || !helper.isValidFlightNumber(helper.extractFlightNumber(flight_number))) {
              this.errors[this.location.extra_fields[i].name + '.0'] = ['Invalid Flight Number'];
              error_found = true;
          }
        }
      }
    }

    for (let i in required_fields) {
      if (!this.form[required_fields[i]]) {
        this.errors[required_fields[i]] = ['This field is required.'];
        error_found = true;
      }
    }

    //validate email
    var re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    if (!re.test(String(this.form.email).toLowerCase())) {
      this.errors.email = ['Invalid email address'];
      error_found = true;
    }
    
    if (this.form.reservation_type != 'parking') {
      for (let i in this.form.guests_in_rooms) {
        if (!this.form.guests_names[i] || !this.form.guests_names[i].length) {          
            let room_number = 1 + parseInt(i);
            this.errors['guests_names.' + i] = ['Guest name in room ' + room_number + ' is required.']
            error_found = true;
        } else {
          if (this.form.guests_names[i].length > 255) {
            this.errors['guests_names.' + i] = ['The guest name may not be greater than 255 characters.']
            error_found = true;
          }
        }
      }
    }

    if (error_found) return false;

    return true;
  },

  decreaseNumberOfSpots (value) {
    for (let i in this.location.extra_fields) {
      if (this.location.extra_fields[i].per_car) {
      while (this.extra_fields[this.location.extra_fields[i].name].length > this.number_of_spots) {
        this.extra_fields[this.location.extra_fields[i].name].pop();
      }
     }
    }
  },
}

const computed = {
  totalGuestsNumber () {
    return _.sum(this.form.guests_in_rooms);
  },

  tripProtections () {
    return this.$store.state.tripProtections.all;
  }
}

export default {
  name: 'checkout-details-step',
  props: ['formData', 'formErrors', 'location', 'airport', 'costs', 'selectedTypes', 'numberOfSpots','currentUser'],
  watch,
  methods,
  computed,
  components,
  data () {
    let extra_fields = {}
    if (this.location) {
      _.each(this.location.extra_fields, field => {
        extra_fields[field.name] = this.formData[field.name] || [""];
      });
    }

    return {
      moment,
      errors: this.formErrors || {},
      user: this.currentUser,
      form: this.formData,
      // from_time: null,
      // to_time: null,
      times: dateTime.getHalfHours(),
      extra_fields,
      number_of_spots: this.numberOfSpots,
    }
  },
  mounted () {
    this.$nextTick(() => {
      $('html, body').animate({
        scrollTop: $(".checkout-step-label.active").offset().top
      }, 500);
    });
  }
}
