import moment from 'moment-timezone';

class DateTime {
  getHalfHours () {
    let times = [];
    for (var i = 1; i <= 24; i++) {

      let label_hour = i > 12 ? i - 12 : i;

      let label1 = label_hour < 10 ? '0'+ label_hour + ':00' + (i % 24 >= 12 ? ' pm' : ' am') : label_hour + ':00' + (i % 24 >= 12 ? ' pm' : ' am');
      let value1 = i < 10 ? '0'+ i + ':00' : (i % 24 == 0 ? '00' : i % 24) + ':00';
      let label2 = label_hour < 10 ? '0'+ label_hour + ':30' + (i % 24 >= 12 ? ' pm' : ' am') : label_hour + ':30' + (i % 24 >= 12 ? ' pm' : ' am');
      let value2 = i < 10 ? '0'+ i + ':30' : (i % 24 == 0 ? '00' : i % 24) + ':30';

      times.push({
        label: label1,
        value: value1,
      });

      times.push({
        label: label2,
        value: value2,
      });

    }

    return times
  }

  getBookingHrs (fromDate, toDate, airportTimezone) {

    let now = moment();
    let todayAtNoon = moment('12:00 pm', ['HH:mm a']);
    if (airportTimezone) {
      now = moment().tz(airportTimezone);
      todayAtNoon = moment.tz('12:00 pm', 'HH:mm a', airportTimezone);
    }

    console.log('now', now.format());
    console.log('todayAtNoon', todayAtNoon.format());

    let diff_days = toDate.diff(fromDate, 'days');
    let is_today = fromDate.isSame(now, 'days');
    let afterNoon = todayAtNoon.isBefore(now, 'hour');
    let from_time = '12:00:00';
    let to_time = '12:00:00';

    if (is_today) {//today
      if (afterNoon) {
        from_time = now.add(1, 'hours').format('HH:00:00');
      }
    }

    if (diff_days == 0) {
      to_time = '23:30:00';
    }

    return {
      from_time,
      to_time
    }
  }

  diffInHours (fromDate, airportTimezone) {
    let now = moment();
    if (airportTimezone) {
      now = moment().tz(airportTimezone);
      fromDate = moment.tz(fromDate, 'YYYY-MM-DD HH:mm:ss', airportTimezone);
    }
    
    let diff = now.diff(fromDate, 'minute')/60.0;
    return diff < 0 ? diff * -1 : diff;
    
  }
}

export default new DateTime()
