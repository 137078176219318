var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "layout",
    [
      !_vm.confirmation
        ? _c(
            "div",
            [
              _c("div", { staticClass: "bg-grey" }, [
                _c("div", { staticClass: "container" }, [
                  _c("div", { staticClass: "checkout-process row" }, [
                    _c(
                      "div",
                      {
                        staticClass:
                          "col-12 mt-md-5 mb-md-5 mt-4 mb-4 text-center text-info"
                      },
                      [
                        _c("h1", { staticClass: "checkout--title" }, [
                          _vm._v("Tripteo Secure Booking")
                        ])
                      ]
                    ),
                    _vm._v(" "),
                    _c("div", { staticClass: "col-md-8 order-md-0 order-1" }, [
                      _c(
                        "div",
                        { staticClass: "white-box margin-bottom-8x" },
                        [
                          _vm.step == "details"
                            ? _c("details-step", {
                                attrs: {
                                  "form-data": _vm.form,
                                  "form-errors": _vm.errors,
                                  airport: _vm.airport,
                                  location: _vm.location,
                                  costs: _vm.costs,
                                  "current-user": _vm.currentUser,
                                  "selected-types": _vm.selectedTypes,
                                  "number-of-spots": _vm.numberOfSpots
                                },
                                on: {
                                  changeStep: _vm.onStepChange,
                                  changeNumberOfSpots:
                                    _vm.onChangeNumberOfSpots,
                                  changeTimes: _vm.onChangeTimes,
                                  formChanged: _vm.onFormChanged,
                                  openLoginModal: _vm.openLoginModal
                                }
                              })
                            : _vm._e(),
                          _vm._v(" "),
                          _vm.step == "addons"
                            ? _c("addons-step", {
                                attrs: {
                                  "form-data": _vm.form,
                                  "form-errors": _vm.errors,
                                  airport: _vm.airport,
                                  location: _vm.location,
                                  "currency-code": _vm.currencyCode,
                                  "number-of-spots": _vm.numberOfSpots
                                },
                                on: {
                                  changeStep: _vm.onStepChange,
                                  selectTripProtection:
                                    _vm.onSelectTripProtection,
                                  formChanged: _vm.onFormChanged
                                }
                              })
                            : _vm._e(),
                          _vm._v(" "),
                          _vm.step == "payment"
                            ? _c("payment-step", {
                                ref: "paymentComponent",
                                attrs: {
                                  "form-data": _vm.form,
                                  "form-errors": _vm.errors,
                                  airport: _vm.airport,
                                  location: _vm.location,
                                  costs: _vm.costs,
                                  "selected-types": _vm.selectedTypes,
                                  "submit-form": _vm.submitForm,
                                  "number-of-spots": _vm.numberOfSpots
                                },
                                on: { changeStep: _vm.onStepChange }
                              })
                            : _vm._e()
                        ],
                        1
                      )
                    ]),
                    _vm._v(" "),
                    _c("div", { staticClass: "col-md-4 order-md-1 order-0" }, [
                      _c(
                        "div",
                        { staticClass: "white-box margin-bottom-8x" },
                        [
                          _vm.loading
                            ? _c("div", { staticClass: "text-center" }, [
                                _c("i", {
                                  staticClass:
                                    "fa fa-circle-o-notch fa-spin fa-3x fa-fw"
                                })
                              ])
                            : _c("checkout-summary", {
                                attrs: {
                                  "form-data": _vm.form,
                                  airport: _vm.airport,
                                  location: _vm.location,
                                  "currency-code": _vm.currencyCode,
                                  costs: _vm.costs,
                                  "selected-types": _vm.selectedTypes,
                                  "number-of-spots": _vm.numberOfSpots
                                },
                                on: {
                                  editSearch: _vm.openSearchModal,
                                  showSubtotalBreakdownModal:
                                    _vm.openSubtotalModal
                                }
                              })
                        ],
                        1
                      )
                    ])
                  ])
                ])
              ]),
              _vm._v(" "),
              _vm.canShowSearchModal
                ? _c("search-modal", {
                    ref: "searchModal",
                    staticClass: "search-modal",
                    attrs: {
                      "form-data": _vm.query,
                      airport: _vm.airport,
                      "on-close-modal": _vm.onCloseModal,
                      "route-name": "location"
                    }
                  })
                : _vm._e(),
              _vm._v(" "),
              _c("div", { staticClass: "floating-dock d-block d-md-none" }, [
                _vm.costs
                  ? _c("div", { staticClass: "floating-dock--container" }, [
                      _c("div", { staticClass: "floating-dock--item w-50" }, [
                        _c(
                          "button",
                          {
                            staticClass: "btn btn-info btn-block",
                            on: {
                              click: function($event) {
                                $event.preventDefault()
                                _vm.goToSummary()
                              }
                            }
                          },
                          [
                            _vm._v("\n          Details\n          "),
                            _c("i", {
                              staticClass: "fa fa-caret-up",
                              attrs: { "aria-hidden": "true" }
                            })
                          ]
                        )
                      ]),
                      _vm._v(" "),
                      _vm.costs
                        ? _c(
                            "div",
                            { staticClass: "floating-dock--item regular w-50" },
                            [
                              _vm._v(
                                "\n        Total: " +
                                  _vm._s(
                                    _vm._f("currency")(
                                      _vm.costs.reservation.grand_total,
                                      _vm.symbol
                                    )
                                  ) +
                                  "\n      "
                              )
                            ]
                          )
                        : _vm._e()
                    ])
                  : _c(
                      "div",
                      { staticClass: "text-center floating-dock--item" },
                      [
                        _c("i", {
                          staticClass:
                            "fa fa-circle-o-notch fa-spin fa-2x fa-fw"
                        })
                      ]
                    )
              ])
            ],
            1
          )
        : _c(
            "div",
            { staticClass: "bg-grey" },
            [
              _c("confirmation", {
                staticClass: "padding-top-3x padding-bottom-8x"
              })
            ],
            1
          ),
      _vm._v(" "),
      _vm.threeDsecure.url
        ? _c("three-d-secure", {
            attrs: { url: _vm.threeDsecure.url },
            on: { closeIframe: _vm.onCloseThreeDSecure }
          })
        : _vm._e(),
      _vm._v(" "),
      _vm.showSubtotalModal
        ? _c("subtotal-modal", {
            ref: "breakDownModal",
            staticClass: "subtotal-modal",
            attrs: {
              "total-days": _vm.totalDays,
              "currency-symbol": _vm.symbol,
              type: _vm.selectedType,
              "type-name": _vm.query.reservation_type,
              "from-date": _vm.query.from_date,
              "to-date": _vm.query.to_date,
              "from-time": _vm.form.from_time,
              "to-time": _vm.form.to_time,
              "location-id": _vm.location.id,
              "currency-code": _vm.currencyCode,
              "number-of-spots": _vm.numberOfSpots,
              "number-of-rooms": _vm.query.number_of_rooms,
              "nights-to-sleep": _vm.query.nights_to_sleep,
              "guests-in-rooms": _vm.query.guests_in_rooms
            }
          })
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true
export { render, staticRenderFns }
if (module.hot) {
  module.hot.accept()
  if (module.hot.data) {
    require("vue-hot-reload-api")      .rerender("data-v-6f7345cd", { render: render, staticRenderFns: staticRenderFns })
  }
}