var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("func-modal", {
    ref: "detailsModal",
    attrs: {
      size: "large",
      "hide-footer": true,
      title: "Cancellation Policy & Special Conditions"
    },
    scopedSlots: _vm._u([
      {
        key: "body",
        fn: function(props) {
          return [
            _c(
              "div",
              { staticClass: "f18 regular margin-top-3x margin-bottom-2x" },
              [_vm._v("\n        Cancellation Policy\n      ")]
            ),
            _vm._v(" "),
            _c("cancellation-policy", {
              attrs: {
                "cancellation-policies": _vm.cancellationPolicy,
                "reservation-type": _vm.reservationType,
                "reservation-from-date": _vm
                  .moment(_vm.query.from_date, ["DD/MMM/YYYY"])
                  .format("YYYY-MM-DD HH:mm:ss")
              }
            }),
            _vm._v(" "),
            _vm.specialConditions
              ? _c("div", {}, [
                  _c(
                    "div",
                    {
                      staticClass: "f18 regular margin-top-6x margin-bottom-2x"
                    },
                    [_vm._v("\n\t        Special Conditions\n\t      ")]
                  ),
                  _vm._v(" "),
                  _c("p", [
                    _vm._v(
                      "\n\t        " +
                        _vm._s(_vm._f("stripTags")(_vm.specialConditions)) +
                        "\n\t      "
                    )
                  ])
                ])
              : _vm._e()
          ]
        }
      }
    ])
  })
}
var staticRenderFns = []
render._withStripped = true
export { render, staticRenderFns }
if (module.hot) {
  module.hot.accept()
  if (module.hot.data) {
    require("vue-hot-reload-api")      .rerender("data-v-f4a36964", { render: render, staticRenderFns: staticRenderFns })
  }
}