class BookingData {
  setItem (key, value) {
    let booking_data = sessionStorage.getItem("__booking_data__");
    if (!booking_data) {
      let obj = {};
      obj[key] = value;
      sessionStorage.setItem("__booking_data__", JSON.stringify(obj));
    } else {
      booking_data = JSON.parse(booking_data);
      if (!booking_data) {
        booking_data = {}
      }
      booking_data[key] = value;
      sessionStorage.setItem('__booking_data__', JSON.stringify(booking_data));
    }
  }

  set (obj) {
    sessionStorage.setItem('__booking_data__', JSON.stringify(obj));
  }

  get () {
    let booking_data = sessionStorage.getItem("__booking_data__");

    if (booking_data) {
      return JSON.parse(booking_data);
    }

    return null;
  }

  clear () {
    sessionStorage.setItem('__booking_data__', null);
  }
}

export default new BookingData()
