import { http, permits } from 'services'

// initial state
const state = () => ({
  all: [],
  parking: [],
  room: [],
  parking_and_room: [],
  active: {},
  errors: null
})

// getters
const getters = {

}

// actions
const actions = {
  //get all ports (params: {_q: search name, page})
  ['tripProtections.getAll'] ({ commit, state }, params) {
    if (!params._q) params._q = null
    if (!params._with_deleted) params._with_deleted = null
    if (!params._only_deleted) params._only_deleted = null

    commit('CLEAR_ERRORS')

    return (http.get('trip-protections', {
      params
    })).then ((res) => {
      commit('GET_ALL_TRIP_PROTECTIONS', res);
    }).catch ((res) => {
      commit('TRIP_PROTECTIONS_ERROR', res);
    })
  },

  ['tripProtections.get'] ({commit, state}, id) {
    commit('CLEAR_ERRORS')


    // return;
    return (Promise.all([
      http.get(`trip-protections/${id}`)
    ])).then(([
      trip
    ]) => {
      commit('GET_TRIP_PROTECTION', trip);
    }).catch ((res) => {
      commit('TRIP_PROTECTIONS_ERROR', res);
    });
  }
}

// mutations
const mutations = {
  GET_ALL_TRIP_PROTECTIONS (state, res) {
    state.all = res.data;
    state.parking = _.filter(res.data, (item) => {
      return item.unit_type == 'parking';
    })
    state.room = _.filter(res.data, (item) => {
      return item.unit_type == 'room';
    })
    state.parking_and_room = _.filter(res.data, (item) => {
      return item.unit_type == 'parking_and_room';
    })
  },

  GET_TRIP_PROTECTION (state, res) {
    state.active = _.assign(state.active, res.data);

    state.active = _.cloneDeep(state.active);
  },

  //ERRORS
  TRIP_PROTECTIONS_ERROR (state, err) {
    try {
      state.errors = err.response.data;
    } catch (e) {
      state.errors = err;
    }
  },

  CLEAR_ERRORS (state) {
    state.errors = null
  }
}

export default {
  state,
  getters,
  actions,
  mutations
}
