import { http } from 'services'
// import axios from 'axios'
// initial state
const state = () => ({
  all: {},
  active: {},
  errors: null
})

// getters
const getters = {
}

// actions
const actions = {
  ['airports.getAll'] ({ commit }, id) {
    commit('CLEAR_ERRORS')
    return (http.get('airports?_all=true')).then((res) => {
      commit('AIRPORTS_GET_ALL', res);
    });
  },
  ['airports.get'] ({ commit }, id) {
    commit('CLEAR_ERRORS')
    return (http.get('airports/' + id)).then((res) => {
      commit('AIRPORTS_GET', res);
    });
  },
}

// mutations
const mutations = {
  AIRPORTS_GET_ALL (state, res) {
      state.all = res.data;
  },

  AIRPORTS_GET (state, res) {
      state.active = res.data;
  },

  //ERRORS
  AIRPORTS_ERROR (state, err) {
    state.errors = err.response.data;
  },

  CLEAR_ERRORS (state) {
    state.errors = null
  }
}

export default {
  state,
  getters,
  actions,
  mutations
}
