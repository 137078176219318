var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("layout", [
    _c("div", { staticClass: "container min-h-screen" }, [
      _c("div", { staticClass: "terms-and-conditions mt-5" }, [
        _c("h2", [_vm._v("Terms & Conditions")]),
        _vm._v(" "),
        _c("p", [_vm._v("Updated October 8, 2018")]),
        _vm._v(" "),
        _c("p", [
          _vm._v(
            "\n          Lorem ipsum dolor sit amet, consectetur adipisicing elit. Iusto doloremque voluptas optio nesciunt minima quis magni enim quas odit praesentium ipsa minus earum aspernatur iste itaque fuga, autem nobis cupiditate nam perferendis accusamus! Quas ratione, sequi ullam natus illum quasi culpa corporis blanditiis ea adipisci doloribus! Quisquam quae, ipsa at.\n        "
          )
        ]),
        _vm._v(" "),
        _c("p", [
          _vm._v(
            "\n          Lorem ipsum dolor sit amet, consectetur adipisicing elit. Iusto doloremque voluptas optio nesciunt minima quis magni enim quas odit praesentium ipsa minus earum aspernatur iste itaque fuga, autem nobis cupiditate nam perferendis accusamus! Quas ratione, sequi ullam natus illum quasi culpa corporis blanditiis ea adipisci doloribus! Quisquam quae, ipsa at.\n        "
          )
        ]),
        _vm._v(" "),
        _c("p", [
          _vm._v(
            "\n          Lorem ipsum dolor sit amet, consectetur adipisicing elit. Iusto doloremque voluptas optio nesciunt minima quis magni enim quas odit praesentium ipsa minus earum aspernatur iste itaque fuga, autem nobis cupiditate nam perferendis accusamus! Quas ratione, sequi ullam natus illum quasi culpa corporis blanditiis ea adipisci doloribus! Quisquam quae, ipsa at.\n        "
          )
        ]),
        _vm._v(" "),
        _c("p", [
          _vm._v(
            "\n          Lorem ipsum dolor sit amet, consectetur adipisicing elit. Iusto doloremque voluptas optio nesciunt minima quis magni enim quas odit praesentium ipsa minus earum aspernatur iste itaque fuga, autem nobis cupiditate nam perferendis accusamus! Quas ratione, sequi ullam natus illum quasi culpa corporis blanditiis ea adipisci doloribus! Quisquam quae, ipsa at.\n        "
          )
        ])
      ])
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true
export { render, staticRenderFns }
if (module.hot) {
  module.hot.accept()
  if (module.hot.data) {
    require("vue-hot-reload-api")      .rerender("data-v-a68316a2", { render: render, staticRenderFns: staticRenderFns })
  }
}