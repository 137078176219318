import Vue from 'vue'
import VueRouter from 'vue-router'
import routes from './routes'
import { permits } from 'services'


Vue.use(VueRouter)

export function createRouter () {
  //vue router options
  let router = new VueRouter({
    hashbang: true,
    mode: 'history',
    routes, // short for routes: routes
    scrollBehavior (to, from, savedPosition) {
      return { x: 0, y: 0 }
    }
  })

  router.afterEach((to, from, next) => {
    console.log('<--- AFTER RENDERING ROUTE -->');

  });

  router.beforeEach((to, from, next) => {
    console.log('<--- BEFORE RENDERING ROUTE -->');
    if (to.meta.permits && !isServer) {
      if (permits.hasPermits(to.meta.permits)) {
        next();
      } else {
        next(false);
      }

      return;
    }

    next();
  });

  return router;
}
