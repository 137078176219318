var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "reservation-details" },
    [
      _c("div", { staticClass: "relative" }, [
        _c("div", { staticClass: "white-box reservation-details" }, [
          _vm.reservation.cancelled
            ? _c("div", { staticClass: "reservation-cancelled" })
            : _vm._e(),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "row justify-content-between padding-bottom-7x" },
            [
              _c("div", { staticClass: "col-md-5" }, [
                _c("div", { staticClass: "location-info margin-bottom-4x" }, [
                  _c("div", { staticClass: "regular f20 margin-bottom-2x" }, [
                    _vm._v(
                      "\n              " +
                        _vm._s(_vm.activeHistory.location.name) +
                        "\n            "
                    )
                  ]),
                  _vm._v(" "),
                  _c("div", {}, [
                    _vm._v(
                      "\n              " +
                        _vm._s(_vm.activeHistory.location.address) +
                        "\n            "
                    )
                  ]),
                  _vm._v(" "),
                  _vm.activeHistory.reservation_type != "parking"
                    ? _c(
                        "div",
                        [
                          _vm.showTripadvisorReviews
                            ? _c("tripadvisor-rating", {
                                staticClass:
                                  "d-flex bold margin-top-2x align-items-center",
                                attrs: {
                                  location_link:
                                    _vm.activeHistory.location
                                      .tripadvisor_reviews.web_url,
                                  rating:
                                    _vm.activeHistory.location
                                      .tripadvisor_reviews.rating,
                                  reviews_count:
                                    _vm.activeHistory.location
                                      .tripadvisor_reviews.num_reviews,
                                  font_size: "14px",
                                  show_icon: true
                                }
                              })
                            : _c("div", { staticClass: "d-flex bold" }, [
                                _c("span", { staticClass: "text-warning" }, [
                                  _vm._v("Not rated yet")
                                ])
                              ])
                        ],
                        1
                      )
                    : _vm._e()
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "guests-and-dates f15" }, [
                  _c("div", { staticClass: "regular margin-bottom-2x f16" }, [
                    _vm._v("\n              Res. No.: "),
                    _c("i", {
                      staticClass: "fa fa-hashtag",
                      attrs: { "aria-hidden": "true" }
                    }),
                    _vm._v(
                      "\n              " +
                        _vm._s(_vm.reservation.reservation_number) +
                        "\n            "
                    )
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "margin-bottom-2x" }, [
                    _vm.activeHistory.reservation_type == "room" ||
                    _vm.activeHistory.reservation_type == "both"
                      ? _c("span", [
                          _vm._v(
                            "\n                " +
                              _vm._s(
                                _vm.activeHistory.rooms[0].room_type_name
                              ) +
                              " room\n              "
                          )
                        ])
                      : _vm._e(),
                    _vm._v(" "),
                    _vm.activeHistory.reservation_type == "both"
                      ? _c("span", [_vm._v(" & ")])
                      : _vm._e(),
                    _vm._v(" "),
                    _vm.activeHistory.reservation_type == "both" ||
                    _vm.activeHistory.reservation_type == "parking"
                      ? _c("span", [
                          _vm._v(
                            "\n                " +
                              _vm._s(_vm.activeHistory.parking_type_name) +
                              " parking\n              "
                          )
                        ])
                      : _vm._e()
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "margin-bottom-2x" }, [
                    _vm._v(
                      "\n              " +
                        _vm._s(
                          _vm.activeHistory.number_of_sleeping_nights
                            ? _vm.activeHistory.number_of_sleeping_nights +
                                " night" +
                                (_vm.activeHistory.number_of_sleeping_nights !=
                                1
                                  ? "s"
                                  : "")
                            : ""
                        ) +
                        "\n              "
                    ),
                    _vm.activeHistory.reservation_type == "both"
                      ? _c("span", [_vm._v(" & ")])
                      : _vm._e(),
                    _vm._v(
                      "\n              " +
                        _vm._s(
                          _vm.activeHistory.number_of_parking_days
                            ? _vm.activeHistory.number_of_parking_days +
                                " day" +
                                (_vm.activeHistory.number_of_parking_days != 1
                                  ? "s"
                                  : "") +
                                " of parking"
                            : ""
                        ) +
                        "\n            "
                    )
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "margin-bottom-2x" }, [
                    _c("i", {
                      staticClass: "fa fa-calendar-o",
                      attrs: { "aria-hidden": "true" }
                    }),
                    _vm._v(
                      "\n              " +
                        _vm._s(
                          _vm._f("dateFormat")(
                            _vm.activeHistory.from_date,
                            "DD/MMM/YYYY",
                            ["YYYY-MM-DD HH:mm:ss"]
                          )
                        ) +
                        "\n              "
                    ),
                    _c("i", {
                      staticClass: "fa fa-long-arrow-right margin-left-1x",
                      attrs: { "aria-hidden": "true" }
                    }),
                    _vm._v(
                      "\n              " +
                        _vm._s(
                          _vm._f("dateFormat")(
                            _vm.activeHistory.to_date,
                            "DD/MMM/YYYY",
                            ["YYYY-MM-DD HH:mm:ss"]
                          )
                        ) +
                        "\n            "
                    )
                  ]),
                  _vm._v(" "),
                  _vm.activeHistory.reservation_type != "parking"
                    ? _c("div", { staticClass: "margin-bottom-2x" }, [
                        _c("i", {
                          staticClass: "fa fa-users",
                          attrs: { "aria-hidden": "true" }
                        }),
                        _vm._v(
                          "\n              " +
                            _vm._s(_vm.totalGuestsNumber) +
                            " Guest" +
                            _vm._s(_vm.totalGuestsNumber != 1 ? "s" : "") +
                            "\n              in\n              " +
                            _vm._s(_vm.activeHistory.number_of_rooms) +
                            " Room" +
                            _vm._s(
                              _vm.activeHistory.number_of_rooms != 1 ? "s" : ""
                            ) +
                            "\n            "
                        )
                      ])
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.activeHistory.reservation_type == "both"
                    ? _c("div", { staticClass: "margin-bottom-2x" }, [
                        _c("i", {
                          staticClass: "fa fa-bed",
                          attrs: { "aria-hidden": "true" }
                        }),
                        _vm._v(" "),
                        _c("span", { staticClass: "regular f15" }, [
                          _vm._v("Rooms:")
                        ]),
                        _vm._v(" "),
                        _vm.activeHistory.nights_to_sleep == "both"
                          ? _c("span", [
                              _vm._v(
                                "\n                Stay first & last nights\n              "
                              )
                            ])
                          : _vm._e(),
                        _vm._v(" "),
                        _vm.activeHistory.nights_to_sleep == "end"
                          ? _c("span", [
                              _vm._v(
                                "\n                Stay the last night\n              "
                              )
                            ])
                          : _vm._e(),
                        _vm._v(" "),
                        _vm.activeHistory.nights_to_sleep == "beginning"
                          ? _c("span", [
                              _vm._v(
                                "\n                Stay the first night\n              "
                              )
                            ])
                          : _vm._e()
                      ])
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.activeHistory.reservation_type != "parking"
                    ? _c("div", [
                        _c("div", { staticClass: "margin-bottom-2x" }, [
                          _c("i", {
                            staticClass: "fa fa-clock-o",
                            attrs: { "aria-hidden": "true" }
                          }),
                          _vm._v(
                            "\n                Room Check-in:\n                "
                          ),
                          _c("span", [
                            _vm._v(
                              "\n                  " +
                                _vm._s(
                                  _vm._f("dateFormat")(
                                    _vm.activeHistory.location.checkin_time,
                                    "hh:mm a",
                                    ["HH:mm"]
                                  )
                                ) +
                                "\n                "
                            )
                          ])
                        ]),
                        _vm._v(" "),
                        _c("div", { staticClass: "margin-bottom-2x" }, [
                          _c("i", {
                            staticClass: "fa fa-clock-o",
                            attrs: { "aria-hidden": "true" }
                          }),
                          _vm._v(
                            "\n                Room Check-out:\n                "
                          ),
                          _c("span", [
                            _vm._v(
                              "\n                  " +
                                _vm._s(
                                  _vm._f("dateFormat")(
                                    _vm.activeHistory.location.checkout_time,
                                    "hh:mm a",
                                    ["HH:mm"]
                                  )
                                ) +
                                "\n                "
                            )
                          ])
                        ])
                      ])
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.activeHistory.number_of_spots &&
                  _vm.activeHistory.reservation_type != "room"
                    ? _c("div", { staticClass: "margin-bottom-2x" }, [
                        _c("i", {
                          staticClass: "fa fa-car",
                          attrs: { "aria-hidden": "true" }
                        }),
                        _vm._v(" "),
                        _c("span", { staticClass: "regular f15" }, [
                          _vm._v("Parking:")
                        ]),
                        _vm._v(
                          "\n              " +
                            _vm._s(_vm.activeHistory.number_of_spots) +
                            " car" +
                            _vm._s(
                              _vm.activeHistory.number_of_spots != 1 ? "s" : ""
                            ) +
                            "\n            "
                        )
                      ])
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.activeHistory.reservation_type != "room"
                    ? _c("div", [
                        _c("div", { staticClass: "margin-bottom-2x" }, [
                          _c("i", {
                            staticClass: "fa fa-clock-o",
                            attrs: { "aria-hidden": "true" }
                          }),
                          _vm._v(
                            "\n                Vehicle Drop-Off:\n                "
                          ),
                          _c("span", [
                            _vm._v(
                              "\n                  " +
                                _vm._s(
                                  _vm._f("dateFormat")(
                                    _vm.activeHistory.from_date,
                                    "DD/MMM/YYYY",
                                    ["YYYY-MM-DD HH:mm:ss"]
                                  )
                                ) +
                                " after " +
                                _vm._s(
                                  _vm._f("dateFormat")(
                                    _vm.activeHistory.from_date,
                                    "hh:mm a",
                                    ["YYYY-MM-DD HH:mm:ss"]
                                  )
                                ) +
                                "\n                "
                            )
                          ])
                        ]),
                        _vm._v(" "),
                        _c("div", { staticClass: "margin-bottom-2x" }, [
                          _c("i", {
                            staticClass: "fa fa-clock-o",
                            attrs: { "aria-hidden": "true" }
                          }),
                          _vm._v(
                            "\n                Vehicle Pick-Up:\n                "
                          ),
                          _c("span", [
                            _vm._v(
                              "\n                  " +
                                _vm._s(
                                  _vm._f("dateFormat")(
                                    _vm.activeHistory.to_date,
                                    "DD/MMM/YYYY",
                                    ["YYYY-MM-DD HH:mm:ss"]
                                  )
                                ) +
                                " before " +
                                _vm._s(
                                  _vm._f("dateFormat")(
                                    _vm.activeHistory.to_date,
                                    "hh:mm a",
                                    ["YYYY-MM-DD HH:mm:ss"]
                                  )
                                ) +
                                "\n                "
                            )
                          ])
                        ])
                      ])
                    : _vm._e()
                ]),
                _vm._v(" "),
                _c("div", {}, [
                  _c(
                    "a",
                    {
                      staticClass: "btn btn-info btn-block",
                      attrs: { href: "#" },
                      on: {
                        click: function($event) {
                          $event.preventDefault()
                          return _vm.openUpdateModal($event)
                        }
                      }
                    },
                    [_vm._v("\n              Manage Booking\n            ")]
                  ),
                  _vm._v(" "),
                  _c("hr")
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "calculations" }, [
                  _c("table", { staticClass: "w-100" }, [
                    _c("tr", [
                      _c("th", [
                        _vm._v("\n                  Subtotal\n                ")
                      ]),
                      _vm._v(" "),
                      _c("td", [
                        _vm._v(
                          _vm._s(
                            _vm._f("currency")(
                              _vm.activeHistory.subtotal,
                              _vm.symbol
                            )
                          )
                        )
                      ])
                    ]),
                    _vm._v(" "),
                    _vm.activeHistory.total_fees
                      ? _c("tr", [
                          _c("th", [
                            _vm._v(
                              "\n                  Total Fees\n                "
                            )
                          ]),
                          _vm._v(" "),
                          _c("td", [
                            _vm._v(
                              _vm._s(
                                _vm._f("currency")(
                                  _vm.activeHistory.total_fees,
                                  _vm.symbol
                                )
                              )
                            )
                          ])
                        ])
                      : _vm._e(),
                    _vm._v(" "),
                    _vm.activeHistory.coupon && _vm.activeHistory.coupon.code
                      ? _c("tr", [
                          _c("th", [
                            _c("i", {
                              staticClass: "fa fa-times",
                              staticStyle: { cursor: "pointer" },
                              attrs: { "aria-hidden": "true" },
                              on: {
                                click: function($event) {
                                  _vm.removeCoupon()
                                }
                              }
                            }),
                            _vm._v(
                              "\n                  Coupon (" +
                                _vm._s(_vm.activeHistory.coupon.code) +
                                ") Total\n                "
                            )
                          ]),
                          _vm._v(" "),
                          _c("td", [
                            _vm._v(
                              "-" +
                                _vm._s(
                                  _vm._f("currency")(
                                    _vm.activeHistory.channel_discount,
                                    _vm.symbol
                                  )
                                )
                            )
                          ])
                        ])
                      : _vm._e(),
                    _vm._v(" "),
                    _vm.activeHistory.long_term_discount
                      ? _c("tr", [
                          _c("th", [
                            _vm._v(
                              "\n                  Long Term Discount\n                "
                            )
                          ]),
                          _vm._v(" "),
                          _c("td", [
                            _vm._v(
                              "-" +
                                _vm._s(
                                  _vm._f("currency")(
                                    _vm.activeHistory.long_term_discount,
                                    _vm.symbol
                                  )
                                )
                            )
                          ])
                        ])
                      : _vm._e(),
                    _vm._v(" "),
                    _vm.activeHistory.total_tax
                      ? _c("tr", [
                          _c("th", [_vm._v("Tax Total")]),
                          _vm._v(" "),
                          _c("td", [
                            _vm._v(
                              _vm._s(
                                _vm._f("currency")(
                                  _vm.activeHistory.total_tax,
                                  _vm.symbol
                                )
                              )
                            )
                          ])
                        ])
                      : _vm._e(),
                    _vm._v(" "),
                    _vm.activeHistory.trip_price
                      ? _c("tr", [
                          _c("th", [
                            _vm._v(
                              "\n                  Trip Protection\n                  "
                            ),
                            _c("small", [
                              _vm._v(
                                _vm._s(
                                  _vm.activeHistory.trip_price.trip_protection
                                    .name
                                )
                              )
                            ])
                          ]),
                          _vm._v(" "),
                          _c("td", [
                            _vm._v(
                              _vm._s(
                                _vm._f("currency")(
                                  _vm.activeHistory.trip_protection_price,
                                  _vm.symbol
                                )
                              )
                            )
                          ])
                        ])
                      : _vm._e()
                  ]),
                  _vm._v(" "),
                  _c("hr"),
                  _vm._v(" "),
                  _c("table", { staticClass: "w-100" }, [
                    _c("tr", [
                      _c("th", { staticClass: "regular" }, [
                        _vm._v("\n                  Total\n                ")
                      ]),
                      _vm._v(" "),
                      _c("td", { staticClass: "regular" }, [
                        _vm._v(
                          "\n                  " +
                            _vm._s(
                              _vm._f("currency")(
                                _vm.activeHistory.grand_total,
                                _vm.symbol
                              )
                            ) +
                            "\n                "
                        )
                      ])
                    ]),
                    _vm._v(" "),
                    _c("tr", [
                      _c("th", { staticClass: "regular" }, [
                        _vm._v(
                          "\n                  Already Paid\n                "
                        )
                      ]),
                      _vm._v(" "),
                      _c("td", { staticClass: "regular" }, [
                        _vm._v(
                          "\n                  " +
                            _vm._s(
                              _vm._f("currency")(
                                _vm.activeHistory.total_paid_amount,
                                _vm.symbol
                              )
                            ) +
                            "\n                "
                        )
                      ])
                    ]),
                    _vm._v(" "),
                    _vm.activeHistory.due_at_location_total
                      ? _c("tr", [
                          _c("th", { staticClass: "regular" }, [
                            _vm._v(
                              "\n                  Due At Check-in\n                "
                            )
                          ]),
                          _vm._v(" "),
                          _c("td", { staticClass: "regular" }, [
                            _vm._v(
                              "\n                  " +
                                _vm._s(
                                  _vm._f("currency")(
                                    _vm.activeHistory.due_at_location_total,
                                    _vm.symbol
                                  )
                                ) +
                                "\n                "
                            )
                          ])
                        ])
                      : _vm._e()
                  ])
                ])
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "col-md-6 d-flex" }, [
                _vm.featuredPhoto
                  ? _c(
                      "a",
                      {
                        staticClass: "d-flex flex-grow-1",
                        attrs: {
                          "data-lightbox": "gallery",
                          href: _vm.featuredPhoto
                        }
                      },
                      [
                        _c("image-bg", {
                          staticClass:
                            "search-results--image reservation-details--image flex-grow-1",
                          attrs: { src: _vm.featuredPhoto }
                        })
                      ],
                      1
                    )
                  : _c(
                      "a",
                      {
                        staticClass: "d-flex flex-grow-1",
                        attrs: {
                          "data-lightbox": "gallery",
                          href: "/assets/images/not-found.png"
                        }
                      },
                      [
                        _c("image-bg", {
                          staticClass:
                            "search-results--image reservation-details--image flex-grow-1",
                          attrs: { src: "/assets/images/not-found.png" }
                        })
                      ],
                      1
                    )
              ])
            ]
          ),
          _vm._v(" "),
          _c("hr"),
          _vm._v(" "),
          _c("div", { staticClass: "row margin-top-7x" }, [
            _c("div", { staticClass: "col-md-4" }, [
              _c(
                "div",
                { staticClass: "text-info regular margin-bottom-3x f18" },
                [_vm._v("\n            Customer Info.\n          ")]
              ),
              _vm._v(" "),
              _c("div", { staticClass: "margin-bottom-2x" }, [
                _c("label", [_vm._v("Name:")]),
                _vm._v(" "),
                _c("span", { staticClass: "regular" }, [
                  _vm._v(
                    "\n              " +
                      _vm._s(_vm.activeHistory.first_name) +
                      "\n              " +
                      _vm._s(_vm.activeHistory.last_name) +
                      "\n            "
                  )
                ])
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "margin-bottom-2x" }, [
                _c("label", [_vm._v("Email:")]),
                _vm._v(" "),
                _c("span", { staticClass: "regular" }, [
                  _vm._v(
                    "\n              " +
                      _vm._s(_vm.activeHistory.email) +
                      "\n            "
                  )
                ])
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "margin-bottom-2x" }, [
                _c("label", [_vm._v("Phone:")]),
                _vm._v(" "),
                _c("span", { staticClass: "regular" }, [
                  _vm._v(
                    "\n              " +
                      _vm._s(_vm.activeHistory.phone) +
                      "\n            "
                  )
                ])
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "margin-bottom-2x" }, [
                _c("label", [_vm._v("Zip/Postal Code:")]),
                _vm._v(" "),
                _c("span", { staticClass: "regular" }, [
                  _vm._v(
                    "\n              " +
                      _vm._s(_vm.activeHistory.zipcode) +
                      "\n            "
                  )
                ])
              ])
            ]),
            _vm._v(" "),
            _vm.activeHistory.reservation_type != "parking"
              ? _c(
                  "div",
                  { staticClass: "col-md-4" },
                  [
                    _c(
                      "div",
                      { staticClass: "text-info regular margin-bottom-3x f18" },
                      [_vm._v("\n            Guests Names\n          ")]
                    ),
                    _vm._v(" "),
                    _vm._l(_vm.activeHistory.rooms, function(room, i) {
                      return _c("div", { staticClass: "margin-bottom-2x" }, [
                        _c("label", [_vm._v("Room " + _vm._s(i + 1) + ":")]),
                        _vm._v(" "),
                        _c("span", { staticClass: "regular" }, [
                          _vm._v(
                            "\n              " +
                              _vm._s(room.guest_name) +
                              "\n            "
                          )
                        ])
                      ])
                    })
                  ],
                  2
                )
              : _vm._e(),
            _vm._v(" "),
            !_vm._.isEmpty(_vm.activeHistory.extra_fields)
              ? _c(
                  "div",
                  { staticClass: "col-md-4" },
                  [
                    _c(
                      "div",
                      { staticClass: "text-info regular margin-bottom-3x f18" },
                      [_vm._v("\n            Other Details\n          ")]
                    ),
                    _vm._v(" "),
                    _vm._l(_vm.activeHistory.extra_fields, function(field) {
                      return _c("div", { staticClass: "margin-bottom-2x" }, [
                        _c("label", [_vm._v(_vm._s(field.label) + ":")]),
                        _vm._v(" "),
                        _c("span", { staticClass: "regular" }, [
                          _vm._v(
                            "\n              " +
                              _vm._s(field.value.join(", ")) +
                              "\n            "
                          )
                        ])
                      ])
                    })
                  ],
                  2
                )
              : _vm._e()
          ])
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "white-box padding-9x" }, [
          _vm.activeHistory.location.shuttle_info_details
            ? _c("div", { staticClass: "margin-bottom-5x" }, [
                _c("div", { staticClass: "regular f18" }, [
                  _vm._v("\n          Shuttle Information\n        ")
                ]),
                _vm._v(" "),
                _c("div", {
                  staticClass: "text-content has-normal-list",
                  domProps: {
                    innerHTML: _vm._s(
                      _vm.activeHistory.location.shuttle_info_details
                    )
                  }
                })
              ])
            : _vm._e(),
          _vm._v(" "),
          _c("div", { staticClass: "margin-bottom-5x" }, [
            _c("div", { staticClass: "regular f18" }, [
              _vm._v("\n          Cancellation Policy\n        ")
            ]),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "text-content" },
              [
                _c("cancellation-policy", {
                  attrs: {
                    "cancellation-policies":
                      _vm.activeHistory.location.cancellation_policies,
                    "reservation-type": _vm.activeHistory.reservation_type,
                    "reservation-from-date": _vm.activeHistory.from_date
                  }
                })
              ],
              1
            )
          ]),
          _vm._v(" "),
          _vm.activeHistory.location.special_conditions
            ? _c("div", { staticClass: "margin-bottom-5x" }, [
                _c("div", { staticClass: "regular f18" }, [
                  _vm._v("\n          Special Conditions\n        ")
                ]),
                _vm._v(" "),
                _c("div", {
                  staticClass: "text-content has-normal-list",
                  domProps: {
                    innerHTML: _vm._s(
                      _vm.activeHistory.location.special_conditions
                    )
                  }
                })
              ])
            : _vm._e(),
          _vm._v(" "),
          _vm.activeHistory.location.description
            ? _c("div", {}, [
                _c("div", { staticClass: "regular f18" }, [
                  _vm._v("\n          Additional Details\n        ")
                ]),
                _vm._v(" "),
                _c("div", {
                  staticClass: "text-content has-normal-list",
                  domProps: {
                    innerHTML: _vm._s(_vm.activeHistory.location.description)
                  }
                })
              ])
            : _vm._e()
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "white-box padding-9x" }, [
          _c("div", { staticClass: "row" }, [
            _c("div", { staticClass: "col-sm-12" }, [
              _c(
                "button",
                {
                  staticClass: "btn btn-md btn-info mr-2 margin-bottom-2x",
                  on: {
                    click: function($event) {
                      $event.preventDefault()
                      return _vm.openEmailModal($event)
                    }
                  }
                },
                [
                  _c("i", {
                    staticClass: "fa fa-paper-plane",
                    attrs: { "aria-hidden": "true" }
                  }),
                  _vm._v(" "),
                  _c("span", [_vm._v("Send to Email")])
                ]
              ),
              _vm._v(" "),
              _c(
                "button",
                {
                  staticClass: "btn btn-md btn-info mr-2 margin-bottom-2x",
                  class: { disabled: _vm.loading.download },
                  attrs: { disabled: _vm.loading.download },
                  on: {
                    click: function($event) {
                      $event.preventDefault()
                      return _vm.downloadReservation($event)
                    }
                  }
                },
                [
                  !_vm.loading.download
                    ? _c("i", {
                        staticClass: "fa fa-download",
                        attrs: { "aria-hidden": "true" }
                      })
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.loading.download
                    ? _c("i", {
                        staticClass: "fa fa-spinner fa-spin",
                        attrs: { "aria-hidden": "true" }
                      })
                    : _vm._e(),
                  _vm._v(" "),
                  !_vm.loading.download
                    ? _c("span", [_vm._v("Download")])
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.loading.download
                    ? _c("span", [_vm._v("Downloading...")])
                    : _vm._e()
                ]
              ),
              _vm._v(" "),
              _vm.activeHistory.location.latitude &&
              _vm.activeHistory.location.longitude
                ? _c(
                    "a",
                    {
                      staticClass: "btn btn-md btn-info mr-2 margin-bottom-2x",
                      attrs: {
                        target: "_blank",
                        href:
                          "https://www.google.com/maps/dir//" +
                          _vm.activeHistory.location.latitude +
                          "," +
                          _vm.activeHistory.location.longitude +
                          "/@" +
                          _vm.activeHistory.location.latitude +
                          "," +
                          _vm.activeHistory.location.longitude +
                          ",13z"
                      }
                    },
                    [
                      _c("i", {
                        staticClass: "fa fa-map-marker",
                        attrs: { "aria-hidden": "true" }
                      }),
                      _vm._v("\n              Get Directions\n            ")
                    ]
                  )
                : _vm._e(),
              _vm._v(" "),
              !_vm.reservation.cancelled
                ? _c(
                    "button",
                    {
                      staticClass: "btn btn-outline-danger margin-bottom-2x",
                      on: {
                        click: function($event) {
                          $event.preventDefault()
                          return _vm.openCancellationModal($event)
                        }
                      }
                    },
                    [_vm._v("\n              Cancel Reservation\n            ")]
                  )
                : _vm._e()
            ])
          ])
        ])
      ]),
      _vm._v(" "),
      _c("send-email-modal", {
        ref: "sendEmailModal",
        attrs: { reservation: _vm.reservation }
      }),
      _vm._v(" "),
      _vm.showModal
        ? _c("update-modal", {
            ref: "updateModal",
            attrs: {
              reservation: _vm.reservation,
              "close-function": _vm.onCloseModal
            },
            on: { reservationUpdated: _vm.onUpdateReservationFinished }
          })
        : _vm._e(),
      _vm._v(" "),
      _c("cancellation-modal", {
        ref: "cancellationModal",
        attrs: { reservation: _vm.reservation }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true
export { render, staticRenderFns }
if (module.hot) {
  module.hot.accept()
  if (module.hot.data) {
    require("vue-hot-reload-api")      .rerender("data-v-4a031a4e", { render: render, staticRenderFns: staticRenderFns })
  }
}