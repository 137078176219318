import toastr from 'toastr'
import { http } from 'services'
import layout from 'layouts/default'
import bigSearch from 'layouts/default/partials/bigSearch'
import imageBg from 'components/imageBg'
import carousel from 'components/carousel'
import howItWorks from 'layouts/default/partials/howItWorks'

const components = {
  layout,
  bigSearch,
  imageBg,
  carousel,
  howItWorks
}

const computed = {
  airports () {
    let ports = {};
    let sorted = _.orderBy(this.$store.state.airports.all, ['name'], ['asc']);
    ports.us = _.filter(sorted, (item) => item.country.code.toLowerCase() == 'us');
    ports.ca = _.filter(sorted, (item) => item.country.code.toLowerCase() == 'ca');

    return ports;
  }
}

const methods = {
  onSubscribe (event, email) {
    console.log('event', event.target.action, email);
    let mc_form = $('#mc_form');

    $.ajax({
      type: event.target.method,
      url: event.target.action,
      data: mc_form.serialize(),
      cache: false,
      dataType: 'jsonp',
      jsonp: 'c',
      contentType: "application/json; charset=utf-8",
      success: (data) => {
        if (data.result != "success") {
          toastr.error(data.msg);
        } else {
          toastr.success('You have subscribed to our mailing list successfully');
        }
      }
    });
  }
}

export default {
  name: 'main-page',
  components,
  computed,
  methods,
  data () {
    return {
      carousels: [],
      renderCarousel: false,
      airportsLoading: false
    }
  },
  asyncData ({store}) {
    store.dispatch('title.setTitle', 'Tripteo | Airport Hotel & Parking');
    store.dispatch('title.setMeta', [
      {name: 'description', content: "Book your airport parking and hotel packages with Tripteo and save 50% on your airport transportation. Options to sleep at premium hotels before or after your trip, with parking included in the packages. Park your car, stay the night & Fly Fresh in the morning."}
    ]);
  },
  created () {
    //  this.$store.dispatch('title.setTitle', 'Tripteo');
    this.$store.dispatch('carousels.getAll').then(() => {
      this.renderCarousel = true;
      this.carousels = _.cloneDeep(this.$store.state.carousels.all);
    });

    this.airportsLoading = true;
    this.$store.dispatch('airports.getAll').then(() => {
      this.airportsLoading = false;
    });
  },
  mounted () {
    console.log('this.$route.query', this.$route.query);
    if (this.$route.query.source && this.$route.query.client_secret) {
      if (window.parent) {
        window.parent.setSource(this.$route.query.source);
      } else {
        toastr.error('Could not reach parent window');
      }
    }
  }
}
