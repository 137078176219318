
const methods = {
	incrementCars () {
		if (this.disabled) return;

		this.number_of_spots = parseInt(this.number_of_spots)
		if(this.number_of_spots + 1 > this.max) return;

		this.number_of_spots++

		if (this.timeout) {
			clearTimeout(this.timeout)
		}

		this.timeout = setTimeout(() => {
			this.$emit('change', this.number_of_spots)
		}, 200);
	},

	decrementCars () {
		if (this.disabled) return;
		
		this.number_of_spots = parseInt(this.number_of_spots)
		if(this.number_of_spots -1 < 1) return;

		this.number_of_spots--

		if (this.timeout) {
			clearTimeout(this.timeout)
		}

		this.timeout = setTimeout(() => {
	  	this.$emit('change', this.number_of_spots)
		this.$emit('decrease', this.number_of_spots)
		}, 200)
	},
}

const watch = {
	numberOfSpots (n, o) {
		if (n != o) {
			this.number_of_spots = n;
		}
	}
}


const computed = {
	max () {
		return (typeof this.maxNumberOfSpots == "undefined" || parseInt(this.maxNumberOfSpots) > 5
			? 5
			: this.maxNumberOfSpots);
	}
}


export default {
  name: 'number-of-cars',
  props: [
    'numberOfSpots', 'maxNumberOfSpots', 'disabled'
  ],
  methods,
  watch,
	computed,
  data () {
    return {
      number_of_spots: this.numberOfSpots,
			timeout: null
    }
  }
}
