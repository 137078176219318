class ScreenSize {
  getScreens() {
    return {
      xxs_mobile: 575,
      xs_mobile: 767,
      s_mobile: 991,
      m_mobile: 1199,
    }
  }
}

export default new ScreenSize().getScreens();
