import { funcModal } from 'components/modals'
import moment from 'moment'

const components = {
  funcModal
}

const filters = {
}

const computed = {
  breakdown () {
    let breakdown = _.cloneDeep(this.$store.state.locations.breakdown);
    breakdown.rates = _.sortBy(breakdown.rates, ['id']).reverse();
    return breakdown;
  },
}

const methods = {
  open () {
    this.$refs.subtotalBreakdownModal.open();
    this.getRatesList();
  },
  priceInThisDate (date) {
    
    let day_name = date.format('dddd').toLowerCase();
    let ratesList = _.cloneDeep(this.breakdown.rates);
    
    for (let i in ratesList) {
        let start_date = moment(ratesList[i].from_date, ['YYYY-MM-DD']);
        let end_date =  moment(ratesList[i].to_date, ['YYYY-MM-DD']);
        if (date.isSameOrAfter(start_date) && (date.isSameOrBefore(end_date) || !end_date.isValid()) && ratesList[i][day_name]) {
          return ratesList[i].price * (this.typeName == 'parking' ? this.numberOfSpots : this.numberOfRooms);
        }
        
    }

    return 0;
  },

  getRatesList () {
    let data = {};
    
    data.from_date = moment(this.fromDate, ['DD/MMM/YYYY']).format('YYYY-MM-DD');
    data.to_date = moment(this.toDate, ['DD/MMM/YYYY']).format('YYYY-MM-DD');
    
    let from_time = _.cloneDeep(this.fromTime);
    if (!from_time) {
      from_time = "00:00"
    }

    let to_time = _.cloneDeep(this.toTime);
    if (!to_time) {
      to_time = "00:00"
    }

    data.from_date = data.from_date + " " + from_time + ":00"
    data.to_date = data.to_date + " " + to_time + ":00"
    data.currency_code = this.currencyCode;
    
    let location_id = this.locationId;
    data.location_id = location_id;
    data.reservation_type = this.typeName;

    if (this.typeName == 'parking') {
      data.parking_type = this.type.id;
      data.number_of_spots = this.numberOfSpots;
    } else if (this.typeName == 'room') {
      data.room_type = this.type.id;
      data.number_of_rooms = this.numberOfRooms;
      data.guests_in_rooms = this.guestsInRooms;
    } else {
      data.number_of_spots = this.numberOfSpots;
      data.number_of_rooms = this.numberOfRooms;
      data.nights_to_sleep = this.nightsToSleep;
      data.guests_in_rooms = this.guestsInRooms;

      if (this.type.id) {
        data.bundle_id = this.type.id;
      } else {
        data.parking_type = this.type.parking_type.id;
        data.room_type = this.type.room_type.id;
      }
    }

    this.loading = true;
    return this.$store.dispatch(`locations.getRatesBreakdown`,{id: location_id, data}).then(() => {
      this.loading = false;
    });
  },
}

export default {
  name: "subtotal-breakdown-modal",
  props: [
    'totalDays', 'type', 'currencySymbol', 'typeName', 
    'fromDate', 'toDate', 'fromTime', 'toTime', 'nightsToSleep',
    'currencyCode', 'locationId', 'numberOfSpots', 'numberOfRooms', 'guestsInRooms'
  ],
  components,
  filters,
  computed,
  methods,
  data () {
    return {
      moment,
      query: _.cloneDeep(this.$route.query),
      loading: false,
    }
  },
  mounted () {

  }
}
