import moment from 'moment'
import { currencySymbol, stripTags } from 'filters'
import tripadvisorRating from 'components/tripadvisorRating'
import detailsModal from 'layouts/default/partials/detailsModal'

let components = {
  detailsModal,
  tripadvisorRating
}

if (!isServer) {
  components.rating = require('vue-star-rating').default;
  components.popover = require('components/popover').default;
}

const watch = {
  formData: {
    handler (n) {
      this.form = _.assign(this.form, n);
    },
    deep: true
  },
}

const computed = {
  symbol () {
    if (this.location && _.isEmpty(this.currencyCode)){
      return currencySymbol(this.location.currency_code)
    } else if (!_.isEmpty(this.currencyCode)) {
      return currencySymbol(this.currencyCode)
    }

    return "$"
  },

  locationCurrencySymbol() {
    return currencySymbol(this.location.currency_code);
  },

  differentCurrencyWhenDueAtLot () {
    return this.costs && this.costs.reservation.due_at_location && 
          this.currencyCode !=  this.location.currency_code
  }, 

  featuredPhoto () {
    if (!this.location.photos.length) return null

    let filepath = null;

    let photo_obj = this.location.photos.filter(photo => photo.featured);
    // console.log('photo_obj', photo_obj);
    if (photo_obj) {
      if (photo_obj.length) {
        return photo_obj[0].filename
      }
    }

    return this.location.photos[0].filename
  },
  totalGuestsNumber () {
    return _.sum(this.form.guests_in_rooms);
  },
  showTripadvisorReviews () {
    return  this.location.tripadvisor_id &&
      ( this.$route.query.reservation_type == 'room' ||
        this.$route.query.reservation_type == 'both' ) &&
      !_.isEmpty(this.location.tripadvisor_reviews);
  },
  showCheckOutInWarning() {
    return this.costs && this.costs.warning && this.costs.warning.includes("booking exceeded hourly slot");
  },

  sortedCancellationPolicies () {
    if (this.location) {
      let data = this.location.cancellation_policies;
      let reservation_type = this.$route.query.reservation_type != 'parking' ? 'room' : 'parking';
      data =  _.filter(data, (item, index) => {
        return item.type == reservation_type && this.getFromDate(item, true);
      });
      return _.orderBy(data, ['number_of_days'], ['desc']);
    }
  },
}

const methods = {
  applyDiscountCode () {
  },
  removeCoupon () {
  },
  editSearch () {
    this.$emit('editSearch');
  },
  showDetailsModal() {
    this.$nextTick(() => {
      this.$refs.detailsModal.open();
    })
  },
  proceed () {
    this.$nextTick(() => {
      $('html, body').animate({
        scrollTop: $(".checkout-step-label.active").offset().top
      }, 500);
    });
  },
  toggleSubtotalModal () {
    this.$emit('showSubtotalBreakdownModal');
  },

  getFromDate (policy, compareWithNow) {
    let from_date = moment(this.formData.from_date).format('YYYY-MM-DD');
    let from_time = this.formData.from_time ? this.formData.from_time : '00:00';
    from_date = moment(from_date + " " + from_time).format("YYYY-MM-DD HH:mm:ss");
    let timezone = this.airport.timezone.code;
    let now = moment().tz(timezone).format("YYYY-MM-DD HH:mm:ss");
    if (compareWithNow) {
      return moment(from_date).subtract(policy.number_of_days, 'days').format("YYYY-MM-DD HH:mm:ss") > now;
    } else {
      return moment(from_date).subtract(policy.number_of_days, 'days').format(this.reservation_type == 'parking' ? "DD/MMM/YYYY hh:mm A" : "DD/MMM/YYYY");
    }
  }
}

const filters = {
  stripTags
}

export default {
  name: 'checkout-summary',
  props: ['formData', 'location', 'airport', 'currencyCode', 'costs', 'selectedTypes', 'numberOfSpots'],
  watch,
  components,
  methods,
  computed,
  filters,
  data () {
    
    return {
      form: this.formData,
      moment,
      reservation_type: this.$route.query.reservation_type,
    }
  },
  created () {

  }
}
