
import 'es6-promise/auto'
import Vue from 'vue'
import Main from './Main'
import Vue2Filters from 'vue2-filters'
import { createRouter } from 'router'
import { createStore } from 'store'
import { lodash } from './entry.mixins'
import { noSSR } from './entry.utils'

Vue.component(noSSR().name, noSSR())
Vue.mixin(lodash());
Vue.use(Vue2Filters);

export function createApp(ssrContext) {
  let router = createRouter();
  let store = createStore();

  let app = new Vue({
    store,
    router,
    render: h => h(Main),

  })

  return {app, router, store};
}
