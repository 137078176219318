import { funcModal } from 'components/modals'
import searchForm from '../searchForm'

const components = {
  funcModal,
  searchForm
}

const methods = {
  open () {
    this.$refs.modal.open();
  }
}

export default {
  name: "search-modal",
  props: ['formData', 'airport', 'routeName', 'onCloseModal', 'defaultActive'],
  components,
  methods,
  mounted () {
  }
}
