<template>
  <a tabindex="0" ref="popover" :class="popoverClass" role="button">{{ text }}</a>
</template>
<script>
export default {
  name: "popover",
  props: ['options', 'popoverClass', 'text'],
  mounted () {
    $(this.$refs.popover).popover(this.options || {});
  }
}
</script>
