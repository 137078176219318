var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.canShow
    ? _c(
        "div",
        {
          ref: "modal",
          staticClass: "modal fade",
          attrs: { tabindex: "-1", role: "dialog", "aria-labelledby": "modal" }
        },
        [
          _c(
            "div",
            { staticClass: "modal-dialog", attrs: { role: "document" } },
            [
              _c("div", { staticClass: "modal-content" }, [
                _c("div", { staticClass: "modal-header" }, [
                  _c(
                    "button",
                    {
                      staticClass: "close",
                      attrs: { type: "button" },
                      on: {
                        click: function($event) {
                          $event.preventDefault()
                          return _vm.close($event)
                        }
                      }
                    },
                    [
                      _c("span", { attrs: { "aria-hidden": "true" } }, [
                        _vm._v("×")
                      ])
                    ]
                  )
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "modal-body f16" }, [
                  _vm._v("\n        " + _vm._s(_vm.text) + "\n      ")
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "modal-footer" }, [
                  _c(
                    "button",
                    {
                      staticClass: "btn",
                      class: "btn-" + (_vm.type ? _vm.type : "default"),
                      on: {
                        click: function($event) {
                          $event.preventDefault()
                          return _vm.ok($event)
                        }
                      }
                    },
                    [_vm._v(_vm._s(_vm.ok_text))]
                  ),
                  _vm._v(" "),
                  _c(
                    "button",
                    {
                      staticClass: "btn btn-default",
                      on: {
                        click: function($event) {
                          $event.preventDefault()
                          return _vm.close($event)
                        }
                      }
                    },
                    [_vm._v(_vm._s(_vm.close_text))]
                  )
                ])
              ])
            ]
          )
        ]
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true
export { render, staticRenderFns }
if (module.hot) {
  module.hot.accept()
  if (module.hot.data) {
    require("vue-hot-reload-api")      .rerender("data-v-2d3214ea", { render: render, staticRenderFns: staticRenderFns })
  }
}