import toastr from 'toastr'
import layout from 'layouts/default'
import { searchFilter, escapeScript } from 'filters'



const components = {
  layout,
}

const filters = {
  searchFilter,
  escapeScript
}

const computed = {
  faqs () {
    return _.cloneDeep(this.$store.state.faq.all);
  },
  errors () {
    return _.cloneDeep(this.$store.state.faq.errors);
  }
}


const methods = {
   goToRef(id) {
    let target = document.querySelector(id);
    const top = target.offsetTop;
    window.scrollTo(0, top);
  },

  cleanHtmlString(value) {
    return escapeScript(value);
  },

  handleCollapse(item) {
    if (typeof this.colapsed[item.id] != undefined) {
       this.colapsed[item.id] = !this.colapsed[item.id];
    } else {
      this.colapsed[item.id] = true;
    }
    this.colapsed = _.cloneDeep(this.colapsed);
  },
  slugify(text) {
    return text.toString().toLowerCase()
      .replace(/\s+/g, '-')           // Replace spaces with -
      .replace(/[^\w\-]+/g, '')       // Remove all non-word chars
      .replace(/\-\-+/g, '-')         // Replace multiple - with single -
      .replace(/^-+/, '')             // Trim - from start of text
      .replace(/-+$/, '');            // Trim - from end of text
  },
  getFilteredResults () {
    let searchKey = this.faqFilter.toLowerCase();
    let categories = [];
    if (!searchKey.length) {
      this.filteredResults = _.cloneDeep(this.faqs);
      return;
    }

    _.forEach(this.faqs, (category) => {
      let category_id = category.id;
      let faq_category = _.cloneDeep(category);
      let faqs = [];
      _.forEach(category.faqs, (faq) => {
        if (faq.question.toLowerCase().includes(searchKey) || faq.answer.toLowerCase().includes(searchKey)) {
          faqs.push(faq);
        }
      })
      if (faqs.length) {
        faq_category.faqs = faqs;
        categories.push(faq_category);
      }
    });
    this.filteredResults = _.cloneDeep(categories);
  }
}
export default {
  name: 'faq',
  components,
  computed,
  methods,
  filters,
  asyncData ({ store, route, router }) {
    let promises = [
      store.dispatch('faq.getAll'),
    ];
    
    return Promise.all(promises).then(() => {
      store.dispatch('title.setTitle', "Frequently Asked Question");
    })
  },
  data () {
    return {
      loading: false,
      faqFilter:'',
      colapsed: {},
      filteredResults: [],
    }
  },
  created () {
    
  },
  mounted () {
    console.log('this.$route.query', this.$route.query);
    this.getFilteredResults();
    if (this.$route.query.source && this.$route.query.client_secret) {
      if (window.parent) {
        window.parent.setSource(this.$route.query.source);
      } else {
        toastr.error('Could not reach parent window');
      }
    }
  }
}
