var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("no-ssr", [
    !_vm._.isEmpty(_vm.flash)
      ? _c(
          "div",
          {
            staticClass: "alert-dismissible fade show alert",
            class: ["alert-" + _vm.flash.type],
            attrs: { role: "alert" }
          },
          [
            _c(
              "button",
              {
                staticClass: "close",
                attrs: {
                  type: "button",
                  "data-dismiss": "alert",
                  "aria-label": "Close"
                }
              },
              [_c("span", { attrs: { "aria-hidden": "true" } }, [_vm._v("×")])]
            ),
            _vm._v(" "),
            _c("div", { staticClass: "contianer" }, [
              _vm._v("\n\t\t\t" + _vm._s(_vm.flash.message) + "\t\n\t\t")
            ])
          ]
        )
      : _vm._e()
  ])
}
var staticRenderFns = []
render._withStripped = true
export { render, staticRenderFns }
if (module.hot) {
  module.hot.accept()
  if (module.hot.data) {
    require("vue-hot-reload-api")      .rerender("data-v-04b0c86c", { render: render, staticRenderFns: staticRenderFns })
  }
}