var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "func-modal",
    {
      ref: "modal",
      attrs: { title: "Cancel Reservation" },
      scopedSlots: _vm._u([
        {
          key: "footer",
          fn: function(props) {
            return [
              _vm.loading
                ? _c(
                    "button",
                    {
                      staticClass: "btn btn-success",
                      attrs: { disabled: _vm.loading, type: "button" }
                    },
                    [
                      _c("i", { staticClass: "fa fa-spinner fa-spin fa-fw" }),
                      _vm._v("\n      SAVING...\n    ")
                    ]
                  )
                : _c(
                    "button",
                    {
                      staticClass: "btn btn-success",
                      attrs: { type: "button" },
                      on: {
                        click: function($event) {
                          $event.preventDefault()
                          return _vm.confirm($event)
                        }
                      }
                    },
                    [_vm._v("\n      Confirm\n    ")]
                  ),
              _vm._v(" "),
              _c(
                "button",
                {
                  staticClass: "btn btn-default",
                  on: {
                    click: function($event) {
                      $event.preventDefault()
                      props.close()
                    }
                  }
                },
                [_vm._v("Close")]
              )
            ]
          }
        }
      ])
    },
    [
      _c("template", { slot: "body" }, [
        _c("div", { staticClass: "f17 regular margin-bottom-3x" }, [
          _vm._v(
            "\n      Are you sure you would like to cancel this reservation?\n    "
          )
        ]),
        _vm._v(" "),
        _vm.refundList.length
          ? _c("div", {}, [
              _c("div", { staticClass: "text-info f15 margin-bottom-3x" }, [
                _vm._v("The customer will get "),
                _c("span", { staticClass: "text-danger" }, [
                  _vm._v("refunded")
                ]),
                _vm._v(" as the following:")
              ]),
              _vm._v(" "),
              _c("table", { staticClass: "table table-bordered" }, [
                _c("thead", [
                  _c("tr", [
                    _c("th", [_vm._v("TYPE")]),
                    _vm._v(" "),
                    _c("th", [_vm._v("CARD NUMBER")]),
                    _vm._v(" "),
                    _c("th", [_vm._v("REFUND AMOUNT")])
                  ])
                ]),
                _vm._v(" "),
                _c(
                  "tbody",
                  _vm._l(_vm.refundList, function(item) {
                    return _c("tr", [
                      _c("td", [_c("span", [_vm._v("Credit Card")])]),
                      _vm._v(" "),
                      _c("td", [
                        _c("span", [
                          _vm._v("XXXXXXXXXXXX" + _vm._s(item.card_number))
                        ])
                      ]),
                      _vm._v(" "),
                      _c("td", [
                        _vm._v(
                          _vm._s(_vm._f("currency")(item.amount, _vm.symbol))
                        )
                      ])
                    ])
                  })
                )
              ])
            ])
          : _c("div", {}, [
              _c("div", { staticClass: "text-info f15 margin-bottom-3x" }, [
                _vm._v(
                  "\n        The reservation will be cancelled. \n        "
                ),
                _c("span", { staticClass: "text-danger" }, [
                  _vm._v(
                    " It is not eligible for a refund as per the refund policy"
                  )
                ])
              ])
            ])
      ])
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true
export { render, staticRenderFns }
if (module.hot) {
  module.hot.accept()
  if (module.hot.data) {
    require("vue-hot-reload-api")      .rerender("data-v-39e8ee98", { render: render, staticRenderFns: staticRenderFns })
  }
}