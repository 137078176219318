import toastr from 'toastr';
import { flashMessage } from 'services'
import { funcModal } from 'components/modals'
const methods = {
	validateFields () {
    for (let field in this.form) {
      if (!this.form[field]) {
        this.$set(this.errors, field, ['This field is required'])
      }
    }
    if (!_.isEmpty(this.errors)) {
			return;
		}
  },
	login () {
		let data = _.cloneDeep(this.form);
		this.errors = {};
		this.validateFields();
		this.loading = true;
		this.$store.dispatch('user.login', data).then(() => {
			this.loading = false;
			if (this.$store.state.user.errors) {
        if (!_.isEmpty(this.$store.state.user.errors.errors)) {
          this.errors = _.cloneDeep(this.$store.state.user.errors.errors);
        }
        toastr.error(this.$store.state.user.errors.message);
      } else {
	      this.$emit('userLoggedIn', true);
	      this.$store.dispatch('user.getUser');
      }
		})
	},

	togglePasswordResetModal () {
		this.$emit('triggerPasswordResetModal', true);
	}

	
}

const watch = {

}

const components = {
	funcModal
}


const computed = {
	
}


export default {
  name: 'login-form',
  methods,
  watch,
	computed,
	components,
  data () {
    return {
    	form: {
	      email: null,
	      password: null,
    	},
    	loading: false,
    	errors: {},
    }
  }
}
