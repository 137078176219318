var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", {}, [
    _c("div", { staticClass: "checkout-step-label visited" }, [
      _c("div", { staticClass: "number" }, [_vm._v("\n      1\n    ")]),
      _vm._v(" "),
      _c("div", { staticClass: "title" }, [
        _vm._v("\n      Your Details\n    ")
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "summary" }, [
        _c(
          "table",
          [
            _vm.form.reservation_type == "parking" &&
            _vm.form.first_name &&
            _vm.form.last_name
              ? _c("tr", [
                  _c("th", { staticClass: "text-right" }, [_vm._v("Name")]),
                  _vm._v(" "),
                  _c("td", [
                    _vm._v(
                      _vm._s(_vm.form.first_name) +
                        " " +
                        _vm._s(_vm.form.last_name)
                    )
                  ])
                ])
              : _vm._e(),
            _vm._v(" "),
            _vm.form.reservation_type != "parking"
              ? _c("tr", [
                  _c("th", { staticClass: "text-right" }, [
                    _vm._v("No. of Guests")
                  ]),
                  _vm._v(" "),
                  _c("td", [
                    _vm._v(
                      _vm._s(_vm.form.number_of_rooms) +
                        " Room" +
                        _vm._s(_vm.form.number_of_rooms != 1 ? "s" : "") +
                        "\n          & " +
                        _vm._s(_vm.totalGuestsNumber) +
                        " Guests"
                    )
                  ])
                ])
              : _vm._e(),
            _vm._v(" "),
            _vm._l(Number(_vm.form.number_of_rooms || 0), function(i) {
              return _vm.form.reservation_type != "parking" &&
                _vm.form.guests_names
                ? _c("tr", [
                    _c("th", { staticClass: "text-right" }, [
                      _vm._v("Room " + _vm._s(i))
                    ]),
                    _vm._v(" "),
                    _c("td", [_vm._v(_vm._s(_vm.form.guests_names[i - 1]))])
                  ])
                : _vm._e()
            }),
            _vm._v(" "),
            _vm.numberOfSpots
              ? _c("tr", [
                  _c("th", { staticClass: "text-right" }, [
                    _vm._v("No. of Cars")
                  ]),
                  _vm._v(" "),
                  _c("td", [
                    _vm._v(
                      _vm._s(_vm.numberOfSpots) +
                        " car" +
                        _vm._s(_vm.numberOfSpots != 1 ? "s" : "")
                    )
                  ])
                ])
              : _vm._e()
          ],
          2
        )
      ]),
      _vm._v(" "),
      _c(
        "button",
        {
          staticClass: "btn btn-outline-secondary edit-btn",
          on: {
            click: function($event) {
              $event.preventDefault()
              _vm.goToStep("details", true)
            }
          }
        },
        [_vm._v("Edit")]
      )
    ]),
    _vm._v(" "),
    _vm.form.reservation_type == "parking" &&
    _vm.tripProtections &&
    _vm.tripProtections.length
      ? _c("div", { staticClass: "checkout-step-label visited" }, [
          _c("div", { staticClass: "number" }, [_vm._v("\n      2\n    ")]),
          _vm._v(" "),
          _c("div", { staticClass: "title" }, [
            _vm._v("\n      Add-ons\n    ")
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "summary" }, [
            _c("table", [
              _vm.costs && _vm.form.trip_protection_id
                ? _c("tr", [
                    _c("th", { staticClass: "text-right" }, [
                      _vm._v("Trip Protection")
                    ]),
                    _vm._v(" "),
                    _c("td", [
                      _vm._v(_vm._s(_vm.costs.reservation.trip_protection.name))
                    ])
                  ])
                : _c("tr", [
                    _c("td", { attrs: { colspan: "2" } }, [_vm._v("None")])
                  ])
            ])
          ]),
          _vm._v(" "),
          _c(
            "button",
            {
              staticClass: "btn btn-outline-secondary edit-btn",
              on: {
                click: function($event) {
                  $event.preventDefault()
                  _vm.goToStep("addons", true)
                }
              }
            },
            [_vm._v("Edit")]
          )
        ])
      : _vm._e(),
    _vm._v(" "),
    _c("div", { staticClass: "checkout-step-label active" }, [
      _c("div", { staticClass: "number" }, [
        _vm._v(
          "\n      " +
            _vm._s(
              _vm.form.reservation_type == "parking" &&
                _vm.tripProtections &&
                _vm.tripProtections.length
                ? "3"
                : "2"
            ) +
            "\n    "
        )
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "title" }, [_vm._v("\n      Payment\n    ")])
    ]),
    _vm._v(" "),
    _c("div", { staticClass: "margin-top-2x margin-bottom-4x" }, [
      _c("div", { staticClass: "row" }, [
        _c("div", { staticClass: "form-group col-md-6" }, [
          _vm._m(0),
          _vm._v(" "),
          _c("input", {
            directives: [
              {
                name: "model",
                rawName: "v-model",
                value: _vm.form.first_name,
                expression: "form.first_name"
              }
            ],
            staticClass: "form-control",
            attrs: { type: "text" },
            domProps: { value: _vm.form.first_name },
            on: {
              input: function($event) {
                if ($event.target.composing) {
                  return
                }
                _vm.$set(_vm.form, "first_name", $event.target.value)
              }
            }
          }),
          _vm._v(" "),
          _vm.errors.first_name
            ? _c("div", { staticClass: "text-danger" }, [
                _vm._v(
                  "\n          " +
                    _vm._s(_vm.errors.first_name[0]) +
                    "\n        "
                )
              ])
            : _vm._e()
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "form-group col-md-6" }, [
          _vm._m(1),
          _vm._v(" "),
          _c("input", {
            directives: [
              {
                name: "model",
                rawName: "v-model",
                value: _vm.form.last_name,
                expression: "form.last_name"
              }
            ],
            staticClass: "form-control",
            attrs: { type: "text" },
            domProps: { value: _vm.form.last_name },
            on: {
              input: function($event) {
                if ($event.target.composing) {
                  return
                }
                _vm.$set(_vm.form, "last_name", $event.target.value)
              }
            }
          }),
          _vm._v(" "),
          _vm.errors.last_name
            ? _c("div", { staticClass: "text-danger" }, [
                _vm._v(
                  "\n          " +
                    _vm._s(_vm.errors.last_name[0]) +
                    "\n        "
                )
              ])
            : _vm._e()
        ])
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "row" }, [
        _c("div", { staticClass: "form-group col-md-6" }, [
          _c("label", { staticClass: "regular" }, [_vm._v("Zipcode")]),
          _vm._v(" "),
          _c("input", {
            directives: [
              {
                name: "model",
                rawName: "v-model",
                value: _vm.form.zipcode,
                expression: "form.zipcode"
              }
            ],
            staticClass: "form-control",
            attrs: { type: "text" },
            domProps: { value: _vm.form.zipcode },
            on: {
              input: function($event) {
                if ($event.target.composing) {
                  return
                }
                _vm.$set(_vm.form, "zipcode", $event.target.value)
              }
            }
          }),
          _vm._v(" "),
          _vm.errors.zipcode
            ? _c("div", { staticClass: "text-danger" }, [
                _vm._v(
                  "\n          " + _vm._s(_vm.errors.zipcode[0]) + "\n        "
                )
              ])
            : _vm._e()
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "form-group col-md-6" }, [
          _c("label", { staticClass: "regular" }, [_vm._v("Phone Number")]),
          _vm._v(" "),
          _c("input", {
            directives: [
              {
                name: "model",
                rawName: "v-model",
                value: _vm.form.phone,
                expression: "form.phone"
              }
            ],
            staticClass: "form-control",
            attrs: { type: "text" },
            domProps: { value: _vm.form.phone },
            on: {
              input: function($event) {
                if ($event.target.composing) {
                  return
                }
                _vm.$set(_vm.form, "phone", $event.target.value)
              }
            }
          }),
          _vm._v(" "),
          _vm.errors.phone
            ? _c("div", { staticClass: "text-danger" }, [
                _vm._v(
                  "\n          " + _vm._s(_vm.errors.phone[0]) + "\n        "
                )
              ])
            : _vm._e()
        ])
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "row" }, [
        _c("div", { staticClass: "form-group col-12" }, [
          _c("label", { staticClass: "regular" }, [
            _vm._v("Credit Card Number")
          ]),
          _vm._v(" "),
          _c("div", { attrs: { id: "cardNumber-element" } }),
          _vm._v(" "),
          _vm.errors.card_number
            ? _c("div", { staticClass: "text-danger" }, [
                _vm._v(
                  "\n          " +
                    _vm._s(_vm.errors.card_number[0]) +
                    "\n        "
                )
              ])
            : _vm._e()
        ])
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "row" }, [
        _c("div", { staticClass: "form-group col-md-6" }, [
          _c("label", { staticClass: "regular" }, [_vm._v("Expiration")]),
          _vm._v(" "),
          _c("div", { attrs: { id: "expiration-element" } }),
          _vm._v(" "),
          _vm.errors.expiration
            ? _c("div", { staticClass: "text-danger" }, [
                _vm._v(
                  "\n          " +
                    _vm._s(_vm.errors.expiration[0]) +
                    "\n        "
                )
              ])
            : _vm._e(),
          _vm._v(" "),
          _vm.errors.expiry_month
            ? _c("div", { staticClass: "text-danger" }, [
                _vm._v(
                  "\n          " +
                    _vm._s(_vm.errors.expiry_month[0]) +
                    "\n        "
                )
              ])
            : _vm._e(),
          _vm._v(" "),
          _vm.errors.expiry_year
            ? _c("div", { staticClass: "text-danger" }, [
                _vm._v(
                  "\n          " +
                    _vm._s(_vm.errors.expiry_year[0]) +
                    "\n        "
                )
              ])
            : _vm._e()
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "form-group col-md-6" }, [
          _c("label", { staticClass: "regular" }, [_vm._v("CVC")]),
          _vm._v(" "),
          _c("div", { attrs: { id: "cvc-element" } }),
          _vm._v(" "),
          _vm.errors.cvc
            ? _c("div", { staticClass: "text-danger" }, [
                _vm._v(
                  "\n          " + _vm._s(_vm.errors.cvc[0]) + "\n        "
                )
              ])
            : _vm._e()
        ])
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "text-right margin-top-3x" }, [
        _c(
          "p",
          { staticClass: "text-center terms-and-conditions" },
          [
            _vm._v(
              "\n        By clicking confirm payment I acknowledge that I have read and accept the \n        "
            ),
            _c(
              "router-link",
              {
                attrs: {
                  to: {
                    name: "page",
                    params: { slug: "terms-and-conditions" }
                  },
                  target: "_blank"
                }
              },
              [_vm._v("Terms & Conditions")]
            )
          ],
          1
        ),
        _vm._v(" "),
        _c(
          "button",
          {
            staticClass: "btn btn-block btn-success",
            class: { disabled: _vm.processing },
            attrs: { disabled: _vm.processing },
            on: { click: _vm.confirm }
          },
          [
            _vm.processing
              ? _c("span", [_c("i", { staticClass: "fa fa-spinner fa-spin" })])
              : _vm._e(),
            _vm._v("\n        Confirm Payment\n      ")
          ]
        )
      ])
    ])
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("label", { staticClass: "regular" }, [
      _vm._v("First Name "),
      _c("span", { staticClass: "light-grey-text" }, [_vm._v(" (on card) ")])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("label", { staticClass: "regular" }, [
      _vm._v("Last Name "),
      _c("span", { staticClass: "light-grey-text" }, [_vm._v(" (on card) ")])
    ])
  }
]
render._withStripped = true
export { render, staticRenderFns }
if (module.hot) {
  module.hot.accept()
  if (module.hot.data) {
    require("vue-hot-reload-api")      .rerender("data-v-3af4da04", { render: render, staticRenderFns: staticRenderFns })
  }
}