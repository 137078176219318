import { http } from 'services'
// import axios from 'axios'
// initial state
const state = () => ({
  results: {},
  default_currency: null,
  errors: null
})

// getters
const getters = {
}

// actions
const actions = {
  ['search.resetSearch'] ({ commit }) {
    commit('CLEAR_ERRORS')
    commit('SEARCH_RESET');
  }, 

  ['search.getResults'] ({ commit }, params) {
    commit('CLEAR_ERRORS')
    // return commit('SEARCH_GET_RESULTS', {data: {data: []}});
    return (http.post('search/locations', params)).then((res) => {
      commit('SEARCH_GET_RESULTS', res);
    }).catch ((res) => {
      commit('SEARCH_ERROR', res);
    });
  },
}

// mutations
const mutations = {
  SEARCH_RESET (state) {
    state.results = {};
  }, 

  SEARCH_GET_RESULTS (state, res) {    
      state.results = Object.assign({}, res.data);
      if (localStorage.getItem("def_currency") === null) {
        localStorage.setItem('def_currency',state.results.results[0].currency_code);
        localStorage.setItem('selected_currency', localStorage.getItem("def_currency"));
      }
      state.default_currency = localStorage.getItem("def_currency");    
  },

  //ERRORS
  ADDRESS_ERROR (state, err) {
    state.errors = err.response.data;
  },

  SEARCH_ERROR (state, err) {
    try {
      state.errors = err.response.data;
    } catch (e) {
      state.errors = err;
    }
  },

  CLEAR_ERRORS (state) {
    state.errors = null
  }
}

export default {
  state,
  getters,
  actions,
  mutations
}
