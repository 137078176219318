class UrlDataValidator {
  validateNoOfRoomsAndParking (value) {
    value = parseInt(value);

    if (isNaN(value)) {
      return 1;
    }

    if (value < 1) {
      return 1;
    }

    if (value > 5) {
      return 5;
    }

    return value
  }

  validateGuestsInRooms (arr, numberOfRooms) {
    let to_return = [];
    for (let i in arr) {
      if (typeof arr[i] != 'number') {
        to_return.push(2);
      } else if (arr[i] > 8) {
        to_return.push(8);
      } else if (arr[i] < 1) {
        to_return.push(1);
      } else {
        to_return.push(parseInt(arr[i]));
      }
    }

    if (to_return.length < numberOfRooms) {
      for (let i = to_return.length; i < numberOfRooms; i++) {
        to_return.push(2);
      }
    }

    return to_return;
  }
}

export default new UrlDataValidator();
