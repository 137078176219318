import { funcModal } from 'components/modals'
import cancellationPolicy from '../cancellationPolicy'
import { stripTags } from 'filters'
import moment from 'moment'

const components = {
  funcModal,
  cancellationPolicy
}

const filters = {
  stripTags
}
const methods = {
  open () {
    this.$refs.detailsModal.open();
  },
}

export default {
  name: "details-modal",
  props: ['cancellationPolicy','reservationType', 'specialConditions'],
  components,
  filters,
  methods,
  data () {
    return {
      moment,
      query: _.cloneDeep(this.$route.query),
    }
  },
  mounted () {
  }
}
