import Vue from 'vue'
import VueHtml5Editor from 'vue-html5-editor'
import VueProgressBar from 'vue-progressbar'
import toastr from 'toastr'
import { createApp } from './app';
import { beforeRouteUpdateMixin, beforeRouteEnterMixin } from './entry.mixins'

Vue.use(VueHtml5Editor);
Vue.use(VueProgressBar, {
  color: '#ffc331'
});

Vue.mixin(beforeRouteEnterMixin())
Vue.mixin(beforeRouteUpdateMixin())

const {app, store, router} = createApp();

console.log('client loaded successfully');

if (window.__INITIAL_STATE__ != 'init_state') {
  store.replaceState(window.__INITIAL_STATE__)
  console.log('__INITIAL_STATE__', window.__INITIAL_STATE__);
}

// router.push(window.location.pathname);

router.onReady(() => {
  router.beforeResolve((to, from, next) => {
    console.log('before resolve', to, from);

    const matched = router.getMatchedComponents(to)
    // const prevMatched = router.getMatchedComponents(from)

    // we only care about non-previously-rendered components,
    // so we compare them until the two matched lists differ
    // let diffed = false
    // const activated = matched.filter((c, i) => {
    //   return diffed || (diffed = (prevMatched[i] !== c))
    // })

    // if (!activated.length) {
    //   return next()
    // }

    app.$Progress.start()
    Promise.all(matched.map(c => {
      if (c.asyncData) {
        return c.asyncData({
          app,
          router,
          store,
          route: to,
          reject: (obj) => obj 
        })
      }
    })).then(() => {
      app.$Progress.finish()
      // stop loading indicator

      next()
    })
    .catch((e) => {
      app.$Progress.fail()
      if (e.response) {
        let errors = e.response.data.errors;
        let error = e.response.data.error;
        if (errors) {
          for (let i in errors) {
            toastr.error(errors[i][0]);
          }
        } else if (error) {
          toastr.error(error);
        }
      } else {
        toastr.error(e.message)
      }
      next(e)
    })
  });

  console.log('--- app mounted ---');
  app.$mount('#app');
});
