var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm._m(0)
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("section", { staticClass: "how-it-works bg-grey" }, [
      _c("div", { staticClass: "super-title text-center" }, [
        _vm._v("\n      How It Works\n    ")
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "container" }, [
        _c("ul", { staticClass: "how-it-works--items" }, [
          _c("li", { staticClass: "how-it-works--item" }, [
            _c("div", {
              staticClass: "icon  mask mask-teal icon-big icon-airport"
            }),
            _vm._v(" "),
            _c("div", { staticClass: "item-title" }, [
              _c("strong", [_vm._v("SEARCH AIRPORT PARKING")])
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "item-description" }, [
              _vm._v(
                "\n            With Tripteo, you will find affordable airport parking in premium hotels, with the option to stay the night. The shuttle to your terminal is courtesy of the hotel.Simply select your airport of departure and trip dates to choose from our special deals, the prices you see are the prices you pay, including all taxes and fees. With Tripteo, there are no hidden fees or charges. Most reservations are prepaid, although some require only a small portion to be prepaid, with the rest due upon check-in.\n          "
              )
            ])
          ]),
          _vm._v(" "),
          _c("li", { staticClass: "how-it-works--item" }, [
            _c("div", {
              staticClass: "icon  mask mask-teal icon-big icon-calendar"
            }),
            _vm._v(" "),
            _c("div", { staticClass: "item-title" }, [
              _c("strong", [_vm._v("OPTION TO STAY THE NIGHT")])
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "item-description" }, [
              _vm._v(
                "\n            You will also have the option to sleep at the hotel. Options are before your flight, after your flight or both. All our offered premium hotel package stays are discounted.If you have a long drive before an early flight, you can stay the night and fly fresh in the morning. Conversely, if your flight returns late at night, you can stay the night and drive rested next morning. Our discounted package deals mean you will save a great deal on everything.\n          "
              )
            ])
          ]),
          _vm._v(" "),
          _c("li", { staticClass: "how-it-works--item" }, [
            _c("div", {
              staticClass: "icon  mask mask-teal icon-big icon-park"
            }),
            _vm._v(" "),
            _c("div", { staticClass: "item-title" }, [
              _c("strong", [_vm._v("HAVE A SMOOTH TRIP")])
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "item-description" }, [
              _vm._v(
                "\n            On the day of your travel, park your car at the location on your receipt or give it to the valet when indicated. If your email confirmation requires a printed copy, make sure you have it with you. If you are staying the night at the hotel, proceed to check-in. Otherwise take the courtesy shuttle to catch your flight.Keep your receipt with you. On your return, call the shuttle number provided to you to get picked up to take your car and be on your way home.\n          "
              )
            ])
          ])
        ])
      ])
    ])
  }
]
render._withStripped = true
export { render, staticRenderFns }
if (module.hot) {
  module.hot.accept()
  if (module.hot.data) {
    require("vue-hot-reload-api")      .rerender("data-v-42b28b6a", { render: render, staticRenderFns: staticRenderFns })
  }
}