import { funcModal } from 'components/modals'
import signupForm from '../signupForm'

const components = {
  funcModal,
  signupForm
}

const methods = {
  open () {
    this.showModal = true;
    this.$nextTick(() => {
      this.$refs.modal.open();  
    })
    
  },
  onCloseModal () {
    this.showModal = false;
    this.$nextTick(() => {
      this.$refs.modal.close();  
    })
  },
  handleUserRegistered () {
    this.$emit('hideSignupModal', true);
  }
}

export default {
  name: "signup-modal",
  props: [],
  components,
  methods,
  data () {
    return {
      showModal: true,
    }
  },
  mounted () {
  }
}
