var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", {}, [
    _c("div", { staticClass: "checkout-step-label visited" }, [
      _c("div", { staticClass: "number" }, [_vm._v("\n      1\n    ")]),
      _vm._v(" "),
      _c("div", { staticClass: "title" }, [
        _vm._v("\n      Your Details\n    ")
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "summary" }, [
        _c(
          "table",
          [
            _vm.form.reservation_type == "parking" &&
            _vm.form.first_name &&
            _vm.form.last_name
              ? _c("tr", [
                  _c("th", { staticClass: "text-right" }, [_vm._v("Name")]),
                  _vm._v(" "),
                  _c("td", [
                    _vm._v(
                      _vm._s(_vm.form.first_name) +
                        " " +
                        _vm._s(_vm.form.last_name)
                    )
                  ])
                ])
              : _vm._e(),
            _vm._v(" "),
            _vm.form.reservation_type != "parking"
              ? _c("tr", [
                  _c("th", { staticClass: "text-right" }, [
                    _vm._v("No. of Guests")
                  ]),
                  _vm._v(" "),
                  _c("td", [
                    _vm._v(
                      _vm._s(_vm.form.number_of_rooms) +
                        " Room" +
                        _vm._s(_vm.form.number_of_rooms != 1 ? "s" : "") +
                        "\n          & " +
                        _vm._s(_vm.totalGuestsNumber) +
                        " Guests"
                    )
                  ])
                ])
              : _vm._e(),
            _vm._v(" "),
            _vm._l(Number(_vm.form.number_of_rooms || 0), function(i) {
              return _vm.form.reservation_type != "parking" &&
                _vm.form.guests_names
                ? _c("tr", [
                    _c("th", { staticClass: "text-right" }, [
                      _vm._v("Room " + _vm._s(i))
                    ]),
                    _vm._v(" "),
                    _c("td", [_vm._v(_vm._s(_vm.form.guests_names[i - 1]))])
                  ])
                : _vm._e()
            }),
            _vm._v(" "),
            _vm.numberOfSpots
              ? _c("tr", [
                  _c("th", { staticClass: "text-right" }, [
                    _vm._v("No. of Cars")
                  ]),
                  _vm._v(" "),
                  _c("td", [
                    _vm._v(
                      _vm._s(_vm.numberOfSpots) +
                        " car" +
                        _vm._s(_vm.numberOfSpots != 1 ? "s" : "")
                    )
                  ])
                ])
              : _vm._e()
          ],
          2
        )
      ]),
      _vm._v(" "),
      _c(
        "button",
        {
          staticClass: "btn btn-outline-secondary edit-btn",
          on: {
            click: function($event) {
              $event.preventDefault()
              _vm.goToStep("details", true)
            }
          }
        },
        [_vm._v("Edit")]
      )
    ]),
    _vm._v(" "),
    _vm._m(0),
    _vm._v(" "),
    _c("div", { staticClass: "margin-top-2x margin-bottom-4x" }, [
      _c(
        "div",
        {
          staticClass:
            "regular f17 uppercase text-center margin-bottom-4x margin-top-4x"
        },
        [_vm._v("Trip Protection")]
      ),
      _vm._v(" "),
      _c(
        "ul",
        { staticClass: "trip-protection-plans" },
        [
          _vm._l(_vm.plans, function(plan) {
            return _c("li", [
              _c(
                "a",
                {
                  class: { active: _vm.selected_plan == plan.id },
                  attrs: { href: "#" },
                  on: {
                    click: function($event) {
                      $event.preventDefault()
                      _vm.selectPlan(plan)
                    }
                  }
                },
                [
                  _c("div", { staticClass: "tripProtection-content" }, [
                    _c("h4", [_vm._v(_vm._s(plan.name))]),
                    _vm._v(" "),
                    _c("div", {
                      domProps: { innerHTML: _vm._s(plan.description) }
                    })
                  ]),
                  _vm._v(" "),
                  plan.price
                    ? _c("div", { staticClass: "tripProtection-price" }, [
                        _vm._v(
                          "\n            " +
                            _vm._s(_vm._f("currency")(plan.price, _vm.symbol)) +
                            "\n          "
                        )
                      ])
                    : _vm._e()
                ]
              )
            ])
          }),
          _vm._v(" "),
          _c("li", [
            _c(
              "a",
              {
                class: { active: _vm.selected_plan == null },
                attrs: { href: "#" },
                on: {
                  click: function($event) {
                    $event.preventDefault()
                    _vm.selectPlan(_vm.free_plan)
                  }
                }
              },
              [
                _c("div", { staticClass: "tripProtection-content" }, [
                  _c("h4", [_vm._v(_vm._s(_vm.free_plan.description))])
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "tripProtection-price" }, [
                  _vm._v(
                    "\n            " +
                      _vm._s(
                        _vm._f("currency")(_vm.free_plan.price, _vm.symbol)
                      ) +
                      "\n          "
                  )
                ])
              ]
            )
          ])
        ],
        2
      ),
      _vm._v(" "),
      _c("div", { staticClass: "text-right margin-top-5x" }, [
        _c(
          "button",
          {
            staticClass: "btn btn-info",
            on: {
              click: function($event) {
                _vm.goToStep("payment")
              }
            }
          },
          [
            _vm._v("\n        Continue\n        "),
            _c("i", {
              staticClass: "fa fa-angle-right",
              attrs: { "aria-hidden": "true" }
            })
          ]
        )
      ])
    ]),
    _vm._v(" "),
    _vm._m(1)
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "checkout-step-label active" }, [
      _c("div", { staticClass: "number" }, [_vm._v("\n      2\n    ")]),
      _vm._v(" "),
      _c("div", { staticClass: "title" }, [_vm._v("\n      Add-ons\n    ")])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "checkout-step-label inactive" }, [
      _c("div", { staticClass: "number" }, [_vm._v("\n      3\n    ")]),
      _vm._v(" "),
      _c("div", { staticClass: "title" }, [_vm._v("\n      Payment\n    ")])
    ])
  }
]
render._withStripped = true
export { render, staticRenderFns }
if (module.hot) {
  module.hot.accept()
  if (module.hot.data) {
    require("vue-hot-reload-api")      .rerender("data-v-becf7e6a", { render: render, staticRenderFns: staticRenderFns })
  }
}