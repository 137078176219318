var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass:
        "search-info-holder d-flex flex-wrap flex-lg-nowrap justify-content-between"
    },
    [
      _c(
        "div",
        {
          staticClass:
            "search-info--items-container d-flex flex-wrap flex-lg-nowrap w-100"
        },
        [
          _vm.query.nights_to_sleep
            ? _c(
                "div",
                {
                  staticClass: "search-info--item search-info--nights-to-sleep"
                },
                [
                  _c("div", { staticClass: "search-info--title" }, [
                    _vm._v("\n        Which night to sleep?\n      ")
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "search-info--body" }, [
                    _vm.query.nights_to_sleep == "beginning"
                      ? _c("span", [_vm._v("Beginning of My Trip")])
                      : _vm._e(),
                    _vm._v(" "),
                    _vm.query.nights_to_sleep == "end"
                      ? _c("span", [_vm._v("End of My Trip")])
                      : _vm._e(),
                    _vm._v(" "),
                    _vm.query.nights_to_sleep == "both"
                      ? _c("span", [_vm._v("Beginning & End of My Trip")])
                      : _vm._e()
                  ])
                ]
              )
            : _vm._e(),
          _vm._v(" "),
          _c("div", { staticClass: "search-info--item search-info--dates" }, [
            _vm.query.reservation_type != "both"
              ? _c(
                  "ul",
                  {
                    class: {
                      clickable: !_vm.query.from_date || !_vm.query.to_date
                    },
                    on: {
                      click: function($event) {
                        $event.preventDefault()
                        return _vm.openSearchModalWhenNoDatesSelected($event)
                      }
                    }
                  },
                  [
                    _c(
                      "li",
                      {
                        attrs: {
                          "data-toggle": "tooltip",
                          "data-placement": "top",
                          title: "Your check in date"
                        }
                      },
                      [
                        _c("div", { staticClass: "search-info--title" }, [
                          _vm._v("\n            Check In\n          ")
                        ]),
                        _vm._v(" "),
                        _c("div", { staticClass: "search-info--body" }, [
                          _c(
                            "a",
                            {
                              attrs: { href: "#" },
                              on: {
                                click: function($event) {
                                  $event.preventDefault()
                                  return _vm.openSearchModal($event)
                                }
                              }
                            },
                            [
                              _vm._v(
                                _vm._s(
                                  _vm.query.from_date
                                    ? _vm
                                        .moment(_vm.query.from_date, [
                                          "DD/MMM/YYYY"
                                        ])
                                        .format("MMM D")
                                    : "No date selected"
                                )
                              )
                            ]
                          )
                        ])
                      ]
                    ),
                    _vm._v(" "),
                    _c(
                      "li",
                      {
                        attrs: {
                          "data-toggle": "tooltip",
                          "data-placement": "top",
                          title: "Your check out date"
                        }
                      },
                      [
                        _c("div", { staticClass: "search-info--title" }, [
                          _vm._v("\n            Check Out\n          ")
                        ]),
                        _vm._v(" "),
                        _c("div", { staticClass: "search-info--body" }, [
                          _c(
                            "a",
                            {
                              attrs: { href: "#" },
                              on: {
                                click: function($event) {
                                  $event.preventDefault()
                                  return _vm.openSearchModal($event)
                                }
                              }
                            },
                            [
                              _vm._v(
                                _vm._s(
                                  _vm.query.to_date
                                    ? _vm
                                        .moment(_vm.query.to_date, [
                                          "DD/MMM/YYYY"
                                        ])
                                        .format("MMM D")
                                    : "No date selected"
                                )
                              )
                            ]
                          )
                        ])
                      ]
                    )
                  ]
                )
              : _c("ul", [
                  _c(
                    "li",
                    {
                      attrs: {
                        "data-toggle": "tooltip",
                        "data-placement": "top",
                        title: "The day before your flight"
                      }
                    },
                    [
                      _c("div", { staticClass: "search-info--title" }, [
                        _vm.query.nights_to_sleep == "both" ||
                        _vm.query.nights_to_sleep == "beginning"
                          ? _c("span", [
                              _vm._v("\n              Sleep On\n            ")
                            ])
                          : _vm._e(),
                        _vm._v(" "),
                        _vm.query.nights_to_sleep == "end"
                          ? _c("span", [
                              _vm._v("\n              Park On\n            ")
                            ])
                          : _vm._e()
                      ]),
                      _vm._v(" "),
                      _c("div", { staticClass: "search-info--body" }, [
                        _c(
                          "a",
                          {
                            attrs: { href: "#" },
                            on: {
                              click: function($event) {
                                $event.preventDefault()
                                return _vm.openSearchModal($event)
                              }
                            }
                          },
                          [
                            _vm._v(
                              _vm._s(
                                _vm.query.from_date
                                  ? _vm
                                      .moment(_vm.query.from_date, [
                                        "DD/MMM/YYYY"
                                      ])
                                      .format("MMM D")
                                  : "No date selected"
                              )
                            )
                          ]
                        )
                      ])
                    ]
                  ),
                  _vm._v(" "),
                  _c(
                    "li",
                    {
                      attrs: {
                        "data-toggle": "tooltip",
                        "data-placement": "top",
                        title: "The day you fly out of airport"
                      }
                    },
                    [
                      _c("div", { staticClass: "search-info--title" }, [
                        _vm._v("\n            Fly On\n          ")
                      ]),
                      _vm._v(" "),
                      _vm.query.nights_to_sleep == "both" ||
                      _vm.query.nights_to_sleep == "beginning"
                        ? _c("div", { staticClass: "search-info--body" }, [
                            _c(
                              "a",
                              {
                                attrs: { href: "#" },
                                on: {
                                  click: function($event) {
                                    $event.preventDefault()
                                    return _vm.openSearchModal($event)
                                  }
                                }
                              },
                              [
                                _vm._v(
                                  _vm._s(
                                    _vm.query.from_date
                                      ? _vm
                                          .moment(_vm.query.from_date, [
                                            "DD/MMM/YYYY"
                                          ])
                                          .add(1, "days")
                                          .format("MMM D")
                                      : "No date selected"
                                  )
                                )
                              ]
                            )
                          ])
                        : _vm._e(),
                      _vm._v(" "),
                      _vm.query.nights_to_sleep == "end"
                        ? _c("div", { staticClass: "search-info--body" }, [
                            _c(
                              "a",
                              {
                                attrs: { href: "#" },
                                on: {
                                  click: function($event) {
                                    $event.preventDefault()
                                    return _vm.openSearchModal($event)
                                  }
                                }
                              },
                              [
                                _vm._v(
                                  _vm._s(
                                    _vm.query.from_date
                                      ? _vm
                                          .moment(_vm.query.from_date, [
                                            "DD/MMM/YYYY"
                                          ])
                                          .format("MMM D")
                                      : "No date selected"
                                  )
                                )
                              ]
                            )
                          ])
                        : _vm._e()
                    ]
                  ),
                  _vm._v(" "),
                  _vm.query.nights_to_sleep == "both" ||
                  _vm.query.nights_to_sleep == "end"
                    ? _c(
                        "li",
                        {
                          attrs: {
                            "data-toggle": "tooltip",
                            "data-placement": "top",
                            title: "Night of your return"
                          }
                        },
                        [
                          _c("div", { staticClass: "search-info--title" }, [
                            _vm._v("\n            Sleep On\n          ")
                          ]),
                          _vm._v(" "),
                          _c("div", { staticClass: "search-info--body" }, [
                            _c(
                              "a",
                              {
                                attrs: { href: "#" },
                                on: {
                                  click: function($event) {
                                    $event.preventDefault()
                                    return _vm.openSearchModal($event)
                                  }
                                }
                              },
                              [
                                _vm._v(
                                  _vm._s(
                                    _vm.query.to_date
                                      ? _vm
                                          .moment(_vm.query.to_date, [
                                            "DD/MMM/YYYY"
                                          ])
                                          .subtract(1, "days")
                                          .format("MMM D")
                                      : "No date selected"
                                  )
                                )
                              ]
                            )
                          ])
                        ]
                      )
                    : _vm._e(),
                  _vm._v(" "),
                  _c(
                    "li",
                    {
                      staticClass: "no-arrow",
                      attrs: {
                        "data-toggle": "tooltip",
                        "data-placement": "top",
                        title: "The last day of your car parking"
                      }
                    },
                    [
                      _c("div", { staticClass: "search-info--title" }, [
                        _vm._v("\n            Park Until\n          ")
                      ]),
                      _vm._v(" "),
                      _c("div", { staticClass: "search-info--body" }, [
                        _c(
                          "a",
                          {
                            attrs: { href: "#" },
                            on: {
                              click: function($event) {
                                $event.preventDefault()
                                return _vm.openSearchModal($event)
                              }
                            }
                          },
                          [
                            _vm._v(
                              _vm._s(
                                _vm.query.to_date
                                  ? _vm
                                      .moment(_vm.query.to_date, [
                                        "DD/MMM/YYYY"
                                      ])
                                      .format("MMM D")
                                  : "No date selected"
                              )
                            )
                          ]
                        )
                      ])
                    ]
                  )
                ])
          ]),
          _vm._v(" "),
          _vm.query.reservation_type &&
          _vm.query.reservation_type != "parking" &&
          _vm.query.number_of_rooms
            ? _c(
                "div",
                {
                  staticClass: "search-info--item search-info--rooms-container"
                },
                [
                  _c("div", { staticClass: "search-info--rooms" }, [
                    _c("div", { staticClass: "search-info--title" }, [
                      _vm._v(
                        "\n          " +
                          _vm._s(_vm.query.number_of_rooms) +
                          " Room" +
                          _vm._s(_vm.query.number_of_rooms == 1 ? "" : "s") +
                          "\n        "
                      )
                    ]),
                    _vm._v(" "),
                    _c("div", { staticClass: "search-info--body" }, [
                      _c("span", [_vm._v(_vm._s(_vm.totalGuests))]),
                      _vm._v(
                        " Guest" +
                          _vm._s(_vm.totalGuests != 1 ? "s" : "") +
                          "\n        "
                      )
                    ])
                  ])
                ]
              )
            : _vm._e()
        ]
      ),
      _vm._v(" "),
      _c("div", { staticClass: "change-button" }, [
        _c(
          "a",
          {
            attrs: { href: "#" },
            on: {
              click: function($event) {
                $event.preventDefault()
                return _vm.openSearchModal($event)
              }
            }
          },
          [_vm._v("Change Search")]
        )
      ])
    ]
  )
}
var staticRenderFns = []
render._withStripped = true
export { render, staticRenderFns }
if (module.hot) {
  module.hot.accept()
  if (module.hot.data) {
    require("vue-hot-reload-api")      .rerender("data-v-635f642c", { render: render, staticRenderFns: staticRenderFns })
  }
}