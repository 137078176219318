import layout from 'layouts/default'

let components = {
  layout,
}

const filters = {
}

const computed = {
}

const methods = {
}

const watch = {
}

export default {
  name: "terms-and-conditions",
  components,
  computed,
  methods,
  filters,
  watch,
  data () {
    return {}
  },
}
