/* https://owlcarousel2.github.io/OwlCarousel2/ */
/*
@INFO: Responsive carousel uses owl.carousel
@PROP options: a json object that contains owl.carousel's options (optional)
@DESCRIPTION: <carousel :options="options" :should-render="true|false">
                <div>item 1</div>
                <div>item 2</div>
                <div>item 3</div>
                ...
              </carousel>
*/


const methods = {
  install () {
    console.log('this.shouldRender', this.shouldRender);
    this.$nextTick(() => {
      $(this.$refs.carousel).addClass('owl-carousel');
      $(this.$refs.carousel).owlCarousel(this.options || {});
    });
  }
}

const watch = {
  shouldRender (n) {
    console.log('shouldRender mnnnnn', n);
    if (n) {
      this.install();
    }
  }
}

export default {
  name: 'responsive-carousel',
  template: require('./carousel.html'),
  props: {
    options: Object,
    shouldRender: {
      type: Boolean,
      default: true
    }
  },
  methods,
  watch,
  mounted () {
    if (this.shouldRender) {
      this.install();
    }
  }
}
