var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("div", {
        staticClass: "d-none d-print-block",
        attrs: { id: "print-receipt" }
      }),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "main-layout d-print-none" },
        [
          _c(
            "section",
            {
              staticClass: "slide_menu hidden-lg hidden-md hidden-print",
              class: { open: _vm.menuOpen }
            },
            [
              _c("div", { staticClass: "slide_menu--wrapper" }, [
                _c(
                  "div",
                  {
                    staticClass: "hidden-lg hidden-md ico-menu-close",
                    on: {
                      click: function($event) {
                        _vm.menuOpen = false
                      }
                    }
                  },
                  [_c("i", { staticClass: "fa fa-times fa-3x" })]
                )
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "slide_menu--wrapper" }, [
                _vm.currentUser
                  ? _c(
                      "h3",
                      { staticClass: "big-title text-center margin-bottom-5x" },
                      [
                        _vm._v("\n          Welcome ("),
                        _c("strong", { staticClass: "osp-blue" }, [
                          _vm._v(
                            "\n            " +
                              _vm._s(_vm.currentUser.first_name) +
                              " " +
                              _vm._s(_vm.currentUser.last_name) +
                              "\n          "
                          )
                        ]),
                        _vm._v(")\n        ")
                      ]
                    )
                  : _vm._e(),
                _vm._v(" "),
                _c(
                  "div",
                  { staticClass: "slide_menu--items" },
                  [
                    _c(
                      "router-link",
                      {
                        staticClass: "slide_menu--item",
                        attrs: { to: { name: "airports" } }
                      },
                      [
                        _c("i", {
                          staticClass: "fa fa-plane",
                          attrs: { "aria-hidden": "true" }
                        }),
                        _vm._v("\n            Airports\n          ")
                      ]
                    ),
                    _vm._v(" "),
                    _c(
                      "router-link",
                      {
                        staticClass: "slide_menu--item",
                        attrs: {
                          to: { name: "page", params: { slug: "how-it-works" } }
                        }
                      },
                      [
                        _c("i", {
                          staticClass: "fa fa-info",
                          attrs: { "aria-hidden": "true" }
                        }),
                        _vm._v("\n            How It Works\n          ")
                      ]
                    )
                  ],
                  1
                ),
                _vm._v(" "),
                _vm.currentUser
                  ? _c("div", { staticClass: "slide_menu--items" })
                  : _vm._e(),
                _vm._v(" "),
                !_vm.currentUser
                  ? _c(
                      "div",
                      { staticClass: "slide_menu--items" },
                      [
                        !_vm.currentUser
                          ? _c(
                              "router-link",
                              {
                                staticClass: "slide_menu--item",
                                attrs: {
                                  to: { name: "guest.requestReservation" }
                                }
                              },
                              [
                                _c("i", { staticClass: "fa fa-map-marker" }),
                                _vm._v(
                                  "\n            My Reservations\n          "
                                )
                              ]
                            )
                          : _vm._e(),
                        _vm._v(" "),
                        _vm.currentUser
                          ? _c(
                              "a",
                              {
                                staticClass: "slide_menu--item",
                                attrs: { href: "#" },
                                on: {
                                  click: function($event) {
                                    $event.preventDefault()
                                  }
                                }
                              },
                              [
                                _c("i", {
                                  staticClass: "fa fa-file-text-o",
                                  attrs: { "aria-hidden": "true" }
                                }),
                                _vm._v(
                                  "\n            Reservation Receipt\n          "
                                )
                              ]
                            )
                          : _vm._e()
                      ],
                      1
                    )
                  : _vm._e(),
                _vm._v(" "),
                _c(
                  "div",
                  { staticClass: "slide_menu--items" },
                  [
                    _c(
                      "router-link",
                      {
                        staticClass: "slide_menu--item",
                        attrs: {
                          to: { name: "page", params: { slug: "about-us" } }
                        }
                      },
                      [
                        _c("i", {
                          staticClass: "fa fa-info-circle",
                          attrs: { "aria-hidden": "true" }
                        }),
                        _vm._v("\n            About\n          ")
                      ]
                    ),
                    _vm._v(" "),
                    _c(
                      "router-link",
                      {
                        staticClass: "slide_menu--item",
                        attrs: {
                          to: { name: "page", params: { slug: "contact-us" } }
                        }
                      },
                      [
                        _c("i", {
                          staticClass: "fa fa-phone",
                          attrs: { "aria-hidden": "true" }
                        }),
                        _vm._v("\n            Contact\n          ")
                      ]
                    ),
                    _vm._v(" "),
                    _c(
                      "router-link",
                      {
                        staticClass: "slide_menu--item",
                        attrs: { to: { name: "faq" } }
                      },
                      [
                        _c("i", {
                          staticClass: "fa fa-question-circle",
                          attrs: { "aria-hidden": "true" }
                        }),
                        _vm._v("\n            FAQ\n          ")
                      ]
                    ),
                    _vm._v(" "),
                    _c(
                      "router-link",
                      {
                        staticClass: "slide_menu--item",
                        attrs: {
                          to: {
                            name: "page",
                            params: { slug: "terms-and-conditions" }
                          }
                        }
                      },
                      [
                        _c("i", {
                          staticClass: "fa fa-file-text",
                          attrs: { "aria-hidden": "true" }
                        }),
                        _vm._v("\n            Terms & Conditions\n          ")
                      ]
                    )
                  ],
                  1
                )
              ])
            ]
          ),
          _vm._v(" "),
          _c("section", { staticClass: "header" }, [
            _c("div", { staticClass: "bottom-header" }, [
              _c(
                "div",
                {
                  class:
                    _vm.isFullContainer("search") ||
                    _vm.isFullContainer("airport")
                      ? "container-fluid"
                      : "container"
                },
                [
                  _c("div", { staticClass: "header-container" }, [
                    _c(
                      "div",
                      { staticClass: "logo-container" },
                      [
                        _c("router-link", {
                          staticClass: "logo",
                          attrs: { to: { name: "home" } }
                        }),
                        _vm._v(" "),
                        _c(
                          "div",
                          {
                            staticClass:
                              "d-block d-sm-block d-md-none ico-menu-open",
                            on: {
                              click: function($event) {
                                _vm.menuOpen = true
                              }
                            }
                          },
                          [
                            _c("i", {
                              staticClass: "fa fa-bars fa-3x",
                              attrs: { "aria-hidden": "true" }
                            })
                          ]
                        )
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c(
                      "div",
                      { staticClass: "header-right d-none d-md-block" },
                      [
                        _c(
                          "ul",
                          { staticClass: "d-flex align-content-center" },
                          [
                            _c(
                              "li",
                              [
                                _c(
                                  "router-link",
                                  {
                                    attrs: {
                                      to: {
                                        name: "page",
                                        params: { slug: "how-it-works" }
                                      }
                                    }
                                  },
                                  [
                                    _vm._v(
                                      "\n                    How it Works\n                  "
                                    )
                                  ]
                                )
                              ],
                              1
                            ),
                            _vm._v(" "),
                            _c("li", [
                              _c(
                                "a",
                                {
                                  staticClass: "dropdown-toggle",
                                  attrs: {
                                    href: "#",
                                    id: "headerMyAccountDropdown",
                                    "data-toggle": "dropdown",
                                    "aria-haspopup": "true",
                                    "aria-expanded": "false"
                                  }
                                },
                                [
                                  _vm._v(
                                    "\n                    Account\n                  "
                                  )
                                ]
                              ),
                              _vm._v(" "),
                              _c(
                                "div",
                                {
                                  staticClass: "dropdown-menu sj-dropdown",
                                  attrs: {
                                    "aria-labelledby": "headerMyAccountDropdown"
                                  }
                                },
                                [
                                  _c(
                                    "router-link",
                                    {
                                      staticClass:
                                        "dropdown-item dropdown-menu--link",
                                      attrs: {
                                        to: { name: "guest.requestReservation" }
                                      }
                                    },
                                    [
                                      _vm._v(
                                        "\n                      Reservations\n                    "
                                      )
                                    ]
                                  )
                                ],
                                1
                              )
                            ]),
                            _vm._v(" "),
                            _c("li", { staticClass: "nav-dropdown-menu" }, [
                              _c(
                                "a",
                                {
                                  staticClass: "dropdown-toggle ",
                                  attrs: {
                                    href: "#",
                                    id: "headerMoreDropdown",
                                    "data-toggle": "dropdown",
                                    "aria-haspopup": "true",
                                    "aria-expanded": "false"
                                  }
                                },
                                [
                                  _vm._v(
                                    "\n                    More\n                  "
                                  )
                                ]
                              ),
                              _vm._v(" "),
                              _c(
                                "div",
                                {
                                  staticClass: "dropdown-menu sj-dropdown",
                                  attrs: {
                                    "aria-labelledby": "headerMoreDropdown"
                                  }
                                },
                                [
                                  _c(
                                    "router-link",
                                    {
                                      staticClass: "dropdown-item",
                                      attrs: {
                                        to: {
                                          name: "page",
                                          params: { slug: "about-us" }
                                        }
                                      }
                                    },
                                    [
                                      _vm._v(
                                        "\n                      About Us\n                    "
                                      )
                                    ]
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "router-link",
                                    {
                                      staticClass: "dropdown-item",
                                      attrs: { to: { name: "faq" } }
                                    },
                                    [
                                      _vm._v(
                                        "\n                      FAQ \n                    "
                                      )
                                    ]
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "router-link",
                                    {
                                      staticClass: "dropdown-item",
                                      attrs: {
                                        to: {
                                          name: "page",
                                          params: { slug: "contact-us" }
                                        }
                                      }
                                    },
                                    [
                                      _vm._v(
                                        "\n                      Contact \n                    "
                                      )
                                    ]
                                  )
                                ],
                                1
                              )
                            ]),
                            _vm._v(" "),
                            _c("li", [
                              _c(
                                "div",
                                { staticClass: "nav-dropdown-menu" },
                                [
                                  _c(
                                    "a",
                                    {
                                      staticClass: "dropdown-toggle",
                                      attrs: {
                                        id: "dropdownMenuButton",
                                        "data-toggle": "dropdown",
                                        "aria-haspopup": "true",
                                        "aria-expanded": "false",
                                        href: "#"
                                      }
                                    },
                                    [
                                      _vm.loading
                                        ? _c("span", [
                                            _c("i", {
                                              staticClass:
                                                "fa fa-spinner fa-spin"
                                            })
                                          ])
                                        : _c("span", [
                                            _vm._v(
                                              "\n                      " +
                                                _vm._s(
                                                  _vm._f("currencySymbol")(
                                                    _vm.selectedCurrency
                                                  )
                                                ) +
                                                "\n                      "
                                            )
                                          ])
                                    ]
                                  ),
                                  _vm._v(" "),
                                  _c("no-ssr", [
                                    _c(
                                      "div",
                                      {
                                        staticClass:
                                          "dropdown-menu sj-dropdown",
                                        attrs: {
                                          "aria-labelledby":
                                            "dropdownMenuButton"
                                        }
                                      },
                                      _vm._l(_vm.currencies, function(
                                        currency
                                      ) {
                                        return _c(
                                          "a",
                                          {
                                            staticClass: "dropdown-item",
                                            attrs: { href: "#" },
                                            on: {
                                              click: function($event) {
                                                $event.preventDefault()
                                                _vm.setCurrency(currency)
                                              }
                                            }
                                          },
                                          [
                                            _vm._v(
                                              "\n                        " +
                                                _vm._s(currency.code) +
                                                " (" +
                                                _vm._s(
                                                  _vm._f("currencySymbol")(
                                                    currency.code
                                                  )
                                                ) +
                                                ")\n                        "
                                            )
                                          ]
                                        )
                                      })
                                    )
                                  ])
                                ],
                                1
                              )
                            ])
                          ]
                        )
                      ]
                    )
                  ])
                ]
              )
            ])
          ]),
          _vm._v(" "),
          _c("flash-message"),
          _vm._v(" "),
          _vm._t("default"),
          _vm._v(" "),
          !_vm.noFooter
            ? _c(
                "section",
                {
                  staticClass: "footer",
                  class: { "footer--hidden": _vm.hideFooter("search") }
                },
                [
                  _c("div", { staticClass: "top-footer" }, [
                    _c("div", { staticClass: "container" }, [
                      _c("div", { staticClass: "row footer-justify-content" }, [
                        _c("div", { staticClass: "col-md-3 col-sm-6" }, [
                          _c("div", { staticClass: "footer-title" }, [
                            _vm._v("\n                Explore\n              ")
                          ]),
                          _vm._v(" "),
                          _c("ul", { staticClass: "footer-links" }, [
                            _c(
                              "li",
                              [
                                _c(
                                  "router-link",
                                  { attrs: { to: { name: "airports" } } },
                                  [_vm._v("Airports")]
                                )
                              ],
                              1
                            ),
                            _vm._v(" "),
                            _c(
                              "li",
                              [
                                _c(
                                  "router-link",
                                  {
                                    attrs: {
                                      to: {
                                        name: "page",
                                        params: { slug: "contact-us" }
                                      }
                                    }
                                  },
                                  [
                                    _vm._v(
                                      "\n                    Contact\n                  "
                                    )
                                  ]
                                )
                              ],
                              1
                            )
                          ])
                        ]),
                        _vm._v(" "),
                        _c("div", { staticClass: "col-md-3 col-sm-6" }, [
                          _c("div", { staticClass: "footer-title" }, [
                            _vm._v("\n                Company\n              ")
                          ]),
                          _vm._v(" "),
                          _c("ul", { staticClass: "footer-links" }, [
                            _c(
                              "li",
                              [
                                _c(
                                  "router-link",
                                  {
                                    attrs: {
                                      to: {
                                        name: "page",
                                        params: { slug: "about-us" }
                                      }
                                    }
                                  },
                                  [_vm._v("About")]
                                )
                              ],
                              1
                            ),
                            _vm._v(" "),
                            _c(
                              "li",
                              [
                                _c(
                                  "router-link",
                                  { attrs: { to: { name: "faq" } } },
                                  [_vm._v(" FAQ ")]
                                )
                              ],
                              1
                            ),
                            _vm._v(" "),
                            _c(
                              "li",
                              [
                                _c(
                                  "router-link",
                                  {
                                    attrs: {
                                      to: {
                                        name: "page",
                                        params: { slug: "terms-and-conditions" }
                                      }
                                    }
                                  },
                                  [_vm._v("Terms & Conditions")]
                                )
                              ],
                              1
                            )
                          ])
                        ]),
                        _vm._v(" "),
                        _vm._m(0)
                      ])
                    ])
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "bottom-footer" }, [
                    _c("div", {}, [
                      _vm._v(
                        "\n          © " +
                          _vm._s(_vm.currentYear) +
                          " Tripteo, Inc.\n        "
                      )
                    ])
                  ])
                ]
              )
            : _vm._e()
        ],
        2
      ),
      _vm._v(" "),
      _vm.canShowSignupModal
        ? _c("signup-modal", {
            ref: "signupModal",
            on: { hideSignupModal: _vm.onSignupEnd }
          })
        : _vm._e(),
      _vm._v(" "),
      _vm.canShowLoginModal
        ? _c("login-modal", {
            ref: "loginModal",
            on: {
              hideLoginModal: _vm.onCloseLoginModal,
              showPasswordResetModal: _vm.openPasswordResetModal
            }
          })
        : _vm._e(),
      _vm._v(" "),
      _c("reset-password-modal", { ref: "resetPasswordModal" })
    ],
    1
  )
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "col-md-3 col-sm-6" }, [
      _c("div", { staticClass: "footer-title" }, [
        _vm._v("\n                Social Media\n              ")
      ]),
      _vm._v(" "),
      _c("ul", { staticClass: "footer-links" }, [
        _c("li", [
          _c(
            "a",
            {
              attrs: {
                target: "_blank",
                href: "https://www.facebook.com/Tripteo-108959857238144/"
              }
            },
            [
              _c("i", {
                staticClass: "fa fa-facebook-square",
                attrs: { "aria-hidden": "true" }
              }),
              _vm._v("\n                    Facebook\n                  ")
            ]
          )
        ]),
        _vm._v(" "),
        _c("li", [
          _c(
            "a",
            {
              attrs: { target: "_blank", href: "https://twitter.com/Tripteo1 " }
            },
            [
              _c("i", {
                staticClass: "fa fa-twitter-square",
                attrs: { "aria-hidden": "true" }
              }),
              _vm._v("\n                    Twitter\n                  ")
            ]
          )
        ])
      ])
    ])
  }
]
render._withStripped = true
export { render, staticRenderFns }
if (module.hot) {
  module.hot.accept()
  if (module.hot.data) {
    require("vue-hot-reload-api")      .rerender("data-v-3f7e4975", { render: render, staticRenderFns: staticRenderFns })
  }
}