/*
* @INFO: This component runs a bootstrap modal as confirm box
* @PROP canOpen: determaines if the confirm should show or not
* @PROP type: a class to change the Ok button color {danger, default, warning, primary, info}
* @callback: v-on:close="doSomething()"
* @callback: v-on:ok="doSomething(close())"
* NOTE: this component can be espcially used by
  referencing it using ref="something" and then
  use it as this.$refs.something.confirm('are you sure?')
  [returns boolean]
*/

const computed = {
  canShow () {
    console.log(typeof this.canOpen);
    return typeof this.canOpen == 'undefined' ? true : this.canOpen
  }
}

const methods = {
  confirm (text, ok, close, ok_text = "Ok", close_text = "Cancel") {
    console.log('text', text);
    this.text = text;
    this.ok_text = ok_text;
    this.close_text = close_text;
    // console.log(this.canShow);
    if (this.canShow) {
      $(this.$refs.modal).modal('show');
    } else {
      $(this.$refs.modal).modal("hide");
    }

    this.$off('ok');
    this.$off('close');

    if(ok) {
      this.$on('ok', ok);
    }

    if(close) {
      this.$on('close', close);
    }
  },
  close () {
    $(this.$refs.modal).modal("hide");
    this.$emit('close');
  },
  ok () {
    this.$emit('ok');
    $(this.$refs.modal).modal("hide");
  }
}

export default {
  name: 'confirm',
  props: ['canOpen', 'type'],
  computed,
  methods,
  data () {
    return {
      text: '',
      ok_text: "Ok",
      close_text: "Cancel",
    }
  },
  mounted () {
  }
}
