var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "svg",
    {
      staticClass: "animated-circle",
      attrs: {
        width: _vm.width || 100,
        version: "1.1",
        xmlns: "http://www.w3.org/2000/svg",
        viewBox: "0 0 130.2 130.2"
      }
    },
    [
      _vm.type == "success"
        ? _c("circle", {
            staticClass: "path circle",
            attrs: {
              fill: "none",
              stroke: "#73AF55",
              "stroke-width": "6",
              "stroke-miterlimit": "10",
              cx: "65.1",
              cy: "65.1",
              r: "62.1"
            }
          })
        : _vm._e(),
      _vm._v(" "),
      _vm.type == "success"
        ? _c("polyline", {
            staticClass: "path check",
            attrs: {
              fill: "none",
              stroke: "#73AF55",
              "stroke-width": "6",
              "stroke-linecap": "round",
              "stroke-miterlimit": "10",
              points: "100.2,40.2 51.5,88.8 29.8,67.5 "
            }
          })
        : _vm._e(),
      _vm._v(" "),
      _vm.type == "error"
        ? _c("circle", {
            staticClass: "path circle",
            attrs: {
              fill: "none",
              stroke: "#D06079",
              "stroke-width": "6",
              "stroke-miterlimit": "10",
              cx: "65.1",
              cy: "65.1",
              r: "62.1"
            }
          })
        : _vm._e(),
      _vm._v(" "),
      _vm.type == "error"
        ? _c("line", {
            staticClass: "path line",
            attrs: {
              fill: "none",
              stroke: "#D06079",
              "stroke-width": "6",
              "stroke-linecap": "round",
              "stroke-miterlimit": "10",
              x1: "34.4",
              y1: "37.9",
              x2: "95.8",
              y2: "92.3"
            }
          })
        : _vm._e(),
      _vm._v(" "),
      _vm.type == "error"
        ? _c("line", {
            staticClass: "path line",
            attrs: {
              fill: "none",
              stroke: "#D06079",
              "stroke-width": "6",
              "stroke-linecap": "round",
              "stroke-miterlimit": "10",
              x1: "95.8",
              y1: "38",
              x2: "34.4",
              y2: "92.2"
            }
          })
        : _vm._e()
    ]
  )
}
var staticRenderFns = []
render._withStripped = true
export { render, staticRenderFns }
if (module.hot) {
  module.hot.accept()
  if (module.hot.data) {
    require("vue-hot-reload-api")      .rerender("data-v-29647fda", { render: render, staticRenderFns: staticRenderFns })
  }
}