class Helper {
  extractFlightNumber (flight_number) {   
    let flight_num;
    if (Array.isArray(flight_number)) {
        flight_num = [];
        flight_number.map((value) => {
          flight_num.push(value.trim().replace(/[^a-zA-Z0-9]/g, '').toUpperCase());
        });
    } else {
        flight_num = flight_number.replace(/[^a-zA-Z0-9]/g, '');
        flight_num = flight_num.toUpperCase();
    }
    return flight_num;
  }

  isValidFlightNumber(flight_number) {
    if (Array.isArray(flight_number)) {
        flight_number = flight_number[0];
    }

    if (flight_number.length < 3 || flight_number.length > 8) {
        return false;
    }

    if (typeof flight_number[2] != 'number' && (typeof flight_number[0] == 'number' || typeof flight_number[1] == 'number')) {
        return false;
    }

    if (!flight_number.match(/^((?:[A-Z][A-Z]|[A-Z][0-9]|[0-9][A-Z])[A-Z]?)([0-9]{1,4}[A-Z]?)$/)) {
        return false;
    }

    return true;
  }
}

export default new Helper();
