import flashMessage from 'services/flashMessage';

export default {
  name: "flash-message",
  created () {
  },
  data () {
  	return {
  		flash: null,
  	}
  },
  mounted () {
   this.flash = flashMessage.get();
  }
}
