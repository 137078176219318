import rating from 'vue-star-rating'
// import numberOfCars from 'layouts/default/partials/numberOfCars'

let components = {
  rating,
  // numberOfCars
}

const methods = {
  hideFilters () {
    this._showFilters = false;
    this.$emit('hideFilters', this._showFilters);
  },

  // changeNumberOfSpots (value) {
  //   console.log('number_of_spots cahaneeeeee', value)
  //   this.number_of_spots = value;
  // },

  changeAmenities (amenity) {
    if (!_.includes(this.active_amenities, amenity.id)) {
      this.active_amenities.push(amenity.id);
    } else {
      this.active_amenities = _.filter(this.active_amenities, item => item != amenity.id)
    }
    this.$emit('amenitiesChanged', this.active_amenities)
  },

  applyFilters () {
    console.log('filtersApply this.number_of_spots', this.number_of_spots)
    this.$emit('filtersApply', {
      numberOfSpots: this.number_of_spots,
      price: this.price_values,
      distance: this.distance_value,
      amenities: this.active_amenities
    })
  }
}

const watch = {
  numberOfSpots (value) {
    this.number_of_spots = value;
  },

  showFilters (value) {
    if (value) {
      this._showFilters = value;
      this.$nextTick(() => {
        this.$refs.priceSlider.refresh()
        this.$refs.distanceSlider.refresh()
      });
    }
  },

  priceSlider (value) {
    if (value) {
      this.price = value;
    }
  },

  distanceSlider (value) {
    if (value) {
      this.distance = value;
    }
  },

  ['amenities.active'] (value) {
    if (value) {
      if (typeof value === "string") {
        value = JSON.parse(value);
      }
      this.active_amenities = value;
    }
    console.log('amenities.active', value)
  },
  priceValues (n) {
    this.price_values = n;
  },

  distanceValue (n) {
    this.distance_value = n;
  },

}

if (!isServer) {
  let VueSlider = require('vue-slider-component')
  components['vue-slider'] = VueSlider
}

export default {
  name: 'search-filters',
  props: [
    'priceSlider',
    'ratingReadOnly',
    'ratingIncrementValue',
    'ratingMaxValue',
    'distanceSlider',
    'numberOfSpots',
    'amenities',
    'showFilters',
    'criteria',
    'priceValues',
    'distanceValue'
  ],
  components,
  methods,
  watch,
  data () {
    if (typeof this.amenities.active === "string") {
      this.amenities.active = JSON.parse(this.amenities.active);
    }

    return {
      price: this.priceSlider,
      distance: this.distanceSlider,
      local_amenities:  this.amenities.list,
      active_amenities: this.amenities.active || [],
      _showFilters: this.showFilters,
      price_values: this.priceValues,
      distance_value: this.distanceValue,
      number_of_spots: this.numberOfSpots
    }
  },
  created () {
    // this.local_amenities = this.amenities;
  }
}
