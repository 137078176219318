import { animatedCircle } from 'components/animations'
import reservationDetails from 'layouts/default/partials/reservationDetails'

const components = {
  reservationDetails,
  animatedCircle,
}

const computed = {
  reservation () {
    return _.cloneDeep(this.$store.state.reservations.active)
  },
}

export default {
  name: 'reservation-confirmation',
  components,
  computed,
  data () {
    return {

    }
  },
  mounted () {
    // if (!this.reservation) {
    //   this.$router.replace({name: 'home'});
    // }
  }
}
