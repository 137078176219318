var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "white-box" }, [
    _c("div", { staticClass: "d-flex flex-wrap flex-md-nowrap" }, [
      _c(
        "div",
        { staticClass: "overall-rating" },
        [
          _c("div", { staticClass: "f20 regular margin-bottom-4x" }, [
            _vm._v(
              "\n                TripAdvisor reviews for " +
                _vm._s(_vm.location.name) +
                "\n            "
            )
          ]),
          _vm._v(" "),
          _c("tripadvisor-rating", {
            attrs: {
              rating: _vm.location.tripadvisor_reviews.rating,
              show_icon: false
            }
          }),
          _vm._v(" "),
          _c("div", [
            _c(
              "a",
              {
                attrs: {
                  href: _vm.location.tripadvisor_reviews.web_url,
                  target: "_BLANK"
                }
              },
              [
                _vm._v(
                  "(Based on " +
                    _vm._s(_vm.location.tripadvisor_reviews.num_reviews) +
                    " reviews)"
                )
              ]
            )
          ])
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "location-rating-summary" },
        [
          _c("div", { staticClass: "f20 regular margin-bottom-4x" }, [
            _vm._v(
              "\n                    Hotel Review Summary\n                "
            )
          ]),
          _vm._v(" "),
          _vm._l(_vm.location.tripadvisor_reviews.subratings, function(review) {
            return _c(
              "div",
              { key: review.name, staticClass: "d-flex margin-bottom-2x" },
              [
                _c("div", { staticClass: "review-rating-title" }, [
                  _vm._v(_vm._s(review.localized_name))
                ]),
                _vm._v(" "),
                _c("tripadvisor-rating", {
                  attrs: { rating: review.value, show_icon: false }
                })
              ],
              1
            )
          })
        ],
        2
      )
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true
export { render, staticRenderFns }
if (module.hot) {
  module.hot.accept()
  if (module.hot.data) {
    require("vue-hot-reload-api")      .rerender("data-v-ae24aeac", { render: render, staticRenderFns: staticRenderFns })
  }
}