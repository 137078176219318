var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "auth-form--container text-center" }, [
    _c("div", { staticClass: "auth-form" }, [
      _c("form", { staticClass: "container" }, [
        _c("h2", [_vm._v("Login to your account")]),
        _vm._v(" "),
        _c("p", { staticClass: "hint-text" }, [
          _vm._v("Login with your social media account or email address")
        ]),
        _vm._v(" "),
        _vm._m(0),
        _vm._v(" "),
        _vm._m(1),
        _vm._v(" "),
        _c("div", { staticClass: "row" }, [
          _c("div", { staticClass: "col-12" }, [
            _c("div", { staticClass: "form-group" }, [
              _c("input", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.form.email,
                    expression: "form.email"
                  }
                ],
                staticClass: "form-control input-lg",
                attrs: {
                  autocomplete: "off",
                  type: "email",
                  placeholder: "Email address"
                },
                domProps: { value: _vm.form.email },
                on: {
                  input: function($event) {
                    if ($event.target.composing) {
                      return
                    }
                    _vm.$set(_vm.form, "email", $event.target.value)
                  }
                }
              }),
              _vm._v(" "),
              _vm.errors.email
                ? _c("div", { staticClass: "text-danger text-left" }, [
                    _vm._v(
                      "\n              " +
                        _vm._s(_vm.errors.email[0]) +
                        "\n            "
                    )
                  ])
                : _vm._e()
            ])
          ])
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "row" }, [
          _c("div", { staticClass: "col-12" }, [
            _c("div", { staticClass: "form-group" }, [
              _c("input", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.form.password,
                    expression: "form.password"
                  }
                ],
                staticClass: "form-control input-lg",
                attrs: {
                  autocomplete: "off",
                  type: "password",
                  placeholder: "Password"
                },
                domProps: { value: _vm.form.password },
                on: {
                  input: function($event) {
                    if ($event.target.composing) {
                      return
                    }
                    _vm.$set(_vm.form, "password", $event.target.value)
                  }
                }
              }),
              _vm._v(" "),
              _vm.errors.password
                ? _c("div", { staticClass: "text-danger text-left" }, [
                    _vm._v(
                      "\n              " +
                        _vm._s(_vm.errors.password[0]) +
                        "\n            "
                    )
                  ])
                : _vm._e()
            ])
          ])
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "row" }, [
          _c("div", { staticClass: "col-12" }, [
            _c("div", { staticClass: "form-group" }, [
              _c(
                "button",
                {
                  staticClass: "btn btn-info btn-lg btn-block auth-btn",
                  attrs: { type: "submit", disabled: _vm.loading },
                  on: {
                    click: function($event) {
                      $event.preventDefault()
                      return _vm.login($event)
                    }
                  }
                },
                [
                  !_vm.loading
                    ? _c("span", [
                        _vm._v("\n                Login\n              ")
                      ])
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.loading
                    ? _c("span", [
                        _c("i", { staticClass: "fa fa-spinner fa-spin" }),
                        _vm._v(
                          "\n                  Logging in...\n              "
                        )
                      ])
                    : _vm._e()
                ]
              )
            ])
          ])
        ])
      ])
    ]),
    _vm._v(" "),
    _c(
      "div",
      {
        staticClass:
          "d-sm-flex d-md-flex d-lg-flex d-xl-flex justify-content-between"
      },
      [
        _c(
          "div",
          { staticClass: "text-center auth--registered" },
          [
            _vm._v("\n      Don't have an account?\n      "),
            _c(
              "router-link",
              {
                staticClass: "auth--form-button",
                attrs: { to: { name: "signup" } }
              },
              [_vm._v("Signup ")]
            )
          ],
          1
        ),
        _vm._v(" "),
        _c("div", { staticClass: "text-center auth--registered" }, [
          _c(
            "a",
            {
              staticClass: "auth--form-button",
              attrs: { href: "#" },
              on: {
                click: function($event) {
                  $event.preventDefault()
                  return _vm.togglePasswordResetModal($event)
                }
              }
            },
            [_vm._v(" Forget password ?")]
          )
        ])
      ]
    )
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "social-btn text-center" }, [
      _c(
        "a",
        { staticClass: "button button-facebook btn-lg", attrs: { href: "#" } },
        [_c("i", { staticClass: "fa fa-facebook" })]
      ),
      _vm._v(" "),
      _c(
        "a",
        { staticClass: "button button-twitter btn-lg", attrs: { href: "#" } },
        [_c("i", { staticClass: "fa fa-twitter" })]
      ),
      _vm._v(" "),
      _c(
        "a",
        { staticClass: "button button-google btn-lg", attrs: { href: "#" } },
        [_c("i", { staticClass: "fa fa-google" })]
      )
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "or-seperator" }, [_c("b", [_vm._v("or")])])
  }
]
render._withStripped = true
export { render, staticRenderFns }
if (module.hot) {
  module.hot.accept()
  if (module.hot.data) {
    require("vue-hot-reload-api")      .rerender("data-v-77361340", { render: render, staticRenderFns: staticRenderFns })
  }
}