const methods = {
  setImage () {
    // console.log('changed' , this.src, this.position);
    $(this.$refs.cont).css({
      'background-image': `url(${this.src})`,
      'background-size': 'cover',
      'background-position': this.position || 'center'
    })
  }
}

const watch = {
  src () {
    this.setImage();
  },
  position () {
    this.setImage();
  }
}

export default {
  name: 'image-bg',
  template: require('./imageBg.html'),
  props: ['src', 'position'],
  methods,
  watch,
  beforeMount () {

  },
  mounted () {
    // $(this.$refs.img).hide();
    this.setImage();
  }
}
