import toastr from 'toastr'
import moment from 'moment'
import layout from 'layouts/default'
import datepicker from 'components/datepicker'

const components = {
  layout,
  datepicker
}

const methods = {
  send () {
    this.errors = {};
    this.showAssistEmail = false;
    for (let i in this.form) {
      if (!this.form[i]) {
        this.errors[i] = ['This field is required!']
      }
    }

    if (!_.isEmpty(this.errors)) {
      return;
    }

    let data = _.cloneDeep(this.form);
    data.check_in = moment(this.form.check_in, ['DD/MMM/YYYY']).format('YYYY-MM-DD');

    this.processing = true;

    this.$store.dispatch('reservations.getAsGuest', data).then(() => {
      this.processing = false;
      let errors = this.$store.state.reservations.errors;
      // console.log(errors);
      if (errors) {
        if (errors.status_code == 404) {
          this.showAssistEmail = true;
          toastr.error(errors.message);
        } else if (errors.status_code == 400) {
          toastr.error(errors.message);
        } else if (errors.errors) {
          this.errors = errors.errors;
          toastr.error(errors.message);
        } else {
          toastr.error(errors.message);
        }
      } else {
        this.$router.replace({name: 'guest.getReservation'});
        // console.log('success!', this.$store.state.reservations.active);
      }
    });
  }
}

export default {
  name: 'guest-reservation-form',
  components,
  methods,
  data () {
    return {
      errors: {},
      processing: false,
      showAssistEmail: false,
      form: {
        number: null,
        check_in: null,
        reserved_by: null
      }
    }
  },

  mounted () {
    this.$store.dispatch('title.setTitle', 'Manage Reservation Form');

  }
}
