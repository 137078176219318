import toastr from 'toastr'
import layout from 'layouts/default'

const components = {
  layout,
}

const computed = {
  page () {
    return _.cloneDeep(this.$store.state.pages.active);
  },
  errors () {
    return _.cloneDeep(this.$store.state.pages.errors);
  }
}

export default {
  name: 'static-page',
  components,
  computed,
  asyncData ({ store, route, router, reject }) {
    let promises = [
      store.dispatch('pages.get', route.params.slug),
    ];
    
    return Promise.all(promises).then(() => {
      try {
        if (!store.state.pages.active.id) {
          reject({code: 404})
        }
      } catch (e) {
        reject({code: 404})
      }

      store.dispatch('title.setTitle', store.state.pages.active.seo_title || store.state.pages.active.title);
    })
  },
  data () {
    return {
      loading: false,
    }
  },
  created () {
    
  },
  mounted () {
    console.log('this.$route.query', this.$route.query);
    if (this.$route.query.source && this.$route.query.client_secret) {
      if (window.parent) {
        window.parent.setSource(this.$route.query.source);
      } else {
        toastr.error('Could not reach parent window');
      }
    }
  }
}
