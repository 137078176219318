import moment from 'moment'
import toastr from 'toastr'
import layout from 'layouts/default'
import { dateTime, bookingData, urlDataValidator } from 'services'
import searchCriteria from 'layouts/default/partials/searchCriteria'
import searchModal from 'layouts/default/partials/searchModal'
import numberOfCars from 'layouts/default/partials/numberOfCars'
import cancellationPolicy from 'layouts/default/partials/cancellationPolicy'
import imageBg from 'components/imageBg'
import tripadvisorRating from 'components/tripadvisorRating'
import tripadvisorReviews from './partials/tripadvisorReviews'
import { stripTags, currencySymbol as currencySymbolFilter } from 'filters'
import screenSizes  from 'services/screenSizes'
import subtotalModal from './partials/subtotalBreakdownModal'


let components = {
  layout,
  searchCriteria,
  searchModal,
  numberOfCars,
  imageBg,
  tripadvisorRating,
  tripadvisorReviews,
  cancellationPolicy,
  subtotalModal
}

if (!isServer) {
  components.rating = require('vue-star-rating').default;
  components.googleMapsResults = require('components/googleMapsResults').default;
}

const filters = {
  currencySymbolFilter
}

const computed = {
  vehcileDropOffText () {
    let from_date =  moment(this.query.from_date, ['DD/MMM/YYYY']);
    return `The time you expect to arrive at location on ${from_date.format('DD MMM')}`
  },
  vehcilePickUpText () {
    let to_date =  moment(this.query.to_date, ['DD/MMM/YYYY']);
    return `The time you expect to leave the location on ${to_date.format('DD MMM')}`
  },
  airport () {
    try {
      return this.$store.state.locations.active.airports[0] || null;
    } catch (e) {
      return null
    }
  },

  totalDays () {
    if (this.query.reservation_type == 'parking') {
      if (!this.locationTypes.parking[0]) return 0;
      return _.clone(this.locationTypes.parking[0].number_of_parking_days);
    } else if (this.query.reservation_type == 'room') {
      if (!this.locationTypes.room[0]) return 0;
      return _.clone(this.locationTypes.room[0].number_of_room_days);
    } else {
      return null;
    }
  },

  currencyCode() {
    return _.cloneDeep(this.$store.state.currencies.selected);
  },

  location () {
    return this.$store.state.locations.active || {};
  },
  distanseFromAirport () {
    try {
      return this.location.airports[0].pivot.distance_from_airport
    } catch (e) {
      return 0
    }
  },
  hasFreeCancellation () {
    let type = this.query.reservation_type != "parking" ? "room" : "parking";
    return _.some(this.location.cancellation_policies, { 'type': type, 'percentage': 100 });
  },
  hasSecurityAmenity () {
    for (let i in this.location.amenities) {
      if (this.location.amenities[i].name == 'security') {
        return true
      }
    }

    return false
  },
  
  showTripadvisorReviews () {
    return  this.location.tripadvisor_id
              &&
            (
              this.query.reservation_type == 'room'
                ||
              this.query.reservation_type == 'both'
            )
              &&
            !_.isEmpty(this.location.tripadvisor_reviews);
  },

  featuredPhoto () {
    if (!this.location.photos || !this.location.photos.length) return null

    let type = 'facility';
    if (this.query.reservation_type && this.query.reservation_type != 'both') {
      type = this.query.reservation_type;
    }

    let photo_obj = this.location.photos.filter(photo => ((photo.type == type) && photo.featured));

    if (photo_obj && photo_obj.length) {
      return photo_obj[0].filename
    }

    return this.location.photos ? this.location.photos[0].filename : null
  },

  otherPhotos () {
    if(!this.featuredPhoto) return []
    return this.location.photos.filter(photo => {
      if (
        photo.filename != this.featuredPhoto && 
        (photo.type == this.query.reservation_type || photo.type == 'facility' || this.query.reservation_type == 'both' || !this.query.reservation_type)
      ) {
        return true
      }
      
      return false
    });
  },

  symbol () {
    let symbol = this.location.currency_code;

    if (this.currencyCode) symbol = this.currencyCode
    return currencySymbolFilter(symbol);
  },

  sortedParkingTypes () {
    let data = this.parkingTypes;
    data = _.orderBy(data, 'price', 'asc');
    return _.orderBy(data, 'sold_out', 'asc');
  },
 
  sortedRoomTypes () {
    let data = this.roomTypes;
    data = _.orderBy(data, 'price', 'asc');
    return _.orderBy(data, 'sold_out', 'asc');
  },

  sortedBundlesCombinations () {
    let data = this.bundlesCombinations;
    data = _.orderBy(data, 'price', 'asc');
    data = _.orderBy(data, 'sold_out', 'asc');
    return data;
  },
 
  cheapestPrice () {
    let minPrice = null;
    for (let i in this.combinations) {
      if (this.combinations[i].price) {
        if (!minPrice || this.combinations[i].price < minPrice) {
          minPrice = this.combinations[i].price;
        }
      }
    }

    for (let i in this.bundleTypes) {
      if (this.bundleTypes[i].price) {
        if (!minPrice || this.bundleTypes[i].price < minPrice) {
          minPrice = this.bundleTypes[i].price;
        }
      }
    }

    for (let i in this.parkingTypes) {
      if (this.parkingTypes[i].price) {
        if (!minPrice || this.parkingTypes[i].price < minPrice) {
          minPrice = this.parkingTypes[i].price;
        }
      }
    }

    for (let i in this.roomTypes) {
      if (this.roomTypes[i].price) {
        if (!minPrice || this.roomTypes[i].price < minPrice) {
          minPrice = this.roomTypes[i].price;
        }
      }
    }

    return minPrice
  },

  hours_before_reservation_error () {
    let from_date =  moment(this.query.from_date, ['DD/MMM/YYYY']);
    let to_date =  moment(this.query.to_date, ['DD/MMM/YYYY']);
    let reservation_type = this.query.reservation_type;
    if (reservation_type != 'parking' ) {
      return false;
    }
    let {from_time, to_time} = this.setCheckinCheckoutTimes(from_date, to_date);
    from_date = from_date.format('YYYY-MM-DD ' + from_time);
    to_date = to_date.format('YYYY-MM-DD ' + to_time);

    let diffInHours = dateTime.diffInHours(from_date,this.airport.timezone.code);
    return (diffInHours <= this.location.hours_before_reservation);
  },

  airportRoute () {
    let condition = this.query.from_date && this.query.to_date 
      && this.query.reservation_type 
      && (this.query.number_of_spots 
        || (this.query.number_of_rooms && this.query.guests_in_rooms))

    let query = _.cloneDeep(this.query);
    query.guests_in_rooms = JSON.stringify(query.guests_in_rooms);

    return {
      name: condition ? 'search' : 'airport', 
      params: condition ? {} : {slug: this.airport.url || this.airport.id},
      query
    }
  }

}

const methods = {
  init (query) {
    let num_of_nights = this.query.nights_to_sleep == 'both' ? 2 : 1;

    //airport_id shoud be in url in case (from_date, to_date and reservation_type in url)
    if (!query.airport_id && query.from_date && query.to_date) {
      let airports = this.location.airports;
      if (airports.length) {
        this.$router.replace({
          query: Object.assign({}, { airport_id: airports[0].id }, this.$route.query),
        });
      }
    }

    this.center = {
      lat: parseFloat(this.location.latitude),
      lng: parseFloat(this.location.longitude)
    }
    this.markers = [{
      lat: parseFloat(this.location.latitude),
      lng: parseFloat(this.location.longitude)
    }]

    if (this.hours_before_reservation_error) {
      this.setCheckinCheckoutTimes();
      return;
    }
    this.typesLoading = true;
    this.showWarning = false;
    if (query.reservation_type && query.from_date && query.to_date) {
      this.$store.dispatch('locations.getTypes', {
        id: this.location.id,
        params: {reservation_type: query.reservation_type}
      }).then(() => {
        this.typesLoading = false;
        let errors = this.$store.state.locations.errors;
        if (errors) {
          if (errors.message) {
            toastr.error(errors.message);
          }
  
          if (errors.errors) {
            for (let i in errors.errors) {
              toastr.error(errors.errors[i]);
            }
          }
        } else {
  
          this.locationTypes = _.cloneDeep(this.$store.state.locations.active.types);
          this.locationTypes.bundle = this.locationTypes.bundle.filter(function(value) {
            return num_of_nights == value.number_of_room_days;
          });
  
          this.setLocationTypes('parking', query)
          this.setLocationTypes('room', query)
          this.setLocationTypes('bundle', query)
          this.setCombinations(query)
          this.setBundlesCombinations()          
        }
        this.shouldRefreshTooltip = false;
        this.$nextTick(() => {
          this.shouldRefreshTooltip = true;
          this.$nextTick(() => {
            $('[data-toggle="tooltip"]').tooltip();
          })
          this.handleResize();
        })
  
      });
    }

    if (
      (this.query.reservation_type == 'room' || this.query.reservation_type == 'both')
        && !this.location.tripadvisor_reviews
    ) {
      this.getTripadvisorReviews()
    }

    // if (query.reservation_type == 'parking') {
    //   this.getParkingRatesList();
    // }
  },

  openSearchModal () {
    this.canShowSearchModal = true;
    this.$nextTick(() => {
      this.$refs.searchModal.open();
    })
  },
  showSubtotalBreakDownModal(type) {
    this.showSubtotalModal = false;
    this.selectedType = type;
    this.$nextTick(() => {
      this.showSubtotalModal = true;
      this.$nextTick(() => {
        this.$refs.breakDownModal.open();  
      })
    })
  },
  onCloseModal () {
    this.canShowSearchModal = false;
  },

  scrollTo (selector) {
    $('html, body').animate({
        scrollTop: $(selector).offset().top
    }, 1000);
  },
  
  initDataToSend () {
    let data = {
      reservation_type: this.query.reservation_type,
      from_date: moment(this.query.from_date, ['DD/MMM/YYYY']),
      to_date: moment(this.query.to_date, ['DD/MMM/YYYY'])
    };

    if (this.query.reservation_type == 'room' || this.query.reservation_type == 'both') {
      data.number_of_rooms = this.query.number_of_rooms;
      data.guests_in_rooms = this.query.guests_in_rooms.slice(0, this.query.number_of_rooms);
    }

    if (this.query.reservation_type == 'parking' || this.query.reservation_type == 'both') {
      data.number_of_spots = this.query.number_of_spots;
    }

    let {from_time, to_time} = this.setCheckinCheckoutTimes(data.from_date, data.to_date);
    data.from_date = data.from_date.format('YYYY-MM-DD ' + from_time);
    data.to_date = data.to_date.format('YYYY-MM-DD ' + to_time);

    if (this.query.reservation_type == 'both') {
      data.nights_to_sleep = this.query.nights_to_sleep;
    }

    return data;
  },

  setCheckinCheckoutTimes (fromDate = null, toDate = null) {
    let from_date = fromDate ? fromDate : moment(this.query.from_date, ['DD/MMM/YYYY']);
    let to_date = toDate ? toDate : moment(this.query.to_date, ['DD/MMM/YYYY']);
    let from_time, to_time;
    let times = dateTime.getBookingHrs(from_date, to_date, this.airport.timezone.code);
    
    if (this.checkin_time) {
      from_time = this.checkin_time + ":00";
    } else {
      from_time = times.from_time;
      this.checkin_time = from_time.slice(0, -3);
    }
    if (this.checkout_time) {
        to_time = this.checkout_time + ":00";
    } else {
      to_time = times.to_time;
      this.checkout_time = to_time.slice(0, -3);
    }
    return {from_time, to_time};
  },

  calculateCosts ({
    parking_type = null,
    room_type = null,
    bundle_type = null,
    search_type,
    indx
  } = {}) {
    let selected_type = (search_type == 'combination') ? 'combinations' : (search_type + 'Types');

    let data = this.initDataToSend();
    data.location_id = this.location.id

    if (this.query.reservation_type == 'room' || this.query.reservation_type == 'both') {
      data.room_type = room_type;
      data.bundle_id = bundle_type;
    }

    if (this.query.reservation_type == 'parking' || this.query.reservation_type == 'both') {
      data.parking_type = parking_type;
    }

    return (this.$store.dispatch('reservations.cost', {data, currencyCode: this.currencyCode})).then((res) => {
      if (this.$store.state.reservations.errors) {
        let err_msg = this.$store.state.reservations.errors.message;
        if (!_.isEmpty(this.$store.state.reservations.errors.errors)) {
          this.errors = _.cloneDeep(this.$store.state.reservations.errors.errors);
          if (this.errors['items.0.from_date']) {
            err_msg = this.errors['items.0.from_date'][0];
          }
        }

        this.$set(this[selected_type][indx], 'error', err_msg);
      }
    })
  },

  checkoutQuery (type, obj) {
    let query = _.clone(this.query);
    query.guests_in_rooms = JSON.stringify(query.guests_in_rooms);
    query.location_id = this.location.id;
    if (type == 'combination') {
      query.parking_type = obj.parking_type.id;
      query.room_type = obj.room_type.id;
    } else if (type == 'bundle') {
      query.bundle_type = obj.id
      query.bundle_id = obj.id
    } else if (type == 'room') {
      query.room_type = obj.id
    } else if (type == 'parking') {
      query.parking_type = obj.id
    }

    return query;
  },

  goToCheckout (type, obj, route) {
    let product = {price: obj.price}
    if (type == 'combination') {
      product.name = type + " - parking: " + obj.parking.name + ", room: " +  obj.room.name
      product.id = "parking: " + obj.parking.id + ", room: " +  obj.room.id
    } else {
      product.name = type + " - " + obj.name
      product.id = obj.id;
    }

    dataLayer.push({
      'event': 'checkout',
      'ecommerce': {
        'checkout': {
          'actionField': { 'step': 'details' },
          'products': [{
            'name': product.name,
            'id': product.id,
            'price': product.price,
            'category': this.location.name,
            'quantity': 1,
            'number_of_cars': this.query.number_of_spots,
            'number_of_rooms': this.query.number_of_rooms
          }]
        }
      }
    });

    this.$router.push(route)
  },

  compareTypeWithReservationType (type, query) {
    return (type == 'room' && query.reservation_type == 'room')
        || (type == 'parking' && query.reservation_type == 'parking')
        || (type == 'bundle' && query.reservation_type == 'both');
  },

  setLocationTypes (type, query) {
    let promises = [];
    let dl_products = [];

    try {
      let types = this.locationTypes[type];
      this[type + 'Types'] = [];
      _.forEach(types, (item, i) => {

        this[type + 'Types'] = this[type + 'Types'].concat([item]);

        this.$set(this.locationTypes[type][i], 'loading', true);
        this.$set(this.locationTypes[type][i], 'price', null);
        this.$set(this.locationTypes[type][i], 'number_of_days', null);

        if (this.compareTypeWithReservationType(type, query)) {
          promises.push(
            this.calculateCosts({
              [type + '_type']: this[type + 'Types'][i].id,
              search_type: type,
              indx: i
            }).then(() => {
              this.setTypesCosts(type, this[type + 'Types'][i].id, i);

              dl_products.push({
                'name': type + ' - ' + this[type + 'Types'][i].name, // Name or ID is required.
                'id': this[type + 'Types'][i].id,
                'price': this[type + 'Types'][i].price,
                'category': this.location.name
              })

              console.log('dl_products', dl_products);
              
            })
          );
        }
      });
      
    } catch (e) { console.log('Error when setting location type ' + type, e); }

    if (promises.length && type == 'bundle') {
      this.bundleLoading = true;
    }

    Promise.all(promises).then(() => {
      if (type == 'bundle') {
        this.bundleLoading = false;
      }
      console.log('dl_products', dl_products);

      if (!isServer && dl_products.length) {
        dataLayer.push({
          'ecommerce': {
            'detail': {
              'actionField': {'list': 'Location page'}, // 'detail' actions have an optional list property.
              'products': dl_products
            }
          }
        });
      }
    });
  },

  setCombinations (query) {
    this.combinations = [];
    let promises = [];
    let dl_products = [];

    this.roomTypes.forEach((room, i) => {

      this.parkingTypes.forEach((parking, j) => {

        this.combinations = this.combinations.concat([{
          parking_type: parking,
          room_type: room,
          sold_out: room.sold_out || parking.sold_out,
          number_of_room_days: query.nights_to_sleep == 'both' ? 2 : 1,
          number_of_parking_days: 0,
          loading: true,
          price: null
        }]);

        let index = this.combinations.length -1

        promises.push(this.calculateCosts({
            parking_type: parking.id,
            room_type: room.id,
            search_type: 'combination',
            indx: index
          }).then(() => {
            this.setTypesCosts('combination', null, index);

            dl_products.push({
              'name': 'combination - parking:' + parking.name + ', room:' + room.name, // Name or ID is required.
              'id': 'parking:' + parking.id + ', room:' + room.id,
              'price': this.combinations[index].price,
              'category': this.location.name
            })
          })
        );
      })
    });

    if (promises.length) {
      this.combinationLoading = true;
    }

    Promise.all(promises).then(() => {
      this.combinationLoading = false;

      if (!isServer && dl_products.length) {
        dataLayer.push({
          'ecommerce': {
            'detail': {
              'actionField': {'list': 'Location page'}, // 'detail' actions have an optional list property.
              'products': dl_products
            }
          }
        });
      }
    });
  },

  setBundlesCombinations () {
    this.bundlesCombinations = this.bundleTypes.concat(this.combinations);
  },

  setTypesCosts (type, id, indx) {

    let selected_type = (type == 'combination') ? 'combinations' : (type + 'Types');

    let costs = this.$store.state.reservations[type + '_costs'];
    let requestItemType = type == 'bundle' ? 'bundle_id' : type + '_type';

    if (costs.length) {
      for (let i in costs) {
        if (
          (selected_type == 'combinations'
          && this.combinations[indx].parking_type.id
            == costs[i].request.parking_type
          && this.combinations[indx].room_type.id
            == costs[i].request.room_type)
          || (selected_type != 'combinations'
          && id == costs[i].request[requestItemType])
        ) {

          if (costs[i].reservation) {
            this.$set(this[selected_type][indx], 'price', costs[i].reservation.grand_total);
            this.$set(this[selected_type][indx], 'sold_out', costs[i].reservation.sold_out);
          }
          
          if (costs[i].total_days) {
              this.$set(this[selected_type][indx], 'number_of_parking_days', costs[i].total_days['parking'].number_of_days);
              this.$set(this[selected_type][indx], 'number_of_room_days', costs[i].total_days['room'].number_of_days);
          }

          this.$set(this[selected_type][indx], 'loading', false);

        }
      }
      let warning = costs[costs.length-1].warning !== null ? costs[costs.length-1].warning : ""; 
      if (warning.includes("outside of shuttle")) {
        this.showWarning = true;
      }
    } else {
      this.$set(this[selected_type][indx], 'loading', false);
    }
  },

  checkIfBundleExists (type) {
    if (!this.bundleLoading && !this.combinationLoading) return false;
    for (let i in this.bundleTypes) {
      if (type.room_type.id == this.bundleTypes[i].room_type.id
      && type.parking_type.id == this.bundleTypes[i].parking_type.id) {
        return true
      }
    }

    return false
  },

  combinationIsCheaper (bundle) {
    if (bundle.loading) return false;

    for (let i in this.combinations) {
      if (!this.combinations[i].price) continue;

      if (
        this.combinations[i].parking_type.id == bundle.parking_type.id
        && this.combinations[i].room_type.id == bundle.room_type.id
        && this.combinations[i].price < bundle.price
      ) {
        return true;
      }
    }

    return false;
  },

  bundleIsCheaper (combination) {
    if (combination.loading) return false;

    for (let i in this.bundleTypes) {
      if (!this.bundleTypes[i].price) continue;

      if (
        this.bundleTypes[i].parking_type.id == combination.parking_type.id
        && this.bundleTypes[i].room_type.id == combination.room_type.id
        && this.bundleTypes[i].price <= combination.price
      ) {
        return true;
      }
    }

    return false;
  },

  getTripadvisorReviews() {
    this.$store.dispatch('locations.getTripadvisorReviews', this.location.id);
  },

  changeNumberOfSpots (value) {
    this.query.number_of_spots = value;
    this.$router.replace({
      query: Object.assign({}, this.$route.query, { number_of_spots: value }),
    });
    // this.init(this.query);
  },

  handleResize (event) {
    let width = $(document).width();
    if (width <= screenSizes.s_mobile ) {
      this.placement = 'bottom';
    } else {
      this.placement = 'right';
    }
    $('[data-toggle="popover"]').popover('dispose');

    $('[data-toggle="popover"]').popover({
      trigger:'hover',
      placement:this.placement,
    })

  },

  validateParking (e) {
    if (!this.checkin_time || !this.checkout_time) {
      e.preventDefault();
      e.stopPropagation();
      toastr.error('Please select Drop-Off and Pick-Up times');
      return false;
    } else if (this.checkin_time && this.checkout_time) {
      let from_date = moment(this.query.from_date, ['DD/MMM/YYYY'])
      let to_date = moment(this.query.to_date, ['DD/MMM/YYYY'])
      let from_time = moment(this.checkin_time, ['HH:mm'])
      let to_time = moment(this.checkout_time, ['HH:mm'])

      if (from_date.isSame(to_date) && from_time.isSameOrAfter(to_time)) {
        e.preventDefault();
        e.stopPropagation();
        toastr.error("Vehicle Drop-Off time must be before Vehicle Pick-Up time");
        return false;
      }
    }

    bookingData.setItem('from_time', this.checkin_time);
    bookingData.setItem('to_time', this.checkout_time);

    return true
  },

  validateBundle(e) {
    let nights_to_sleep = this.query.nights_to_sleep;
    let from_time = null;
    let to_time = null;
    if (nights_to_sleep == 'beginning') {
      if (!this.checkout_time) {
        e.preventDefault();
        e.stopPropagation();
        this.$refs.pickupTime.focus();
        toastr.error('Please select pick up time');
        return false;
      }
      from_time = this.location.checkin_time || '12:00';
      to_time = this.checkout_time;
    }

    if (nights_to_sleep == 'end') {
      if (!this.checkin_time) {
        e.preventDefault();
        e.stopPropagation();
        this.$refs.dropoffTime.focus();
        toastr.error('Please select drop off time');
        return false;
      }
      from_time = this.checkin_time;
      to_time = this.location.checkout_time || '12:00';
    }

    if (nights_to_sleep == 'both') {
      from_time = this.location.checkin_time || '12:00';
      to_time = this.location.checkout_time || '12:00';
    }


    bookingData.setItem('from_time', from_time);
    bookingData.setItem('to_time', to_time);
    return true
  },

  validateRoom (e) {
    let from_time = this.location.checkin_time || '12:00';
    let to_time = this.location.checkout_time || '12:00';
    bookingData.setItem('from_time', from_time);
    bookingData.setItem('to_time', to_time);
    return true;
  },

  setCheckinAndCheckoutTime() {
    if (this.query.reservation_type == 'room' || (this.query.reservation_type == 'both' && this.query.nights_to_sleep !='end')) {
      this.checkin_time = this.location.checkin_time;
    }

    if (this.query.reservation_type == 'room' || (this.query.reservation_type == 'both' && this.query.nights_to_sleep !='beginning')) {
      this.checkout_time = this.location.checkout_time;
    }
  },

  onChangeTimes () {
    if (this.checkin_time && this.checkout_time) {
      let from_date = moment(this.query.from_date, ['DD/MMM/YYYY'])
      let to_date = moment(this.query.to_date, ['DD/MMM/YYYY'])
      let from_time = moment(this.checkin_time, ['HH:mm'])
      let to_time = moment(this.checkout_time, ['HH:mm'])

      if (from_date.isSame(to_date) && from_time.isSameOrAfter(to_time)) {
        toastr.error("Vehicle Drop-Off time must be before Vehicle Pick-Up time");
      } else {
        this.init(this.query);
      }
    }
  }
}

const watch = {
  currencyCode (n, o) {
    if (n && o && n != o)
      this.init(this.query);
    console.log('currencyCode xxx', n, o);
  }
}

export default {
  name: "location-page",
  components,
  computed,
  methods,
  filters,
  watch,
  asyncData ({ store, route, router, reject }) {
    let promises = [
      store.dispatch('locations.get',{ 
        id: route.params.slug,
        params: {reservation_type: route.query.reservation_type} 
      }),
    ];

    return new Promise((resolve) => {
      Promise.all(promises).then(() => {
        try {
          if (!store.state.locations.active.id) {
            reject({code: 404})
          }
        } catch (e) {
          reject({code: 404})
        }

        store.dispatch('title.setTitle', store.state.locations.active.head_title || store.state.locations.active.name);
        store.dispatch('title.setMeta', [
          {property: 'og:title', content: store.state.locations.active.head_title || store.state.locations.active.name},
          {name: 'description', content: store.state.locations.active.head_description},
          {property: 'og:description', content: store.state.locations.active.head_description},
          {name: 'keywords', content: store.state.locations.active.head_keywords},
        ]);
        resolve();
      })
    });
  },
  beforeRouteUpdate (to, from, next) {
    let query = _.cloneDeep(to.query);
    if (query.guests_in_rooms) {
      try {
        query.guests_in_rooms = JSON.parse(query.guests_in_rooms);
      } catch (err) {
        query.guests_in_rooms = [2];
      }
    }

    query.number_of_rooms = urlDataValidator.validateNoOfRoomsAndParking(query.number_of_rooms)
    query.number_of_spots = urlDataValidator.validateNoOfRoomsAndParking(query.number_of_spots)
    query.guests_in_rooms = urlDataValidator.validateGuestsInRooms(query.guests_in_rooms, query.number_of_rooms);

    this.$set(this, 'query', query);

    this.checkin_time = null;
    this.checkout_time = null;
    this.setCheckinAndCheckoutTime();

    console.log('query changed');
    this.init(query)

    next()
  },
  data () {
    let query = _.cloneDeep(this.$route.query);
    console.log('this.$route.query', this.$route.query);
    if (query.guests_in_rooms) {
      try {
        query.guests_in_rooms = JSON.parse(query.guests_in_rooms);
      } catch (err) {
        query.guests_in_rooms = [2];
      }
    }

    query.number_of_rooms = urlDataValidator.validateNoOfRoomsAndParking(query.number_of_rooms)
    query.number_of_spots = urlDataValidator.validateNoOfRoomsAndParking(query.number_of_spots)
    query.guests_in_rooms = urlDataValidator.validateGuestsInRooms(query.guests_in_rooms, query.number_of_rooms);

    return {
      stripTags,
      moment,
      query,
      amenities: [],
      canShowSearchModal: false,
      center: {
        lat: 0.0,
        lng: 0.0
      },
      markers: [
        {
          lat: 0.0,
          lng: 0.0
        }
      ],
      typesLoading: false,
      bundleLoading: false,
      combinationLoading: false,
      locationTypes: {},
      roomTypes: [],
      parkingTypes: [],
      bundleTypes: [],
      combinations: [],
      bundlesCombinations: [],
      placement:'right',
      checkin_time: null,
      checkout_time: null,
      times: dateTime.getHalfHours(),
      selectedType: null,
      showSubtotalModal: false,
      shouldRefreshTooltip:true,
      showWarning: false
    }
  },
  created () {

  },
  mounted () {
    let currencyByDefault = localStorage.getItem('is_selected_currency_by_default');
    currencyByDefault = currencyByDefault !== null ? JSON.parse(currencyByDefault) : true;
    
    this.$store.dispatch('currencies.saveIsDefaultFlag', currencyByDefault);
    let location = _.cloneDeep(this.$store.state.locations.active);
    
    if (currencyByDefault && location && location.currency_code) {
      this.$store.dispatch('currencies.save',{currency: location.currency_code, isByDefault: true});
    }


    this.setCheckinAndCheckoutTime();
    
    this.init(this.query)

    if (!isServer) {
      dataLayer.push({
        'ecommerce': {
          'detail': {
            'actionField': {'list': 'Location page'}, // 'detail' actions have an optional list property.
            'products': [{
              'name': this.location.name, // Name or ID is required.
              'id': this.location.id,
              'category': this.airport.name
            }]
          }
        }
      });
    }

    this.$nextTick(function() {
      window.addEventListener('resize', this.handleResize)
      $('[data-toggle="tooltip"]').tooltip();
    });

    $('body').scrollspy({ target: '#scrollSpy' })

    console.log('store.state.locations.active', this.$store.state.locations.active);
  },

  beforeDestroy () {
    window.removeEventListener('resize', this.handleResize)
  }
}
