var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "container" },
    [
      _c(
        "div",
        { staticClass: "text-success margin-bottom-3x text-center" },
        [
          _c("animated-circle", {
            staticClass: "margin-bottom-2x",
            attrs: { type: "success", width: "70" }
          }),
          _vm._v(" "),
          _vm._m(0)
        ],
        1
      ),
      _vm._v(" "),
      _c("reservation-details", { attrs: { reservation: _vm.reservation } })
    ],
    1
  )
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "margin-left-2x f19" }, [
      _vm._v("\n        Thank you for booking with Tripteo!"),
      _c("br"),
      _vm._v("\n        Here is your reservation confirmation\n      ")
    ])
  }
]
render._withStripped = true
export { render, staticRenderFns }
if (module.hot) {
  module.hot.accept()
  if (module.hot.data) {
    require("vue-hot-reload-api")      .rerender("data-v-311daae6", { render: render, staticRenderFns: staticRenderFns })
  }
}