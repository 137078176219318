const methods = {
  close () {
    this.$emit('closeIframe');
  }
}

export default {
  name: 'three-d-secure',
  props: ['url'],
  methods
}
