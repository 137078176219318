import { funcModal } from 'components/modals'
import loginForm from '../loginForm'

const components = {
  funcModal,
  loginForm
}

const methods = {
  open () {
    this.$refs.modal.open();
  },
  onCloseModal () {
    this.$refs.modal.close();
  },
  handleUserLoggedIn() {
    this.$emit('hideLoginModal', true);
  },
  togglePasswordResetModal () {
    this.$emit('showPasswordResetModal', true);
  }
}

export default {
  name: "login-modal",
  props: [],
  components,
  methods,
  mounted () {
  }
}
