const computed = {
	classList () {
		return `icon icon-${this.icon}`;
	}
}

export default {
  name: 'input-icon',
  props: ['icon'],
  computed,

}
