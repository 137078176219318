/*
* @INFO: this component is a rebuild of "select" element
* @PROP selected: is the selected option -> {text: 'the text to show', value: 'the value of the option'}
* @PROP options: an array of options -> [{text: '...', value: '...'}]
* @CALLBACK selectedChanged: fires when the selected option has changed
*/
const computed = {
  filterredOptions () {
    console.log('this.filter_value', this.filter_value);
    if (this.filter_value)
      return this.options.filter((item) => item.text.toLowerCase().indexOf(this.filter_value.toLowerCase()) > -1);

    return this.options;
  }
}

const methods = {
  selectOption (option) {
    this.$emit('selectedChanged', option);
    console.log('option', option);
    this.filter_value = option.text;
    this.closeDropdown();
  },

  onChangeSearch (value) {
    console.log('triggered');
    
    this.filter_value = value
    this.$emit('selectedChanged', null);
    this.openDropdown()
  },

  toggleDropdown () {
    this.show = !this.show;
  },

  closeDropdown() {
    this.$nextTick(() => {
      this.show = false
    })
  },

  openDropdown () {
    this.show = true
  },

  closeIfClickOutside (event) {
    if (!$(event.target).closest(this.$refs.dropdown).length) {
      this.closeDropdown();
    }
  },

  resetValue () {
    this.onChangeSearch("")
  }
}

const watch = {
  text (n) {
    if (n == null) n = "";
    this.filter_value = n;
  }
}

export default {
  name: 's-select2',
  props: {
    'text': {
      required: false,
      type: String
    },
    'options': {
      required: true,
      type: Array
    }
  },
  computed,
  methods,
  watch,
  data () {
    return {
      filter_value: "",
      show: false
      // active: this.selected
    }
  },
  mounted () {
    $(document).on('click', this.closeIfClickOutside);

    try {
      let text = this.text
      if (text == null) text = ""
      this.filter_value = text
    } catch (e) {}
  },

  destroyed () {
    $(document).off('click', this.closeIfClickOutside);
  }
}
