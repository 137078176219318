import tripadvisorRating from 'components/tripadvisorRating'

let components = {
    tripadvisorRating,
  }

const computed = {
    
}
  
export default {
    name: 'tripadvisorReviews',
    props: ['location'],
    computed,
    components
}
  