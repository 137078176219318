var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "number-of-rooms-field" }, [
    _c(
      "div",
      {
        staticClass: "number-of-rooms-field--label",
        on: {
          click: function($event) {
            $event.preventDefault()
            _vm.active = !_vm.active
          }
        }
      },
      [
        _vm._v(
          "\n    " +
            _vm._s(_vm.rooms) +
            " " +
            _vm._s(_vm.rooms == 1 ? "Room" : "Rooms") +
            ",\n    " +
            _vm._s(_vm.totalGuests) +
            " " +
            _vm._s(_vm.totalGuests == 1 ? "Guest" : "Guests") +
            "\n  "
        )
      ]
    ),
    _vm._v(" "),
    _vm.active
      ? _c(
          "div",
          { staticClass: "number-of-rooms-field--list" },
          [
            _c("div", { staticClass: "number-of-rooms-field--rooms" }, [
              _vm._v(
                "\n      " +
                  _vm._s(_vm.rooms) +
                  " " +
                  _vm._s(_vm.rooms == 1 ? "Room" : "Rooms") +
                  "\n      "
              ),
              _c("div", { staticClass: "number-of-rooms-field--actions" }, [
                _c(
                  "a",
                  {
                    attrs: { href: "#" },
                    on: {
                      click: function($event) {
                        $event.preventDefault()
                        _vm.decreaseRooms()
                      }
                    }
                  },
                  [
                    _c("i", {
                      staticClass: "fa fa-minus-circle",
                      attrs: { "aria-hidden": "true" }
                    })
                  ]
                ),
                _vm._v(" "),
                _c(
                  "a",
                  {
                    attrs: { href: "#" },
                    on: {
                      click: function($event) {
                        $event.preventDefault()
                        _vm.increaseRooms()
                      }
                    }
                  },
                  [
                    _c("i", {
                      staticClass: "fa fa-plus-circle",
                      attrs: { "aria-hidden": "true" }
                    })
                  ]
                )
              ])
            ]),
            _vm._v(" "),
            _vm._l(_vm.rooms, function(room, i) {
              return _c(
                "div",
                { staticClass: "number-of-rooms-field--guests" },
                [
                  _vm._v(
                    "\n      " +
                      _vm._s(_vm.guests[i]) +
                      " " +
                      _vm._s(_vm.guests[i] == 1 ? "Guest" : "Guests") +
                      "\n      in Room " +
                      _vm._s(i + 1) +
                      "\n      "
                  ),
                  _c("div", { staticClass: "number-of-rooms-field--actions" }, [
                    _c(
                      "a",
                      {
                        attrs: { href: "#" },
                        on: {
                          click: function($event) {
                            $event.preventDefault()
                            _vm.decreaseGuests(i)
                          }
                        }
                      },
                      [
                        _c("i", {
                          staticClass: "fa fa-minus-circle",
                          attrs: { "aria-hidden": "true" }
                        })
                      ]
                    ),
                    _vm._v(" "),
                    _c(
                      "a",
                      {
                        attrs: { href: "#" },
                        on: {
                          click: function($event) {
                            $event.preventDefault()
                            _vm.increaseGuests(i)
                          }
                        }
                      },
                      [
                        _c("i", {
                          staticClass: "fa fa-plus-circle",
                          attrs: { "aria-hidden": "true" }
                        })
                      ]
                    )
                  ])
                ]
              )
            }),
            _vm._v(" "),
            _c("hr"),
            _vm._v(" "),
            _c("div", { staticClass: "d-flex justify-content-end" }, [
              _c(
                "button",
                {
                  staticClass: "btn btn-teal btn-block",
                  on: {
                    click: function($event) {
                      $event.preventDefault()
                      _vm.ok()
                    }
                  }
                },
                [_vm._v("OK")]
              )
            ])
          ],
          2
        )
      : _vm._e()
  ])
}
var staticRenderFns = []
render._withStripped = true
export { render, staticRenderFns }
if (module.hot) {
  module.hot.accept()
  if (module.hot.data) {
    require("vue-hot-reload-api")      .rerender("data-v-709e14ea", { render: render, staticRenderFns: staticRenderFns })
  }
}