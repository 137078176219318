import { http } from 'services'

// initial state
const state = () => ({
  all: [],
  errors: null
})

// getters
const getters = {
}

// actions
const actions = {
  
  ['amenities.getAll'] ({ commit, state }, params) {
    commit('CLEAR_ERRORS')
    if (!params._q) params._q = null  
    return (http.get('all-amenities', {
      params
    }))
    .then ((res) => {
      commit('GET_ALL_AMENITIES', res);
    })
    .catch ((res) => {
      commit('AMENITIES_ERROR', res);
    })
  },

}

// mutations
const mutations = {

  GET_ALL_AMENITIES (state, res) {
    if (res)
      state.all = res.data
  },

  //ERRORS
  AMENITIES_ERROR (state, err) {
    try {
      state.errors = err.response.data;
    } catch (e) {
      state.errors = err;
    }
  },

  CLEAR_ERRORS (state) {
    state.errors = null
  },

  
}

export default {
  state,
  getters,
  actions,
  mutations
}
