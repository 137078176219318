var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("span", [
    _c(
      "button",
      {
        staticClass: "btn",
        class: _vm.btnClass,
        attrs: { type: "button", "data-toggle": "modal" },
        on: {
          click: function($event) {
            $event.preventDefault()
            return _vm.open($event)
          }
        }
      },
      [_vm._v("\n    " + _vm._s(_vm.label) + "\n  ")]
    ),
    _vm._v(" "),
    _c(
      "div",
      {
        ref: "modal",
        staticClass: "modal fade",
        class: _vm.modalClass,
        attrs: { tabindex: "-1", role: "dialog", "aria-labelledby": "modal" }
      },
      [
        _c(
          "div",
          {
            staticClass: "modal-dialog",
            class: _vm.size ? "modal-" + _vm.size : "",
            attrs: { role: "document" }
          },
          [
            _c("div", { staticClass: "modal-content" }, [
              _c("div", { staticClass: "modal-header" }, [
                _c(
                  "button",
                  {
                    staticClass: "close",
                    attrs: { type: "button" },
                    on: {
                      click: function($event) {
                        $event.preventDefault()
                        return _vm.close($event)
                      }
                    }
                  },
                  [
                    _c("span", { attrs: { "aria-hidden": "true" } }, [
                      _vm._v("×")
                    ])
                  ]
                ),
                _vm._v(" "),
                _c("h4", { staticClass: "modal-title" }, [
                  _vm._v(_vm._s(_vm.title))
                ])
              ]),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "modal-body" },
                [_vm._t("body", null, { close: _vm.close })],
                2
              ),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "modal-footer" },
                [_vm._t("footer", null, { close: _vm.close })],
                2
              )
            ])
          ]
        )
      ]
    )
  ])
}
var staticRenderFns = []
render._withStripped = true
export { render, staticRenderFns }
if (module.hot) {
  module.hot.accept()
  if (module.hot.data) {
    require("vue-hot-reload-api")      .rerender("data-v-359a97fd", { render: render, staticRenderFns: staticRenderFns })
  }
}