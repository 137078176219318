import toastr from 'toastr'
import moment from 'moment'
import { funcModal } from 'components/modals'

const components = {
  funcModal
}

const methods = {
  open () {
    this.$refs.modal.open();
  },
  close () {
    this.$refs.modal.close();
  },
  initSendViaEmailParams () {
    return {
      email: this.email,
      reserved_by: this.reservation.reserved_by,
      check_in: moment(this.reservation.history[0].from_date,['YYYY-MM-DD hh:mm:ss']).format('YYYY-MM-DD')
    };
  },
  sendViaEmail () {
    this.loading = true;
    this.errors = [];
    this.$store.dispatch('reservations.sendReceiptViaEmail', {
      id: this.reservation.reservation_number,
      params: this.initSendViaEmailParams()
    }).then(() => {
      this.loading = false;
     let errors = this.$store.state.reservations.errors;
      // console.log(errors);
      if (errors) {
        if (errors.errors) {
          this.errors = _.cloneDeep(errors.errors);
        }
        toastr.error(errors.message);
      } else {
        toastr.success("Your receipt has been sent to your email!");
        this.close();
      }
      
    });
  }
}

export default {
  name: "send-email-modal",
  props: ['reservation'],
  components,
  methods,
  data () {
    return {
      email: this.reservation.reserved_by,
      loading: false,
      errors:[],
    }
  },
  mounted () {
  }
}
