import toastr from 'toastr';
import {flashMessage} from 'services'
const methods = {

  validateFields () {
    for (let field in this.form) {
      if (!this.form[field]) {
        this.$set(this.errors, field, ['This field is required'])
      }
    }
  },
	signup () {
		let data = _.cloneDeep(this.form);
		
    this.errors = {};
    this.validateFields();
    if (!this.terms_and_conditions) {
      let terms = ['Please, confirm accepting the terms & conditions']
      this.errors.terms_and_conditions = terms;
      return;
    }
    this.loading = true;
		this.$store.dispatch('user.signup', data).then(() => {
			this.loading = false;
			if (this.$store.state.user.errors) {
        if (!_.isEmpty(this.$store.state.user.errors.errors)) {
          this.errors = _.cloneDeep(this.$store.state.user.errors.errors);
        }
        toastr.error(this.$store.state.user.errors.message);
      } else {
      	let message = `Welcome to Tripteo! Your account has been created successfully.
      	To be able to login to your account please check your email to activate it.`;
	      flashMessage.set(message);
        this.$emit('userRegistered', true);
	      this.$router.push({name: 'login'});
      }
		})
	}
}

const watch = {

}


const computed = {
	
}


export default {
  name: 'signup-form',
  methods,
  watch,
	computed,
  data () {
    return {
    	form: {
    		first_name: null,
    		last_name: null,
	      email: null,
	      password: null,
	      password_confirmation: null
    	},
      terms_and_conditions: false,
    	loading: false,
    	errors: {},
    }
  }
}
