var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("layout", [
    _c("div", { staticClass: "bg-grey padding-top-10x padding-bottom-10x" }, [
      _c(
        "div",
        { staticClass: "container" },
        [
          !_vm._.isEmpty(_vm.reservation)
            ? _c("reservation-details", {
                attrs: { reservation: _vm.reservation }
              })
            : _vm._e()
        ],
        1
      )
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true
export { render, staticRenderFns }
if (module.hot) {
  module.hot.accept()
  if (module.hot.data) {
    require("vue-hot-reload-api")      .rerender("data-v-10173e97", { render: render, staticRenderFns: staticRenderFns })
  }
}