var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "section",
    { staticClass: "main-search-container" },
    [
      _c("image-bg", {
        staticClass: "main-seach-image",
        attrs: { src: "/assets/images/sliding_image_bicycle.JPG" }
      }),
      _vm._v(" "),
      _c("searchForm", { attrs: { "route-name": "search" } })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true
export { render, staticRenderFns }
if (module.hot) {
  module.hot.accept()
  if (module.hot.data) {
    require("vue-hot-reload-api")      .rerender("data-v-ec1f5f0a", { render: render, staticRenderFns: staticRenderFns })
  }
}