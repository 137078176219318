import { http } from 'services'
// import axios from 'axios'
// initial state
const state = () => ({
  all: {},
  selected: null,
  isSelectedByDefault: false,
  errors: null,
})

// getters
const getters = {
}

// actions
const actions = {
  ['currencies.getAll'] ({ commit , dispatch}, id) {
    commit('CLEAR_ERRORS')
    return (http.get('currencies?_all=true')).then((res) => {
      commit('CURRENCIES_GET_ALL', res);
      
      let storedCurrency = localStorage.getItem('selected_currency');
      if (!storedCurrency) {
        let defaultCurrency = _.find(res.data, (item, index) => {
          return item.default;
        });  
        if (defaultCurrency) {
          dispatch('currencies.save', {currency: defaultCurrency.code, isByDefault: true});  
        }
      }
    });
  },

  ['currencies.get'] ({ commit }) {
    commit('CLEAR_ERRORS')
    let selected_currency = localStorage.getItem('selected_currency');
    commit('CURRENCIES_GET', selected_currency);

  },

  ['currencies.save'] ({ commit }, {currency, isByDefault = false}) {
    commit('CLEAR_ERRORS')
    if (!!currency) {
      localStorage.setItem('selected_currency', currency);
      localStorage.setItem('is_selected_currency_by_default', JSON.stringify(isByDefault));
      commit('CURRENCIES_SET', {currency, isByDefault});
    } else {
      localStorage.removeItem('selected_currency')
      localStorage.removeItem('is_selected_currency_by_default')
      commit('CURRENCIES_SET', {currency: null, isByDefault:false});
    }
  },

  ['currencies.saveIsDefaultFlag'] ({ commit }, isByDefault) {
    localStorage.setItem('is_selected_currency_by_default', JSON.stringify(isByDefault));
    commit('BY_DEFAULT_SET', isByDefault);
    
  },

}

// mutations
const mutations = {
  CURRENCIES_GET_ALL (state, res) {
    if (res.data) {
      state.all = res.data;
    }
  },

  CURRENCIES_GET (state, res) {
    if (res) {
      state.selected = res;
    }
  },

  CURRENCIES_SET (state, res) {
    state.selected = res.currency;
    state.isSelectedByDefault = res.isByDefault;
  },

  BY_DEFAULT_SET (state, res) {
    state.isSelectedByDefault = res;
  },

  //ERRORS
  CURRENCIES_ERROR (state, err) {
    state.errors = err.response.data;
  },

  CLEAR_ERRORS (state) {
    state.errors = null
  }
}

export default {
  state,
  getters,
  actions,
  mutations
}
