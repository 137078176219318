<template>
  <airport :query="query" :key="renderCount" />
</template>
<script>
import { urlDataValidator } from "services";
import airport from "../airport";

const computed = {};

const methods = {
  prepareQuery(q) {
    let query = _.cloneDeep(q);
    if (query.guests_in_rooms) {
      try {
        query.guests_in_rooms = JSON.parse(query.guests_in_rooms);
      } catch (err) {
        query.guests_in_rooms = [2];
      }
    }

    query.number_of_rooms = urlDataValidator.validateNoOfRoomsAndParking(
      query.number_of_rooms
    );
    query.number_of_spots = urlDataValidator.validateNoOfRoomsAndParking(
      query.number_of_spots
    );
    query.guests_in_rooms = urlDataValidator.validateGuestsInRooms(
      query.guests_in_rooms,
      query.number_of_rooms
    );

    return query;
  }
};

const components = {
  airport
};

const watch = {
  $route (n, o) {
    if (n == o) return;
    let query = this.prepareQuery(this.$route.query);
    console.log('query ---- changed', query);
    
    this.$set(this, "query", query);
    this.renderCount++;
  }
};

export default {
  name: "mutual-page",
  components,
  computed,
  methods,
  watch,
  data() {
    let query = this.prepareQuery(this.$route.query);
    return {
      renderCount: 0,
      query
    };
  },
  asyncData({ store, route, router, reject }) {
    console.log("async called");
    let query = _.cloneDeep(route.query);
    let port_id = query.airport_id;
    if (route.params.slug) {
      port_id = route.params.slug;
    }
    store.dispatch('search.resetSearch');
    return new Promise((resolve) => {
      Promise.all([
        store.dispatch("search.getResults", { airport_id: port_id }),
        store.dispatch("airports.get", port_id)
      ]).then(() => {
        try {
          if (!store.state.airports.active.id) {
            reject({ code: 404 });
          }
        } catch (e) {
          reject({ code: 404 });
        }

        store.dispatch("title.setTitle", store.state.airports.active.head_title);
        store.dispatch('title.setMeta', [
          {property: 'og:title', content: store.state.airports.active.head_title},
          {name: 'description', content: store.state.airports.active.head_description},
          {property: 'og:description', content: store.state.airports.active.head_description},
          {name: 'keywords', content: store.state.airports.active.head_keywords},
        ]);
        resolve();
      });
    });
  },
};
</script>