var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "func-modal",
    {
      ref: "forgotPass",
      attrs: { title: "Reset Password" },
      scopedSlots: _vm._u([
        {
          key: "footer",
          fn: function(props) {
            return [
              _c(
                "button",
                {
                  staticClass: "btn btn-info",
                  class: { disabled: _vm.processing },
                  attrs: { disabled: _vm.processing },
                  on: {
                    click: function($event) {
                      $event.preventDefault()
                      _vm.submitSendEmail(props)
                    }
                  }
                },
                [
                  _vm.processing
                    ? _c("span", [
                        _c("i", { staticClass: "fa fa-spin fa-spinner" }),
                        _vm._v("\n        Sending...\n      ")
                      ])
                    : _c("span", [_vm._v("\n        Send\n      ")])
                ]
              ),
              _vm._v(" "),
              _c(
                "button",
                {
                  staticClass: "btn btn-default",
                  on: {
                    click: function($event) {
                      $event.preventDefault()
                      return _vm.close($event)
                    }
                  }
                },
                [_vm._v("Close")]
              )
            ]
          }
        }
      ])
    },
    [
      _c("template", { slot: "body" }, [
        _c("input", {
          directives: [
            {
              name: "model",
              rawName: "v-model",
              value: _vm.forgotPass.email,
              expression: "forgotPass.email"
            }
          ],
          staticClass: "form-control",
          attrs: {
            type: "email",
            autocomplete: "off",
            placeholder: "Type your email"
          },
          domProps: { value: _vm.forgotPass.email },
          on: {
            input: function($event) {
              if ($event.target.composing) {
                return
              }
              _vm.$set(_vm.forgotPass, "email", $event.target.value)
            }
          }
        }),
        _vm._v(" "),
        _vm.errors.email
          ? _c("div", { staticClass: "text-danger" }, [
              _vm._v("\n        " + _vm._s(_vm.errors.email[0]) + "\n      ")
            ])
          : _vm._e(),
        _vm._v(" "),
        _vm.errors.account_not_found
          ? _c(
              "div",
              { staticClass: "text-danger" },
              [
                _vm._v(
                  "\n        Your account was not found, to create an account please\n        "
                ),
                _c(
                  "router-link",
                  {
                    staticClass: "text-info",
                    attrs: { to: { name: "signup" } }
                  },
                  [_vm._v(" sign up ")]
                ),
                _vm._v(".\n        "),
                _c("br"),
                _vm._v(
                  "\n        If you believe you already have an account please "
                ),
                _c(
                  "a",
                  {
                    staticClass: "text-info",
                    attrs: { href: "#" },
                    on: {
                      click: function($event) {
                        $event.preventDefault()
                      }
                    }
                  },
                  [_vm._v(" contact us.")]
                )
              ],
              1
            )
          : _vm._e(),
        _vm._v(" "),
        _vm.errors.email_not_sent
          ? _c("div", { staticClass: "text-danger" }, [
              _vm._v(
                "\n        Ops, something wen't wrong, we're unable to process your request now, please try again later.\n      "
              )
            ])
          : _vm._e()
      ])
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true
export { render, staticRenderFns }
if (module.hot) {
  module.hot.accept()
  if (module.hot.data) {
    require("vue-hot-reload-api")      .rerender("data-v-3878e986", { render: render, staticRenderFns: staticRenderFns })
  }
}