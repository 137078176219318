var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "layout",
    [
      _c("big-search"),
      _vm._v(" "),
      _c("how-it-works"),
      _vm._v(" "),
      _c(
        "section",
        { staticClass: "top-packages" },
        [
          _c("image-bg", {
            staticClass: "top-packages--main-image",
            attrs: { src: "/assets/images/nights-to-stay-bg.jpg" }
          }),
          _vm._v(" "),
          _c("div", { staticClass: "top-packages--container" }, [
            _c("div", { staticClass: "container" }, [
              _c("div", { staticClass: "top-packages--content " }, [
                _c("h1", { staticClass: "content--title" }, [
                  _c("span", [
                    _vm._v("Stay the night,"),
                    _c("br"),
                    _vm._v("\n            fly fresh in the morning,"),
                    _c("br"),
                    _vm._v("\n            save on everything."),
                    _c("br")
                  ])
                ]),
                _vm._v(" "),
                _c(
                  "a",
                  {
                    staticClass: "btn btn-lg btn-teal mt-4",
                    attrs: { href: "#" }
                  },
                  [_vm._v("CHECK TOP PACKAGES")]
                )
              ])
            ])
          ])
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "section",
        { staticClass: "promotional-content container" },
        [
          _c(
            "no-ssr",
            [
              _c(
                "carousel",
                {
                  staticClass: "owl-theme promotional-content--carousel",
                  attrs: {
                    "should-render": _vm.renderCarousel,
                    options: {
                      responsive: { 0: { items: 1 } },
                      autoplay: true,
                      autoplayTimeout: 7000
                    }
                  }
                },
                _vm._l(_vm.carousels, function(carousel) {
                  return _c("div", { staticClass: "col-sm-10" }, [
                    _c(
                      "div",
                      {
                        staticClass:
                          "promotion-content--pos wrapper wrapper-overflow"
                      },
                      [
                        _c("image-bg", {
                          staticClass: "promotion-content--image",
                          attrs: { src: carousel.image }
                        }),
                        _vm._v(" "),
                        _c("div", { staticClass: "promotion-content--data" }, [
                          _c("h4", [_vm._v(" " + _vm._s(carousel.title))]),
                          _vm._v(" "),
                          _c("p", {
                            domProps: { innerHTML: _vm._s(carousel.text) }
                          }),
                          _vm._v(" "),
                          carousel.link
                            ? _c(
                                "div",
                                { staticClass: "promotion-content--showmore" },
                                [
                                  _c(
                                    "a",
                                    {
                                      attrs: {
                                        href: carousel.link,
                                        target: "_blank"
                                      }
                                    },
                                    [
                                      _vm._v(
                                        "\n                  Show More\n                "
                                      )
                                    ]
                                  )
                                ]
                              )
                            : _vm._e()
                        ])
                      ],
                      1
                    )
                  ])
                })
              )
            ],
            1
          )
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "section",
        { staticClass: "best-airports bg-light d-none d-sm-none d-md-block" },
        [
          _vm.airportsLoading
            ? _c("div", { staticClass: "text-center" }, [
                _c("i", {
                  staticClass: "fa fa-circle-o-notch fa-spin fa-3x fa-fw"
                })
              ])
            : _c("div", [
                _vm.airports.us.length
                  ? _c("div", {}, [
                      _c("div", { staticClass: "super-title text-center" }, [
                        _vm._v("\n          TOP US AIRPORTS\n        ")
                      ]),
                      _vm._v(" "),
                      _c("div", { staticClass: "container" }, [
                        _c("div", { staticClass: "row" }, [
                          _c(
                            "div",
                            { staticClass: "col-xs-12 col-md-3 col-sm-6" },
                            _vm._l(_vm.airports.us, function(port, i) {
                              return i < 25
                                ? _c(
                                    "div",
                                    { staticClass: "best-airports--item" },
                                    [
                                      _c(
                                        "router-link",
                                        {
                                          attrs: {
                                            to: {
                                              name: "airport",
                                              params: { slug: port.url }
                                            }
                                          }
                                        },
                                        [_vm._v(_vm._s(port.name))]
                                      )
                                    ],
                                    1
                                  )
                                : _vm._e()
                            })
                          ),
                          _vm._v(" "),
                          _c(
                            "div",
                            { staticClass: "col-xs-12 col-md-3 col-sm-6" },
                            _vm._l(_vm.airports.us, function(port, i) {
                              return i < 50 && i >= 25
                                ? _c(
                                    "div",
                                    { staticClass: "best-airports--item" },
                                    [
                                      _c(
                                        "router-link",
                                        {
                                          attrs: {
                                            to: {
                                              name: "airport",
                                              params: { slug: port.url }
                                            }
                                          }
                                        },
                                        [_vm._v(_vm._s(port.name))]
                                      )
                                    ],
                                    1
                                  )
                                : _vm._e()
                            })
                          ),
                          _vm._v(" "),
                          _c(
                            "div",
                            { staticClass: "col-xs-12 col-md-3 col-sm-6" },
                            _vm._l(_vm.airports.us, function(port, i) {
                              return i < 75 && i >= 50
                                ? _c(
                                    "div",
                                    { staticClass: "best-airports--item" },
                                    [
                                      _c(
                                        "router-link",
                                        {
                                          attrs: {
                                            to: {
                                              name: "airport",
                                              params: { slug: port.url }
                                            }
                                          }
                                        },
                                        [_vm._v(_vm._s(port.name))]
                                      )
                                    ],
                                    1
                                  )
                                : _vm._e()
                            })
                          ),
                          _vm._v(" "),
                          _c(
                            "div",
                            { staticClass: "col-xs-12 col-md-3 col-sm-6" },
                            _vm._l(_vm.airports.us, function(port, i) {
                              return i < 100 && i >= 75
                                ? _c(
                                    "div",
                                    { staticClass: "best-airports--item" },
                                    [
                                      _c(
                                        "router-link",
                                        {
                                          attrs: {
                                            to: {
                                              name: "airport",
                                              params: { slug: port.url }
                                            }
                                          }
                                        },
                                        [_vm._v(_vm._s(port.name))]
                                      )
                                    ],
                                    1
                                  )
                                : _vm._e()
                            })
                          )
                        ])
                      ])
                    ])
                  : _vm._e(),
                _vm._v(" "),
                _vm.airports.ca.length
                  ? _c("div", {}, [
                      _c("div", { staticClass: "super-title text-center" }, [
                        _vm._v("\n          TOP CANADIAN AIRPORTS\n        ")
                      ]),
                      _vm._v(" "),
                      _c("div", { staticClass: "container" }, [
                        _c("div", { staticClass: "row" }, [
                          _c(
                            "div",
                            { staticClass: "col-xs-12 col-md-3 col-sm-6" },
                            _vm._l(_vm.airports.ca, function(port, i) {
                              return i < 25
                                ? _c(
                                    "div",
                                    { staticClass: "best-airports--item" },
                                    [
                                      _c(
                                        "router-link",
                                        {
                                          attrs: {
                                            to: {
                                              name: "airport",
                                              params: { slug: port.url }
                                            }
                                          }
                                        },
                                        [_vm._v(_vm._s(port.name))]
                                      )
                                    ],
                                    1
                                  )
                                : _vm._e()
                            })
                          ),
                          _vm._v(" "),
                          _c(
                            "div",
                            { staticClass: "col-xs-12 col-md-3 col-sm-6" },
                            _vm._l(_vm.airports.ca, function(port, i) {
                              return i < 50 && i >= 25
                                ? _c(
                                    "div",
                                    { staticClass: "best-airports--item" },
                                    [
                                      _c(
                                        "router-link",
                                        {
                                          attrs: {
                                            to: {
                                              name: "airport",
                                              params: { slug: port.url }
                                            }
                                          }
                                        },
                                        [_vm._v(_vm._s(port.name))]
                                      )
                                    ],
                                    1
                                  )
                                : _vm._e()
                            })
                          ),
                          _vm._v(" "),
                          _c(
                            "div",
                            { staticClass: "col-xs-12 col-md-3 col-sm-6" },
                            _vm._l(_vm.airports.ca, function(port, i) {
                              return i < 75 && i >= 50
                                ? _c(
                                    "div",
                                    { staticClass: "best-airports--item" },
                                    [
                                      _c(
                                        "router-link",
                                        {
                                          attrs: {
                                            to: {
                                              name: "airport",
                                              params: { slug: port.url }
                                            }
                                          }
                                        },
                                        [_vm._v(_vm._s(port.name))]
                                      )
                                    ],
                                    1
                                  )
                                : _vm._e()
                            })
                          ),
                          _vm._v(" "),
                          _c(
                            "div",
                            { staticClass: "col-xs-12 col-md-3 col-sm-6" },
                            _vm._l(_vm.airports.ca, function(port, i) {
                              return i < 100 && i >= 75
                                ? _c(
                                    "div",
                                    { staticClass: "best-airports--item" },
                                    [
                                      _c(
                                        "router-link",
                                        {
                                          attrs: {
                                            to: {
                                              name: "airport",
                                              params: { slug: port.url }
                                            }
                                          }
                                        },
                                        [_vm._v(_vm._s(port.name))]
                                      )
                                    ],
                                    1
                                  )
                                : _vm._e()
                            })
                          )
                        ])
                      ])
                    ])
                  : _vm._e()
              ])
        ]
      ),
      _vm._v(" "),
      _c(
        "section",
        { staticClass: "subscribe" },
        [
          _c("div", { staticClass: "subscribe--overlay" }),
          _vm._v(" "),
          _c("image-bg", {
            staticClass: "subscribe--main-image",
            attrs: {
              src: "/assets/images/subscribe-bg.jpg",
              position: "center"
            }
          }),
          _vm._v(" "),
          _c("div", { staticClass: "subscribe--container" }, [
            _c("div", { staticClass: "container" }, [
              _c("div", { staticClass: "subscribe--content" }, [
                _c("div", { staticClass: "subscribe--text text-center" }, [
                  _c("div", { staticClass: "mb-2 subscribe--small-text" }, [
                    _vm._v("\n              Subscribe for\n            ")
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "mb-2 subscripe--deals" }, [
                    _vm._v("exclusive deals")
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "mb-5 subscribe--small-text" }, [
                    _vm._v(
                      "\n              Sign up for our email list\n              to get secret coupons!\n            "
                    )
                  ])
                ]),
                _vm._v(" "),
                _c(
                  "form",
                  {
                    attrs: {
                      id: "mc_form",
                      action:
                        "https://tripteo.us4.list-manage.com/subscribe/post-json?u=bd0f3c6a92ca41b4af406c03b&id=d6a751b831",
                      method: "get"
                    },
                    on: {
                      submit: function($event) {
                        $event.preventDefault()
                        _vm.onSubscribe($event, _vm.$refs.subscribeEmail.value)
                      }
                    }
                  },
                  [
                    _c("div", { staticClass: "form-group subscribe--form" }, [
                      _c("input", {
                        ref: "subscribeEmail",
                        staticClass: "form-control",
                        attrs: {
                          required: "",
                          type: "text",
                          name: "EMAIL",
                          placeholder: "Email Address"
                        }
                      }),
                      _vm._v(" "),
                      _c(
                        "button",
                        {
                          staticClass: "btn btn-lg btn-teal",
                          attrs: { type: "submit" }
                        },
                        [_vm._v("SUBSCRIBE")]
                      )
                    ])
                  ]
                )
              ])
            ])
          ])
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true
export { render, staticRenderFns }
if (module.hot) {
  module.hot.accept()
  if (module.hot.data) {
    require("vue-hot-reload-api")      .rerender("data-v-5252bf00", { render: render, staticRenderFns: staticRenderFns })
  }
}