var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "layout",
    [
      _c("div", { staticClass: "location-page" }, [
        _c("div", { staticClass: "breadcrumb" }, [
          _c("div", { staticClass: "container" }, [
            _c("ol", [
              _c(
                "li",
                {
                  staticClass: "breadcrumb-item",
                  attrs: { "aria-current": "page" }
                },
                [
                  _c("router-link", { attrs: { to: { name: "home" } } }, [
                    _c("i", {
                      staticClass: "fa fa-home",
                      attrs: { "aria-hidden": "true" }
                    })
                  ])
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "li",
                {
                  staticClass: "breadcrumb-item",
                  attrs: { "aria-current": "page" }
                },
                [
                  _c("router-link", { attrs: { to: { name: "airports" } } }, [
                    _vm._v("\n              Airports\n            ")
                  ])
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "li",
                {
                  staticClass: "breadcrumb-item",
                  attrs: { "aria-current": "page" }
                },
                [
                  _vm.airport
                    ? _c("router-link", { attrs: { to: _vm.airportRoute } }, [
                        _vm._v(
                          "\n              " +
                            _vm._s(_vm.airport.name) +
                            "\n            "
                        )
                      ])
                    : _vm._e()
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "li",
                {
                  staticClass: "breadcrumb-item active",
                  attrs: { "aria-current": "page" }
                },
                [
                  _vm._v(
                    "\n              " +
                      _vm._s(_vm.location.name) +
                      "\n          "
                  )
                ]
              )
            ])
          ])
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "location-top-info" }, [
          _c("div", { staticClass: "container" }, [
            _c(
              "div",
              {
                staticClass:
                  "d-flex flex-wrap flex-lg-nowrap justify-content-between"
              },
              [
                _c("div", {}, [
                  _c("h1", { staticClass: "margin-bottom-2x" }, [
                    _vm._v(_vm._s(_vm.location.name))
                  ]),
                  _vm._v(" "),
                  _vm.query.reservation_type != "parking"
                    ? _c("div", [
                        _vm.showTripadvisorReviews
                          ? _c(
                              "div",
                              {
                                staticClass:
                                  "d-flex bold margin-bottom-6x margin-top-2x"
                              },
                              [
                                _c("tripadvisor-rating", {
                                  attrs: {
                                    location_link:
                                      _vm.location.tripadvisor_reviews.web_url,
                                    rating:
                                      _vm.location.tripadvisor_reviews.rating,
                                    reviews_count:
                                      _vm.location.tripadvisor_reviews
                                        .num_reviews,
                                    font_size: "14px",
                                    show_icon: true
                                  }
                                })
                              ],
                              1
                            )
                          : _c(
                              "div",
                              { staticClass: "d-flex bold margin-bottom-2x" },
                              [
                                _c(
                                  "span",
                                  { staticClass: "text-warning mr-2" },
                                  [_vm._v("Not rated yet")]
                                )
                              ]
                            )
                      ])
                    : _vm._e(),
                  _vm._v(" "),
                  _c("p", { staticClass: "margin-bottom-2x" }, [
                    _c("i", {
                      staticClass: "fa fa-map-marker",
                      attrs: { "aria-hidden": "true" }
                    }),
                    _vm._v(
                      "\n              " +
                        _vm._s(_vm.location.address) +
                        "\n            "
                    )
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "margin-bottom-2x" }, [
                    _c("i", {
                      staticClass: "fa fa-plane",
                      attrs: { "aria-hidden": "true" }
                    }),
                    _vm._v(
                      "\n              " +
                        _vm._s(_vm.distanseFromAirport) +
                        "\n              " +
                        _vm._s(
                          _vm._f("capitalize")(
                            _vm.location.unit +
                              (_vm.distanseFromAirport == 1 ? "" : "s")
                          )
                        ) +
                        "\n              from airport\n            "
                    )
                  ])
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "location-best-deal" }, [
                  _c("div", { staticClass: "card margin-bottom-3x" }, [
                    _c(
                      "div",
                      {
                        staticClass:
                          "card-header d-flex justify-content-between "
                      },
                      [
                        _vm.query.reservation_type &&
                        _vm.query.from_date &&
                        _vm.query.to_date &&
                        _vm.cheapestPrice
                          ? _c("div", { staticClass: "margin-top-2x" }, [
                              _vm.query.reservation_type == "both"
                                ? _c("span", [
                                    _vm._v(
                                      "\n                    Package Deal\n                  "
                                    )
                                  ])
                                : _c("span", [
                                    _vm._v(
                                      "\n                    Discount Deals\n                  "
                                    )
                                  ]),
                              _vm._v(
                                "\n                   from\n                "
                              )
                            ])
                          : _vm._e(),
                        _vm._v(" "),
                        _vm.query.reservation_type &&
                        _vm.query.from_date &&
                        _vm.query.to_date &&
                        _vm.cheapestPrice
                          ? _c("div", { staticClass: "f20 bold text-center" }, [
                              _vm._v(
                                "\n                  " +
                                  _vm._s(
                                    _vm._f("currency")(
                                      _vm.cheapestPrice,
                                      _vm.symbol
                                    )
                                  ) +
                                  "\n                  "
                              ),
                              _c(
                                "span",
                                { staticClass: "f12 text-center d-block" },
                                [_vm._v(" Incl. taxes & fees ")]
                              )
                            ])
                          : _c("div", {}, [
                              _vm._v(
                                "\n                  Search dates for availability\n                "
                              )
                            ])
                      ]
                    ),
                    _vm._v(" "),
                    _c("div", { staticClass: "card-body" }, [
                      _vm.query.reservation_type &&
                      _vm.query.from_date &&
                      _vm.query.to_date &&
                      _vm.cheapestPrice
                        ? _c(
                            "a",
                            {
                              staticClass: "btn btn-teal btn-lg btn-block",
                              attrs: { href: "#booking" },
                              on: {
                                click: function($event) {
                                  $event.preventDefault()
                                  _vm.scrollTo("#booking")
                                }
                              }
                            },
                            [_vm._v("View Options")]
                          )
                        : _c(
                            "a",
                            {
                              staticClass: "btn btn-teal btn-lg btn-block",
                              attrs: { href: "#" },
                              on: {
                                click: function($event) {
                                  $event.preventDefault()
                                  return _vm.openSearchModal($event)
                                }
                              }
                            },
                            [_vm._v("Check Prices")]
                          ),
                      _vm._v(" "),
                      _vm.hasFreeCancellation
                        ? _c(
                            "p",
                            {
                              staticClass:
                                "card-text text-link f14 margin-top-1x"
                            },
                            [
                              _c("i", {
                                staticClass: "fa fa-check",
                                attrs: { "aria-hidden": "true" }
                              }),
                              _vm._v(
                                "\n                  Free Cancellation\n                "
                              )
                            ]
                          )
                        : _vm._e()
                    ])
                  ])
                ])
              ]
            )
          ])
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "bg-grey padding-top-3x padding-bottom-8x" }, [
          _c("div", { staticClass: "container" }, [
            _c(
              "div",
              {
                attrs: {
                  "data-spy": "scroll",
                  "data-target": "#scrollSpy",
                  "data-offset": "0"
                }
              },
              [
                _c(
                  "nav",
                  {
                    staticClass: "location-nav white-box",
                    attrs: { id: "scrollSpy" }
                  },
                  [
                    _c("ul", [
                      _c("li", [
                        _c(
                          "a",
                          {
                            staticClass: "nav-link",
                            attrs: { href: "#overview" },
                            on: {
                              click: function($event) {
                                $event.preventDefault()
                                _vm.scrollTo("#overview")
                              }
                            }
                          },
                          [_vm._v("Overview")]
                        )
                      ]),
                      _vm._v(" "),
                      _c("li", [
                        _c(
                          "a",
                          {
                            staticClass: "nav-link",
                            attrs: { href: "#booking" },
                            on: {
                              click: function($event) {
                                $event.preventDefault()
                                _vm.scrollTo("#booking")
                              }
                            }
                          },
                          [_vm._v("Booking")]
                        )
                      ]),
                      _vm._v(" "),
                      _vm.location.special_conditions &&
                      _vm.query.reservation_type != "parking"
                        ? _c("li", { staticClass: "d-none d-lg-block" }, [
                            _c(
                              "a",
                              {
                                staticClass: "nav-link",
                                attrs: { href: "#special-conditions" },
                                on: {
                                  click: function($event) {
                                    $event.preventDefault()
                                    _vm.scrollTo("#special-conditions")
                                  }
                                }
                              },
                              [_vm._v("Special Conditions")]
                            )
                          ])
                        : _vm._e(),
                      _vm._v(" "),
                      _c("li", [
                        _c(
                          "a",
                          {
                            staticClass: "nav-link",
                            attrs: { href: "#details" },
                            on: {
                              click: function($event) {
                                $event.preventDefault()
                                _vm.scrollTo("#details")
                              }
                            }
                          },
                          [_vm._v("Details")]
                        )
                      ]),
                      _vm._v(" "),
                      _c("li", [
                        _c(
                          "a",
                          {
                            staticClass: "nav-link",
                            attrs: { href: "#amenities" },
                            on: {
                              click: function($event) {
                                $event.preventDefault()
                                _vm.scrollTo("#amenities")
                              }
                            }
                          },
                          [_vm._v("Amenities")]
                        )
                      ]),
                      _vm._v(" "),
                      _c("li", [
                        _c(
                          "a",
                          {
                            staticClass: "nav-link",
                            attrs: { href: "#location" },
                            on: {
                              click: function($event) {
                                $event.preventDefault()
                                _vm.scrollTo("#location")
                              }
                            }
                          },
                          [_vm._v("Location")]
                        )
                      ]),
                      _vm._v(" "),
                      _c("li", [
                        _c(
                          "a",
                          {
                            staticClass: "nav-link",
                            attrs: { href: "#reviews" },
                            on: {
                              click: function($event) {
                                $event.preventDefault()
                                _vm.scrollTo("#reviews")
                              }
                            }
                          },
                          [_vm._v("Reviews")]
                        )
                      ])
                    ])
                  ]
                ),
                _vm._v(" "),
                _c("div", {}, [
                  _c(
                    "h5",
                    {
                      staticClass: "regular margin-top-6x margin-bottom-3x",
                      attrs: { id: "overview" }
                    },
                    [_vm._v("\n              Overview\n            ")]
                  ),
                  _vm._v(" "),
                  _c(
                    "div",
                    { staticClass: "white-box d-flex flex-wrap flex-lg-wrap" },
                    [
                      _c(
                        "div",
                        {
                          staticClass:
                            "overview-photos padding-top-2x padding-left-2x padding-right-2x"
                        },
                        [
                          _vm.featuredPhoto
                            ? _c(
                                "a",
                                {
                                  attrs: {
                                    "data-lightbox": "gallery",
                                    href: _vm.featuredPhoto
                                  }
                                },
                                [
                                  _c("img", {
                                    staticClass: "w-100 margin-bottom-2x",
                                    attrs: { src: _vm.featuredPhoto, alt: "" }
                                  })
                                ]
                              )
                            : _c(
                                "a",
                                {
                                  attrs: {
                                    "data-lightbox": "gallery",
                                    href: "/assets/images/not-found.png"
                                  }
                                },
                                [
                                  _c("img", {
                                    staticClass: "w-100 margin-bottom-2x",
                                    attrs: {
                                      src: "/assets/images/not-found.png",
                                      alt: ""
                                    }
                                  })
                                ]
                              ),
                          _vm._v(" "),
                          _c(
                            "div",
                            { staticClass: "d-flex flex-wrap" },
                            _vm._l(_vm.otherPhotos, function(photo) {
                              return _c(
                                "a",
                                {
                                  attrs: {
                                    "data-lightbox": "gallery",
                                    href: photo.filename
                                  }
                                },
                                [
                                  _c("img", {
                                    staticClass: "thumbnail",
                                    attrs: { src: photo.filename, alt: "" }
                                  })
                                ]
                              )
                            })
                          )
                        ]
                      ),
                      _vm._v(" "),
                      _c("div", { staticClass: "overview-content" }, [
                        _vm.query.reservation_type != "parking"
                          ? _c("div", [
                              _c(
                                "div",
                                { staticClass: "f20 regular margin-bottom-2x" },
                                [
                                  _vm._v(
                                    "\n                    " +
                                      _vm._s(
                                        _vm.showTripadvisorReviews
                                          ? "TripAdvisor Traveler Rating"
                                          : "Overall Rating"
                                      ) +
                                      "\n                  "
                                  )
                                ]
                              ),
                              _vm._v(" "),
                              _vm.showTripadvisorReviews
                                ? _c("div", [
                                    _c(
                                      "div",
                                      [
                                        _c(
                                          "div",
                                          {
                                            staticClass:
                                              "d-flex margin-bottom-6x"
                                          },
                                          [
                                            _c("tripadvisor-rating", {
                                              attrs: {
                                                location_link:
                                                  _vm.location
                                                    .tripadvisor_reviews
                                                    .web_url,
                                                rating:
                                                  _vm.location
                                                    .tripadvisor_reviews.rating,
                                                reviews_count:
                                                  _vm.location
                                                    .tripadvisor_reviews
                                                    .num_reviews,
                                                show_icon: true
                                              }
                                            })
                                          ],
                                          1
                                        ),
                                        _vm._v(" "),
                                        _c(
                                          "div",
                                          {
                                            staticClass:
                                              "f20 regular margin-bottom-2x"
                                          },
                                          [
                                            _vm._v(
                                              "\n                        Hotel Review Summary\n                      "
                                            )
                                          ]
                                        ),
                                        _vm._v(" "),
                                        _vm._l(
                                          _vm.location.tripadvisor_reviews
                                            .subratings,
                                          function(review) {
                                            return _c(
                                              "div",
                                              {
                                                key: review.name,
                                                staticClass:
                                                  "d-flex margin-bottom-2x"
                                              },
                                              [
                                                _c(
                                                  "div",
                                                  {
                                                    staticClass:
                                                      "review-rating-title"
                                                  },
                                                  [
                                                    _vm._v(
                                                      _vm._s(
                                                        review.localized_name
                                                      )
                                                    )
                                                  ]
                                                ),
                                                _vm._v(" "),
                                                _c("tripadvisor-rating", {
                                                  attrs: {
                                                    rating: review.value,
                                                    show_icon: false
                                                  }
                                                })
                                              ],
                                              1
                                            )
                                          }
                                        )
                                      ],
                                      2
                                    )
                                  ])
                                : _c("div", [
                                    _c(
                                      "div",
                                      {
                                        staticClass: "d-flex margin-bottom-6x"
                                      },
                                      [
                                        _c(
                                          "span",
                                          { staticClass: "text-warning mr-2" },
                                          [_vm._v("Not rated yet")]
                                        )
                                      ]
                                    ),
                                    _vm._v(" "),
                                    _c(
                                      "div",
                                      {
                                        staticClass:
                                          "f20 regular margin-bottom-2x"
                                      },
                                      [
                                        _vm._v(
                                          "\n                      Hotel Review Summary\n                    "
                                        )
                                      ]
                                    ),
                                    _vm._v(" "),
                                    _c(
                                      "div",
                                      {
                                        staticClass: "d-flex margin-bottom-2x"
                                      },
                                      [
                                        _c(
                                          "div",
                                          {
                                            staticClass: "review-rating-title"
                                          },
                                          [_vm._v("Suite Service")]
                                        ),
                                        _vm._v(" "),
                                        _c(
                                          "span",
                                          { staticClass: "text-warning ml-2" },
                                          [_vm._v("Not rated yet")]
                                        )
                                      ]
                                    ),
                                    _vm._v(" "),
                                    _c(
                                      "div",
                                      {
                                        staticClass: "d-flex margin-bottom-2x"
                                      },
                                      [
                                        _c(
                                          "div",
                                          {
                                            staticClass: "review-rating-title"
                                          },
                                          [_vm._v("Room")]
                                        ),
                                        _vm._v(" "),
                                        _c(
                                          "span",
                                          { staticClass: "text-warning ml-2" },
                                          [_vm._v("Not rated yet")]
                                        )
                                      ]
                                    ),
                                    _vm._v(" "),
                                    _c(
                                      "div",
                                      {
                                        staticClass: "d-flex margin-bottom-2x"
                                      },
                                      [
                                        _c(
                                          "div",
                                          {
                                            staticClass: "review-rating-title"
                                          },
                                          [_vm._v("Hotel Staff")]
                                        ),
                                        _vm._v(" "),
                                        _c(
                                          "span",
                                          { staticClass: "text-warning ml-2" },
                                          [_vm._v("Not rated yet")]
                                        )
                                      ]
                                    ),
                                    _vm._v(" "),
                                    _c(
                                      "div",
                                      {
                                        staticClass: "d-flex margin-bottom-6x"
                                      },
                                      [
                                        _c(
                                          "div",
                                          {
                                            staticClass: "review-rating-title"
                                          },
                                          [_vm._v("Amenities")]
                                        ),
                                        _vm._v(" "),
                                        _c(
                                          "span",
                                          { staticClass: "text-warning ml-2" },
                                          [_vm._v("Not rated yet")]
                                        )
                                      ]
                                    )
                                  ])
                            ])
                          : _vm._e(),
                        _vm._v(" "),
                        _c(
                          "div",
                          { staticClass: "f20 regular margin-bottom-2x" },
                          [
                            _vm._v(
                              "\n                  Quick Info\n                "
                            )
                          ]
                        ),
                        _vm._v(" "),
                        _vm.location.shuttle_info_summary
                          ? _c("div", { staticClass: "margin-bottom-2x" }, [
                              _c("i", {
                                staticClass: "fa fa-bus",
                                attrs: { "aria-hidden": "true" }
                              }),
                              _vm._v(" "),
                              _c("span", {
                                domProps: {
                                  innerHTML: _vm._s(
                                    _vm.location.shuttle_info_summary
                                  )
                                }
                              })
                            ])
                          : _vm._e(),
                        _vm._v(" "),
                        _vm.hasFreeCancellation
                          ? _c("div", { staticClass: "margin-bottom-2x" }, [
                              _c("i", {
                                staticClass: "fa fa-check",
                                attrs: { "aria-hidden": "true" }
                              }),
                              _vm._v(
                                "\n                  Free Cancellation\n                "
                              )
                            ])
                          : _vm._e(),
                        _vm._v(" "),
                        _vm.hasSecurityAmenity
                          ? _c("div", { staticClass: "margin-bottom-2x" }, [
                              _c("i", {
                                staticClass: "fa fa-shield",
                                attrs: { "aria-hidden": "true" }
                              }),
                              _vm._v(
                                "\n                  Parking Security\n                "
                              )
                            ])
                          : _vm._e(),
                        _vm._v(" "),
                        _vm.location.special_conditions &&
                        _vm.query.reservation_type == "parking"
                          ? _c("div", [
                              _c(
                                "div",
                                { staticClass: "f20 regular margin-bottom-2x" },
                                [
                                  _vm._v(
                                    "\n                    Special Conditions\n                  "
                                  )
                                ]
                              ),
                              _vm._v(" "),
                              _c("div", {
                                domProps: {
                                  innerHTML: _vm._s(
                                    _vm.stripTags(
                                      _vm.location.special_conditions,
                                      ["br"]
                                    )
                                  )
                                }
                              })
                            ])
                          : _vm._e()
                      ])
                    ]
                  )
                ]),
                _vm._v(" "),
                _c("div", {}, [
                  _c(
                    "h5",
                    {
                      staticClass: "regular margin-top-6x margin-bottom-3x",
                      attrs: { id: "booking" }
                    },
                    [_vm._v("\n              Booking Options\n            ")]
                  ),
                  _vm._v(" "),
                  _c(
                    "div",
                    { staticClass: "white-box" },
                    [
                      _c("search-criteria", {
                        staticClass: "bg-grey padding-2x",
                        attrs: {
                          query: _vm.query,
                          "open-search-modal": _vm.openSearchModal
                        }
                      }),
                      _vm._v(" "),
                      _c("hr"),
                      _vm._v(" "),
                      _vm.query.reservation_type != "room"
                        ? _c("div", {}, [
                            _c("div", { staticClass: "row" }, [
                              _vm.query.reservation_type == "parking" ||
                              _vm.query.nights_to_sleep == "end"
                                ? _c(
                                    "div",
                                    { staticClass: "col-md-4 mb-md-0 mb-2" },
                                    [
                                      _c(
                                        "div",
                                        {
                                          staticClass:
                                            "form-row align-items-center"
                                        },
                                        [
                                          _c(
                                            "div",
                                            { staticClass: "col-md-auto col" },
                                            [
                                              _vm.shouldRefreshTooltip
                                                ? _c(
                                                    "span",
                                                    {
                                                      staticClass:
                                                        "text-info d-inline-block mr-1",
                                                      attrs: {
                                                        "data-placement": "top",
                                                        "data-toggle":
                                                          "tooltip",
                                                        title:
                                                          _vm.vehcileDropOffText
                                                      }
                                                    },
                                                    [
                                                      _c("i", {
                                                        staticClass:
                                                          "fa fa-info-circle",
                                                        attrs: {
                                                          "aria-hidden": "true"
                                                        }
                                                      })
                                                    ]
                                                  )
                                                : _vm._e(),
                                              _vm._v(
                                                "Vehicle Drop-Off Time\n                      "
                                              )
                                            ]
                                          ),
                                          _vm._v(" "),
                                          _c("div", { staticClass: "col" }, [
                                            _c(
                                              "select",
                                              {
                                                directives: [
                                                  {
                                                    name: "model",
                                                    rawName: "v-model",
                                                    value: _vm.checkin_time,
                                                    expression: "checkin_time"
                                                  }
                                                ],
                                                ref: "dropoffTime",
                                                staticClass: "form-control",
                                                on: {
                                                  change: [
                                                    function($event) {
                                                      var $$selectedVal = Array.prototype.filter
                                                        .call(
                                                          $event.target.options,
                                                          function(o) {
                                                            return o.selected
                                                          }
                                                        )
                                                        .map(function(o) {
                                                          var val =
                                                            "_value" in o
                                                              ? o._value
                                                              : o.value
                                                          return val
                                                        })
                                                      _vm.checkin_time = $event
                                                        .target.multiple
                                                        ? $$selectedVal
                                                        : $$selectedVal[0]
                                                    },
                                                    _vm.onChangeTimes
                                                  ]
                                                }
                                              },
                                              [
                                                _c(
                                                  "option",
                                                  { domProps: { value: null } },
                                                  [_vm._v("-- Select Time --")]
                                                ),
                                                _vm._v(" "),
                                                _vm._l(_vm.times, function(
                                                  time
                                                ) {
                                                  return _c(
                                                    "option",
                                                    {
                                                      domProps: {
                                                        value: time.value
                                                      }
                                                    },
                                                    [
                                                      _vm._v(
                                                        "\n                            " +
                                                          _vm._s(time.label) +
                                                          "\n                          "
                                                      )
                                                    ]
                                                  )
                                                })
                                              ],
                                              2
                                            )
                                          ])
                                        ]
                                      )
                                    ]
                                  )
                                : _vm._e(),
                              _vm._v(" "),
                              _vm.query.reservation_type == "parking" ||
                              _vm.query.nights_to_sleep == "beginning"
                                ? _c(
                                    "div",
                                    { staticClass: "col-md-4 mb-md-0 mb-2" },
                                    [
                                      _c(
                                        "div",
                                        {
                                          staticClass:
                                            "form-row align-items-center"
                                        },
                                        [
                                          _c(
                                            "div",
                                            { staticClass: "col-md-auto col" },
                                            [
                                              _vm.shouldRefreshTooltip
                                                ? _c(
                                                    "span",
                                                    {
                                                      staticClass:
                                                        "text-info d-inline-block mr-1",
                                                      attrs: {
                                                        "data-placement": "top",
                                                        "data-toggle":
                                                          "tooltip",
                                                        title:
                                                          _vm.vehcilePickUpText
                                                      }
                                                    },
                                                    [
                                                      _c("i", {
                                                        staticClass:
                                                          "fa fa-info-circle",
                                                        attrs: {
                                                          "aria-hidden": "true"
                                                        }
                                                      })
                                                    ]
                                                  )
                                                : _vm._e(),
                                              _vm._v(
                                                "\n                        Vehicle Pick-Up Time\n                      "
                                              )
                                            ]
                                          ),
                                          _vm._v(" "),
                                          _c("div", { staticClass: "col" }, [
                                            _c(
                                              "select",
                                              {
                                                directives: [
                                                  {
                                                    name: "model",
                                                    rawName: "v-model",
                                                    value: _vm.checkout_time,
                                                    expression: "checkout_time"
                                                  }
                                                ],
                                                ref: "pickupTime",
                                                staticClass: "form-control",
                                                on: {
                                                  change: [
                                                    function($event) {
                                                      var $$selectedVal = Array.prototype.filter
                                                        .call(
                                                          $event.target.options,
                                                          function(o) {
                                                            return o.selected
                                                          }
                                                        )
                                                        .map(function(o) {
                                                          var val =
                                                            "_value" in o
                                                              ? o._value
                                                              : o.value
                                                          return val
                                                        })
                                                      _vm.checkout_time = $event
                                                        .target.multiple
                                                        ? $$selectedVal
                                                        : $$selectedVal[0]
                                                    },
                                                    _vm.onChangeTimes
                                                  ]
                                                }
                                              },
                                              [
                                                _c(
                                                  "option",
                                                  { domProps: { value: null } },
                                                  [_vm._v("-- Select Time --")]
                                                ),
                                                _vm._v(" "),
                                                _vm._l(_vm.times, function(
                                                  time
                                                ) {
                                                  return _c(
                                                    "option",
                                                    {
                                                      domProps: {
                                                        value: time.value
                                                      }
                                                    },
                                                    [
                                                      _vm._v(
                                                        "\n                            " +
                                                          _vm._s(time.label) +
                                                          "\n                          "
                                                      )
                                                    ]
                                                  )
                                                })
                                              ],
                                              2
                                            )
                                          ])
                                        ]
                                      )
                                    ]
                                  )
                                : _vm._e(),
                              _vm._v(" "),
                              _c(
                                "div",
                                {
                                  staticClass: "mb-md-0 mb-2",
                                  class: [
                                    {
                                      "col-md-4":
                                        _vm.query.reservation_type == "parking"
                                    },
                                    {
                                      "col-md-3":
                                        _vm.query.reservation_type != "parking"
                                    }
                                  ]
                                },
                                [
                                  _c("number-of-cars", {
                                    staticClass: "search--number-of-cars",
                                    attrs: {
                                      "number-of-spots":
                                        _vm.query.number_of_spots
                                    },
                                    on: { change: _vm.changeNumberOfSpots }
                                  })
                                ],
                                1
                              )
                            ]),
                            _vm._v(" "),
                            _c("hr"),
                            _vm._v(" "),
                            _vm.showWarning && !_vm.typesLoading
                              ? _c(
                                  "div",
                                  { staticClass: "shuttle-warning text-left" },
                                  [
                                    _vm._v(
                                      "\n                  Booking falls outside of shuttle hours.\n                  "
                                    ),
                                    _c("hr")
                                  ]
                                )
                              : _vm._e()
                          ])
                        : _vm._e(),
                      _vm._v(" "),
                      _vm.query.reservation_type &&
                      _vm.query.from_date &&
                      _vm.query.to_date
                        ? _c(
                            "div",
                            {
                              staticClass:
                                " location-options-table booking-options--table"
                            },
                            [
                              _vm.hours_before_reservation_error
                                ? _c(
                                    "div",
                                    {
                                      staticClass: "alert alert-danger",
                                      attrs: { role: "alert" }
                                    },
                                    [
                                      _vm._v(
                                        "\n                      This location does not accept reservations that start within " +
                                          _vm._s(
                                            _vm.location
                                              .hours_before_reservation
                                          ) +
                                          " hours. Please search and choose from our other Ibrahem locations\n                "
                                      )
                                    ]
                                  )
                                : _vm._e(),
                              _vm._v(" "),
                              _vm.typesLoading
                                ? _c("div", { staticClass: "text-center" }, [
                                    _c("i", {
                                      staticClass:
                                        "fa fa-circle-o-notch fa-spin fa-3x fa-fw"
                                    })
                                  ])
                                : _c("div", [
                                    !_vm.hours_before_reservation_error
                                      ? _c(
                                          "table",
                                          {
                                            staticClass:
                                              "table table-bordered booking-options--table"
                                          },
                                          [
                                            _c("thead", [
                                              _c(
                                                "tr",
                                                {
                                                  staticClass:
                                                    "text-center regular"
                                                },
                                                [
                                                  _vm.query.reservation_type ==
                                                    "both" ||
                                                  _vm.query.reservation_type ==
                                                    "room"
                                                    ? _c("th", [
                                                        _vm._v("Room Type")
                                                      ])
                                                    : _vm._e(),
                                                  _vm._v(" "),
                                                  _vm.query.reservation_type ==
                                                    "both" ||
                                                  _vm.query.reservation_type ==
                                                    "parking"
                                                    ? _c("th", [
                                                        _vm._v("Parking Type")
                                                      ])
                                                    : _vm._e(),
                                                  _vm._v(" "),
                                                  _c("th", [
                                                    _vm._v("Includes")
                                                  ]),
                                                  _vm._v(" "),
                                                  _c("th", [
                                                    _vm._v(
                                                      "\n                          Total\n                          "
                                                    ),
                                                    _c(
                                                      "span",
                                                      {
                                                        staticClass:
                                                          "text-info d-inline-block",
                                                        attrs: {
                                                          "data-placement":
                                                            "right",
                                                          "data-toggle":
                                                            "tooltip",
                                                          title:
                                                            "Including all Taxes & Fees"
                                                        }
                                                      },
                                                      [
                                                        _c("i", {
                                                          staticClass:
                                                            "fa fa-info-circle",
                                                          attrs: {
                                                            "aria-hidden":
                                                              "true"
                                                          }
                                                        })
                                                      ]
                                                    )
                                                  ]),
                                                  _vm._v(" "),
                                                  _c("th")
                                                ]
                                              )
                                            ]),
                                            _vm._v(" "),
                                            _c(
                                              "tbody",
                                              [
                                                _vm._l(
                                                  _vm.sortedBundlesCombinations,
                                                  function(type) {
                                                    return _vm.query
                                                      .reservation_type ==
                                                      "both"
                                                      ? [
                                                          _vm.bundleTypes.includes(
                                                            type
                                                          ) &&
                                                          !_vm.combinationIsCheaper(
                                                            type
                                                          )
                                                            ? _c(
                                                                "tr",
                                                                {
                                                                  staticClass:
                                                                    "text-center"
                                                                },
                                                                [
                                                                  _c(
                                                                    "td",
                                                                    {
                                                                      staticClass:
                                                                        "types--font-size",
                                                                      attrs: {
                                                                        "data-th":
                                                                          "Room Type"
                                                                      }
                                                                    },
                                                                    [
                                                                      _c(
                                                                        "div",
                                                                        {
                                                                          attrs: {
                                                                            "data-toggle":
                                                                              "popover",
                                                                            "data-content": type.room_type
                                                                              ? type
                                                                                  .room_type
                                                                                  .description
                                                                              : "",
                                                                            "data-placement":
                                                                              _vm.placement
                                                                          }
                                                                        },
                                                                        [
                                                                          _vm._v(
                                                                            "\n                            " +
                                                                              _vm._s(
                                                                                type.room_type
                                                                                  ? type
                                                                                      .room_type
                                                                                      .name
                                                                                  : ""
                                                                              ) +
                                                                              " "
                                                                          ),
                                                                          type.room_type &&
                                                                          type
                                                                            .room_type
                                                                            .description
                                                                            ? _c(
                                                                                "i",
                                                                                {
                                                                                  staticClass:
                                                                                    "fa fa-info-circle clickable",
                                                                                  attrs: {
                                                                                    "aria-hidden":
                                                                                      "true"
                                                                                  }
                                                                                }
                                                                              )
                                                                            : _vm._e(),
                                                                          _vm._v(
                                                                            " "
                                                                          ),
                                                                          _c(
                                                                            "div",
                                                                            {
                                                                              staticClass:
                                                                                "line-height18"
                                                                            },
                                                                            _vm._l(
                                                                              type
                                                                                .room_type
                                                                                .beds,
                                                                              function(
                                                                                bed,
                                                                                i
                                                                              ) {
                                                                                return _c(
                                                                                  "small",
                                                                                  {
                                                                                    staticClass:
                                                                                      "f11 lighter"
                                                                                  },
                                                                                  [
                                                                                    _vm._v(
                                                                                      "\n                                " +
                                                                                        _vm._s(
                                                                                          bed.name
                                                                                        )
                                                                                    ),
                                                                                    i +
                                                                                      1 <
                                                                                    type
                                                                                      .room_type
                                                                                      .beds
                                                                                      .length
                                                                                      ? _c(
                                                                                          "span",
                                                                                          [
                                                                                            _vm._v(
                                                                                              ","
                                                                                            )
                                                                                          ]
                                                                                        )
                                                                                      : _vm._e()
                                                                                  ]
                                                                                )
                                                                              }
                                                                            )
                                                                          )
                                                                        ]
                                                                      )
                                                                    ]
                                                                  ),
                                                                  _vm._v(" "),
                                                                  _c(
                                                                    "td",
                                                                    {
                                                                      staticClass:
                                                                        "types--font-size",
                                                                      attrs: {
                                                                        "data-th":
                                                                          "Parking Types"
                                                                      }
                                                                    },
                                                                    [
                                                                      _vm._v(
                                                                        "\n                          " +
                                                                          _vm._s(
                                                                            type.parking_type
                                                                              ? type
                                                                                  .parking_type
                                                                                  .name
                                                                              : ""
                                                                          ) +
                                                                          "\n                        "
                                                                      )
                                                                    ]
                                                                  ),
                                                                  _vm._v(" "),
                                                                  _c(
                                                                    "td",
                                                                    {
                                                                      staticClass:
                                                                        "includes--font-size",
                                                                      attrs: {
                                                                        "data-th":
                                                                          "Includes"
                                                                      }
                                                                    },
                                                                    [
                                                                      type.error
                                                                        ? _c(
                                                                            "div",
                                                                            [
                                                                              _vm._v(
                                                                                "-"
                                                                              )
                                                                            ]
                                                                          )
                                                                        : _c(
                                                                            "div",
                                                                            [
                                                                              type.loading
                                                                                ? _c(
                                                                                    "span",
                                                                                    [
                                                                                      _vm._v(
                                                                                        "\n                              Fetching\n                            "
                                                                                      )
                                                                                    ]
                                                                                  )
                                                                                : _c(
                                                                                    "span",
                                                                                    [
                                                                                      _vm._v(
                                                                                        "\n                              " +
                                                                                          _vm._s(
                                                                                            type.number_of_room_days +
                                                                                              " Night" +
                                                                                              (type.number_of_room_days >
                                                                                                1 ||
                                                                                              type.number_of_room_days ==
                                                                                                0
                                                                                                ? "s"
                                                                                                : "")
                                                                                          ) +
                                                                                          " & " +
                                                                                          _vm._s(
                                                                                            type.number_of_parking_days +
                                                                                              " Day" +
                                                                                              (type.number_of_parking_days >
                                                                                                1 ||
                                                                                              type.number_of_parking_days ==
                                                                                                0
                                                                                                ? "s"
                                                                                                : "")
                                                                                          ) +
                                                                                          " of Parking\n                            "
                                                                                      )
                                                                                    ]
                                                                                  ),
                                                                              _vm._v(
                                                                                " "
                                                                              ),
                                                                              _c(
                                                                                "br"
                                                                              ),
                                                                              _vm._v(
                                                                                " "
                                                                              ),
                                                                              !type.loading &&
                                                                              _vm
                                                                                .query
                                                                                .number_of_rooms &&
                                                                              _vm
                                                                                .query
                                                                                .number_of_rooms >
                                                                                1
                                                                                ? _c(
                                                                                    "small",
                                                                                    {
                                                                                      staticClass:
                                                                                        "small-text--font-size"
                                                                                    },
                                                                                    [
                                                                                      _vm._v(
                                                                                        "\n                              For " +
                                                                                          _vm._s(
                                                                                            _vm
                                                                                              .query
                                                                                              .number_of_rooms
                                                                                          ) +
                                                                                          " rooms\n                            "
                                                                                      )
                                                                                    ]
                                                                                  )
                                                                                : _vm._e()
                                                                            ]
                                                                          )
                                                                    ]
                                                                  ),
                                                                  _vm._v(" "),
                                                                  _c(
                                                                    "td",
                                                                    {
                                                                      staticClass:
                                                                        "total--font-size",
                                                                      attrs: {
                                                                        "data-th":
                                                                          "Total"
                                                                      }
                                                                    },
                                                                    [
                                                                      type.error
                                                                        ? _c(
                                                                            "div",
                                                                            {
                                                                              staticClass:
                                                                                "text-danger"
                                                                            },
                                                                            [
                                                                              _vm._v(
                                                                                _vm._s(
                                                                                  type.error
                                                                                )
                                                                              )
                                                                            ]
                                                                          )
                                                                        : _c(
                                                                            "div",
                                                                            [
                                                                              type.loading
                                                                                ? _c(
                                                                                    "span",
                                                                                    [
                                                                                      _vm._v(
                                                                                        "\n                              Calculating...\n                            "
                                                                                      )
                                                                                    ]
                                                                                  )
                                                                                : _c(
                                                                                    "span",
                                                                                    [
                                                                                      !type.sold_out
                                                                                        ? _c(
                                                                                            "span",
                                                                                            {
                                                                                              staticClass:
                                                                                                "clickable-blue show-park",
                                                                                              on: {
                                                                                                click: function(
                                                                                                  $event
                                                                                                ) {
                                                                                                  $event.preventDefault()
                                                                                                  _vm.showSubtotalBreakDownModal(
                                                                                                    type
                                                                                                  )
                                                                                                }
                                                                                              }
                                                                                            },
                                                                                            [
                                                                                              _vm._v(
                                                                                                "\n                                " +
                                                                                                  _vm._s(
                                                                                                    _vm._f(
                                                                                                      "currency"
                                                                                                    )(
                                                                                                      type.price,
                                                                                                      _vm.symbol
                                                                                                    )
                                                                                                  ) +
                                                                                                  "\n                              "
                                                                                              )
                                                                                            ]
                                                                                          )
                                                                                        : _c(
                                                                                            "span",
                                                                                            [
                                                                                              _vm._v(
                                                                                                "\n                                " +
                                                                                                  _vm._s(
                                                                                                    _vm._f(
                                                                                                      "currency"
                                                                                                    )(
                                                                                                      type.price,
                                                                                                      _vm.symbol
                                                                                                    )
                                                                                                  ) +
                                                                                                  "\n                              "
                                                                                              )
                                                                                            ]
                                                                                          )
                                                                                    ]
                                                                                  ),
                                                                              _vm._v(
                                                                                " "
                                                                              ),
                                                                              _c(
                                                                                "br"
                                                                              ),
                                                                              _vm._v(
                                                                                " "
                                                                              ),
                                                                              !type.loading
                                                                                ? _c(
                                                                                    "small",
                                                                                    {
                                                                                      staticClass:
                                                                                        "small-text--font-size"
                                                                                    },
                                                                                    [
                                                                                      _vm._v(
                                                                                        "Incl. All Taxes & Fees"
                                                                                      )
                                                                                    ]
                                                                                  )
                                                                                : _vm._e()
                                                                            ]
                                                                          )
                                                                    ]
                                                                  ),
                                                                  _vm._v(" "),
                                                                  _c(
                                                                    "td",
                                                                    {
                                                                      on: {
                                                                        "!click": function(
                                                                          $event
                                                                        ) {
                                                                          _vm.validateBundle(
                                                                            $event
                                                                          )
                                                                        }
                                                                      }
                                                                    },
                                                                    [
                                                                      !type.sold_out &&
                                                                      !type.error
                                                                        ? _c(
                                                                            "router-link",
                                                                            {
                                                                              staticClass:
                                                                                "btn btn-teal btn-block f18",
                                                                              class: {
                                                                                disabled:
                                                                                  type.loading
                                                                              },
                                                                              attrs: {
                                                                                to: {
                                                                                  name:
                                                                                    "checkout",
                                                                                  query: _vm.checkoutQuery(
                                                                                    "bundle",
                                                                                    type
                                                                                  )
                                                                                },
                                                                                disabled:
                                                                                  type.loading
                                                                              },
                                                                              nativeOn: {
                                                                                click: function(
                                                                                  $event
                                                                                ) {
                                                                                  $event.preventDefault()
                                                                                  _vm.goToCheckout(
                                                                                    "bundle",
                                                                                    type,
                                                                                    {
                                                                                      name:
                                                                                        "checkout",
                                                                                      query: _vm.checkoutQuery(
                                                                                        "bundle",
                                                                                        type
                                                                                      )
                                                                                    }
                                                                                  )
                                                                                }
                                                                              }
                                                                            },
                                                                            [
                                                                              _vm._v(
                                                                                "\n                            Select\n                          "
                                                                              )
                                                                            ]
                                                                          )
                                                                        : type.error
                                                                        ? _c(
                                                                            "button",
                                                                            {
                                                                              staticClass:
                                                                                "btn btn-secondary btn-teal disabled btn-block f18"
                                                                            },
                                                                            [
                                                                              _vm._v(
                                                                                "Select"
                                                                              )
                                                                            ]
                                                                          )
                                                                        : _c(
                                                                            "button",
                                                                            {
                                                                              staticClass:
                                                                                "btn btn-secondary btn-teal disabled btn-block f18",
                                                                              attrs: {
                                                                                disabled:
                                                                                  ""
                                                                              }
                                                                            },
                                                                            [
                                                                              _vm._v(
                                                                                "Sold Out"
                                                                              )
                                                                            ]
                                                                          )
                                                                    ],
                                                                    1
                                                                  )
                                                                ]
                                                              )
                                                            : _vm.combinations.includes(
                                                                type
                                                              ) &&
                                                              !_vm.checkIfBundleExists(
                                                                type
                                                              ) &&
                                                              !_vm.bundleIsCheaper(
                                                                type
                                                              )
                                                            ? _c(
                                                                "tr",
                                                                {
                                                                  staticClass:
                                                                    "text-center"
                                                                },
                                                                [
                                                                  _c(
                                                                    "td",
                                                                    {
                                                                      staticClass:
                                                                        "types--font-size",
                                                                      attrs: {
                                                                        "data-th":
                                                                          "Room Type"
                                                                      }
                                                                    },
                                                                    [
                                                                      _c(
                                                                        "div",
                                                                        {
                                                                          attrs: {
                                                                            "data-toggle":
                                                                              "popover",
                                                                            "data-content": type.room_type
                                                                              ? type
                                                                                  .room_type
                                                                                  .description
                                                                              : "",
                                                                            "data-placement":
                                                                              _vm.placement
                                                                          }
                                                                        },
                                                                        [
                                                                          _vm._v(
                                                                            "\n                            " +
                                                                              _vm._s(
                                                                                type.room_type
                                                                                  ? type
                                                                                      .room_type
                                                                                      .name
                                                                                  : ""
                                                                              ) +
                                                                              " "
                                                                          ),
                                                                          type.room_type &&
                                                                          type
                                                                            .room_type
                                                                            .description
                                                                            ? _c(
                                                                                "i",
                                                                                {
                                                                                  staticClass:
                                                                                    "fa fa-info-circle clickable",
                                                                                  attrs: {
                                                                                    "aria-hidden":
                                                                                      "true"
                                                                                  }
                                                                                }
                                                                              )
                                                                            : _vm._e(),
                                                                          _vm._v(
                                                                            " "
                                                                          ),
                                                                          _c(
                                                                            "div",
                                                                            {
                                                                              staticClass:
                                                                                "line-height18"
                                                                            },
                                                                            _vm._l(
                                                                              type
                                                                                .room_type
                                                                                .beds,
                                                                              function(
                                                                                bed,
                                                                                i
                                                                              ) {
                                                                                return _c(
                                                                                  "small",
                                                                                  {
                                                                                    staticClass:
                                                                                      "f11 lighter"
                                                                                  },
                                                                                  [
                                                                                    _vm._v(
                                                                                      "\n                                " +
                                                                                        _vm._s(
                                                                                          bed.name
                                                                                        )
                                                                                    ),
                                                                                    i +
                                                                                      1 <
                                                                                    type
                                                                                      .room_type
                                                                                      .beds
                                                                                      .length
                                                                                      ? _c(
                                                                                          "span",
                                                                                          [
                                                                                            _vm._v(
                                                                                              ","
                                                                                            )
                                                                                          ]
                                                                                        )
                                                                                      : _vm._e()
                                                                                  ]
                                                                                )
                                                                              }
                                                                            )
                                                                          )
                                                                        ]
                                                                      )
                                                                    ]
                                                                  ),
                                                                  _vm._v(" "),
                                                                  _c(
                                                                    "td",
                                                                    {
                                                                      staticClass:
                                                                        "types--font-size",
                                                                      attrs: {
                                                                        "data-th":
                                                                          "Parking Types"
                                                                      }
                                                                    },
                                                                    [
                                                                      _vm._v(
                                                                        "\n                          " +
                                                                          _vm._s(
                                                                            type.parking_type
                                                                              ? type
                                                                                  .parking_type
                                                                                  .name
                                                                              : ""
                                                                          ) +
                                                                          "\n                        "
                                                                      )
                                                                    ]
                                                                  ),
                                                                  _vm._v(" "),
                                                                  _c(
                                                                    "td",
                                                                    {
                                                                      staticClass:
                                                                        "includes--font-size",
                                                                      attrs: {
                                                                        "data-th":
                                                                          "Includes"
                                                                      }
                                                                    },
                                                                    [
                                                                      type.error
                                                                        ? _c(
                                                                            "div",
                                                                            [
                                                                              _vm._v(
                                                                                "-"
                                                                              )
                                                                            ]
                                                                          )
                                                                        : _c(
                                                                            "div",
                                                                            [
                                                                              type.loading
                                                                                ? _c(
                                                                                    "span",
                                                                                    [
                                                                                      _vm._v(
                                                                                        "\n                              Fetching\n                            "
                                                                                      )
                                                                                    ]
                                                                                  )
                                                                                : _c(
                                                                                    "span",
                                                                                    [
                                                                                      _vm._v(
                                                                                        "\n                              " +
                                                                                          _vm._s(
                                                                                            type.number_of_room_days +
                                                                                              " Night" +
                                                                                              (type.number_of_room_days >
                                                                                                1 ||
                                                                                              type.number_of_room_days ==
                                                                                                0
                                                                                                ? "s"
                                                                                                : "")
                                                                                          ) +
                                                                                          " & " +
                                                                                          _vm._s(
                                                                                            type.number_of_parking_days +
                                                                                              " Day" +
                                                                                              (type.number_of_parking_days >
                                                                                                1 ||
                                                                                              type.number_of_parking_days ==
                                                                                                0
                                                                                                ? "s"
                                                                                                : "")
                                                                                          ) +
                                                                                          " of Parking\n                            "
                                                                                      )
                                                                                    ]
                                                                                  ),
                                                                              _vm._v(
                                                                                " "
                                                                              ),
                                                                              _c(
                                                                                "br"
                                                                              ),
                                                                              _vm._v(
                                                                                " "
                                                                              ),
                                                                              !type.loading &&
                                                                              _vm
                                                                                .query
                                                                                .number_of_rooms &&
                                                                              _vm
                                                                                .query
                                                                                .number_of_rooms >
                                                                                1
                                                                                ? _c(
                                                                                    "small",
                                                                                    {
                                                                                      staticClass:
                                                                                        "small-text--font-size"
                                                                                    },
                                                                                    [
                                                                                      _vm._v(
                                                                                        "\n                              For " +
                                                                                          _vm._s(
                                                                                            _vm
                                                                                              .query
                                                                                              .number_of_rooms
                                                                                          ) +
                                                                                          " rooms\n                            "
                                                                                      )
                                                                                    ]
                                                                                  )
                                                                                : _vm._e()
                                                                            ]
                                                                          )
                                                                    ]
                                                                  ),
                                                                  _vm._v(" "),
                                                                  _c(
                                                                    "td",
                                                                    {
                                                                      staticClass:
                                                                        "total--font-size",
                                                                      attrs: {
                                                                        "data-th":
                                                                          "Total"
                                                                      }
                                                                    },
                                                                    [
                                                                      type.error
                                                                        ? _c(
                                                                            "div",
                                                                            {
                                                                              staticClass:
                                                                                "text-danger"
                                                                            },
                                                                            [
                                                                              _vm._v(
                                                                                _vm._s(
                                                                                  type.error
                                                                                )
                                                                              )
                                                                            ]
                                                                          )
                                                                        : _c(
                                                                            "div",
                                                                            [
                                                                              type.loading
                                                                                ? _c(
                                                                                    "span",
                                                                                    [
                                                                                      _vm._v(
                                                                                        "\n                              Calculating...\n                            "
                                                                                      )
                                                                                    ]
                                                                                  )
                                                                                : _c(
                                                                                    "span",
                                                                                    [
                                                                                      !type.sold_out
                                                                                        ? _c(
                                                                                            "span",
                                                                                            {
                                                                                              staticClass:
                                                                                                "clickable-blue show-park",
                                                                                              on: {
                                                                                                click: function(
                                                                                                  $event
                                                                                                ) {
                                                                                                  $event.preventDefault()
                                                                                                  _vm.showSubtotalBreakDownModal(
                                                                                                    type
                                                                                                  )
                                                                                                }
                                                                                              }
                                                                                            },
                                                                                            [
                                                                                              _vm._v(
                                                                                                "\n                                " +
                                                                                                  _vm._s(
                                                                                                    _vm._f(
                                                                                                      "currency"
                                                                                                    )(
                                                                                                      type.price,
                                                                                                      _vm.symbol
                                                                                                    )
                                                                                                  ) +
                                                                                                  "\n                              "
                                                                                              )
                                                                                            ]
                                                                                          )
                                                                                        : _c(
                                                                                            "span",
                                                                                            [
                                                                                              _vm._v(
                                                                                                "\n                                " +
                                                                                                  _vm._s(
                                                                                                    _vm._f(
                                                                                                      "currency"
                                                                                                    )(
                                                                                                      type.price,
                                                                                                      _vm.symbol
                                                                                                    )
                                                                                                  ) +
                                                                                                  "\n                              "
                                                                                              )
                                                                                            ]
                                                                                          )
                                                                                    ]
                                                                                  ),
                                                                              _vm._v(
                                                                                " "
                                                                              ),
                                                                              _c(
                                                                                "br"
                                                                              ),
                                                                              _vm._v(
                                                                                " "
                                                                              ),
                                                                              !type.loading
                                                                                ? _c(
                                                                                    "small",
                                                                                    {
                                                                                      staticClass:
                                                                                        "small-text--font-size"
                                                                                    },
                                                                                    [
                                                                                      _vm._v(
                                                                                        "Incl. All Taxes & Fees"
                                                                                      )
                                                                                    ]
                                                                                  )
                                                                                : _vm._e()
                                                                            ]
                                                                          )
                                                                    ]
                                                                  ),
                                                                  _vm._v(" "),
                                                                  _c(
                                                                    "td",
                                                                    {
                                                                      on: {
                                                                        "!click": function(
                                                                          $event
                                                                        ) {
                                                                          _vm.validateBundle(
                                                                            $event
                                                                          )
                                                                        }
                                                                      }
                                                                    },
                                                                    [
                                                                      !type.sold_out &&
                                                                      !type.error
                                                                        ? _c(
                                                                            "router-link",
                                                                            {
                                                                              staticClass:
                                                                                "btn btn-teal btn-block f18",
                                                                              class: {
                                                                                disabled:
                                                                                  type.loading
                                                                              },
                                                                              attrs: {
                                                                                to: {
                                                                                  name:
                                                                                    "checkout",
                                                                                  query: _vm.checkoutQuery(
                                                                                    "combination",
                                                                                    type
                                                                                  )
                                                                                },
                                                                                disabled:
                                                                                  type.loading
                                                                              },
                                                                              nativeOn: {
                                                                                click: function(
                                                                                  $event
                                                                                ) {
                                                                                  $event.preventDefault()
                                                                                  _vm.goToCheckout(
                                                                                    "combination",
                                                                                    type,
                                                                                    {
                                                                                      name:
                                                                                        "checkout",
                                                                                      query: _vm.checkoutQuery(
                                                                                        "combination",
                                                                                        type
                                                                                      )
                                                                                    }
                                                                                  )
                                                                                }
                                                                              }
                                                                            },
                                                                            [
                                                                              _vm._v(
                                                                                "\n                            Select\n                          "
                                                                              )
                                                                            ]
                                                                          )
                                                                        : type.error
                                                                        ? _c(
                                                                            "button",
                                                                            {
                                                                              staticClass:
                                                                                "btn btn-secondary btn-teal disabled btn-block f18",
                                                                              attrs: {
                                                                                disabled:
                                                                                  ""
                                                                              }
                                                                            },
                                                                            [
                                                                              _vm._v(
                                                                                "Select"
                                                                              )
                                                                            ]
                                                                          )
                                                                        : _c(
                                                                            "button",
                                                                            {
                                                                              staticClass:
                                                                                "btn btn-secondary btn-teal disabled btn-block f18",
                                                                              attrs: {
                                                                                disabled:
                                                                                  ""
                                                                              }
                                                                            },
                                                                            [
                                                                              _vm._v(
                                                                                "Sold Out"
                                                                              )
                                                                            ]
                                                                          )
                                                                    ],
                                                                    1
                                                                  )
                                                                ]
                                                              )
                                                            : _vm._e()
                                                        ]
                                                      : _vm._e()
                                                  }
                                                ),
                                                _vm._v(" "),
                                                _vm._l(
                                                  _vm.sortedParkingTypes,
                                                  function(type, index) {
                                                    return _vm.query
                                                      .reservation_type ==
                                                      "parking"
                                                      ? _c(
                                                          "tr",
                                                          {
                                                            staticClass:
                                                              "text-center"
                                                          },
                                                          [
                                                            _c(
                                                              "td",
                                                              {
                                                                staticClass:
                                                                  "types--font-size",
                                                                attrs: {
                                                                  "data-th":
                                                                    "Parking Type"
                                                                }
                                                              },
                                                              [
                                                                _vm._v(
                                                                  "\n                        " +
                                                                    _vm._s(
                                                                      type.name
                                                                    ) +
                                                                    "\n                      "
                                                                )
                                                              ]
                                                            ),
                                                            _vm._v(" "),
                                                            _c(
                                                              "td",
                                                              {
                                                                staticClass:
                                                                  "includes--font-size",
                                                                attrs: {
                                                                  "data-th":
                                                                    "Includes"
                                                                }
                                                              },
                                                              [
                                                                type.error
                                                                  ? _c("div", [
                                                                      _vm._v(
                                                                        "-"
                                                                      )
                                                                    ])
                                                                  : _c("div", [
                                                                      type.number_of_parking_days
                                                                        ? _c(
                                                                            "span",
                                                                            [
                                                                              _vm._v(
                                                                                "\n                            " +
                                                                                  _vm._s(
                                                                                    type.number_of_parking_days +
                                                                                      " Day" +
                                                                                      (type.number_of_parking_days >
                                                                                        1 ||
                                                                                      type.number_of_parking_days ==
                                                                                        0
                                                                                        ? "s"
                                                                                        : "")
                                                                                  ) +
                                                                                  "\n                            of Parking\n                          "
                                                                              )
                                                                            ]
                                                                          )
                                                                        : _c(
                                                                            "span",
                                                                            [
                                                                              _vm._v(
                                                                                "\n                            Fetching...\n                          "
                                                                              )
                                                                            ]
                                                                          )
                                                                    ])
                                                              ]
                                                            ),
                                                            _vm._v(" "),
                                                            _c(
                                                              "td",
                                                              {
                                                                staticClass:
                                                                  "total--font-size",
                                                                attrs: {
                                                                  "data-th":
                                                                    "Total"
                                                                }
                                                              },
                                                              [
                                                                type.error
                                                                  ? _c(
                                                                      "div",
                                                                      {
                                                                        staticClass:
                                                                          "text-danger"
                                                                      },
                                                                      [
                                                                        _vm._v(
                                                                          _vm._s(
                                                                            type.error
                                                                          )
                                                                        )
                                                                      ]
                                                                    )
                                                                  : _c("div", [
                                                                      type.loading
                                                                        ? _c(
                                                                            "span",
                                                                            [
                                                                              _vm._v(
                                                                                "\n                            Calculating...\n                          "
                                                                              )
                                                                            ]
                                                                          )
                                                                        : _c(
                                                                            "span",
                                                                            [
                                                                              !type.sold_out
                                                                                ? _c(
                                                                                    "span",
                                                                                    {
                                                                                      staticClass:
                                                                                        "clickable-blue show-park",
                                                                                      on: {
                                                                                        click: function(
                                                                                          $event
                                                                                        ) {
                                                                                          $event.preventDefault()
                                                                                          _vm.showSubtotalBreakDownModal(
                                                                                            type
                                                                                          )
                                                                                        }
                                                                                      }
                                                                                    },
                                                                                    [
                                                                                      _vm._v(
                                                                                        "\n                              " +
                                                                                          _vm._s(
                                                                                            _vm._f(
                                                                                              "currency"
                                                                                            )(
                                                                                              type.price,
                                                                                              _vm.symbol
                                                                                            )
                                                                                          ) +
                                                                                          "\n                            "
                                                                                      )
                                                                                    ]
                                                                                  )
                                                                                : _c(
                                                                                    "span",
                                                                                    [
                                                                                      _vm._v(
                                                                                        "\n                              " +
                                                                                          _vm._s(
                                                                                            _vm._f(
                                                                                              "currency"
                                                                                            )(
                                                                                              type.price,
                                                                                              _vm.symbol
                                                                                            )
                                                                                          ) +
                                                                                          "\n                            "
                                                                                      )
                                                                                    ]
                                                                                  )
                                                                            ]
                                                                          ),
                                                                      _vm._v(
                                                                        " "
                                                                      ),
                                                                      _c("br"),
                                                                      _vm._v(
                                                                        " "
                                                                      ),
                                                                      !type.loading
                                                                        ? _c(
                                                                            "small",
                                                                            {
                                                                              staticClass:
                                                                                "small-text--font-size"
                                                                            },
                                                                            [
                                                                              _vm._v(
                                                                                "Incl. All Taxes & Fees"
                                                                              )
                                                                            ]
                                                                          )
                                                                        : _vm._e()
                                                                    ])
                                                              ]
                                                            ),
                                                            _vm._v(" "),
                                                            _c(
                                                              "td",
                                                              {
                                                                on: {
                                                                  "!click": function(
                                                                    $event
                                                                  ) {
                                                                    _vm.validateParking(
                                                                      $event
                                                                    )
                                                                  }
                                                                }
                                                              },
                                                              [
                                                                !type.sold_out &&
                                                                !type.error
                                                                  ? _c(
                                                                      "router-link",
                                                                      {
                                                                        staticClass:
                                                                          "btn btn-teal btn-block f18",
                                                                        class: {
                                                                          disabled:
                                                                            type.loading
                                                                        },
                                                                        attrs: {
                                                                          to: {
                                                                            name:
                                                                              "checkout",
                                                                            query: _vm.checkoutQuery(
                                                                              "parking",
                                                                              type
                                                                            )
                                                                          },
                                                                          disabled:
                                                                            type.loading
                                                                        },
                                                                        nativeOn: {
                                                                          click: function(
                                                                            $event
                                                                          ) {
                                                                            $event.preventDefault()
                                                                            _vm.goToCheckout(
                                                                              "parking",
                                                                              type,
                                                                              {
                                                                                name:
                                                                                  "checkout",
                                                                                query: _vm.checkoutQuery(
                                                                                  "parking",
                                                                                  type
                                                                                )
                                                                              }
                                                                            )
                                                                          }
                                                                        }
                                                                      },
                                                                      [
                                                                        _vm._v(
                                                                          "\n                          Select\n                        "
                                                                        )
                                                                      ]
                                                                    )
                                                                  : type.error
                                                                  ? _c(
                                                                      "button",
                                                                      {
                                                                        staticClass:
                                                                          "btn btn-secondary btn-teal disabled btn-block f18",
                                                                        attrs: {
                                                                          disabled:
                                                                            ""
                                                                        }
                                                                      },
                                                                      [
                                                                        _vm._v(
                                                                          "Select"
                                                                        )
                                                                      ]
                                                                    )
                                                                  : _c(
                                                                      "button",
                                                                      {
                                                                        staticClass:
                                                                          "btn btn-secondary btn-teal disabled btn-block f18",
                                                                        attrs: {
                                                                          disabled:
                                                                            ""
                                                                        }
                                                                      },
                                                                      [
                                                                        _vm._v(
                                                                          "Sold Out"
                                                                        )
                                                                      ]
                                                                    )
                                                              ],
                                                              1
                                                            )
                                                          ]
                                                        )
                                                      : _vm._e()
                                                  }
                                                ),
                                                _vm._v(" "),
                                                _vm._l(
                                                  _vm.sortedRoomTypes,
                                                  function(type) {
                                                    return _vm.query
                                                      .reservation_type ==
                                                      "room"
                                                      ? _c(
                                                          "tr",
                                                          {
                                                            staticClass:
                                                              "text-center"
                                                          },
                                                          [
                                                            _c(
                                                              "td",
                                                              {
                                                                staticClass:
                                                                  "types--font-size",
                                                                attrs: {
                                                                  "data-th":
                                                                    "Room Type"
                                                                }
                                                              },
                                                              [
                                                                _c(
                                                                  "div",
                                                                  {
                                                                    attrs: {
                                                                      "data-toggle":
                                                                        "popover",
                                                                      "data-content": type.description
                                                                        ? type.description
                                                                        : "",
                                                                      "data-placement":
                                                                        _vm.placement
                                                                    }
                                                                  },
                                                                  [
                                                                    _vm._v(
                                                                      "\n                          " +
                                                                        _vm._s(
                                                                          type.name
                                                                        ) +
                                                                        " "
                                                                    ),
                                                                    type.description
                                                                      ? _c(
                                                                          "i",
                                                                          {
                                                                            staticClass:
                                                                              "fa fa-info-circle clickable",
                                                                            attrs: {
                                                                              "aria-hidden":
                                                                                "true"
                                                                            }
                                                                          }
                                                                        )
                                                                      : _vm._e(),
                                                                    _vm._v(" "),
                                                                    _c(
                                                                      "div",
                                                                      {
                                                                        staticClass:
                                                                          "line-height18"
                                                                      },
                                                                      _vm._l(
                                                                        type.beds,
                                                                        function(
                                                                          bed,
                                                                          i
                                                                        ) {
                                                                          return _c(
                                                                            "small",
                                                                            {
                                                                              staticClass:
                                                                                "f11 lighter"
                                                                            },
                                                                            [
                                                                              _vm._v(
                                                                                "\n                              " +
                                                                                  _vm._s(
                                                                                    bed.name
                                                                                  )
                                                                              ),
                                                                              i +
                                                                                1 <
                                                                              type
                                                                                .beds
                                                                                .length
                                                                                ? _c(
                                                                                    "span",
                                                                                    [
                                                                                      _vm._v(
                                                                                        ","
                                                                                      )
                                                                                    ]
                                                                                  )
                                                                                : _vm._e()
                                                                            ]
                                                                          )
                                                                        }
                                                                      )
                                                                    )
                                                                  ]
                                                                )
                                                              ]
                                                            ),
                                                            _vm._v(" "),
                                                            _c(
                                                              "td",
                                                              {
                                                                staticClass:
                                                                  "includes--font-size",
                                                                attrs: {
                                                                  "data-th":
                                                                    "Includes"
                                                                }
                                                              },
                                                              [
                                                                type.error
                                                                  ? _c("div", [
                                                                      _vm._v(
                                                                        "-"
                                                                      )
                                                                    ])
                                                                  : _c("div", [
                                                                      type.number_of_room_days
                                                                        ? _c(
                                                                            "span",
                                                                            [
                                                                              _vm._v(
                                                                                "\n                            " +
                                                                                  _vm._s(
                                                                                    type.number_of_room_days +
                                                                                      " Night" +
                                                                                      (type.number_of_room_days >
                                                                                        1 ||
                                                                                      type.number_of_room_days ==
                                                                                        0
                                                                                        ? "s"
                                                                                        : "")
                                                                                  ) +
                                                                                  "\n                          "
                                                                              )
                                                                            ]
                                                                          )
                                                                        : _c(
                                                                            "span",
                                                                            [
                                                                              _vm._v(
                                                                                "\n                            Fetching...\n                          "
                                                                              )
                                                                            ]
                                                                          ),
                                                                      _vm._v(
                                                                        " "
                                                                      ),
                                                                      _c("br"),
                                                                      _vm._v(
                                                                        " "
                                                                      ),
                                                                      !type.loading &&
                                                                      _vm.query
                                                                        .number_of_rooms &&
                                                                      _vm.query
                                                                        .number_of_rooms >
                                                                        1
                                                                        ? _c(
                                                                            "small",
                                                                            {
                                                                              staticClass:
                                                                                "small-text--font-size"
                                                                            },
                                                                            [
                                                                              _vm._v(
                                                                                "\n                            For " +
                                                                                  _vm._s(
                                                                                    _vm
                                                                                      .query
                                                                                      .number_of_rooms
                                                                                  ) +
                                                                                  " rooms\n                          "
                                                                              )
                                                                            ]
                                                                          )
                                                                        : _vm._e()
                                                                    ])
                                                              ]
                                                            ),
                                                            _vm._v(" "),
                                                            _c(
                                                              "td",
                                                              {
                                                                staticClass:
                                                                  "total--font-size",
                                                                attrs: {
                                                                  "data-th":
                                                                    "Total"
                                                                }
                                                              },
                                                              [
                                                                type.error
                                                                  ? _c(
                                                                      "div",
                                                                      {
                                                                        staticClass:
                                                                          "text-danger"
                                                                      },
                                                                      [
                                                                        _vm._v(
                                                                          _vm._s(
                                                                            type.error
                                                                          )
                                                                        )
                                                                      ]
                                                                    )
                                                                  : _c("div", [
                                                                      type.loading
                                                                        ? _c(
                                                                            "span",
                                                                            [
                                                                              _vm._v(
                                                                                "\n                            Calculating...\n                          "
                                                                              )
                                                                            ]
                                                                          )
                                                                        : _c(
                                                                            "span",
                                                                            [
                                                                              !type.sold_out
                                                                                ? _c(
                                                                                    "span",
                                                                                    {
                                                                                      staticClass:
                                                                                        "clickable-blue show-park",
                                                                                      on: {
                                                                                        click: function(
                                                                                          $event
                                                                                        ) {
                                                                                          $event.preventDefault()
                                                                                          _vm.showSubtotalBreakDownModal(
                                                                                            type
                                                                                          )
                                                                                        }
                                                                                      }
                                                                                    },
                                                                                    [
                                                                                      _vm._v(
                                                                                        "\n                              " +
                                                                                          _vm._s(
                                                                                            _vm._f(
                                                                                              "currency"
                                                                                            )(
                                                                                              type.price,
                                                                                              _vm.symbol
                                                                                            )
                                                                                          ) +
                                                                                          "\n                            "
                                                                                      )
                                                                                    ]
                                                                                  )
                                                                                : _c(
                                                                                    "span",
                                                                                    [
                                                                                      _vm._v(
                                                                                        "\n                              " +
                                                                                          _vm._s(
                                                                                            _vm._f(
                                                                                              "currency"
                                                                                            )(
                                                                                              type.price,
                                                                                              _vm.symbol
                                                                                            )
                                                                                          ) +
                                                                                          "\n                            "
                                                                                      )
                                                                                    ]
                                                                                  )
                                                                            ]
                                                                          ),
                                                                      _vm._v(
                                                                        " "
                                                                      ),
                                                                      _c("br"),
                                                                      _vm._v(
                                                                        " "
                                                                      ),
                                                                      !type.loading
                                                                        ? _c(
                                                                            "small",
                                                                            {
                                                                              staticClass:
                                                                                "small-text--font-size"
                                                                            },
                                                                            [
                                                                              _vm._v(
                                                                                "Incl. All Taxes & Fees"
                                                                              )
                                                                            ]
                                                                          )
                                                                        : _vm._e()
                                                                    ])
                                                              ]
                                                            ),
                                                            _vm._v(" "),
                                                            _c(
                                                              "td",
                                                              {
                                                                on: {
                                                                  "!click": function(
                                                                    $event
                                                                  ) {
                                                                    _vm.validateRoom(
                                                                      $event
                                                                    )
                                                                  }
                                                                }
                                                              },
                                                              [
                                                                !type.sold_out &&
                                                                !type.error
                                                                  ? _c(
                                                                      "router-link",
                                                                      {
                                                                        staticClass:
                                                                          "btn btn-teal btn-block f18",
                                                                        class: {
                                                                          disabled:
                                                                            type.loading
                                                                        },
                                                                        attrs: {
                                                                          to: {
                                                                            name:
                                                                              "checkout",
                                                                            query: _vm.checkoutQuery(
                                                                              "room",
                                                                              type
                                                                            )
                                                                          },
                                                                          disabled:
                                                                            type.loading
                                                                        },
                                                                        nativeOn: {
                                                                          click: function(
                                                                            $event
                                                                          ) {
                                                                            $event.preventDefault()
                                                                            _vm.goToCheckout(
                                                                              "room",
                                                                              type,
                                                                              {
                                                                                name:
                                                                                  "checkout",
                                                                                query: _vm.checkoutQuery(
                                                                                  "room",
                                                                                  type
                                                                                )
                                                                              }
                                                                            )
                                                                          }
                                                                        }
                                                                      },
                                                                      [
                                                                        _vm._v(
                                                                          "\n                          Select\n                        "
                                                                        )
                                                                      ]
                                                                    )
                                                                  : type.error
                                                                  ? _c(
                                                                      "button",
                                                                      {
                                                                        staticClass:
                                                                          "btn btn-secondary btn-teal disabled btn-block f18",
                                                                        attrs: {
                                                                          disabled:
                                                                            ""
                                                                        }
                                                                      },
                                                                      [
                                                                        _vm._v(
                                                                          "Select"
                                                                        )
                                                                      ]
                                                                    )
                                                                  : _c(
                                                                      "button",
                                                                      {
                                                                        staticClass:
                                                                          "btn btn-secondary btn-teal disabled btn-block f18",
                                                                        attrs: {
                                                                          disabled:
                                                                            ""
                                                                        }
                                                                      },
                                                                      [
                                                                        _vm._v(
                                                                          "Sold Out"
                                                                        )
                                                                      ]
                                                                    )
                                                              ],
                                                              1
                                                            )
                                                          ]
                                                        )
                                                      : _vm._e()
                                                  }
                                                )
                                              ],
                                              2
                                            )
                                          ]
                                        )
                                      : _vm._e()
                                  ])
                            ]
                          )
                        : _c(
                            "div",
                            { staticClass: "text-warning text-center" },
                            [
                              _vm._v(
                                "\n                Please search dates for availability\n              "
                              )
                            ]
                          )
                    ],
                    1
                  )
                ]),
                _vm._v(" "),
                _vm.location.special_conditions &&
                _vm.query.reservation_type != "parking"
                  ? _c("div", {}, [
                      _c(
                        "h5",
                        {
                          staticClass: "regular margin-top-6x margin-bottom-3x",
                          attrs: { id: "special-conditions" }
                        },
                        [
                          _vm._v(
                            "\n              Special Conditions\n            "
                          )
                        ]
                      ),
                      _vm._v(" "),
                      _c("div", { staticClass: "white-box" }, [
                        _c("div", {}, [
                          _c("p", {
                            staticClass: "has-normal-list",
                            domProps: {
                              innerHTML: _vm._s(_vm.location.special_conditions)
                            }
                          })
                        ])
                      ])
                    ])
                  : _vm._e(),
                _vm._v(" "),
                _c("div", {}, [
                  _c(
                    "h5",
                    {
                      staticClass: "regular margin-top-6x margin-bottom-3x",
                      attrs: { id: "details" }
                    },
                    [_vm._v("\n              Details\n            ")]
                  ),
                  _vm._v(" "),
                  _c(
                    "div",
                    { staticClass: "white-box" },
                    [
                      _c("p", {
                        staticClass: "has-normal-list",
                        domProps: {
                          innerHTML: _vm._s(_vm.location.description)
                        }
                      }),
                      _vm._v(" "),
                      _vm.location.shuttle_info_details
                        ? _c("div", {}, [
                            _c(
                              "div",
                              {
                                staticClass:
                                  "f18 regular margin-top-6x margin-bottom-2x"
                              },
                              [
                                _vm._v(
                                  "\n                  Shuttle Information\n                "
                                )
                              ]
                            ),
                            _vm._v(" "),
                            _c("p", {
                              staticClass: "has-normal-list",
                              domProps: {
                                innerHTML: _vm._s(
                                  _vm.location.shuttle_info_details
                                )
                              }
                            })
                          ])
                        : _vm._e(),
                      _vm._v(" "),
                      _c(
                        "div",
                        {
                          staticClass:
                            "f18 regular margin-top-6x margin-bottom-2x"
                        },
                        [
                          _vm._v(
                            "\n                Cancellation Policy\n              "
                          )
                        ]
                      ),
                      _vm._v(" "),
                      _vm.query.reservation_type && _vm.query.from_date
                        ? _c("cancellation-policy", {
                            attrs: {
                              "cancellation-policies":
                                _vm.location.cancellation_policies,
                              "reservation-type": _vm.query.reservation_type,
                              "reservation-from-date": _vm
                                .moment(_vm.query.from_date, ["DD/MMM/YYYY"])
                                .format("YYYY-MM-DD HH:mm:ss")
                            }
                          })
                        : _c("div", [
                            _vm._v(
                              "\n                Please search a reservation type to check cancellation policy \n              "
                            )
                          ]),
                      _vm._v(" "),
                      _vm.location.checkin_time &&
                      _vm.location.checkout_time &&
                      _vm.query.reservation_type != "parking"
                        ? _c("div", {}, [
                            _c(
                              "div",
                              {
                                staticClass:
                                  "f18 regular margin-top-6x margin-bottom-2x"
                              },
                              [
                                _vm._v(
                                  "\n                  Check-In/Out Time\n                "
                                )
                              ]
                            ),
                            _vm._v(" "),
                            _c("p", [
                              _vm._v(
                                "\n                  " +
                                  _vm._s(
                                    _vm
                                      .moment(_vm.location.checkin_time, [
                                        "HH:mm"
                                      ])
                                      .format("hh:mm A")
                                  ) +
                                  " /\n                  " +
                                  _vm._s(
                                    _vm
                                      .moment(_vm.location.checkout_time, [
                                        "HH:mm"
                                      ])
                                      .format("hh:mm A")
                                  ) +
                                  "\n                "
                              )
                            ])
                          ])
                        : _vm._e(),
                      _vm._v(" "),
                      _vm.location.front_desk_hours
                        ? _c("div", {}, [
                            _c(
                              "div",
                              {
                                staticClass:
                                  "f18 regular margin-top-6x margin-bottom-2x"
                              },
                              [
                                _vm._v(
                                  "\n                  Front Desk Hours\n                "
                                )
                              ]
                            ),
                            _vm._v(" "),
                            _c("p", [
                              _vm._v(
                                "\n                  " +
                                  _vm._s(_vm.location.front_desk_hours) +
                                  "\n                "
                              )
                            ])
                          ])
                        : _vm._e(),
                      _vm._v(" "),
                      _vm.location.directions
                        ? _c("div", {}, [
                            _c(
                              "div",
                              {
                                staticClass:
                                  "f18 regular margin-top-6x margin-bottom-2x"
                              },
                              [
                                _vm._v(
                                  "\n                  Directions\n                "
                                )
                              ]
                            ),
                            _vm._v(" "),
                            _c("p", {
                              staticClass: "has-normal-list",
                              domProps: {
                                innerHTML: _vm._s(_vm.location.directions)
                              }
                            })
                          ])
                        : _vm._e()
                    ],
                    1
                  )
                ]),
                _vm._v(" "),
                _c("div", {}, [
                  _c(
                    "h5",
                    {
                      staticClass: "regular margin-top-6x margin-bottom-3x",
                      attrs: { id: "amenities" }
                    },
                    [_vm._v("\n              Amenities\n            ")]
                  ),
                  _vm._v(" "),
                  _c("div", { staticClass: "white-box" }, [
                    _c(
                      "ul",
                      { staticClass: "d-flex flex-wrap" },
                      _vm._l(_vm.location.amenities, function(amenity) {
                        return _vm.query.reservation_type == amenity.type ||
                          amenity.type == "facility" ||
                          _vm.query.reservation_type == "both" ||
                          !_vm.query.reservation_type
                          ? _c(
                              "li",
                              {
                                staticClass:
                                  "d-flex align-items-center location-amenity"
                              },
                              [
                                _c("image-bg", {
                                  staticClass:
                                    "location--amenity-icon margin-2x",
                                  attrs: { src: amenity.icon }
                                }),
                                _vm._v(" "),
                                _c("div", {}, [
                                  _vm._v(
                                    "\n                    " +
                                      _vm._s(amenity.display_name) +
                                      "\n                  "
                                  )
                                ])
                              ],
                              1
                            )
                          : _vm._e()
                      })
                    ),
                    _vm._v(" "),
                    _vm.location.facility_custom_amenities &&
                    _vm.location.facility_custom_amenities.length
                      ? _c("div", {}, [
                          _c(
                            "div",
                            {
                              staticClass:
                                "margin-right-2x margin-left-2x margin-top-4x margin-bottom-2x regular f18"
                            },
                            [
                              _vm._v(
                                "\n                  Facility Amenities\n                "
                              )
                            ]
                          ),
                          _vm._v(" "),
                          _c(
                            "ul",
                            { staticClass: "d-flex flex-wrap" },
                            _vm._l(
                              _vm.location.facility_custom_amenities,
                              function(amenity) {
                                return _c(
                                  "li",
                                  {
                                    staticClass:
                                      "d-flex align-items-center location-amenity"
                                  },
                                  [
                                    _c("i", {
                                      staticClass:
                                        "fa fa-check green margin-2x",
                                      attrs: { "aria-hidden": "true" }
                                    }),
                                    _vm._v(" "),
                                    _c("div", {}, [
                                      _vm._v(
                                        "\n                      " +
                                          _vm._s(amenity) +
                                          "\n                    "
                                      )
                                    ])
                                  ]
                                )
                              }
                            )
                          )
                        ])
                      : _vm._e(),
                    _vm._v(" "),
                    _vm.location.room_custom_amenities &&
                    _vm.location.room_custom_amenities.length &&
                    _vm.query.reservation_type != "parking"
                      ? _c("div", {}, [
                          _c(
                            "div",
                            {
                              staticClass:
                                "margin-right-2x margin-left-2x margin-top-4x margin-bottom-2x regular f18"
                            },
                            [
                              _vm._v(
                                "\n                  Room Amenities\n                "
                              )
                            ]
                          ),
                          _vm._v(" "),
                          _c(
                            "ul",
                            { staticClass: "d-flex flex-wrap" },
                            _vm._l(_vm.location.room_custom_amenities, function(
                              amenity
                            ) {
                              return _c(
                                "li",
                                {
                                  staticClass:
                                    "d-flex align-items-center location-amenity"
                                },
                                [
                                  _c("i", {
                                    staticClass: "fa fa-check green margin-2x",
                                    attrs: { "aria-hidden": "true" }
                                  }),
                                  _vm._v(" "),
                                  _c("div", {}, [
                                    _vm._v(
                                      "\n                      " +
                                        _vm._s(amenity) +
                                        "\n                    "
                                    )
                                  ])
                                ]
                              )
                            })
                          )
                        ])
                      : _vm._e(),
                    _vm._v(" "),
                    _vm.location.parking_custom_amenities &&
                    _vm.location.parking_custom_amenities.length &&
                    _vm.query.reservation_type != "room"
                      ? _c("div", {}, [
                          _c(
                            "div",
                            {
                              staticClass:
                                "margin-right-2x margin-left-2x margin-top-4x margin-bottom-2x regular f18"
                            },
                            [
                              _vm._v(
                                "\n                  Parking Amenities\n                "
                              )
                            ]
                          ),
                          _vm._v(" "),
                          _c(
                            "ul",
                            { staticClass: "d-flex flex-wrap" },
                            _vm._l(
                              _vm.location.parking_custom_amenities,
                              function(amenity) {
                                return _c(
                                  "li",
                                  {
                                    staticClass:
                                      "d-flex align-items-center location-amenity"
                                  },
                                  [
                                    _c("i", {
                                      staticClass:
                                        "fa fa-check green margin-2x",
                                      attrs: { "aria-hidden": "true" }
                                    }),
                                    _vm._v(" "),
                                    _c("div", {}, [
                                      _vm._v(
                                        "\n                      " +
                                          _vm._s(amenity) +
                                          "\n                    "
                                      )
                                    ])
                                  ]
                                )
                              }
                            )
                          )
                        ])
                      : _vm._e()
                  ])
                ]),
                _vm._v(" "),
                _c(
                  "div",
                  {},
                  [
                    _c(
                      "h5",
                      {
                        staticClass: "regular margin-top-6x margin-bottom-3x",
                        attrs: { id: "location" }
                      },
                      [_vm._v("\n              Location\n            ")]
                    ),
                    _vm._v(" "),
                    _c(
                      "no-ssr",
                      [
                        _c("google-maps-results", {
                          staticClass: "location-map",
                          attrs: {
                            markers: _vm.markers,
                            center: _vm.center,
                            zoom: 12
                          }
                        })
                      ],
                      1
                    )
                  ],
                  1
                ),
                _vm._v(" "),
                _c("div", {}, [
                  _c(
                    "h5",
                    {
                      staticClass: "regular margin-top-6x margin-bottom-3x",
                      attrs: { id: "reviews" }
                    },
                    [_vm._v("\n              Reviews\n            ")]
                  ),
                  _vm._v(" "),
                  _vm.showTripadvisorReviews
                    ? _c(
                        "div",
                        [
                          _c("tripadvisor-reviews", {
                            attrs: { location: _vm.location }
                          })
                        ],
                        1
                      )
                    : _c("div", { staticClass: "white-box" }, [
                        _c(
                          "div",
                          { staticClass: "d-flex flex-wrap flex-md-nowrap" },
                          [
                            _c("div", { staticClass: "overall-rating" }, [
                              _c(
                                "div",
                                { staticClass: "f20 regular margin-bottom-4x" },
                                [
                                  _vm._v(
                                    "\n                    Overall Rating\n                  "
                                  )
                                ]
                              ),
                              _vm._v(" "),
                              _c("div", {
                                staticClass:
                                  "f20 text-info regular margin-bottom-2x"
                              }),
                              _vm._v(" "),
                              _c("span", { staticClass: "text-warning mr-2" }, [
                                _vm._v("Not rated yet")
                              ])
                            ]),
                            _vm._v(" "),
                            _c(
                              "div",
                              { staticClass: "location-rating-summary" },
                              [
                                _c(
                                  "div",
                                  {
                                    staticClass: "f20 regular margin-bottom-4x"
                                  },
                                  [
                                    _vm._v(
                                      "\n                    Hotel Review Summary\n                  "
                                    )
                                  ]
                                ),
                                _vm._v(" "),
                                _c(
                                  "div",
                                  { staticClass: "d-flex margin-bottom-2x" },
                                  [
                                    _c(
                                      "div",
                                      { staticClass: "review-rating-title" },
                                      [_vm._v("Suite Service")]
                                    ),
                                    _vm._v(" "),
                                    _c(
                                      "span",
                                      { staticClass: "text-warning ml-2" },
                                      [_vm._v("Not rated yet")]
                                    )
                                  ]
                                ),
                                _vm._v(" "),
                                _c(
                                  "div",
                                  { staticClass: "d-flex margin-bottom-2x" },
                                  [
                                    _c(
                                      "div",
                                      { staticClass: "review-rating-title" },
                                      [_vm._v("Room")]
                                    ),
                                    _vm._v(" "),
                                    _c(
                                      "span",
                                      { staticClass: "text-warning ml-2" },
                                      [_vm._v("Not rated yet")]
                                    )
                                  ]
                                ),
                                _vm._v(" "),
                                _c(
                                  "div",
                                  { staticClass: "d-flex margin-bottom-2x" },
                                  [
                                    _c(
                                      "div",
                                      { staticClass: "review-rating-title" },
                                      [_vm._v("Hotel Staff")]
                                    ),
                                    _vm._v(" "),
                                    _c(
                                      "span",
                                      { staticClass: "text-warning ml-2" },
                                      [_vm._v("Not rated yet")]
                                    )
                                  ]
                                ),
                                _vm._v(" "),
                                _c(
                                  "div",
                                  { staticClass: "d-flex margin-bottom-6x" },
                                  [
                                    _c(
                                      "div",
                                      { staticClass: "review-rating-title" },
                                      [_vm._v("Amenities")]
                                    ),
                                    _vm._v(" "),
                                    _c(
                                      "span",
                                      { staticClass: "text-warning ml-2" },
                                      [_vm._v("Not rated yet")]
                                    )
                                  ]
                                )
                              ]
                            )
                          ]
                        )
                      ])
                ])
              ]
            )
          ])
        ])
      ]),
      _vm._v(" "),
      this.canShowSearchModal
        ? _c("search-modal", {
            ref: "searchModal",
            staticClass: "search-modal",
            attrs: {
              "form-data": _vm.query,
              airport: _vm.airport,
              "default-active":
                _vm.location && _vm.location.number_of_rooms > 0
                  ? "hotel-parking"
                  : "parking",
              "on-close-modal": _vm.onCloseModal,
              "route-name": "location"
            }
          })
        : _vm._e(),
      _vm._v(" "),
      _vm.showSubtotalModal
        ? _c("subtotal-modal", {
            ref: "breakDownModal",
            staticClass: "subtotal-modal",
            attrs: {
              "total-days": _vm.totalDays,
              "currency-symbol": _vm.symbol,
              type: _vm.selectedType,
              "type-name": _vm.query.reservation_type,
              "from-date": _vm.query.from_date,
              "to-date": _vm.query.to_date,
              "from-time": _vm.checkin_time,
              "to-time": _vm.checkout_time,
              "location-id": _vm.location.id,
              "currency-code": _vm.currencyCode,
              "number-of-spots": _vm.query.number_of_spots,
              "number-of-rooms": _vm.query.number_of_rooms,
              "nights-to-sleep": _vm.query.nights_to_sleep,
              "guests-in-rooms": _vm.query.guests_in_rooms
            }
          })
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true
export { render, staticRenderFns }
if (module.hot) {
  module.hot.accept()
  if (module.hot.data) {
    require("vue-hot-reload-api")      .rerender("data-v-76dacd08", { render: render, staticRenderFns: staticRenderFns })
  }
}