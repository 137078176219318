var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("layout", [
    _c("div", { staticClass: "container page--container" }, [
      _vm.page && !_vm.errors
        ? _c("div", [
            _c("h1", {
              staticClass: "page--title",
              domProps: { innerHTML: _vm._s(_vm.page.title) }
            }),
            _vm._v(" "),
            _c("div", { domProps: { innerHTML: _vm._s(_vm.page.content) } })
          ])
        : _c("div", [
            _c("h1", { staticClass: "page--title text-center text-danger" }, [
              _vm._v("\n        Ops! 404 Page Not found\n      ")
            ])
          ])
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true
export { render, staticRenderFns }
if (module.hot) {
  module.hot.accept()
  if (module.hot.data) {
    require("vue-hot-reload-api")      .rerender("data-v-310269d6", { render: render, staticRenderFns: staticRenderFns })
  }
}