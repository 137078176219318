// import { http } from 'services'
import axios from 'axios'
// initial state
const state = () => ({
  title: null,
  meta: ""
})

// getters
const getters = {
}

// actions
const actions = {
  ['title.setTitle'] ({ commit }, title) {
    commit('META_SET_TITLE', title)
  },
  ['title.setMeta'] ({commit}, data = []) {
    commit('META_SET_DATA', data)
  }
}

// mutations
const mutations = {
  META_SET_TITLE (state, title) {
    state.title = title
    if (!isServer) {
      document.title = title;
    }
  }, 
  META_SET_DATA (state, data) {

    let meta_string = "";
    if (!isServer) {
      document.querySelectorAll('.rendered-meta').forEach(el => el.remove());
    }
    
    for (let item of data) {
      if (!isServer) {
        let meta = document.createElement('meta');
        for (let attr in item) {
          meta.setAttribute(attr, item[attr]);
        }
        meta.setAttribute('class', 'rendered-meta');
        document.getElementsByTagName('head')[0].appendChild(meta);
      } else {
        meta_string += "<meta class='rendered-meta' ";
        for (let attr in item) {
          meta_string += attr + "='"+ item[attr] +"' "
        }
        meta_string += " />";
      }
    }

    state.meta = meta_string
  }
}

export default {
  state,
  getters,
  actions,
  mutations
}
