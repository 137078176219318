import { http } from 'services'
// import axios from 'axios'
// initial state
const state = () => ({
  all: {},
  active: {},
  totals: [],
  breakdown: {
    rates: [],
    subtotal: 0,
    fees: 0,
    taxes: 0
  },
  errors: null,
})

// getters
const getters = {
}

// actions
const actions = {
  ['locations.get'] ({commit}, {id, params}) {
    commit('CLEAR_ERRORS');
    return (http.get(`locations/${id}`, {params})).then ((res) => {
      commit('LOCATIONS_GET', res);
    }).catch ((res) => {
      commit('LOCATIONS_ERROR', res);
    })
  },
  ['locations.getTypes'] ({commit}, {id, params}) {
    commit('CLEAR_ERRORS');
    return (http.get(`locations/${id}/types`, {params})).then ((res) => {
      commit('LOCATIONS_GET_TYPES', res);
    }).catch ((res) => {
      commit('LOCATIONS_ERROR', res);
    })
  },
  ['locations.getTripadvisorReviews'] ({commit}, id) {
    commit('CLEAR_ERRORS');
    return (http.get(`locations/${id}/tripadvisor-reviews`)).then ((res) => {
      commit('LOCATIONS_GET_TRIPADVISOR_REVIEWS', res);
    }).catch ((res) => {
      commit('LOCATIONS_ERROR', res);
    })
  },
  ['locations.getMinGrandTotal'] ({commit}, {id, data, currencyCode}) {
    commit('CLEAR_ERRORS');
    if (currencyCode) data.currency_code = currencyCode;
    return (http.post(`locations/${id}/min-price`, data)).then ((res) => {
      commit('LOCATIONS_GET_MIN_TOTAL', {res, id});
    }).catch ((res) => {
      commit('LOCATIONS_GET_MIN_TOTAL_ERROR', {res: res.response, id});
    })
  },

  ['locations.getRatesBreakdown'] ({commit}, {id, data}) {
    commit('CLEAR_ERRORS');
    return (http.post(`locations/${id}/rates-breakdown`, data)).then ((res) => {
      commit('LOCATIONS_GET_BREAKDOWN', {res, id});
    }).catch ((res) => {
      commit('LOCATIONS_ERROR',res);
    })
  },
}

// mutations
const mutations = {
  LOCATIONS_GET (state, res) {
    if (state.active.tripadvisor_reviews && res.data.tripadvisor_id == state.active.tripadvisor_reviews.location_id) {
      res.data.tripadvisor_reviews = state.active.tripadvisor_reviews;
    }

    state.active = Object.assign({}, res.data);
  },

  LOCATIONS_GET_TYPES (state, res) {
    state.active = Object.assign({}, state.active, {types: res.data});
  },

  LOCATIONS_GET_MIN_TOTAL (state, {id, res}) {
    let index = _.findIndex(state.totals, (o) => o.id == id);
    res.data.sold_out = false
    if (index > -1) {
      state.totals[index] = Object.assign(state.totals[index], res.data, {error: null})
    } else {
      state.totals.push(Object.assign({id}, res.data));
    }
  },

  LOCATIONS_GET_MIN_TOTAL_ERROR (state, {id, res}) {
    let index = _.findIndex(state.totals, (o) => o.id == id);
    if (index > -1) {
      state.totals[index] = Object.assign(state.totals[index], {sold_out: true, error: res.data.message, errors: res.data.errors})
    } else {
      state.totals.push(Object.assign({id}, {sold_out: true, error: res.data.message, errors: res.data.errors}));
    }
  },

  LOCATIONS_GET_TRIPADVISOR_REVIEWS (state, res) {
    state.active = Object.assign({}, state.active, {tripadvisor_reviews: res.data});
  },

  LOCATIONS_GET_BREAKDOWN (state, res) {
    state.breakdown = _.cloneDeep(res.res.data);
  },

  //ERRORS
  LOCATIONS_ERROR (state, err) {
    state.errors = err.response.data;
  },

  CLEAR_ERRORS (state) {
    state.errors = null
  }
}

export default {
  state,
  getters,
  actions,
  mutations
}
