import { currencySymbol } from 'filters'


const watch = {
  formData (n) {
    this.form = _.assign(this.form, n);
  },
  ['formData.trip_protection_id'] (n) {
    this.selected_plan = n;
    // this.$emit('selectTripProtection', n);
  },
  formErrors (n) {
    this.errors = n;
  },
}

const computed = {
  plans () {
    let type = this.form.reservation_type == 'both' ? 'parking_and_room' : this.form.reservation_type;
    let selected_currency = ""

    if (!_.isEmpty(this.location) && _.isEmpty(this.currencyCode) ) {
      selected_currency = this.location.currency_code.toLowerCase();
    } else if (!_.isEmpty(this.location) && !_.isEmpty(this.currencyCode) ) {
      selected_currency = this.currencyCode.toLowerCase();
    }

    if (_.isEmpty(this.$store.state.tripProtections.all)) return [];

    console.log('this.$store.state.tripProtections[type]', type,this.$store.state.tripProtections[type]);

    let x = _.cloneDeep(this.$store.state.tripProtections[type] || []).map(item => {
      let price;
      if (!_.isEmpty(this.location)) {
        try {
          price = item.trip_protection_prices.find(
            x => x.currency.code.toLowerCase() == selected_currency
          ).price;
        } catch(e) {
          // statements
          price = null;
        }

      }
      return {
        id: item.id,
        name: item.name,
        description: item.description,
        price
      }
    });
    console.log('x', x);
    return x;
  },

  symbol () {
    if (this.location && _.isEmpty(this.currencyCode)){
      return currencySymbol(this.location.currency_code)
    } else if (!_.isEmpty(this.currencyCode)) {
      return currencySymbol(this.currencyCode)
    }

    return "$"
  },

  totalGuestsNumber () {
    return _.sum(this.form.guests_in_rooms);
  }
}

const methods = {
  goToStep (step, ignoreValidation) {
    if (this.validate() || ignoreValidation) {
      this.$router.replace({
        query: Object.assign({}, this.$route.query, { step }),
        // No need to specify route name and params, they will be preserved
      });
      this.$emit('changeStep', step);
    }
  },

  validate () {
    return true;
  },

  selectPlan (plan) {
    try {
      this.selected_plan = null;
        if (plan.id) {
          this.selected_plan = plan.id;
        }
    } catch(e) {
      this.selected_plan = null;
    }

    this.$emit('selectTripProtection', plan.id);
    this.$emit('formChanged', _.assign(this.form, {trip_protection_id: this.selected_plan}));
  }
}

export default {
  name: 'checkout-addons-step',
  props: ['formData', 'formErrors', 'location', 'airport', 'currencyCode', 'numberOfSpots'],
  watch,
  methods,
  computed,
  data () {
    return {
      errors: this.formErrors || {},
      form: this.formData,
      selected_plan: this.formData.trip_protection_id || null,
      free_plan: {
        price: 0,
        title: 'Free Plan',
        description: 'No, thanks'
      }
    }
  },
  created () {
    if (this.form.reservation_type != 'parking') {
      this.goToStep('payment');
    }
  },
  mounted () {
    this.$nextTick(() => {
      $('html, body').animate({
        scrollTop: $(".checkout-step-label.active").offset().top
      }, 500);
    });
  }
}
