import moment from 'moment'

const computed = {
  sortedCancellationPolicies () {
    let data = this.cancellationPolicies;
    let reservation_type = this.reservationType != 'parking' ? 'room' : 'parking';
    data =  _.filter(data, (item, index) => {
      return item.type == reservation_type;
    });
    return _.orderBy(data, ['number_of_days'], ['desc']);
  }
}
const methods =  {
  calculateCancellationPeriod(currentValue, previousValue) {
    let str = "";
    if (previousValue) {
      if ((previousValue.number_of_days - 1) - currentValue.number_of_days > 0) {
        str = currentValue.number_of_days + "-" + (previousValue.number_of_days - 1)
      } else {
        str = +currentValue.number_of_days;
      }
    }

    return str;
  },
  peroidDiff(currentValue, previousValue) {
    let result = this.calculateCancellationPeriod(currentValue, previousValue) ;
    if (typeof(result) == "string") {
      return 'days';
    }
    return result > 1 ? 'days' : 'day';
  }
}
export default {
  name: "cancellation-policy",
  computed,
  methods,
  props: ['cancellationPolicies', 'reservationType', 'reservationFromDate'],
  data () {
    return {
      moment,
      database_date_format: "YYYY-MM-DD HH:mm:ss",
      client_date_format: "DD/MMM/YYYY",
    }
  }
}
