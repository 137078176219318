import { http } from 'services'
// import axios from 'axios'
// initial state
const state = () => ({
  all: {},
  errors: null
})

// getters
const getters = {
}

// actions
const actions = {
  ['carousels.getAll'] ({ commit }, id) {
    commit('CLEAR_ERRORS')
    return (http.get('carousels')).then((res) => {
      commit('CAROUSELS_GET_ALL', res);
    });
  },
}

// mutations
const mutations = {
  CAROUSELS_GET_ALL (state, res) {
      if (res.data) {
        state.all = res.data.data;
        console.log('state.search', state);
      }
      
  },

  //ERRORS
  CAROUSELS_ERROR (state, err) {
    state.errors = err.response.data;
  },

  CLEAR_ERRORS (state) {
    state.errors = null
  }
}

export default {
  state,
  getters,
  actions,
  mutations
}
